import styled from "styled-components";
import {
  spacing,
  color,
  fontSize,
  elementSize,
  deviceSize,
  fontWeight,
} from "src/styles/variables";
import {
  Avatar,
  Card,
  Input,
  InputNumber,
  Modal,
  Select,
  Typography,
} from "antd";
const { TextArea } = Input;

export const CardMetaContainer = styled(Card.Meta)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .ant-card-meta-avatar {
    padding-inline-end: 0px !important;
    padding-bottom: ${spacing.xs};
  }

  .ant-card-meta-description::first-letter {
    text-transform: capitalize;
  }
`;

export const UserProfileAvatar = styled(Avatar)`
  width: ${elementSize.xxxxxl};
  line-height: 4.8rem;
  font-size: ${fontSize.displayXXL};
  height: ${elementSize.xxxxxl};
  &&.ant-avatar .ant-avatar-string {
    font-size: ${fontSize.displayXXXL};
  }
`;

export const SpaceBetweenDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

export const StyledInput = styled(Input)`
  &&.ant-input:focus,
  &&.ant-input:hover,
  &&:where(.css-dev-only-do-not-override-pr0fja).ant-input-affix-wrapper:not(
      .ant-input-affix-wrapper-disabled
    ):hover,
  &&.ant-input-affix-wrapper:hover,
  &&:where(.css-dev-only-do-not-override-pr0fja).ant-input-affix-wrapper:focus,
  &&:where(
      .css-dev-only-do-not-override-pr0fja
    ).ant-input-affix-wrapper-focused {
    border-color: ${color.olive}!important;
  }
  &.ant-input {
    border-color: ${(props) => (props.error ? color.red : "")} !important;
  }
`;

export const StyledInputGroup = styled(Input.Group)`
  /* Apply the styles to the input within the group, checking for error prop */
  .ant-input:first-child {
    border-color: ${(props) => (props.error ? color.red : "")} !important;
    &:hover,
    &:focus {
      border-color: ${(props) =>
        props.error ? color.red : color.olive} !important;
    }
  }

  /* Additional styles for the addon */
  .ant-input-group-addon {
    background-color: transparent; /* Or any other styles you want */
    border-color: none;
  }

  /* More styles can be added as necessary */
`;

export const StyledInputNumber = styled(InputNumber)`
  &&.ant-input-number:hover,
  &&.ant-input-number:focus,
  &&.ant-input-number:active,
  &&.ant-input-number-focused {
    border-color: ${color.olive}!important;
  }
  &&.ant-input-number .ant-input-number-handler-up-inner svg,
  &&.ant-input-number .ant-input-number-handler-down-inner svg {
    fill: ${color.olive}!important;
  }

  &&.ant-input-number {
    border: ${(props) =>
      props.error ? `1px solid ${color.red}` : ""} !important;
  }
`;

export const StyledSelectInTable = styled(Select)`
  &&:where(.css-dev-only-do-not-override-pr0fja).ant-select:not(
      .ant-select-disabled
    ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector,
  &&.ant-select-focused:where(
      .css-dev-only-do-not-override-pr0fja
    ).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ) {
    border-color: ${color.olive}!important;
    box-shadow: none !important;
    background-color: inherit !important;
  }

  &&:where(.css-dev-only-do-not-override-pr0fja).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background-color: inherit !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
`;
export const StyledTextArea = styled(TextArea)`
  transition: width 0.2s ease-in-out; // Smooth transition for width change
  &&.ant-input:hover,
  &&.ant-input:focus {
    border-color: ${color.olive}!important;
  }
  border-color: ${(props) => (props.error ? color.red : "")} !important;
`;

export const StyledTextAreaInTable = styled(TextArea)`
  transition: width 0.2s ease-in-out; // Smooth transition for width change
  &&.ant-input:hover,
  &&.ant-input:focus {
    border-color: ${color.olive}!important;
  }
  background-color: transparent;
  border-color: ${(props) =>
    props.error ? color.red : "transparent"} !important;

  &&:where(.css-dev-only-do-not-override-pr0fja).ant-input-disabled,
  :where(.css-dev-only-do-not-override-pr0fja).ant-input[disabled] {
    background-color: transparent;
  }
  @media (max-width: ${deviceSize.large_phone}) {
    width: 10rem;
  }
`;

export const CustomizedEvaluationModal = styled(Modal)`
  &.ant-modal {
    width: 90% !important; /* Default width for small screens */

    @media (min-width: ${deviceSize.large_phone}) {
      /* Adjust breakpoint as needed */
      width: ${(props) =>
        props.width
          ? props.width
          : "60vw"} !important; /* Width for large screens */
    }
  }
`;

export const BorderlessSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .ant-select-selection-item {
    font-weight: normal;
    color: ${(props) => (props.color ? color.orange : color.black)} !important;
    padding-inline-end: 0px !important;
  }
  .ant-select-arrow {
    display: ${(props) => (props.showarrow ? "block" : "none")} !important;
  }
`;

export const CustomOption = styled.div`
  .role-title {
    font-weight: ${fontWeight.bold};
  }

  .role-description {
    font-size: ${fontSize.body};
    color: ${color.grayDark};
    white-space: normal;
  }
`;

export const ResponsiveDescription = styled.span`
  display: none;

  @media (min-width: ${deviceSize.large_phone}) {
    display: block;
  }
`;

export const OrganizationSettingCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  maxwidth: "800px";
`;
export const ProfileContainer = styled.div`
  margin: 70px;
  min-height: 600px;
  @media (max-width: ${deviceSize.large_phone}) {
    margin: 20px;
  }
`;

export const IntgerationDescription = styled(Typography.Text)`
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
