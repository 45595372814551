import React from "react";
import { Navigate } from "react-router-dom";
import { useUserState } from "./state/UserState";
import { ACCESS_TYPE } from "./utils/enums";

export const PublicOnlyRoute = ({ children }) => {
  const { user } = useUserState();

  if (user) {
    // Redirect to profile if the user is part of an organization, otherwise redirect home
    return <Navigate to={user.organization ? "/profile" : "/"} replace />;
  }

  return children;
};

export const OrganizationRoute = ({ children }) => {
  const { user } = useUserState();

  return user && user.organization ? children : <Navigate to="/" replace />;
};

export const AdminRoute = ({ children }) => {
  const { user } = useUserState();
  const hasAccessToEdit =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;

  return user && hasAccessToEdit ? children : <Navigate to="/" replace />;
};
export const TrucoAdminRoute = ({ children }) => {
  const { user } = useUserState();
  const hasAccessToEdit = user.accessType === ACCESS_TYPE.trucoAdmin;

  return user && hasAccessToEdit ? children : <Navigate to="/" replace />;
};
