import React, { useState } from "react";
import {
  CalculatorCard,
  CostCalculatorContainer,
  SectionContainer,
} from "./LandingPage.styles";
import { StandardLandingPage } from "./LandingPage";
import {
  AnimatedParagraph,
  AnimatedTitle,
  BlogContainer,
} from "./BlogPage/BlogPage.styles";
import { color, elementSize, fontSize } from "src/styles/variables";

import { TRUCO_CAL_LINK } from "src/utils/variables";
import { GetDemoButton } from "./ProductDemo/ProductDemo.styles";
import { InputNumber, Slider, Typography, Badge, Row, Col } from "antd";

const CostCalculator = () => {
  const [tickets, setTickets] = useState(5000);
  const [qaTime, setQATime] = useState(20);
  const [qaPercentage, setQaPercentage] = useState<number>(10);

  const [hourlyWage, setHourlyWage] = useState<number>(15);

  const numQATickets = (tickets * qaPercentage) / 100;
  const numAgentHours = (numQATickets * qaTime) / 60;
  const numTicketHours = (numQATickets * qaTime * 0.5) / 60;

  const totalCostQA = numAgentHours * hourlyWage;
  const trucoPrice = numTicketHours * 3;

  const trucoPriceHundred = ((tickets * qaTime * 0.5) / 60) * 3;

  const dataComparisons = [
    {
      title: "Current Operations",
      count: numQATickets,
      percentage: qaPercentage,
      cost: totalCostQA,
      badge: null,
    },
    {
      title: "Truco Initiative",
      count: numQATickets,
      percentage: qaPercentage,
      cost: trucoPrice,
      badge: "High Value",
    },
    {
      title: "Truco Full Coverage",
      count: tickets,
      percentage: 100,
      cost: trucoPriceHundred,
      badge: "High Coverage",
    },
  ];

  return (
    <CostCalculatorContainer>
      <Row gutter={[48, 32]}>
        <Col xl={6} xs={24}>
          <Typography.Title level={4}>Total Volume of Tickets</Typography.Title>
          <Slider
            min={0}
            max={50000}
            step={1000}
            value={tickets}
            onChange={setTickets}
          />
          <Typography.Title level={4} style={{ paddingTop: elementSize.sm }}>
            Percentage of Tickets QAed
          </Typography.Title>
          <Slider
            min={1}
            max={100}
            step={1}
            value={qaPercentage}
            onChange={setQaPercentage}
          />

          <Typography.Title level={4} style={{ paddingTop: elementSize.sm }}>
            Time per ticket to QA (mins)
          </Typography.Title>
          <Slider
            min={1}
            max={300}
            step={1}
            value={qaTime}
            onChange={setQATime}
          />
          <Typography.Title level={4} style={{ paddingTop: elementSize.sm }}>
            Hourly wage of QA Agent ($)
          </Typography.Title>
          <InputNumber
            min={0}
            step={1}
            value={hourlyWage}
            onChange={(value: number) => setHourlyWage(value)}
            style={{ width: "20%" }}
          />
        </Col>
        {dataComparisons.map((item) => {
          const itemComponent = (
            <CalculatorCard fill={item.percentage} key={item.title}>
              <Typography.Title level={2} style={{ color: color.white }}>
                {item.title}
              </Typography.Title>
              <Typography.Title level={3} style={{ color: color.white }}>
                ${item.cost.toFixed(0)}
              </Typography.Title>
              <Typography.Paragraph style={{ color: color.white }}>
                Cost of service
              </Typography.Paragraph>
              <Typography.Paragraph style={{ color: color.white }}>
                QA Percentage: {item.percentage}%
              </Typography.Paragraph>
              <Typography.Paragraph style={{ color: color.white }}>
                Tickets QAed: {item.count}
              </Typography.Paragraph>
            </CalculatorCard>
          );

          return item.badge ? (
            <Col xl={6} xs={24}>
              <Badge.Ribbon text={item.badge} color={color.orange}>
                {itemComponent}
              </Badge.Ribbon>
            </Col>
          ) : (
            <Col xl={6} xs={24}>
              <div>{itemComponent}</div>
            </Col>
          );
        })}
      </Row>
    </CostCalculatorContainer>
  );
};

export const CostCalculatorPage = () => {
  const metadata = {
    title: "Truco ROI Calculator",
    keywords:
      "truco, pricing, calculator, ROI, customer support, quality assurance, sentiment analysis, insights, ai driven, summaries, transcripts, support, integrations,cost",
    thumbnail_paragraph: "Truco's ROI Calculator",
  };
  return (
    <StandardLandingPage
      headercolor={color.olive}
      content={
        <>
          <BlogContainer>
            <AnimatedTitle>ROI Calculator</AnimatedTitle>
            <AnimatedParagraph
              style={{
                fontSize: fontSize.displayXXL,
                textAlign: "center",
                maxWidth: "800px",
              }}
              center
            >
              Not sure if it's the right time to invest in a QA solution? Our
              ROI calculator below can help you assess the return on your
              investment. For a custom assessment tailored to your business
              needs, talk to us today.
            </AnimatedParagraph>
            <GetDemoButton
              onClick={() => (window.location.href = TRUCO_CAL_LINK)}
            >
              Get A Demo{" "}
            </GetDemoButton>
          </BlogContainer>
          <SectionContainer>
            <CostCalculator />
          </SectionContainer>
        </>
      }
      metadata={metadata}
    />
  );
};
