import styled from "styled-components";
import { deviceSize } from "./variables";

export const DivLimitText = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DeskTopOnly = styled.div`
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;
export const MobileOnly = styled.div`
  @media (min-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;
