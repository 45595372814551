import React, { useEffect, useRef, useState } from "react";
import { InputNumber, Switch, Tooltip } from "antd";
import dayjs from "dayjs";
import {
  elementSize,
  fontSize,
  fontWeight,
  spacing,
} from "src/styles/variables";
import {
  ArrowLeft,
  ArrowRight,
  ScrollableContainer,
  SettingCard,
  SettingsContainer,
  StyledTimePicker,
  TimeDisplay,
} from "./EvaluationSettings.styles";
import { TooltipIcon } from "src/components";
import { formatTime, unformatTime } from "src/utils/utils";
import { EVALUATION_SETTINGS } from "src/utils/enums";

const SettingsSection = ({
  currentSelectedEvaluation,
  handleChange,
  setCurrentSelectedEvaluation,
  disabled,
}) => {
  const format = "mm:ss";
  const defaultHoldTime = dayjs("01:00", format);
  const defaultLongestMonologue = dayjs("02:30", format);
  const defaultHandleTime = dayjs("30:00", format);

  const {
    holdTime: currentEvaluationholdTime,
    handleTime: currentEvaluationhandleTime,
    sentiment: currentEvaluationSentiment,
    talkRatio: currentEvaluationTalkRatio,
    longestMonologue: currentEvaluationLongestMonologue,
    interactivity: currentEvaluationInteractivity,
  } = currentSelectedEvaluation || {};

  const [holdTime, setHoldTime] = useState(
    currentEvaluationholdTime
      ? dayjs(formatTime(currentEvaluationholdTime), format)
      : defaultHoldTime,
  );
  const [handleTime, setHandleTime] = useState(
    currentEvaluationhandleTime
      ? dayjs(formatTime(currentEvaluationhandleTime), format)
      : defaultHandleTime,
  );

  const [talkRatio, setTalkRatio] = useState(
    currentEvaluationTalkRatio ? currentEvaluationTalkRatio : 43,
  );

  const [longestMonologue, setLongestMonologue] = useState(
    currentEvaluationLongestMonologue
      ? dayjs(formatTime(currentEvaluationLongestMonologue), format)
      : defaultLongestMonologue,
  );
  const [interactivity, setInteractivity] = useState(
    currentEvaluationInteractivity ? currentEvaluationInteractivity : 5,
  );

  const handleChangeValueLogic = (
    e,
    currentEvaluationTime,
    updatedTime,
    defaultTime,
    setTime,
    settingsName: EVALUATION_SETTINGS,
  ) => {
    if (e) {
      let finalTime = updatedTime;
      if (!updatedTime && !currentEvaluationTime) {
        setTime(defaultTime);
        finalTime = defaultTime;
      }
      const ValuesToFormatTimeFor = [
        EVALUATION_SETTINGS.HANDLE_TIME,
        EVALUATION_SETTINGS.HOLD_TIME,
        EVALUATION_SETTINGS.LONGEST_MONOLOGUE,
      ];
      handleChange(
        settingsName,
        ValuesToFormatTimeFor.includes(settingsName)
          ? unformatTime(finalTime.format(format))
          : finalTime,
        setCurrentSelectedEvaluation,
      );
    } else {
      handleChange(settingsName, null, setCurrentSelectedEvaluation);
    }
  };

  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -600, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 600, behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleScroll(); // Initial check
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      {showLeftArrow && <ArrowLeft onClick={scrollLeft} />}
      <ScrollableContainer ref={scrollRef} onScroll={handleScroll}>
        <SettingsContainer>
          <SettingCard>
            <div>
              Hold Time
              <Tooltip title={`Maximum hold time before rating drops to 0.`}>
                <TooltipIcon />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: elementSize.xs,
                justifyContent: "space-between",
              }}
            >
              <Switch
                checked={!!currentEvaluationholdTime}
                onChange={(checked) =>
                  handleChangeValueLogic(
                    checked,
                    currentEvaluationholdTime,
                    holdTime,
                    defaultHoldTime,
                    setHoldTime,
                    EVALUATION_SETTINGS.HOLD_TIME,
                  )
                }
                disabled={disabled}
              />
              <TimeDisplay visible={!!currentEvaluationholdTime}>
                <div
                  style={{
                    fontSize: fontSize.body,
                    marginLeft: spacing.xs,
                  }}
                >
                  {disabled
                    ? `${holdTime.format("mm [min] ss [sec]")}`
                    : `mm : ss`}
                </div>
                {!disabled && (
                  <StyledTimePicker
                    showNow={false}
                    defaultValue={holdTime}
                    onChange={(value) => {
                      setHoldTime(value);
                      value &&
                        handleChange(
                          `${EVALUATION_SETTINGS.HOLD_TIME}`,
                          unformatTime(value.format(format)),
                          setCurrentSelectedEvaluation,
                        );
                    }}
                    format={format}
                    allowClear={false}
                    suffixIcon={null} // Removes the default clock icon
                  />
                )}
              </TimeDisplay>
            </div>
          </SettingCard>

          <SettingCard>
            <div>
              Handle Time
              <Tooltip
                title={`Maximum call duration before rating drops to 0.`}
              >
                <TooltipIcon />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: elementSize.xs,
                justifyContent: "space-between",
              }}
            >
              <Switch
                checked={!!currentEvaluationhandleTime}
                onChange={(checked) =>
                  handleChangeValueLogic(
                    checked,
                    currentEvaluationhandleTime,
                    handleTime,
                    defaultHandleTime,
                    setHandleTime,
                    EVALUATION_SETTINGS.HANDLE_TIME,
                  )
                }
                disabled={disabled}
              />
              <TimeDisplay visible={!!currentEvaluationhandleTime}>
                <div
                  style={{
                    fontSize: fontSize.body,
                    marginLeft: spacing.xs,
                  }}
                >
                  {disabled
                    ? `${handleTime.format("mm [min] ss [sec]")}`
                    : `mm : ss`}{" "}
                </div>
                {!disabled && (
                  <StyledTimePicker
                    showNow={false}
                    defaultValue={handleTime}
                    format={format}
                    onChange={(value) => {
                      setHandleTime(value);
                      value &&
                        handleChange(
                          `${EVALUATION_SETTINGS.HANDLE_TIME}`,
                          unformatTime(value.format(format)),
                          setCurrentSelectedEvaluation,
                        );
                    }}
                    allowClear={false}
                    suffixIcon={null} // Removes the default clock icon
                  />
                )}
              </TimeDisplay>
            </div>
          </SettingCard>

          <SettingCard>
            <div>
              Sentiments
              <Tooltip
                title={`Evaluate conversations based on sentiment analysis`}
              >
                <TooltipIcon />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: elementSize.xs,
                justifyContent: "space-between",
              }}
            >
              <Switch
                checked={!!currentEvaluationSentiment}
                onChange={(checked) =>
                  handleChange(
                    `${EVALUATION_SETTINGS.SENTIMENT}`,
                    checked,
                    setCurrentSelectedEvaluation,
                  )
                }
                disabled={disabled}
              />
            </div>
          </SettingCard>

          {/*  New Cards */}
          <SettingCard>
            <div>
              Talk Ratio
              <Tooltip
                title={`Evaluate conversations based on talk ratio (recommended: 43% rep, 57% client)`}
              >
                <TooltipIcon />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: elementSize.xs,
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <Switch
                checked={!!currentEvaluationTalkRatio}
                onChange={(checked) =>
                  handleChangeValueLogic(
                    checked,
                    currentEvaluationTalkRatio,
                    talkRatio,
                    43,
                    setTalkRatio,
                    EVALUATION_SETTINGS.TALK_RATIO,
                  )
                }
                disabled={disabled}
              />
              <TimeDisplay visible={!!currentEvaluationTalkRatio}>
                <Tooltip
                  title={`Representitive talk is ${talkRatio}%. Customer talk is ${
                    100 - talkRatio
                  }%`}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!disabled ? (
                      <InputNumber
                        min={0}
                        style={{ width: "60%" }}
                        max={100}
                        defaultValue={talkRatio}
                        disabled={disabled}
                        onChange={(value) => {
                          setTalkRatio(value);
                          value &&
                            handleChange(
                              `${EVALUATION_SETTINGS.TALK_RATIO}`,
                              value,
                              setCurrentSelectedEvaluation,
                            );
                        }}
                      />
                    ) : (
                      <div>{talkRatio}</div>
                    )}
                    <span style={{ margin: "0 5px" }}>:</span>
                    <div>{100 - talkRatio}</div>
                  </div>
                </Tooltip>
              </TimeDisplay>
            </div>
          </SettingCard>

          <SettingCard>
            <div>
              Longest Monologue
              <Tooltip
                title={`Evaluate longest monologue duration (recommended: ≤2.30 minutes)`}
              >
                <TooltipIcon />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: elementSize.xs,
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <Switch
                checked={!!currentEvaluationLongestMonologue}
                onChange={(checked) =>
                  handleChangeValueLogic(
                    checked,
                    currentEvaluationLongestMonologue,
                    longestMonologue,
                    defaultLongestMonologue,
                    setLongestMonologue,
                    EVALUATION_SETTINGS.LONGEST_MONOLOGUE,
                  )
                }
                disabled={disabled}
              />
              <TimeDisplay visible={!!currentEvaluationLongestMonologue}>
                <div
                  style={{
                    fontSize: fontSize.body,
                    marginLeft: spacing.xs,
                  }}
                >
                  {disabled
                    ? `${longestMonologue.format("mm [min] ss [sec]")}`
                    : `mm : ss`}
                </div>
                {!disabled && (
                  <StyledTimePicker
                    showNow={false}
                    defaultValue={longestMonologue}
                    onChange={(value) => {
                      setLongestMonologue(value);
                      value &&
                        handleChange(
                          `${EVALUATION_SETTINGS.LONGEST_MONOLOGUE}`,
                          unformatTime(value.format(format)),
                          setCurrentSelectedEvaluation,
                        );
                    }}
                    format={format}
                    allowClear={false}
                    suffixIcon={null} // Removes the default clock icon
                  />
                )}
              </TimeDisplay>
            </div>
          </SettingCard>

          <SettingCard>
            <div>
              Interactivity
              <Tooltip
                title={`Evaluate conversation interactivity (recommended: ≥5 meaningful exchanges).`}
              >
                <TooltipIcon />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginBottom: elementSize.xs,
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <Switch
                checked={!!currentEvaluationInteractivity}
                onChange={(checked) =>
                  handleChangeValueLogic(
                    checked,
                    currentEvaluationInteractivity,
                    interactivity,
                    5,
                    setInteractivity,
                    EVALUATION_SETTINGS.INTERACTIVITY,
                  )
                }
                disabled={disabled}
              />
              <TimeDisplay visible={!!currentEvaluationInteractivity}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!disabled ? (
                    <InputNumber
                      min={0}
                      style={{ width: "60%" }}
                      max={100}
                      defaultValue={interactivity}
                      onChange={(value) => {
                        setInteractivity(value);
                        value &&
                          handleChange(
                            `${EVALUATION_SETTINGS.INTERACTIVITY}`,
                            value,
                            setCurrentSelectedEvaluation,
                          );
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: elementSize.sm,
                        fontWeight: fontWeight.semiBold,
                      }}
                    >
                      {interactivity}
                    </div>
                  )}
                </div>
              </TimeDisplay>
            </div>
          </SettingCard>
        </SettingsContainer>
      </ScrollableContainer>
      {showRightArrow && <ArrowRight onClick={scrollRight} />}
    </div>
  );
};

export default SettingsSection;
