import {
  and,
  average,
  collection,
  count,
  doc,
  getAggregateFromServer,
  getDocs,
  or,
  orderBy,
  query,
  sum,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "src/firebaseAuth";
import { formatTime } from "./utils";
import { notDeletedConversations } from "./variables";
import { EvaluationFormData, InvitedUser, QueryConditions } from "./types";
import { ACCESS_TYPE, COLLECTION_DATA } from "./enums";

export const getAccountStats = async (
  organization,
  user,
  setUserStatistics,
  transcriptsConditions = [],
) => {
  let agentQueryConditions: QueryConditions = null;
  if (user?.accessType === ACCESS_TYPE.agent) {
    agentQueryConditions = {
      comparisonField: "metadata.agent",
      comparisonOperator: "==",
      value: user?.email,
    };
  } else {
    agentQueryConditions = {
      comparisonField: "metadata.user",
      comparisonOperator: "==",
      value: user?.email,
    };
  }
  const collectionRef = collection(
    db,
    "/organization/" + organization + "/conversations",
  );
  let q;
  const queryConditions = [
    ...transcriptsConditions,
    notDeletedConversations,
    ...(agentQueryConditions ? [agentQueryConditions] : []),
  ];

  let queryConstraints = [];
  queryConditions.forEach((queryCondition) => {
    if (queryCondition.comparisonField) {
      if (queryCondition.value !== undefined) {
        queryConstraints.push(
          where(
            queryCondition.comparisonField,
            queryCondition.comparisonOperator,
            queryCondition.value,
          ),
        );
      }
      if (queryCondition.order) {
        queryConstraints.push(
          orderBy(queryCondition.comparisonField, queryCondition.order),
        );
      }
    }
  });
  q = query(collectionRef, ...queryConstraints);
  try {
    const snapshot = await getAggregateFromServer(q, {
      totalDuration: sum("transcript_data.metadata.transcriber.duration"),
      avgDuration: average("transcript_data.metadata.transcriber.duration"),
      totalTranscripts: count(),
    });
    const userStats = [
      {
        title: "Total Transcripts",
        value: snapshot.data().totalTranscripts,
      },
      {
        title: "Average Duration",
        value: formatTime(snapshot.data().avgDuration) || "N/A",
      },
      {
        title: "Total Duration",
        value: formatTime(snapshot.data().totalDuration) || "N/A",
      },
    ];
    setUserStatistics && setUserStatistics(userStats);
  } catch (error) {
    console.error("Failed to fetch account stats:", error);
  }
};
export const getOrgStats = async (
  organization,
  setOrgStatistics,
  transcriptsConditions = [],
) => {
  const collectionRef = collection(
    db,
    "/organization/" + organization + "/conversations",
  );
  let q;
  const queryConditions = [...transcriptsConditions];

  let queryConstraints = [];
  queryConditions.forEach((queryCondition) => {
    if (queryCondition.comparisonField) {
      if (queryCondition.value !== undefined) {
        queryConstraints.push(
          where(
            queryCondition.comparisonField,
            queryCondition.comparisonOperator,
            queryCondition.value,
          ),
        );
      }
      if (queryCondition.order) {
        queryConstraints.push(
          orderBy(queryCondition.comparisonField, queryCondition.order),
        );
      }
    }
  });
  q = query(collectionRef, ...queryConstraints);
  try {
    const snapshot = await getAggregateFromServer(q, {
      totalDuration: sum("transcript_data.metadata.transcriber.duration"),
      avgDuration: average("transcript_data.metadata.transcriber.duration"),
      totalTranscripts: count(),
    });

    setOrgStatistics &&
      setOrgStatistics(formatTime(snapshot.data().totalDuration) || "N/A");
  } catch (error) {
    console.error("Failed to fetch account stats:", error);
  }
};

export const checkUserAndInvitations = async (
  email: string,
  organization: string,
) => {
  const usersQuery = query(
    collection(db, "users"),
    where("email", "==", email),
  );
  const invitationsQuery = query(
    collection(db, "invitations"),
    where("email", "==", email),
    where("pendingInvitation", "==", true),
  );
  // Run both queries in parallel
  const [userSnapshot, invitationSnapshot] = await Promise.all([
    getDocs(usersQuery),
    getDocs(invitationsQuery),
  ]);
  if (!userSnapshot.empty && userSnapshot.docs[0].data().organization) {
    return userSnapshot.docs[0].data().organization === organization
      ? "This user already exists in your organization"
      : "This user is assigned to another organization";
  }
  if (!invitationSnapshot.empty) {
    return invitationSnapshot.docs[0].data().organization === organization
      ? "This user already exists in your organization"
      : "This user is assigned to another organization";
  }
  return;
};

export const getUserInvitationIfWithOutCode = async (email: string) => {
  const invitationsQuery = query(
    collection(db, "invitations"),
    where("email", "==", email),
    where("pendingInvitation", "==", true),
  );
  const invitationSnapshot = await getDocs(invitationsQuery);
  if (!invitationSnapshot.empty) {
    const doc = invitationSnapshot.docs[0];
    return {
      invitationCode: doc.id,
      invitationData: doc.data() as InvitedUser,
    };
  }

  return null;
};

export const fetchEvaluationHistory = async (
  organization: string,
  evalId: string,
  setEvaluationForms,
) => {
  const historyRef = collection(
    db,
    `organization/${organization}/${COLLECTION_DATA.EVALUATION_FORMS}/${evalId}/${COLLECTION_DATA.HISTORY}`,
  );
  try {
    const historySnapshot = await getDocs(historyRef);
    const history = historySnapshot.docs.map((doc) => ({
      // id: doc.id,
      ...(doc.data() as EvaluationFormData),
    }));

    setEvaluationForms((prev) => ({
      ...prev,
      [evalId]: {
        ...prev[evalId],
        history,
      },
    }));

    return history;
  } catch (error) {
    console.error("Failed to fetch history:", error);
  }
};

export async function migrateEvaluationForms(organization) {
  const oldCollectionPath = `/organization/${organization}/${COLLECTION_DATA.EVALUATION_FORMS}`;
  const oldCollectionRef = collection(db, oldCollectionPath);

  try {
    const snapshot = await getDocs(oldCollectionRef);
    const batch = writeBatch(db);

    snapshot.docs.forEach((snapshotDoc) => {
      // Renamed `doc` to `snapshotDoc` to avoid confusion
      const oldData = snapshotDoc.data();
      const newData = {
        ...oldData,
        version: 1,
        timestamp: new Date().toISOString(), // Current timestamp
      };

      const newDocRef = doc(oldCollectionRef, snapshotDoc.id);
      batch.set(newDocRef, newData);

      // Add the history subcollection with the initial version
      const historyRef = doc(newDocRef, "history", "1");
      batch.set(historyRef, newData);
    });

    // Commit the batch
    await batch.commit();
    console.log("Migration completed successfully");
  } catch (error) {
    console.error("Error during migration:", error);
  }
}

export const updateOrdersWithTimestamp = async (organization) => {
  const customersCollectionPath = `/organization/${organization}/customers`;
  const customersCollectionRef = collection(db, customersCollectionPath);

  try {
    // Fetch all documents in the collection
    const querySnapshot = await getDocs(customersCollectionRef);

    // Create a batch for updating documents
    const batch = writeBatch(db);

    // Iterate over each document and update it
    querySnapshot.docs.forEach((docSnapshot) => {
      const docRef = doc(db, customersCollectionPath, docSnapshot.id);
      const updatedAt = new Date().toISOString(); // ISO string in UTC format

      // Update document with updatedAt and optionally timeZone
      batch.update(docRef, {
        updatedAt,
      });
    });

    // Commit the batch update
    await batch.commit();
    console.log("Documents updated with updatedAt and timeZone");
  } catch (error) {
    console.error("Error updating customers:", error);
  }
};

export const countTranscriptsForUser = async (organization, user) => {
  try {
    // Firestore collection reference
    const collectionRef = collection(
      db,
      `/organization/${organization}/conversations`,
    );

    // Create the OR condition for roles (agent, lead, user, reviewer, and calibration reviewer)
    const userRoleConditions = or(
      where("metadata.agent", "==", user?.email),
      where("metadata.lead", "==", user?.email),
      where("metadata.user", "==", user?.email),
      where("metadata.reviewer", "==", user?.email),
      where("calibration.reviewers", "array-contains", user?.email),
    );

    const baseConditions = and(
      where("transcript_data.metadata.deleted", "==", false), // AND condition for not deleted
      userRoleConditions, // OR conditions for roles
    );

    // Perform the query with the combined conditions
    const docQuery = query(collectionRef, baseConditions);

    // Perform the aggregation to count the matching transcripts
    const snapshot = await getAggregateFromServer(docQuery, {
      totalTranscripts: count(),
    });

    // Return the total count of transcripts
    return snapshot.data().totalTranscripts;
  } catch (error) {
    console.error("Failed to count transcripts:", error);
    return 0; // Return 0 if there's an error
  }
};
export const countVerifiedTranscriptsForUser = async (organization, user) => {
  try {
    // Firestore collection reference
    const collectionRef = collection(
      db,
      `/organization/${organization}/conversations`,
    );

    // OR conditions for the user's role (user or reviewer)
    const userRoleConditions = or(
      where("metadata.user", "==", user?.email),
      where("metadata.reviewer", "==", user?.email),
    );

    // Condition for verified transcripts
    const verifiedCondition = where("evaluation.verified", "==", true);

    // OR condition for verifications that include the user's email or verified transcripts
    const verificationOrVerifiedConditions = or(
      where("evaluation.verifications", "array-contains", user?.email),
      and(verifiedCondition, userRoleConditions),
    );

    // AND condition for non-deleted, date range, and verification conditions
    const baseConditions = and(
      where("transcript_data.metadata.deleted", "==", false), // AND condition for not deleted
      // ...validDateRangeConditions, // AND condition for date range
      verificationOrVerifiedConditions, // OR conditions for verifications and verified roles
    );

    // Perform the query with the combined conditions
    const docQuery = query(collectionRef, baseConditions);

    // Perform the aggregation to count the matching transcripts
    const snapshot = await getAggregateFromServer(docQuery, {
      totalTranscriptsVerfied: count(),
    });

    // Return the total count of verified transcripts
    return snapshot.data().totalTranscriptsVerfied;
  } catch (error) {
    console.error("Failed to count verified transcripts:", error);
    return 0; // Return 0 if there's an error
  }
};
