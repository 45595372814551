import React from "react";

import {
  Button,
  FormContainer,
  CustomizedTextWithPadding,
  SubTitleSignIn,
} from "./User.styles"; // Import your styles
import { spacing } from "src/styles/variables";
import { CardImg } from "../LandingPage/CustomizedDotCard/CustomizedDotCard.styles";

const EmailNotification = ({ messageText, timeLeft, onResendEmail }) => {
  return (
    <FormContainer>
      <CardImg width={spacing.xxxl}>
        <img src={"icons/successMail.svg"} alt={"successMail"} />
      </CardImg>
      <SubTitleSignIn>Check Your Email</SubTitleSignIn>
      <CustomizedTextWithPadding>{messageText}</CustomizedTextWithPadding>
      <Button
        size={"fullWidth"}
        onClick={(e) => onResendEmail(e)}
        disabled={timeLeft > 0}
      >
        {`Resend email ${timeLeft > 0 ? `${timeLeft} sec` : ""}`}
      </Button>
    </FormContainer>
  );
};

export default EmailNotification;
