import { color, elementSize, fontSize, spacing } from "src/styles/variables";
import { CollaboratorAvatar } from "src/components";
import {
  LeaderboardItem,
  PlayerInfo,
  Position,
  ScrollableContent,
  TopThree,
  LeaderBoardListItem,
} from "./LeaderBoard.styles";
import { VerifiedOutlined } from "@mui/icons-material";
import { METRIC_SCORE_ITEMS } from "./BusinessInsights.utils";
import { useNavigate } from "react-router-dom";

export const Leaderboard = ({ leaderboardUsers, scoreType }) => {
  const scoreTypeText =
    scoreType === "Verified" ? <VerifiedOutlined /> : scoreType;
  const navigate = useNavigate();
  return (
    <>
      <TopThree usersNumber={leaderboardUsers.length}>
        {leaderboardUsers
          .map((leaderboardUser, index) => (
            <LeaderboardItem key={index} isFirst={index === 0}>
              <Position>{index + 1}</Position>
              <CollaboratorAvatar
                collaborator={leaderboardUser?.[METRIC_SCORE_ITEMS.USER]}
                avatarColor={color.gray}
                style={{
                  position: "absolute",
                  top: `-${elementSize.xl}`,
                  width: `${index === 0 ? elementSize.xxxl : elementSize.xxll}`,
                  height: `${
                    index === 0 ? elementSize.xxxl : elementSize.xxll
                  }`,

                  fontSize: fontSize.displayXXLL,
                }}
              />
              <PlayerInfo
                onClick={() =>
                  navigate(
                    `/agents?user=${encodeURIComponent(leaderboardUser?.[METRIC_SCORE_ITEMS.USER]?.email)}`,
                  )
                }
              >
                {leaderboardUser?.[METRIC_SCORE_ITEMS.USER]?.name}
              </PlayerInfo>
              <PlayerInfo
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.xxxs,
                }}
                isScore
              >
                {scoreTypeText} {leaderboardUser?.[METRIC_SCORE_ITEMS.SCORE]}%
              </PlayerInfo>
              <PlayerInfo isScore>
                {leaderboardUser?.[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]}{" "}
                transcripts
              </PlayerInfo>
              {/* <PlayerInfo isScore>{user.avgDuration}</PlayerInfo> */}
            </LeaderboardItem>
          ))
          .slice(0, 3)}
      </TopThree>

      <ScrollableContent>
        {leaderboardUsers.slice(3).map((leaderboardUser, index) => (
          <LeaderBoardListItem key={index}>
            <Position>{index + 4}</Position>
            <CollaboratorAvatar
              collaborator={leaderboardUser?.[METRIC_SCORE_ITEMS.USER]}
              avatarColor={color.gray}
            />
            <PlayerInfo
              style={{ maxWidth: "12vw" }}
              onClick={() =>
                navigate(
                  `/agents?user=${encodeURIComponent(leaderboardUser?.[METRIC_SCORE_ITEMS.USER]?.email)}`,
                )
              }
            >
              {leaderboardUser?.[METRIC_SCORE_ITEMS.USER]?.name}
            </PlayerInfo>
            <PlayerInfo
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                gap: spacing.xxxs,
              }}
              isScore
            >
              {scoreTypeText} {leaderboardUser?.[METRIC_SCORE_ITEMS.SCORE]}%
            </PlayerInfo>
            <PlayerInfo isScore>
              {leaderboardUser?.[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]}{" "}
              transcripts
            </PlayerInfo>
          </LeaderBoardListItem>
        ))}
      </ScrollableContent>
    </>
  );
};
