import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { humanize } from "src/utils/utils";

export function flattenJSONArray(data) {
  if (!Array.isArray(data) && typeof data === "object") {
    data = [data];
  }

  let result = [];
  for (let i = 0; i < data.length; i++) {
    if (typeof data[i] === "object" && data[i] !== null) {
      let flattened = flattenJSON(data[i]);
      result.push(flattened);
    } else {
      // If the element is not an object, just add it to the result as is.
      result.push(data[i]);
    }
  }
  return result;
}
function flattenJSON(data, prefix = "") {
  let result = {};
  const { utterances, topics, evaluation, analysis, ...restData } = data;
  if (utterances) {
    result["Transcript"] = utterances
      .map(
        (utterance) =>
          "Speaker " + utterance.speaker + ": " + utterance.transcript,
      )
      .join("\n");
  }
  if (evaluation) {
    result["verified"] = evaluation?.verified;
  }
  if (analysis) {
    analysis.forEach((analysisGroup, analysisIndex) => {
      const analysisType = analysisGroup?.id;
      result[humanize(analysisType) + " Score"] = analysisGroup?.score;
      analysisGroup?.value.forEach((metricGroup) => {
        const metricType = metricGroup?.id;
        const humanizedMetricType = humanize(metricType);
        result[humanizedMetricType + " Rating"] = metricGroup?.rating;
        result[humanizedMetricType + " Value"] = metricGroup?.value;

        const updateName = analysisType + "." + metricType;
        if (evaluation?.updates?.[updateName]) {
          result[humanizedMetricType + " Old Rating"] =
            evaluation?.updates?.[updateName]["old_rating"];
          result[humanizedMetricType + " Old Value"] =
            evaluation?.updates?.[updateName]["old_value"];
        }
      });
    });
  }

  for (let key in restData) {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      !Array.isArray(data[key])
    ) {
      let nestedObj = flattenJSON(data[key], prefix + key + "_");
      result = { ...result, ...nestedObj };
    } else {
      result[humanize(prefix + key)] = data[key];
    }
  }

  return result;
}
export const DownloadCSV = ({ data, fileName }) => {
  let flattenedData = flattenJSONArray(data);
  return (
    <CSVLink
      data={flattenedData}
      filename={`${fileName}_${new Date().toISOString().substring(0, 10)}.csv`}
      className="btn btn-primary"
      target="_blank"
    >
      <DownloadOutlined />
    </CSVLink>
  );
};
