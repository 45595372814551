import {
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
  FormOutlined,
  SnippetsOutlined,
  ExperimentOutlined,
  ArrowLeftOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { ProfileTabs } from "./Profile.enums";

const menuIcons = {
  [ProfileTabs.SETTINGS]: <SettingOutlined />,
  [ProfileTabs.INTEGRATIONS]: <AppstoreOutlined />,
  [ProfileTabs.COLLABORATORS]: <UserOutlined />,
  [ProfileTabs.EVALUATION]: <FormOutlined />,
  [ProfileTabs.ASSIGNMENTS]: <SnippetsOutlined />,
  [ProfileTabs.CALIBRATIONS]: <ExperimentOutlined />,
  [ProfileTabs.HOME]: <ArrowLeftOutlined />,
  [ProfileTabs.SUBSCRIPTION]: <TagOutlined />,
};

export const menuItems = [
  {
    label: "Home",
    key: ProfileTabs.HOME,
    icon: menuIcons[ProfileTabs.HOME],
  },
  {
    label: "Settings",
    key: ProfileTabs.SETTINGS,
    icon: menuIcons[ProfileTabs.SETTINGS],
  },
  {
    label: "Integrations",
    key: ProfileTabs.INTEGRATIONS,
    icon: menuIcons[ProfileTabs.INTEGRATIONS],
  },
  {
    label: "Collaborators",
    key: ProfileTabs.COLLABORATORS,
    icon: menuIcons[ProfileTabs.COLLABORATORS],
  },
  {
    label: "Evaluation Forms",
    key: ProfileTabs.EVALUATION,
    icon: menuIcons[ProfileTabs.EVALUATION],
  },
  {
    label: "Assignments",
    key: ProfileTabs.ASSIGNMENTS,
    icon: menuIcons[ProfileTabs.ASSIGNMENTS],
  },
  {
    label: "Calibrations",
    key: ProfileTabs.CALIBRATIONS,
    icon: menuIcons[ProfileTabs.CALIBRATIONS],
  },
  {
    label: "Subscription",
    key: ProfileTabs.SUBSCRIPTION,
    icon: menuIcons[ProfileTabs.SUBSCRIPTION],
  },
];

export const tagTypeMapping = {
  teams: "team",
  conversationProperties: "property",
  customerStages: "stage",
};
