import { useEffect, useRef } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import {
  ButtonContents,
  ButtonIcon,
  ButtonState,
  ContentWrapper,
  StyledButton,
} from "../User/GoogleSignInButton/GoogleSignInButton.styles";
import { GoogleDriveIcon } from "../Loading/icons";
import { processFile } from "./DragDropFiles.utils";
import { STORAGE_TYPE } from "src/utils/enums";
import { message } from "antd";

export const GoogleDrivePicker = ({
  evaluationId,
  setFiles,
  selectedDefault,
}) => {
  const [openPicker, authResponse] = useDrivePicker();
  const { organization } = useOrganizationState();
  const { user } = useUserState();

  const { access_token } = authResponse || {};

  let token = useRef<string | undefined>(undefined);

  const updateFileStatus = (uid, status) => {
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.uid === uid) {
          return { ...file, status };
        }
        return file;
      });
    });
  };

  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = (e) => {
    if (!selectedDefault && !evaluationId) {
      message.error(
        "Please select an Evaluation type before browsing Google Drive.",
      );
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      developerKey: process.env.REACT_APP_FIREBASE_API_KEY,
      viewId: "DOCS",
      viewMimeTypes:
        "video/mp4,video/webm,video/x-m4v,video/quicktime,audio/mpeg,audio/x-wav",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: ["https://www.googleapis.com/auth/drive.file"],
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data?.docs) {
          const transformedFiles = data?.docs.map((file, index) => {
            return {
              uid: file.id || index,
              status: "uploading",
              ...file,
            };
          });

          setFiles((prevFiles) => {
            return [...prevFiles, ...transformedFiles];
          });

          transformedFiles.forEach(async (driveFile) => {
            processFile(
              driveFile,
              organization,
              user,
              STORAGE_TYPE.GDRIVE,
              token.current,
              evaluationId,
            )
              .then((response) => {
                updateFileStatus(driveFile.id, response?.status);
              })
              .catch((error) => {
                console.log(error);
                updateFileStatus(driveFile.id, "error");
              });
          });
        }
      },
    });
  };
  useEffect(() => {
    if (access_token !== undefined) {
      token.current = access_token;
    }
  }, [access_token]);

  return (
    <StyledButton
      style={{ width: "100%" }}
      onClick={(e) => handleOpenPicker(e)}
    >
      <ButtonState />
      <ContentWrapper>
        <ButtonIcon>
          <GoogleDriveIcon />
        </ButtonIcon>
        <ButtonContents>Browse google drive</ButtonContents>
      </ContentWrapper>
    </StyledButton>
  );
};
