import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom"; // Ensure these are imported from 'react-router-dom'

import {
  Card,
  Typography,
  Divider,
  Statistic,
  Row,
  Col,
  Space,
  Button,
} from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { SocialShareButtons, TrucoTitle, TrucoParagraph } from "src/components";
import {
  color,
  elementSize,
  fontSize,
  spacing,
  borderRadius,
} from "src/styles/variables";
import { getBlogs, truncateString, BlogPostProps } from "./BlogPage.utils";
import { SectionContainer } from "./../LandingPage.styles";
import { StandardLandingPage } from "./../LandingPage";
import {
  AnimatedParagraph,
  AnimatedTitle,
  BlogContainer,
  BlogListDiv,
  ImageContainer,
  BlogPostCard,
  BlogTitleContainer,
  StyledReactMarkdown,
} from "./BlogPage.styles";

interface BlogPostThumbnailProps {
  blog: BlogPostProps;
  expand: boolean;
  maxWidth?: string;
}

const BlogPostThumbnail = ({
  blog,
  expand,
  maxWidth = "500px",
}: BlogPostThumbnailProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BlogPostCard style={{ maxWidth: maxWidth }}>
        <Card
          onClick={() => {
            navigate(blog.url);
          }}
          cover={
            <ImageContainer>
              <img
                src={blog.image?.thumbnail_url}
                alt={blog.image?.description}
              />
            </ImageContainer>
          }
        >
          {expand && (
            <>
              <TrucoTitle level={3} maxNumLines={3}>
                {blog.title}
              </TrucoTitle>
              <TrucoParagraph>{blog?.thumbnail_paragraph || ""}</TrucoParagraph>
              <TrucoParagraph type="secondary">
                {blog?.date || ""}
              </TrucoParagraph>
              <Typography.Link href={blog.url} style={{ color: color.orange }}>
                Continue Reading &rarr;{" "}
              </Typography.Link>
            </>
          )}
          {!expand && (
            <>
              <TrucoTitle level={4} maxNumLines={3}>
                {blog.title}
              </TrucoTitle>
              <TrucoParagraph type="secondary">
                {blog?.date || ""}
              </TrucoParagraph>
            </>
          )}
        </Card>
      </BlogPostCard>
      {/* {expand && <Divider />} */}
    </>
  );
};
export const BlogListPage = ({ blogs }: { blogs: BlogPostProps[] }) => {
  return (
    <StandardLandingPage
      headercolor={color.olive}
      content={
        <>
          <BlogContainer>
            <AnimatedTitle level={1}>Blog</AnimatedTitle>
            <AnimatedParagraph
              style={{
                margin: `0px ${elementSize.sm}`,
                textAlign: "center",
                fontSize: fontSize.displayXXL,
              }}
            >
              Navigating the intersection of customer centric workflows and
              artificial intelligence.
            </AnimatedParagraph>
          </BlogContainer>
          <SectionContainer>
            <BlogListDiv>
              {blogs.map((blog, index) => {
                return (
                  <BlogPostThumbnail key={index} blog={blog} expand={true} />
                );
              })}
            </BlogListDiv>
          </SectionContainer>
        </>
      }
    />
  );
};

export const BlogPost = ({ blogs, idx }) => {
  const blog = blogs[idx];
  const navigate = useNavigate();
  const getRandomBlogs = (blogs: BlogPostProps[], currentIdx: number) => {
    const filteredBlogs = blogs.filter((_, index) => index !== currentIdx);
    const shuffled = filteredBlogs.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  };
  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/blogs");
  };

  const randomBlogs = getRandomBlogs(blogs, idx);
  return (
    <BlogPostCard style={{ maxWidth: "900px" }}>
      <a
        href={
          blog.image?.redirect_url
            ? blog.image?.redirect_url
            : blog.image?.thumbnail_url
        }
      >
        <img
          src={blog.image?.thumbnail_url}
          alt={blog.image?.description}
          style={{
            objectFit: "contain",
            maxWidth: "100%",
            borderRadius: borderRadius.xs,
            margin: `${spacing.lg} auto`,
          }}
        />
      </a>
      {blog?.statistics?.length > 0 && (
        <Row gutter={[32, 32]}>
          {blog.statistics.map((stat, idx) => {
            let icon = stat?.increasing ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            );
            return (
              <Col xl={8} md={8} xs={24}>
                <Card>
                  <Statistic
                    title={stat?.title}
                    value={stat.value}
                    valueStyle={{ color: color.orange }}
                    prefix={icon}
                    suffix={stat?.suffix}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      )}

      {blog.paragraphs?.map((paragraph, idx) => {
        return (
          <>
            <Typography.Title level={4}>{paragraph?.heading}</Typography.Title>
            <Typography.Paragraph>{paragraph?.body}</Typography.Paragraph>
          </>
        );
      })}

      {blog.markdown && <StyledReactMarkdown children={blog.markdown} />}

      <SocialShareButtons
        url={window.location.origin + blog.url}
        title={truncateString(
          blog.title + ". " + blog.thumbnail_paragraph || "",
          100,
        )}
      />

      {blogs?.length > 1 && (
        <>
          <Divider type="horizontal" />
          <Typography.Title level={4}>You may also like</Typography.Title>
          <Space wrap>
            {randomBlogs.map((blog, index) => (
              <BlogPostThumbnail
                key={index}
                blog={blog}
                expand={false}
                maxWidth={"400px"}
              />
            ))}
          </Space>
          <Space
            align="center"
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: spacing.md,
            }}
          >
            <Button
              onClick={handleNavigate}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50px", // Rounded corners
                padding: elementSize.ml,
              }}
            >
              View All <RightOutlined />
            </Button>
          </Space>
        </>
      )}
    </BlogPostCard>
  );
};

export const BlogPostPage = ({ blogs, idx }) => {
  return (
    <StandardLandingPage
      headercolor={color.olive}
      content={
        <>
          <BlogContainer>
            <BlogTitleContainer>
              <AnimatedTitle>{blogs[idx]?.title}</AnimatedTitle>
              <AnimatedParagraph>{blogs[idx]?.date || ""}</AnimatedParagraph>
            </BlogTitleContainer>
          </BlogContainer>
          <SectionContainer>
            <BlogPost blogs={blogs} idx={idx} />
          </SectionContainer>
        </>
      }
      metadata={blogs[idx]}
    />
  );
};

export const BlogRoutes = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getBlogs().then(setBlogs);
  }, []);

  if (!blogs.length) return <></>;

  return (
    <Routes>
      <Route path="/" element={<BlogListPage blogs={blogs} />} />
      {blogs.map((blog, idx) => (
        <Route
          key={blog.name}
          path={blog.name}
          element={<BlogPostPage blogs={blogs} idx={idx} />}
        />
      ))}
    </Routes>
  );
};
