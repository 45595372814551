import axios from "axios";

export async function addOrUpdateClip(
  clipId,
  organizationId,
  conversationId,
  playlistId,
  playlistTitle,
  created,
  clipData,
) {
  const data = {
    clipId,
    conversationId,
    organizationId,
    playlistId,
    playlistTitle,
    created,
    clipData,
  };

  const response = await axios.post(
    process.env.REACT_APP_TRUCO_BACKEND + "/clips/upsert",
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response.data;
}
