import { Card, List, Tag } from "antd";
import { useEffect, useRef } from "react";
import { DeleteWithPopConfirm } from "src/components/Global";
import { useUserState } from "src/state/UserState";
import { color, elementSize } from "src/styles/variables";
import { ACCESS_TYPE } from "src/utils/enums";
import { formatTime } from "src/utils/utils";
import styled from "styled-components";

const ChapterItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;

  background-color: ${({ isActive }) =>
    isActive ? color.grayLight : "transparent"};
  &:hover {
    background-color: ${color.grayLight};
  }
`;

const ChapterDetails = styled.div`
  display: flex;
  align-items: center;
`;

const ChapterThumbnail = styled.video`
  height: 70px;
  margin-right: 10px;
`;

const ChapterTitle = styled.div`
  flex: 1;
`;

const ScrollableCard = styled(Card)`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex: 1;
    overflow-y: auto;
  }
`;

interface SideBarChapters {
  timeline: any;
  onChapterClick: any;
  mimeType: string;
  url: string;
  height: any;
  currentClipIndex: number;
  signalPlaylistUser?: any;
  handleDeleteTimelineItemFromPlaylistClip?: any;
}

export const Sidebar: React.FC<SideBarChapters> = ({
  timeline,
  onChapterClick,
  signalPlaylistUser,
  handleDeleteTimelineItemFromPlaylistClip,
  mimeType,
  url,
  height,
  currentClipIndex,
}) => {
  const { user } = useUserState();
  const activeChapterRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (activeChapterRef.current) {
      activeChapterRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentClipIndex]);

  return (
    <ScrollableCard title="Chapters" style={{ height: height }}>
      <List
        dataSource={timeline}
        renderItem={(clip: { start: any; end: any; topic?: string }, index) => (
          <ChapterItem
            isActive={index === currentClipIndex} // Apply conditional styling
            onClick={() => {
              onChapterClick(clip, index);
            }}
            ref={index === currentClipIndex ? activeChapterRef : null} // Set ref on the active chapter
          >
            <ChapterDetails>
              {mimeType.includes("video") && (
                <ChapterThumbnail
                  src={`${url}#t=${clip.start}`}
                  alt={`Chapter ${index + 1}`}
                />
              )}
              <div>
                <ChapterTitle>{clip.topic ?? `Part ${index + 1}`}</ChapterTitle>
                <Tag>{formatTime(clip.start)}</Tag>
              </div>
            </ChapterDetails>
            {timeline?.length > 1 &&
              handleDeleteTimelineItemFromPlaylistClip && (
                <div
                  style={{
                    margin: `${elementSize.sm} ${elementSize.sm} ${elementSize.sm} ${elementSize.xxxl}`,
                  }}
                >
                  {DeleteWithPopConfirm(
                    "Delete Chapter?",
                    "Are you sure you want to delete this Chapter? ",
                    (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDeleteTimelineItemFromPlaylistClip(index);
                    },
                    signalPlaylistUser !== user?.email ||
                      user.accessType === ACCESS_TYPE.agent ||
                      user.accessType === ACCESS_TYPE.lead,
                  )}
                </div>
              )}
          </ChapterItem>
        )}
      />
    </ScrollableCard>
  );
};
