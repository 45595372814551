import { Empty, List, Tooltip, Space, Typography } from "antd";
import { useState } from "react";
import { generatePlaylistShareableLink } from "src/components/Playlist/Playlist.utils";
import { updateClipSummaryInPlaylist } from "src/components/Playlist/Playlist.clip.utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { capitalizeFirstLetter } from "src/utils/utils";
import { color } from "src/styles/variables";
import { TagModal } from "src/components/Playlist/PlaylistDetails/TagModal";
import { StyledBorderedTag } from "src/components/Global/global.styles";

const { Link, Text } = Typography;

export const PlaylistAndSignals = ({ modalData, setModalData }) => {
  const { organization } = useOrganizationState();
  const { playlists } = modalData;
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  const handleSave = (playlistId, clipId, editedValue) => {
    updateClipSummaryInPlaylist(
      organization,
      playlistId,
      modalData?.id,
      clipId,
      editedValue,
    );
    setModalData((prevModalData) => ({
      ...prevModalData,
      playlists: prevModalData.playlists.map((playlist) =>
        playlist?.id === playlistId
          ? {
              ...playlist,
              summary: editedValue,
              old_summary: playlist?.original_summary || playlist?.summary,
            }
          : playlist,
      ),
    }));
  };

  return playlists && playlists.length > 0 ? (
    <>
      <List
        dataSource={
          playlists as Array<{
            id: string;
            title: string;
            summary?: string;
            clipId?: string;
            original_summary?: string;
            type: string;
            tags?: string[];
          }>
        }
        renderItem={(playlist) => (
          <List.Item>
            <Space
              direction="vertical"
              size="small"
              style={{ overflow: "auto" }}
            >
              <Space>
                <Link
                  href={`${window.location.origin}${generatePlaylistShareableLink(organization, playlist?.id)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text strong style={{ color: color.orange }}>
                    {capitalizeFirstLetter(playlist.title)}
                  </Text>
                </Link>
              </Space>
              {playlist.summary && (
                <Tooltip
                  title={
                    playlist.original_summary &&
                    playlist.summary !== playlist.original_summary ? (
                      <span>
                        <strong>Old summary:</strong>{" "}
                        {playlist.original_summary}
                        <br />
                        <strong>New summary:</strong> {playlist.summary}
                      </span>
                    ) : (
                      playlist.summary
                    )
                  }
                >
                  <Text
                    type="secondary"
                    style={{ overflow: "auto" }}
                    editable={{
                      onChange: (value) =>
                        handleSave(playlist?.id, playlist?.clipId, value),
                    }}
                  >
                    {playlist.summary}
                  </Text>
                </Tooltip>
              )}
              <Space wrap>
                {playlist?.tags?.map((tag) => (
                  <StyledBorderedTag
                    key={tag}
                    style={{ overflow: "auto", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedPlaylist(playlist);
                      setSelectedTag(tag);
                    }}
                  >
                    {tag}
                  </StyledBorderedTag>
                ))}{" "}
              </Space>
            </Space>
          </List.Item>
        )}
      />
      <TagModal
        playlistId={selectedPlaylist?.id}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
    </>
  ) : (
    <Empty description="No playlists or signals exist" />
  );
};
