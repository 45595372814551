import axios from "axios";

export const fetchFileUrl = async (fileId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRUCO_BACKEND}/get_file_url`,
      { file_id: fileId },
      { headers: { "Content-Type": "application/json" } },
    );
    return response.data.url;
  } catch (error) {
    console.error("Error fetching file URL:", error);
    throw error;
  }
};

export const formatTooltipFromSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  }`;
};
