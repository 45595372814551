import { deviceSize } from "src/styles/variables";
import styled, { css } from "styled-components";
export const MainSearchContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 70%;
  margin: auto;
  margin-top: -7%;

  @media (max-width: ${deviceSize.large_phone}) {
    height: 70vh;
    width: 90%;
  }

  ${(props) =>
    props.isSearchPerformed &&
    css`
      width: 100%;
      display: block;
      height: auto;
      justify-content: flex-start;
      margin-top: auto;

      @media (max-width: ${deviceSize.large_phone}) {
        height: 100%;
        width: 100%;
      }
    `}
`;
