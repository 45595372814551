import React from "react";
import { Col, Row, Tooltip } from "antd";
import {
  AttachedFileName,
  AttachmentItem,
  IconWrapper,
  StyledAttachmentList,
} from "../../Dashboard.styles";
import { getFileIcon } from "../../Dashboard.utils";
import { OpenInNew } from "@mui/icons-material";

// const attachments = [
//   {
//     name: "document.txt",
//     url: "http://example.com/document.txt",
//   },
//   { name: "proposal.docx", url: "http://example.com/proposal.docx" },
//   { name: "Plan 2023.pdf", url: "http://example.com/Plan2023.pdf" },// More attachments...
// ];

const AttachmentsList = ({ attachments }) => {
  const openAttachment = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {attachments && (
        <StyledAttachmentList>
          <Row gutter={[16, 16]} wrap={true}>
            {attachments.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <AttachmentItem onClick={() => openAttachment(item.url)}>
                  <IconWrapper>{getFileIcon(item.name)}</IconWrapper>
                  <Tooltip title={item.name}>
                    <AttachedFileName>{item.name}</AttachedFileName>
                  </Tooltip>
                  <OpenInNew style={{ fontSize: "16px", cursor: "pointer" }} />
                </AttachmentItem>
              </Col>
            ))}
          </Row>
        </StyledAttachmentList>
      )}
    </>
  );
};

export default AttachmentsList;
