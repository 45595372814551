import { Checkbox, Input, Select, InputNumber, DatePicker } from "antd";
import {
  getRecordFromFireStoreWithQuery,
  createOrUpdateDocInFirestore,
} from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { COLLECTION_DATA } from "src/utils/enums";
import { UserSelect } from "src/components";
import dayjs from "dayjs";

export const updateCustomerInfo = (
  organization,
  customerId,
  updatedCustomer,
  setCustomers = null,
  field?,
) => {
  try {
    if (
      field &&
      field.type === "timestamp" &&
      typeof updatedCustomer[field.key] === "object" &&
      Object.keys(updatedCustomer[field.key]).length === 0
    ) {
      return;
    }
    createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${customerId}`,
      Object.fromEntries(
        Object.entries(updatedCustomer).filter(([_, v]) => v !== undefined),
      ),
    );

    if (setCustomers) {
      setCustomers((prevstate) => {
        return {
          ...prevstate,
          data: prevstate?.data?.map((customer) => {
            if (customer.id === customerId) {
              return { ...customer, ...updatedCustomer };
            }
            return customer;
          }),
        };
      });
    }
  } catch (error) {
    console.error("Error updating customer stage: ", error);
  }
};
export const getCustomerInfo = async (customerValue, organization) => {
  const customerData = await getRecordFromFireStoreWithQuery(
    `organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
    [
      {
        fieldPath: "customer",
        opStr: "==",
        value: customerValue,
      },
      {
        fieldPath: "phone",
        opStr: "==",
        value: customerValue,
      },
      {
        fieldPath: "email",
        opStr: "==",
        value: customerValue,
      },
    ],
    customerConverter,
    null,
  );

  return customerData;
};

export const getCustomerIdentity = (customer) => {
  return {
    name: customer?.firstName
      ? `${customer?.firstName} ${customer?.lastName}`.trim()
      : customer?.name || "",
    email: customer?.email || customer?.phone || customer?.customer || "N/A",
  };
};

export const FieldRenderer = ({
  field,
  value,
  editMode,
  onChange = (key, value) => {},
  onSave = (key, value) => {},
  isRowSetup = false, // Pass a prop to indicate if this is in row setup,
  rowNumber = 2,
}) => {
  let dateValue = value;
  const handleChange = (newValue) => {
    onChange(field.key, newValue);
  };

  const handleSave = (newValue) => {
    if (field.type === "timestamp" && isRowSetup) {
      onSave(field.key, dateValue); // Save local state only for timestamp in row setup
    } else {
      onSave(field.key, newValue); // Save normally for other types
    }
  };

  if (!editMode) {
    if (field.type === "boolean") {
      return <Checkbox checked={!!value} disabled />;
    }
    return value || "";
  }

  switch (field.type) {
    case "boolean":
      return (
        <Checkbox
          checked={!!value}
          onChange={(e) => {
            handleChange(e.target.checked);
            handleSave(e.target.checked);
          }}
        />
      );

    case "select":
      return (
        <Select
          value={value || ""}
          onChange={(newValue) => {
            handleChange(newValue);
            handleSave(newValue);
          }}
          dropdownStyle={{ maxHeight: 200 }} // Adjust dropdown height here
          style={{ width: "100%" }} // Adjust the width of the select
        >
          {(field.options || []).map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );

    case "number":
      return (
        <InputNumber
          value={value || 0}
          onChange={(newValue) => handleChange(newValue)}
          onBlur={(newValue) => handleSave(newValue)}
          onMouseLeave={(newValue) => handleSave(newValue)}
          placeholder="0"
        />
      );

    case "timestamp":
      return (
        <DatePicker
          value={dateValue ? dayjs(dateValue) : undefined}
          onChange={(date, dateString) => {
            dateValue = dateString;
            handleChange(dateString);
          }}
          onOpenChange={(open) => {
            if (isRowSetup && !open) {
              handleSave(dateValue);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && isRowSetup) {
              handleSave(dateValue); // Save when the user presses "Enter"
            }
          }}
          placeholder="Select Date"
          minDate={dayjs(new Date())}
        />
      );

    case "currency":
      return (
        <InputNumber
          value={value || 0}
          onChange={(newValue) => handleChange(newValue)}
          onBlur={(newValue) => handleSave(newValue)}
          onMouseLeave={(e) =>
            handleSave((e.target as HTMLTextAreaElement).value)
          }
          placeholder="0"
        />
      );
    case "user":
      return (
        <UserSelect
          handleUser={(newValue) => {
            handleChange(newValue?.email);
            handleSave(newValue?.email);
          }}
          defaultUser={value} // Adjust dropdown height here
        />
      );

    default:
      return (
        <Input.TextArea
          rows={rowNumber}
          value={value || ""}
          onChange={(e) =>
            handleChange((e.target as HTMLTextAreaElement).value)
          }
          onMouseLeave={(e) =>
            handleSave((e.target as HTMLTextAreaElement).value)
          }
          onBlur={(e) => handleSave((e.target as HTMLTextAreaElement).value)}
        />
      );
  }
};

export const getDomainFromEmail = (email) => {
  const domainMatch = email.match(/@(.+)$/);
  return domainMatch ? domainMatch[1] : "";
};
