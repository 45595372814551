import { useState, useRef, createRef, useEffect } from "react";
import { List, Select, Button, Popover, Input, Form, Checkbox } from "antd";

import { FlagOutlined, FlagFilled } from "@ant-design/icons";
import { sentimentType, updateTranscriptToDb } from "../../Dashboard.utils";

import { color, spacing, fontSize, hexToRgba } from "src/styles/variables";
import { formatTime, getColorForValue } from "src/utils/utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { Person2Outlined } from "@mui/icons-material";
import { UpdatedTextDiv } from "../../Dashboard.styles";
import AttachmentsList from "./AttachmentsList";

const DropdownIcon = ({ options, handleSubmit, icon }) => {
  const [visible, setVisible] = useState(false);

  // Toggle popover visibility
  const handleVisibilityChange = (visible) => {
    setVisible(visible);
  };

  const content = (
    <Form
      layout="vertical"
      onFinish={(values) => {
        handleSubmit(values);
        setVisible(false); // Close the popover after selection
      }}
    >
      <Form.Item name="topic" label="Topic">
        <Select
          placeholder="Select Topic"
          options={options}
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
      <Form.Item name="feedback" label="Feedback">
        <Input.TextArea
          placeholder="Leave any feedback for agent"
          allowClear={true}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div
      style={{
        color: color.grayDark,
      }}
    >
      <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={handleVisibilityChange}
      >
        {icon}
      </Popover>
    </div>
  );
};
interface UtterancesListProps {
  fileId?: string;
  utterances: any;
  handleFeedbackUpdate?: (value: any) => void;
  handleUtteranceClick?: (index: number) => void;
  currentUtteranceIndex?: number | null;
  action?: boolean;
  checkAction?: boolean;
  selectedItems?: number[];
  handleSelectChange?: (index: number, checked: boolean) => void;
  setSelectedItems?: (value: number[] | ((prev: number[]) => number[])) => void;
}
export const UtterancesList: React.FC<UtterancesListProps> = ({
  fileId,
  utterances,
  handleFeedbackUpdate,
  handleUtteranceClick,
  currentUtteranceIndex,
  selectedItems = [],
  setSelectedItems,
  handleSelectChange,
  action = true,
  checkAction = false,
}) => {
  const { organization, analysisStructures, organizationSettings } =
    useOrganizationState();
  const { user } = useUserState();
  const itemRefs = useRef([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!checkAction) return;
    if (selectAll) {
      setSelectedItems?.(utterances.map((_, index) => index));
    } else {
      setSelectedItems?.([]);
    }
  }, [selectAll, utterances, checkAction]);

  useEffect(() => {
    if (utterances) {
      itemRefs.current = utterances?.map(
        (_, i) => itemRefs.current[i] || createRef(),
      );
    }
  }, [utterances]);

  useEffect(() => {
    if (currentUtteranceIndex !== null) {
      itemRefs?.current[currentUtteranceIndex]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [currentUtteranceIndex]);

  const getUtteranceActions = (start, end, index) => {
    const handleSubmit = (values) => {
      if (values?.topic) {
        if (!utterances[index].topics) {
          utterances[index].topics = [];
        }
        utterances[index].topics.push({
          topic: values?.topic,
          name: user?.name,
          email: user?.email,
          time: new Date().toISOString(),
        });
      }
      if (values?.feedback) {
        if (!utterances[index].feedback) {
          utterances[index].feedback = [];
        }
        const newFeedback = {
          text: values?.feedback,
          name: user?.name,
          email: user?.email,
          photoURL: user?.photoURL,
          time: new Date().toISOString(),
        };
        // keeping in both places for now.
        utterances[index].feedback.push(newFeedback);
        handleFeedbackUpdate?.({ ...newFeedback, start, end });
      }
      updateTranscriptToDb(organization, fileId, {
        "transcript_data.utterances": utterances,
      });
    };

    return (
      <UpdatedTextDiv style={{ gap: spacing.xs }}>
        {(organizationSettings?.showSentiment ?? true) &&
          currentUtteranceIndex === index &&
          utterances[currentUtteranceIndex].sentiment &&
          sentimentType(utterances[currentUtteranceIndex].sentiment)}
        <DropdownIcon
          options={analysisStructures}
          handleSubmit={handleSubmit}
          icon={
            utterances[index].feedback || utterances[index].feedback ? (
              <FlagFilled style={{ color: color.olive }} />
            ) : (
              <FlagOutlined />
            )
          }
        />
      </UpdatedTextDiv>
    );
  };

  return (
    <>
      {checkAction && (
        <div style={{ padding: spacing.xs }}>
          <Checkbox
            onChange={(e) => setSelectAll(e.target.checked)}
            checked={selectAll}
          >
            Select All
          </Checkbox>
        </div>
      )}
      {utterances && utterances.length > 0 && (
        <div
          id="scrollableDiv"
          style={{
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={utterances}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => handleUtteranceClick?.(index)}
                ref={itemRefs.current[index]}
                style={{
                  backgroundColor:
                    index === currentUtteranceIndex
                      ? hexToRgba(getColorForValue(item["speaker"]), 0.1)
                      : "transparent",
                  padding: spacing.xs,
                  cursor: handleUtteranceClick ? "pointer" : "default",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: getColorForValue(item["speaker"]),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {checkAction && (
                      <Checkbox
                        checked={selectedItems.includes(index)}
                        onChange={(e) =>
                          handleSelectChange(index, e.target.checked)
                        }
                        style={{ marginRight: spacing.xs }}
                      />
                    )}
                    <Person2Outlined
                      style={{
                        fontSize: fontSize.bodyLarge,
                        verticalAlign: "text-top",
                        marginRight: spacing.xs,
                      }}
                    />
                    <b>
                      {typeof item["speaker"] === "string" // when it's not index,so in email,name,phone number
                        ? item["speaker"]
                        : formatTime(item["start"])}
                    </b>
                    &nbsp;
                  </div>
                  {action && (
                    <div>
                      {getUtteranceActions(item["start"], item["end"], index)}
                    </div>
                  )}
                </div>
                {item["transcript"]}
                {item?.["attachments"] && (
                  <AttachmentsList attachments={item["attachments"]} />
                )}
              </List.Item>
            )}
          />
        </div>
      )}
    </>
  );
};
