import { createOrUpdateDocInFirestore } from "src/firebaseAuth";
import { COLLECTION_DATA } from "src/utils/enums";
import {
  collection,
  query,
  where,
  getAggregateFromServer,
  count,
} from "firebase/firestore";
import { db } from "src/firebaseAuth";
import { updateCustomerInfo } from "../CustomersDashboard/Customers.utils";

// Helper function to get customer counts for each account
export const getCustomerCountsByAccount = async (organization, accountIds) => {
  const customerCounts = {};

  for (const accountId of accountIds) {
    const customerCountQuery = query(
      collection(
        db,
        `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
      ),
      where("accountId", "==", accountId),
    );

    const customerCountSnapshot = await getAggregateFromServer(
      customerCountQuery,
      {
        customerCount: count(),
      },
    );

    customerCounts[accountId] = customerCountSnapshot.data().customerCount || 0;
  }

  return customerCounts;
};

export const updateAccountInfo = async (
  organization,
  accountId,
  updatedAccount,
  setAccounts = null,
  field?,
  customers?,
  setCustomers?,
) => {
  try {
    if (
      field &&
      field.type === "timestamp" &&
      typeof updatedAccount[field.key] === "object" &&
      Object.keys(updatedAccount[field.key]).length === 0
    ) {
      return;
    }
    await createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.ACCOUNTS}/${accountId}`,
      Object.fromEntries(
        Object.entries(updatedAccount).filter(([_, v]) => v !== undefined),
      ),
    );

    if (setAccounts) {
      setAccounts((prevState) => {
        return {
          ...prevState,
          data: prevState?.data?.map((account) => {
            if (account.id === accountId) {
              return { ...account, ...updatedAccount };
            }
            return account;
          }),
        };
      });
    }
    if (updatedAccount.name && customers?.length > 0 && setCustomers) {
      console.log("customers", customers, accountId);
      customers.forEach((customer) => {
        if (
          customer.accountId === accountId && // Ensure the customer is associated with the account
          customer.company !== updatedAccount.name // Only update if different
        ) {
          updateCustomerInfo(
            organization,
            customer.id,
            {
              company: updatedAccount.name,
            },
            setCustomers,
          );
        }
      });
    }
  } catch (error) {
    console.error("Error updating account stage: ", error);
  }
};
