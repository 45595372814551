import React from "react";
import { Typography } from "antd";
import { ExportOutlined, SelectOutlined } from "@ant-design/icons";

import { color, spacing, fontSize } from "src/styles/variables";
import { useNavigate } from "react-router-dom";

const { Link } = Typography;

export const SearchLink = ({ id, url, title }) => {
  const navigate = useNavigate();

  if (id === url) {
    // external link
    return (
      <Link
        href={url}
        target="_blank"
        style={{
          color: color.orange,
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          fontSize: fontSize.caption,
        }}
      >
        <ExportOutlined style={{ marginRight: spacing.xs }} />
        {title}
      </Link>
    );
  } else {
    // internal link
    return (
      <>
        <Link
          target="_blank"
          style={{
            color: color.orange,
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            fontSize: fontSize.caption,
          }}
          onClick={() => {
            navigate(`?conversation=${encodeURIComponent(id)}`);
          }}
        >
          <SelectOutlined style={{ marginRight: spacing.xs }} />
          {title}
        </Link>
      </>
    );
  }
};
