import React from "react";

import { Row, Col, Typography } from "antd";
import {
  SectionContainer,
  GradientOrangeSpan,
  InsightsImage,
  OrangeBlobBackground,
  CardContainer,
  InsightCard,
  IconContainer,
} from "./LandingPage.styles";
import { fontSize, color, hexToHex } from "src/styles/variables";
import { WaveSvgIcon } from "../Loading/icons";

export const ProductInsights = () => {
  const insightsData = [
    {
      title: "Transcripts and Summaries across all channels",
      icon: "/icons/transcript.svg",
    },
    {
      title: "AI Powered Triage and User Analysis with state of the art AI",
      icon: "/icons/sentimentAnalysis.svg",
    },
    {
      title:
        "Customer Satisfaction, Intents and Hold times for all conversations",
      icon: "intents.png",
    },
    {
      title: "Actionable product insights for customer-driven decisions",
      icon: "/icons/aiPowered.svg",
    },
  ];
  return (
    <SectionContainer>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          padding: "30px",
        }}
      >
        <Col xl={18} lg={15} md={20} xs={22}>
          <Typography.Title
            level={1}
            style={{
              fontSize: fontSize.displayXXXXL,
              marginTop: 0,
              textAlign: "center",
            }}
          >
            Harness insights from every customer conversation with{" "}
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </Typography.Title>
          <Typography.Paragraph
            style={{
              fontSize: fontSize.displayXXL,
              color: color.grayMedium,
              textAlign: "center",
            }}
          >
            Imagine being able to gain deep visibility to improve product and
            service offerings.
          </Typography.Paragraph>
          <InsightsImage src={"insightsImg.png"} alt="Product Insights" />
        </Col>
        <CardContainer>
          <OrangeBlobBackground>
            <WaveSvgIcon fillColor={hexToHex(color.orange, 0.24)} />
          </OrangeBlobBackground>
          {insightsData.map(({ title, icon }, index) => (
            <InsightCard key={index}>
              <IconContainer>
                <img src={icon} alt={title} width="40px" />
              </IconContainer>
              <br />
              <Typography.Text>{title}</Typography.Text>
            </InsightCard>
          ))}
        </CardContainer>
      </Row>
    </SectionContainer>
  );
};
