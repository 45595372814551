import React from "react";
import { Space, Dropdown, Input } from "antd";
import { PlaylistPlay } from "@mui/icons-material";
import { TagsOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { PLAYLIST_VIEW } from "./PlaylistDetails";
import { PlaylistMenu } from "./PlaylistMenu";
import { ModeButton, DropDownWrapper } from "./PlaylistDetails.styles";
import {
  grayBoxShadow,
  color,
  spacing,
  elementSize,
} from "src/styles/variables";

interface ViewModeSelectorProps {
  viewMode: PLAYLIST_VIEW;
  setViewMode: (viewMode: PLAYLIST_VIEW) => void;
}

const commonDropDown = (setLoading, playlist) => {
  return (
    <Dropdown
      overlay={
        <PlaylistMenu
          currentPlaylist={playlist}
          redirectToPlaylist={true}
          linkToCopy={`${window.location.href}`}
        />
      }
      trigger={["click"]}
      overlayStyle={{ boxShadow: grayBoxShadow }}
    >
      <MoreOutlined
        style={{
          cursor: "pointer",
          fontSize: "20px",
          color: color.orange,
          transform: "rotate(90deg)",
        }}
      />
    </Dropdown>
  );
};

export const PlaylistActions = ({
  viewMode,
  setViewMode,
  setLoading,
  playlist,
  handleSearch,
}) => {
  const handleViewModeChange = (mode: PLAYLIST_VIEW) => {
    setViewMode(mode);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: 10,
      }}
    >
      <Input
        placeholder="Search by title"
        allowClear
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        style={{ width: "300px" }}
      />

      <Space direction="horizontal">
        {
          <>
            <ModeButton
              isActive={viewMode === PLAYLIST_VIEW.CLIPS}
              onClick={() => handleViewModeChange(PLAYLIST_VIEW.CLIPS)}
            >
              <PlaylistPlay
                style={{ fontSize: elementSize.md, marginRight: spacing.xs }}
              />{" "}
              Clips
            </ModeButton>
            <ModeButton
              isActive={viewMode === PLAYLIST_VIEW.TAGS}
              onClick={() => handleViewModeChange(PLAYLIST_VIEW.TAGS)}
            >
              <TagsOutlined
                style={{ fontSize: elementSize.md, marginRight: spacing.xs }}
              />{" "}
              Tags
            </ModeButton>
          </>
        }
        <DropDownWrapper>
          {commonDropDown(setLoading, playlist)}
        </DropDownWrapper>
      </Space>
    </div>
  );
};
