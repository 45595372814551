import { useEffect, useState } from "react";
import { Button, Space, Tooltip, Tag } from "antd";
import { BankOutlined, LoadingOutlined } from "@ant-design/icons";
import { COLLECTION_DATA } from "src/utils/enums";
import { useNavigate } from "react-router-dom";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { color } from "src/styles/variables";
import { AccountSetupModal } from "./AccountSetupModal";

const AccountInfo = ({
  accountId,
  organization,
  companyDomain,
  company,
  selectedCustomer,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerAccount, setCustomerAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountData = async () => {
      if (accountId) {
        setIsLoading(true);
        try {
          const accountData = await getRecordFromFireStore(
            `/organization/${organization}/${COLLECTION_DATA.ACCOUNTS}/${accountId}`,
          );
          setCustomerAccount(accountData || null);
        } catch (error) {
          console.error("Failed to fetch account data:", error);
          setCustomerAccount(null);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAccountData();
  }, [accountId, organization]);

  if (isLoading) {
    return <LoadingOutlined style={{ paddingLeft: "8px" }} />;
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {accountId ? (
        <>
          <Tooltip title="Go to account">
            <Tag
              onClick={() => {
                navigate(`/accounts?account=${accountId}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <BankOutlined
                style={{ marginRight: "4px", color: color.orange }}
              />

              {customerAccount?.name ||
                customerAccount?.domain ||
                companyDomain ||
                company}
            </Tag>
          </Tooltip>
        </>
      ) : (
        <Space direction="vertical" size={3}>
          <Button onClick={() => setIsModalVisible(true)}>
            <BankOutlined style={{ marginRight: "4px", color: color.orange }} />
            {"Add Account"}
          </Button>
        </Space>
      )}

      {isModalVisible && (
        <AccountSetupModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          selectedCustomer={selectedCustomer}
          companyDomain={companyDomain}
        />
      )}
    </div>
  );
};

export default AccountInfo;
