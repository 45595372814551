import styled from "styled-components";

import { color } from "src/styles/variables";

export const Frame = styled.div`
  flex: 1;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TrackContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 6px;
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${color.lightOrange};
  opacity: 0.3;
`;

export const Tooltip = styled.div`
  position: absolute;
  top: 65px;
  background: ${color.lightOrange};
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 12px;
  color: ${color.white};
  z-index: 2;
`;
export const TooltipProgress = styled.div`
  position: absolute;
  top: -25px;
  background: ${color.gray};
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 12px;
  color: ${color.white};
  z-index: 2;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
`;

export const ProgressIndicator = styled.div`
  position: absolute;
  height: 100%;
  width: 2px;
  background: ${color.gray};
  z-index: 1;
`;

export const Handle = styled.div`
  width: 10px;
  height: 100%;
  border-radius: 4px;
  cursor: ew-resize;
  background-color: ${color.orange};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
`;

export const TrimBox = styled.div`
  position: absolute;
  height: 100%;
  background-color: transparent;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TimelineContainer = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  background-color: black;
`;
