import React, { useEffect, useRef, useState } from "react";
import { Spin, Button, Row, Col } from "antd";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import {
  Container,
  Frame,
  Handle,
  Overlay,
  ProgressIndicator,
  TimelineContainer,
  Tooltip,
  TooltipProgress,
  TrackContainer,
  TrimBox,
} from "./Playlist.styles";
import { useVideoFrames, VideoToFramesMethod } from "src/hooks/useVideoFrames";
import { formatTooltipFromSeconds } from "./Playlist.utils";
import { UtterancesList } from "../ConversationModalBody/UtterancesList";
import { spacing } from "src/styles/variables";
import useFetchFileUrl from "src/hooks/useFetchFileUrl";

interface AddToPlaylistContentProps {
  data: any;
  startTime: number;
  endTime: number;
  setStartTime: (time: number) => void;
  setEndTime: (time: number) => void;
  setDisable?: (value: boolean) => void;
}

const MediaPlaylist: React.FC<AddToPlaylistContentProps> = ({
  data,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  setDisable,
}) => {
  const { url } = useFetchFileUrl(data?.id);
  const [duration, setDuration] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(true);
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);
  const [frames] = useVideoFrames(url, 15, VideoToFramesMethod.totalFrames);
  const [trimmerWidth, setTrimmerWidth] = useState<number>(50);
  const [trimmerPosition, setTrimmerPosition] = useState<number>(50);
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const [timelineContainerWidth, setTimelineContainerWidth] =
    useState<number>(0);
  const [currentUtteranceIndex, setCurrentUtteranceIndex] = useState<
    number | null
  >(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [handleMove, setHandleMove] = useState<boolean>(false);
  const overlayLeftWidth = (startTime / duration) * 100 + "%";
  const overlayRightLeft = (endTime / duration) * 100 + "%";
  const overlayRightWidth = 100 - (endTime / duration) * 100 + "%";
  const isAudio = data?.mimeType?.includes("audio");

  const filledFrames = new Array(15)
    .fill(null)
    .map((_, index) => frames[index] || null);

  useEffect(() => {
    if (timelineRef.current) {
      setTimelineContainerWidth(timelineRef.current.offsetWidth);
    }
    const handleContainerResize = () => {
      if (timelineRef.current) {
        setTimelineContainerWidth(timelineRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleContainerResize);
    return () => window.removeEventListener("resize", handleContainerResize);
  }, []);
  useEffect(() => {
    if (timelineContainerWidth && duration) {
      const newStartTime =
        (trimmerPosition / timelineContainerWidth) * duration;
      const newEndTime =
        ((trimmerPosition + trimmerWidth) / timelineContainerWidth) * duration;
      setStartTime(newStartTime);
      setEndTime(newEndTime);
      const media = isAudio ? audioRef.current : videoRef.current;
      if (media) {
        media.currentTime = newStartTime;
      }
      const progress = (newStartTime / duration) * 100;
      setProgress(progress);
    }
  }, [
    timelineContainerWidth,
    duration,
    trimmerPosition,
    trimmerWidth,
    isAudio,
  ]);

  useEffect(() => {
    if (isPlaying) {
      playMedia();
    } else {
      pauseMedia();
    }
  }, [isPlaying]);

  const findUtteranceIndexWithTime = (currentTime) => {
    return data?.utterances.findIndex(
      (utterance) =>
        currentTime >= utterance.start && currentTime <= utterance.end,
    );
  };
  const updatedUtteranceIndex = (time) => {
    const utteranceIndex = findUtteranceIndexWithTime(time);
    if (utteranceIndex !== -1) {
      setCurrentUtteranceIndex(utteranceIndex);
    }
  };

  const handleTimeUpdate = () => {
    const media = isAudio ? audioRef.current : videoRef.current;
    if (!media) return;
    const progress = (media.currentTime / media.duration) * 100;
    setProgress(progress);
    const currentMediaTime = media.currentTime;
    updatedUtteranceIndex(currentMediaTime);
  };

  const handleLoadedMetadata = () => {
    const media = isAudio ? audioRef.current : videoRef.current;
    if (!media) return;
    setDuration(media.duration);
    const progress = (media.currentTime / media.duration) * 100;
    setProgress(progress);
    setDisable?.(false);
    setIsMediaLoaded(true);
  };
  const handleCanPlay = () => {
    setIsMediaLoading(false);
  };

  const handleWaiting = () => {
    setIsMediaLoading(true);
  };
  const playMedia = () => {
    const media = isAudio ? audioRef.current : videoRef.current;
    if (!media) return;
    media.play();
  };

  const pauseMedia = () => {
    const media = isAudio ? audioRef.current : videoRef.current;
    if (!media) return;
    media.pause();
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleMouseDownResize = (e) => {
    e.preventDefault();
    const handleType = e.target.getAttribute("data-handle");
    const startX = e.clientX;
    const startWidth = trimmerWidth;
    const startPosition = trimmerPosition;

    const doResize = (e) => {
      const currentX = e.clientX;
      const dx = currentX - startX;
      setHandleMove(false);

      if (handleType === "left") {
        const newWidth = startWidth - dx;
        if (newWidth > 40 && startPosition + dx >= 0) {
          setHandleMove(true);
          setTrimmerWidth(newWidth);
          setTrimmerPosition(startPosition + dx);
        }
      } else if (handleType === "right") {
        const newWidth = startWidth + dx;
        if (
          newWidth > 40 &&
          startPosition + newWidth <= timelineRef.current.offsetWidth
        ) {
          setHandleMove(true);
          setTrimmerWidth(newWidth);
        }
      }
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", doResize);
      window.removeEventListener("mouseup", stopResize);
      if (handleMove) {
        setHandleMove(false);
      }
    };

    window.addEventListener("mousemove", doResize);
    window.addEventListener("mouseup", stopResize);
  };
  const handleMouseDownDrag = (e) => {
    if (e.target.getAttribute("data-handle")) {
      return;
    }
    setIsDragging(false);
    const startX = e.clientX;
    const startPos = trimmerPosition;

    const doDrag = (e) => {
      const currentX = e.clientX;
      const dx = currentX - startX;
      const newPos = startPos + dx;

      if (
        newPos >= 0 &&
        newPos + trimmerWidth <= timelineRef.current.offsetWidth
      ) {
        setIsDragging(true);
        setTrimmerPosition(newPos);
      }
    };

    const stopDrag = () => {
      window.removeEventListener("mousemove", doDrag);
      window.removeEventListener("mouseup", stopDrag);
      if (isDragging) {
        setIsDragging(false);
      }
    };

    window.addEventListener("mousemove", doDrag);
    window.addEventListener("mouseup", stopDrag);
  };

  const handleJumpMedia = (time) => {
    const media = isAudio ? audioRef.current : videoRef.current;
    if (!media) return;
    media.currentTime = time;
    setProgress((time / duration) * 100);
  };

  const handleProgressBarClick = (e) => {
    if (isDragging || handleMove) {
      setIsDragging(false);
      setHandleMove(false);
      return;
    }
    if (timelineRef.current && isPlaying) {
      const boundingRect = timelineRef.current.getBoundingClientRect();
      const clickedPosition = e.clientX - boundingRect.left;
      const newTime = (clickedPosition / boundingRect.width) * duration;
      handleJumpMedia(newTime);
      updatedUtteranceIndex(newTime);
    }
  };
  const handleUtteranceClick = (index) => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    handleJumpMedia(data?.utterances[index]["start"]);
    updatedUtteranceIndex(data?.utterances[index]["start"]);
  };

  return (
    <>
      <Row
        gutter={[8, 32]}
        align="middle"
        justify="center"
        style={{
          paddingBottom: spacing.lg,
          margin: "auto",
        }}
      >
        <Col xl={10} xs={24}>
          <div>
            {isAudio ? (
              <audio
                ref={audioRef}
                src={url}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                onCanPlay={handleCanPlay}
                onWaiting={handleWaiting}
              />
            ) : (
              <video
                ref={videoRef}
                src={url}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                onCanPlay={handleCanPlay}
                onWaiting={handleWaiting}
              />
            )}
          </div>
        </Col>
        <Col xl={6} xs={24}>
          {isMediaLoaded && (
            <UtterancesList
              utterances={data?.utterances}
              currentUtteranceIndex={currentUtteranceIndex}
              handleUtteranceClick={handleUtteranceClick}
              action={false}
            />
          )}
        </Col>
      </Row>

      <TimelineContainer
        ref={timelineRef}
        onClick={handleProgressBarClick}
        style={{ cursor: "pointer" }}
      >
        {isMediaLoaded && (
          <>
            <TrackContainer>
              {isAudio || !frames?.length ? (
                <TrackContainer style={{ background: "black" }} />
              ) : (
                filledFrames.map((frame, index) => (
                  <Frame
                    key={index}
                    style={{
                      backgroundImage: frame ? `url(${frame})` : "none",
                    }}
                  >
                    {!frame && <Spin size="small" />}
                  </Frame>
                ))
              )}
            </TrackContainer>
            <Overlay style={{ width: overlayLeftWidth }} />
            <Overlay
              style={{
                left: overlayRightLeft,
                width: overlayRightWidth,
              }}
            />
            {isPlaying && !isDragging && !handleMove && (
              <ProgressIndicator style={{ left: `${progress}%` }}>
                <TooltipProgress style={{ left: -15 }}>
                  {formatTooltipFromSeconds((progress / 100) * duration)}
                </TooltipProgress>
              </ProgressIndicator>
            )}

            <TrimBox
              onMouseDown={handleMouseDownDrag}
              style={{
                left: `${trimmerPosition}px`,
                width: `${trimmerWidth}px`,
                cursor: "pointer",
              }}
            >
              <Handle data-handle="left" onMouseDown={handleMouseDownResize} />
              <Handle data-handle="right" onMouseDown={handleMouseDownResize} />
              <Tooltip style={{ left: endTime - startTime > 50 ? -15 : -35 }}>
                {formatTooltipFromSeconds(startTime)}
              </Tooltip>
              <Tooltip style={{ right: endTime - startTime > 50 ? -15 : -35 }}>
                {formatTooltipFromSeconds(endTime)}
              </Tooltip>
            </TrimBox>
          </>
        )}
      </TimelineContainer>

      <Container
        style={{
          paddingTop: "20px",
        }}
      >
        <Button
          shape="circle"
          icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
          onClick={togglePlay}
          loading={isMediaLoading}
        />
      </Container>
    </>
  );
};

export default MediaPlaylist;
