import styled from "styled-components";

import {
  borderRadius,
  color,
  deviceSize,
  elementSize,
  fontSize,
  fontWeight,
  spacing,
} from "src/styles/variables";

export const ScrollableContent = styled.div`
  overflow: auto;
  height: 230px;
`;

export const TopThree = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: ${elementSize.xl} 0 ${elementSize.xs} 0;
  & > div:nth-child(2) {
    order: ${(props) => props.usersNumber > 2 && "-1"};
  }
`;

export const LeaderboardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: ${(props) => (props.isFirst ? "8rem" : "7rem")};
  text-align: center;
  padding-top: ${spacing.lg};
  border: 1px solid ${color.gray};
  margin: ${spacing.md};
  position: relative;
  border-radius: ${borderRadius.xs};
  cursor: pointer;
  transition: transform 200ms ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

export const PlayerInfo = styled.div`
  font-size: ${(props) => (props.isScore ? fontSize.caption : fontSize.body)};
  opacity: ${(props) => (props.isScore ? 0.8 : 1)};
  font-weight: ${(props) => !props.isScore && fontWeight.medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  ::first-letter {
    text-transform: capitalize;
  }
`;

export const Position = styled(PlayerInfo)`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.displayXXLL};
  width: 41px;
  @media (max-width: ${deviceSize.large_phone}) {
    width: 30px;
  }
`;

export const LeaderBoardListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  margin: ${spacing.xs} auto;
  padding: 0 ${spacing.md};
  border-radius: ${borderRadius.lg};
  box-shadow: 0 1px 3px 0 ${color.gray};
  border: 1px solid ${color.gray};
  transition: 0.3s;
  &:hover {
    box-shadow: 0 4px 9px 0 ${color.gray};
  }
`;
