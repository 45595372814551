import { color, elementSize } from "src/styles/variables";
import {
  FloatingLabelInputWrapper,
  StyledAntInput,
  StyledAntTextArea,
  StyledLabel,
  StyledAntInputNumber,
  DivFlexColumn,
} from "./FloatingInputText.styles";
import { EvaluationSubTitle } from "../Evaluation/Evaluation.styles";
import { Select } from "antd";

export const FloatingSelectWrapper = ({ id, label, ...props }) => {
  return (
    <FloatingLabelInputWrapper>
      {props?.disabled ? (
        <DivFlexColumn>
          <EvaluationSubTitle>{label}: </EvaluationSubTitle>
          {props?.defaultValue?.join(", ") || "None"}
        </DivFlexColumn>
      ) : (
        <>
          <EvaluationSubTitle>{label}</EvaluationSubTitle>
          <Select {...props} id={id} />
        </>
      )}
    </FloatingLabelInputWrapper>
  );
};

export const FloatingLabelInput = ({
  id,
  label,
  inputValue,
  required,
  disabled,
  error,
  onChangeInput,
  showLabelOnDisable,
  ...props
}) => {
  return (
    <FloatingLabelInputWrapper>
      {disabled ? (
        <DivFlexColumn style={{ paddingBottom: elementSize.sm }}>
          {showLabelOnDisable && (
            <EvaluationSubTitle>{label}: </EvaluationSubTitle>
          )}{" "}
          {inputValue}
        </DivFlexColumn>
      ) : (
        <>
          <StyledAntInput
            id={id}
            {...props}
            value={inputValue}
            disabled={disabled}
            error={error}
            onChange={onChangeInput}
            required
            placeholder=""
          />
          <StyledLabel htmlFor={id}>
            {label} {required && <span style={{ color: color.orange }}>*</span>}
          </StyledLabel>
        </>
      )}
    </FloatingLabelInputWrapper>
  );
};

export const FloatingLabelInputNumber = ({
  id,
  label,
  inputValue,
  required,
  disabled,
  showLabelOnDisable,
  error,
  onChangeInput,
  ...props
}) => {
  return (
    <FloatingLabelInputWrapper>
      {disabled ? (
        <DivFlexColumn>
          {showLabelOnDisable && (
            <EvaluationSubTitle>{label}: </EvaluationSubTitle>
          )}{" "}
          {inputValue}
        </DivFlexColumn>
      ) : (
        <>
          <StyledAntInputNumber
            id={id}
            {...props}
            value={inputValue}
            disabled={disabled}
            error={error}
            onChange={onChangeInput}
            required
            placeholder=""
          />
          <StyledLabel htmlFor={id}>
            {label} {required && <span style={{ color: color.orange }}>*</span>}
          </StyledLabel>
        </>
      )}
    </FloatingLabelInputWrapper>
  );
};

export const FloatingLabelTextArea = ({
  id,
  label,
  required,
  inputValue,
  onChangeInput,
  disabled,
  showLabelOnDisable,

  error,
  ...props
}) => {
  return (
    <FloatingLabelInputWrapper>
      {disabled ? (
        <DivFlexColumn>
          {showLabelOnDisable && (
            <EvaluationSubTitle>{label}: </EvaluationSubTitle>
          )}{" "}
          {inputValue}
        </DivFlexColumn>
      ) : (
        <>
          <StyledAntTextArea
            id={id}
            {...props}
            required
            value={inputValue}
            disabled={disabled}
            error={error}
            onChange={onChangeInput}
            placeholder=""
          />
          <StyledLabel htmlFor={id}>
            {label} {required && <span style={{ color: color.orange }}>*</span>}
          </StyledLabel>
        </>
      )}
    </FloatingLabelInputWrapper>
  );
};
