import { Typography } from "antd";
import { StyledCard } from "./Subscription.styles";
const { Text, Title } = Typography;

export const MiniSubscriptionDetailsCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: React.ReactNode;
  sx?: React.CSSProperties;
}> = ({ icon, label, value, sx }) => (
  <StyledCard style={sx}>
    {icon}
    <Title level={5}>{label}</Title>
    <Text>{value}</Text>
  </StyledCard>
);
