import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { arrayUnion } from "firebase/firestore";
import { updateDocInFireStore, addDocumentWithId } from "src/firebaseAuth";
import { List, Button, Spin, Dropdown, Space, Popover, Mentions } from "antd";
import { SendOutlined, DownOutlined, StopOutlined } from "@ant-design/icons";
import { color, elementSize, fontSize, spacing } from "src/styles/variables";
import { useUserState } from "src/state/UserState";
import { formatDateToHumanReadable, formatTime } from "src/utils/utils";
import {
  ChatMessageItem,
  CustomChatCard,
  TimeTextStyle,
} from "./Coaching.styles";
import { ChatMessage } from "src/utils/types";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { CollaboratorAvatar } from "src/components";
import { COLLECTION_DATA } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import { Citations, Citation } from "./Citations";
import { TrucoChatActions, formatTrucoResponse } from "./TrucoChatActions";

export const DeletedMessage = () => {
  return (
    <span>
      <StopOutlined /> This message has been deleted!
    </span>
  );
};

export const formatMentions = (text, allUsers) => {
  const mentionedEmails = [];

  const formattedMsg = text.replace(
    /@([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g,
    (match, email) => {
      const user = allUsers.find((user) => user.email === email);
      if (user) {
        mentionedEmails.push(email); // Add the email to the mentionedEmails array
        return `@${user.name}(${user.email})`; // Format the mention
      }
      return match; // Return the original match if no user is found
    },
  );
  return { formattedMsg, mentionedEmails };
};

interface ChatProps {
  header?: boolean;
  border?: boolean;
  initialMessages?: ChatMessage[] | void;
  onNewMessage?: (
    message: ChatMessage,
    allMessages: ChatMessage[],
    setMessages: any,
    setTyping: any,
    mention?: String[],
  ) => Promise<ChatMessage | void>;
  onDeleteMessage?: (
    messageIndex: number,
    setMessages: any,
  ) => Promise<ChatMessage | void>;
  citations?: Citation[];
  [key: string]: any; // Allows any additional properties
}

export const Chat: React.FC<ChatProps> = ({
  header = true,
  border = true,
  initialMessages = null,
  onNewMessage = async () => undefined,
  onDeleteMessage = null,
  citations = null,
  markAsRead = () => undefined,
  ...props
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>(
    initialMessages ? initialMessages : [],
  );
  const [isTyping, setTyping] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const { user } = useUserState();
  const chatContainerRef = useRef(null); // Ref for the chat container
  const lastMessageRef = useRef(null); // Ref to last message

  const { collaborators, pendingCollaborators } = useOrganizationState();
  const allUsers = useMemo(
    () => [...(collaborators ?? []), ...(pendingCollaborators ?? [])],
    [collaborators, pendingCollaborators],
  );

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            markAsRead();
          }
        });
      },
      { threshold: 0.8 },
    );

    if (lastMessageRef.current) {
      observer.observe(lastMessageRef.current);
    }

    return () => {
      if (lastMessageRef.current) {
        observer.unobserve(lastMessageRef.current);
      }
    };
  }, [messages, markAsRead]);

  const sendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = {
        text: inputValue,
        name: user?.name,
        email: user?.email,
        photoURL: user?.photoURL,
        time: new Date().toISOString(),
      };
      const { formattedMsg, mentionedEmails } = formatMentions(
        inputValue,
        allUsers,
      );
      newMessage.text = formattedMsg;
      onNewMessage &&
        onNewMessage(
          newMessage,
          messages,
          setMessages,
          setTyping,
          mentionedEmails,
        );
      setInputValue("");
    }
  };

  const renderMessageText = (text) => {
    const parts = text.split(/(@[^\(]+\([^\)]+\))/g); // Split text at each mention

    return (
      <span>
        {parts.map((part, index) => {
          const match = part.match(/@([^\(]+)\(([^)]+)\)/);

          if (match) {
            const [, name, email] = match;
            const user = allUsers.find((user) => user.email === email);

            return (
              <Popover
                content={
                  <Space>
                    <CollaboratorAvatar collaborator={user} />
                    <Space direction="vertical" size={0}>
                      <span style={{ fontWeight: "bold" }}>{name}</span>
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        {email}
                      </span>
                    </Space>
                  </Space>
                }
                key={index}
              >
                <span style={{ color: color.orange, cursor: "pointer" }}>
                  @{name}
                </span>
              </Popover>
            );
          }

          // For plain text or markdown parts
          return <ReactMarkdown key={index}>{part}</ReactMarkdown>;
        })}
      </span>
    );
  };

  const sendInstruction = (instruction: ChatMessage) => {
    onNewMessage && onNewMessage(instruction, messages, setMessages, setTyping);
  };

  return (
    <CustomChatCard
      header={header}
      border={border}
      title="Chat with Truco"
      {...props}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: props?.height ? props.height : "100%",
        }}
      >
        <div
          ref={chatContainerRef} // Attach the ref here
          style={{
            display: "flex",
            flexDirection: "column",
            height: props?.height ? props.height : "63vh",
            justifyContent: "space-between",
            overflow: "auto",
          }}
        >
          <List
            dataSource={messages}
            renderItem={(item, index) => {
              if (!item?.email) return;
              if (item?.hide) return;
              const deleteAccess = user?.email === item?.email;
              const messageActions =
                onDeleteMessage && deleteAccess && !item.deleted ? (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          danger: true,
                          label: (
                            <span
                              onClick={() =>
                                onDeleteMessage(index, setMessages)
                              }
                            >
                              Delete Message
                            </span>
                          ),
                        },
                      ],
                    }}
                  >
                    <DownOutlined />
                  </Dropdown>
                ) : null;

              const description = item?.start
                ? `[${formatTime(item?.start)} : ${formatTime(item?.end)}] ${
                    item?.text
                  }`
                : item?.text;
              const isLastItem = index === messages.length - 1;
              return (
                <List.Item ref={isLastItem ? lastMessageRef : null}>
                  <ChatMessageItem
                    avatar={
                      <CollaboratorAvatar key={index} collaborator={item} />
                    }
                    title={
                      <SpaceBetweenDiv>
                        <div>{item.name}</div>

                        <Space>
                          {item.time && (
                            <TimeTextStyle>
                              {formatDateToHumanReadable(item.time)}
                            </TimeTextStyle>
                          )}
                          {messageActions}
                        </Space>
                      </SpaceBetweenDiv>
                    }
                    description={
                      item?.deleted ? (
                        <DeletedMessage />
                      ) : (
                        <>
                          {renderMessageText(description)}
                          {item?.citations?.length > 0 && (
                            <Citations
                              citations={item.citations}
                              size={props?.size}
                            />
                          )}
                        </>
                      )
                    }
                  />
                </List.Item>
              );
            }}
          />
          {isTyping && <Spin tip="Truco is typing" />}
        </div>

        {messages?.length < 2 && props?.showActions && (
          <TrucoChatActions sendInstruction={sendInstruction} />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: elementSize.sm,
            marginTop: spacing.xs,
          }}
        >
          <Mentions
            disabled={isTyping}
            autoSize={true}
            value={inputValue}
            onChange={setInputValue}
            style={{ fontSize: "16px", width: "100%" }}
            placeholder={
              isTyping
                ? "Waiting for a response..."
                : "Type a message and press Enter to send..."
            }
            onPressEnter={(e) => {
              if (!e.shiftKey) {
                sendMessage();
              } else {
                setInputValue(inputValue + "\n");
              }
            }}
          >
            {allUsers.map((user) => (
              <Mentions.Option key={user.email} value={user.email}>
                <Space direction="horizontal">
                  <CollaboratorAvatar collaborator={user} />
                  {user.name}
                </Space>
              </Mentions.Option>
            ))}
          </Mentions>
          <Button
            type="primary"
            onClick={sendMessage}
            style={{
              backgroundColor: color.orange,
              borderRadius: "50%",
            }}
          >
            <SendOutlined
              style={{
                color: "white",
                fontWeight: "bolder",
                fontSize: fontSize.bodyLarge,
              }}
            />
          </Button>
        </div>
      </div>
    </CustomChatCard>
  );
};

export const TrucoChat = ({
  initialMessage = null,
  citations = null,
  ...props
}) => {
  const { organization } = useOrganizationState();
  const { user } = useUserState();
  const [chatId, setChatId] = useState(null);
  const initialMessages: [ChatMessage] = [
    formatTrucoResponse(
      initialMessage
        ? initialMessage
        : "Hi I'm Truco, I'm here to help you find your rhythm while talking to customers. Ask me anything and I'll try to help!",
      citations,
      true,
    ),
  ];

  async function fetchTrucoReply(allMessages: ChatMessage[]) {
    const response = await axios.post(
      `${process.env.REACT_APP_TRUCO_BACKEND}/get_chat_reply`,
      { messages: allMessages, organization },
      { headers: { "Content-Type": "application/json" } },
    );

    return formatTrucoResponse(response.data.text, response.data?.citations);
  }

  async function fetchTrucoReplyStream(
    allMessages,
    setTyping,
    setMessages,
    organization,
    newChatId,
    updateDocInFireStore,
    formatTrucoResponse,
  ) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRUCO_BACKEND}/get_chat_reply`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: allMessages, organization }),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch Truco reply: ${response.statusText}`);
      }

      setTyping(true);

      let buffer = "";
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processStream = async () => {
        let { done, value } = await reader.read();
        let accumulatedText = "";
        let accumulatedCitations = [];

        const updateChunk = (chunk, finalChunk = false) => {
          try {
            const chunkData = JSON.parse(chunk);
            console.log("Received chunk", chunkData);

            setMessages((prevMessages) => {
              accumulatedText += chunkData.text || "";
              if (chunkData.citations)
                accumulatedCitations.push(...chunkData.citations);

              const trucoReply = formatTrucoResponse(
                accumulatedText,
                accumulatedCitations,
              );

              if (finalChunk) {
                updateDocInFireStore(
                  `/organization/${organization}/${COLLECTION_DATA.CHATS}/${newChatId}`,
                  {
                    feedback: arrayUnion(trucoReply),
                    lastFeedbackTime: new Date().toISOString(),
                  },
                );
              }

              return [...prevMessages.slice(0, -1), trucoReply];
            });
          } catch (err) {
            console.error("JSON parsing error:", err, chunk);
          }
        };

        while (!done) {
          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;

          let boundary = buffer.indexOf("\n");

          while (boundary !== -1) {
            const jsonString = buffer.slice(0, boundary);
            buffer = buffer.slice(boundary + 1);
            updateChunk(jsonString);
            boundary = buffer.indexOf("\n");
          }

          ({ done, value } = await reader.read());
        }

        if (buffer) {
          updateChunk(buffer, true);
        }

        setTyping(false);
      };

      await processStream();
    } catch (error) {
      console.error("Error fetching Truco reply:", error);
      setTyping(false);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        formatTrucoResponse("I'm sorry, I'm unable to help at this time."),
      ]);
    }
  }

  async function createChatDocument() {
    if (chatId) return chatId;
    const newChatId = await addDocumentWithId(
      `/organization/${organization}/${COLLECTION_DATA.CHATS}`,
      {
        feedback: [],
        participants: [user?.email],
        lastFeedbackTime: new Date().toISOString(),
      },
    );
    setChatId(newChatId);
    return newChatId;
  }

  const onNewMessage = async (
    newMessage: ChatMessage,
    allMessages: ChatMessage[],
    setMessages: any,
    setTyping: any,
  ) => {
    const newChatId = await createChatDocument();
    setMessages((prevMessages) => {
      updateDocInFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CHATS}/${newChatId}`,
        {
          feedback: arrayUnion(newMessage),
          lastFeedbackTime: newMessage.time,
        },
      );
      return [...prevMessages, newMessage];
    });
    setTyping(true);
    fetchTrucoReplyStream(
      [...allMessages, newMessage],
      setTyping,
      setMessages,
      organization,
      newChatId,
      updateDocInFireStore,
      formatTrucoResponse,
    );
  };

  return (
    <Chat
      {...props}
      showActions={true}
      initialMessages={initialMessages}
      onNewMessage={onNewMessage}
      citations={citations}
    />
  );
};
