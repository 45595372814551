import React, { useState } from "react";
import type { UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, Space, message } from "antd";

import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { color } from "src/styles/variables";

import { GoogleDrivePicker } from "./GoogleDrivePicker";
import { MarginTop, OrDividor } from "../User/User.styles";
import { processFile, uploadFileToGCS } from "./DragDropFiles.utils";
import { STORAGE_TYPE } from "src/utils/enums";
import { Loading } from "../Loading";
import { useFiltersState } from "src/state/FiltersState";
import { EvaluationSelector } from "../Global/EvaluationSelector";

const { Dragger } = Upload;

export const DragDropFiles = ({ minHeight = "500px", padding = "5%" }) => {
  const [files, setFiles] = useState([]);
  const { organization, transcripts } = useOrganizationState();
  const { loading } = useFiltersState();
  const { user } = useUserState();

  const [evaluationId, setEvaluationId] = useState(null);
  // should clean this in the future bad practice
  const [selectedDefault, setSelectedDefault] = useState(false);

  const handleEvaluationId = (selection) => {
    if (selection === "Default") {
      setSelectedDefault(true);
      return;
    }
    setEvaluationId(selection);
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: "audio/*,video/*",
    fileList: files,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(0))}%`,
    },
    customRequest: ({ file, onSuccess, onError, onProgress }) => {
      if (!selectedDefault && !evaluationId) {
        message.error("Please select an Evaluation type before uploading.");
        return;
      }

      if (typeof file === "string") {
        return;
      }
      if (!("name" in file)) {
        return;
      }

      let fileId = `${Date.now()}_${file?.name}`;
      fetch(`${process.env.REACT_APP_TRUCO_BACKEND}/get_file_url`, {
        method: "POST",
        body: JSON.stringify({
          file_id: fileId,
          method: "PUT",
          content_type: file?.type,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_API_TRUCO_API_KEY,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("get_file_url failed");
          }
          return response.json();
        })
        .then(({ url: signedUrl }) =>
          uploadFileToGCS(file, signedUrl, onError, onProgress),
        )
        .then(() =>
          processFile(
            {
              name: file?.name,
              file_id: fileId,
              mime_type: file?.type,
            },
            organization,
            user,
            STORAGE_TYPE.GCS,
            null,
            evaluationId,
            onProgress,
          ),
        )
        .then((response) => {
          onSuccess("done");
        })
        .catch((error) => {
          onError(error);
          console.log(error);
        });
    },
    onChange(info) {
      (evaluationId || selectedDefault) && setFiles(info.fileList);
    },
    onDrop(e) {
      if (!selectedDefault && !evaluationId) {
        message.error("Please select an Evaluation type before uploading.");
        return;
      }
      setFiles((prevFiles) => {
        return [...prevFiles, ...e.dataTransfer.files];
      });
    },
  };

  return (
    <>
      {!loading && transcripts ? (
        <div>
          <Dragger
            {...props}
            style={{
              minHeight: minHeight,
              padding: padding,
              backgroundColor: "#fff",
              border: `1px solid ${color.grayLight}`,
            }}
          >
            <Space direction="vertical">
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: color.orange }} />
              </p>
              <EvaluationSelector handleEvaluationId={handleEvaluationId} />

              <p className="ant-upload-text">
                Click or drag files to this area to upload
              </p>
              <OrDividor>
                <div>Or</div>
              </OrDividor>
              <MarginTop>
                <GoogleDrivePicker
                  selectedDefault={selectedDefault}
                  evaluationId={evaluationId}
                  setFiles={setFiles}
                />
              </MarginTop>
            </Space>
          </Dragger>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
