// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  collection,
  deleteDoc,
  query,
  getDocs,
  limit,
  orderBy,
  where,
  startAfter,
  or,
} from "firebase/firestore";
import { filterUserFields } from "./utils/utils";
import { SANDBOX_TRIALS } from "./utils/variables";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

export const getUser = async (user) => {
  const docRef = doc(db, "/users", user.uid);
  const document = await getDoc(docRef);
  if (!document.exists()) {
    return null;
  }
  const userDocumentData = document.data();
  return userDocumentData;
};

export const getRecordFromFireStore = async (
  queryPath,
  converter = null,
  defaultValue = null,
) => {
  try {
    let docRef = doc(db, queryPath);
    if (converter) {
      docRef = docRef.withConverter(converter);
    }
    const document = await getDoc(docRef);

    if (!document.exists()) {
      return defaultValue;
    }

    const data = document.data();
    return data;
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};
export const getRecordFromFireStoreWithQuery = async (
  collectionPath,
  fieldQueries,
  converter = null,
  defaultValue = null,
) => {
  try {
    const collRef = collection(db, collectionPath);

    let whereConditions;
    if (Array.isArray(fieldQueries)) {
      whereConditions = fieldQueries.map(({ fieldPath, opStr, value }) =>
        where(fieldPath, opStr, value),
      );
    } else {
      const { fieldPath, opStr, value } = fieldQueries;
      whereConditions = [where(fieldPath, opStr, value)];
    }

    const q = query(collRef, or(...whereConditions)).withConverter(
      converter || null,
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No matching documents found.");
      return defaultValue;
    }

    const document = querySnapshot.docs[0];
    return document.data();
  } catch (error) {
    console.error("Error fetching document with query:", error);
    throw new Error("Failed to fetch document.");
  }
};

export const getRecordsFromFireStore = async (
  queryPath,
  queryConstraints = [],
  converter = null,
  transformFunction = null,
  limitDocs = null,
  startAfterDoc = null, // Add this parameter to handle pagination
) => {
  try {
    let docRef = query(collection(db, queryPath));
    let queryConditions = [];

    queryConstraints.forEach((constraint) => {
      const { comparisonField, comparisonOperator, value, order } = constraint;
      if (comparisonField) {
        if (value !== undefined && comparisonOperator) {
          queryConditions.push(
            where(comparisonField, comparisonOperator, value),
          );
        }

        if (order) {
          queryConditions.push(orderBy(comparisonField, order));
        }
      }
    });

    if (limitDocs) {
      queryConditions.push(limit(limitDocs));
    }

    if (startAfterDoc) {
      queryConditions.push(startAfter(startAfterDoc));
    }

    if (converter) {
      docRef = docRef.withConverter(converter);
    }

    const documentSnapshots = await getDocs(query(docRef, ...queryConditions));

    let data = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    data = transformFunction ? transformFunction(data) : data;

    return {
      data,
      lastDoc: documentSnapshots.docs[documentSnapshots.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const addUser = async (user, authProvider) => {
  const userDocumentData = await getUser(user);
  user.trialsLeft = SANDBOX_TRIALS;
  if (
    userDocumentData?.photoURL !== user.photoURL ||
    user.displayName !== userDocumentData?.name
  ) {
    const combinedUserData = filterUserFields({
      ...(userDocumentData ?? {}),
      ...user,
      authProvider,
    });
    await setDoc(doc(db, "/users", user.uid), combinedUserData);
  }
};

export const addForm = async (user, formData) => {
  await updateDoc(doc(db, "/users", user.uid), formData);
};

export const addDocumentWithId = async (queryPath, data, id = undefined) => {
  try {
    let docRef;

    if (id) {
      // If an ID is provided, create or update the document with that ID
      docRef = doc(db, queryPath, id);
      await setDoc(docRef, data); // This will overwrite the document at this ID
    } else {
      // If no ID is provided, let Firestore automatically generate an ID
      docRef = await addDoc(collection(db, queryPath), data);
    }

    return docRef.id; // Return the document ID
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updateDocInFireStore = async (
  queryPath,
  update,
  converter = null,
) => {
  const docRef = doc(db, queryPath);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (converter) {
        const convertedData = converter.toFirestore(update);
        await updateDoc(docRef, convertedData);
      } else {
        await updateDoc(docRef, update);
      }
      console.log("Document successfully updated!");
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};
export const hardDeleteDocInFireStore = async (queryPath) => {
  try {
    await deleteDoc(doc(db, queryPath));
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error removing document: ", error);
  }
};

export const createOrUpdateDocInFirestore = async (queryPath, update) => {
  const docRef = doc(db, queryPath);
  try {
    // Use setDoc with merge: true to update or create as needed
    await setDoc(docRef, update, { merge: true });
    console.log(`Document created or updated successfully!`);
  } catch (error) {
    console.error("Error creating or updating document: ", error);
  }
};

export async function getFirebaseIdToken() {
  const auth = getAuth(); // Get the Firebase Auth instance

  // Return a Promise that resolves when the auth state is known
  return new Promise((resolve, reject) => {
    // Set up the observer for the auth state
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Get the ID token of the authenticated user
          const idToken = await user.getIdToken();
          resolve(idToken); // Resolve with the ID token
        } catch (error) {
          console.error("Error fetching Firebase ID token:", error);
          reject(new Error("Failed to retrieve ID token"));
        }
      } else {
        console.log("No user is currently logged in");
        resolve(null); // Resolve with null if no user is logged in
      }
    });
  });
}
