import {
  Tooltip,
  notification,
  List,
  Avatar,
  Select,
  message,
  Modal,
} from "antd";

import { color, fontSize } from "src/styles/variables";

import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { ACCESS_TYPE } from "src/utils/enums";

import { Dangerous, Verified } from "@mui/icons-material";
import { useState } from "react";
import { humanize, isReviewerInCalibration } from "src/utils/utils";
import { updateTranscriptToDb } from "../../Dashboard.utils";
import { CollaboratorAvatar } from "src/components/Global";
import { ParticipantsContainer } from "./AnalysisContent.styles";
import { useNavigate } from "react-router-dom";
import { DeleteWithPopConfirm, UserSelect } from "src/components";

export const Team = ({ modalData, setModalData, handleEvaluation }) => {
  const { id, evaluation, calibration, team } = modalData;
  const [api, contextHolder] = notification.useNotification();
  let verifications = [...new Set(evaluation?.verifications ?? [])];
  const navigate = useNavigate();
  if (
    evaluation?.verified &&
    !verifications?.includes(modalData?.reviewer?.email)
  )
    verifications.push(modalData?.reviewer?.email);

  const { organization, transcripts, setTranscripts } = useOrganizationState();
  const { user } = useUserState();
  const [addUser, setAddUser] = useState(null);
  const [editUserRoleIndex, setEditUserRoleIndex] = useState(null); // Track which user is being edited

  const handleEditClick = (index) => {
    if (editUserRoleIndex === index) {
      setEditUserRoleIndex(null); // Deselect the user if clicked again
    } else {
      setEditUserRoleIndex(index); // Set the index of the user being edited
    }
  };

  // needs edit
  const editPermission =
    modalData?.reviewer?.email === user?.email ||
    [ACCESS_TYPE.reviewer, ACCESS_TYPE.owner, ACCESS_TYPE.trucoAdmin].includes(
      user?.accessType,
    );

  const handleVerification = (newVerification, participant) => {
    if (modalData?.reviewer?.email === participant?.email) {
      evaluation.verified = newVerification;
      handleEvaluation("verified", newVerification);
    }

    if (!participant?.email) {
      api["error"]({
        message: "No user assigned",
        description: `Select user to continue`,
      });
    } else if (participant.email !== user.email) {
      api["error"]({
        message: "No permissions",
        description: `Only ${participant.name} can take this action`,
      });
    } else {
      const participantIndex = verifications.indexOf(participant?.email);
      if (newVerification && participantIndex === -1) {
        verifications.push(user?.email);
        handleEvaluation("verifications", verifications);
      } else if (!newVerification) {
        verifications.splice(participantIndex, 1);
        handleEvaluation("verifications", verifications);
      }
    }
  };

  const handleUser = (user, prevUser = null) => {
    let updates = {};

    const filterTeamByRole = (role) =>
      modalData.team.filter((member) => member.conversationRole !== role);

    const filterTeamByEmail = (email) =>
      modalData.team.filter((member) => member.user.email !== email);

    const updateMetadata = (role, email) => {
      updates[`metadata.${role}`] = email;
      modalData[role] = email;
    };

    const applyChanges = () => {
      if (!user && prevUser?.email) {
        modalData.team = modalData.team.filter(
          (item) =>
            item.user.email !== prevUser.email ||
            item.conversationRole !== prevUser.conversationRole,
        );
        updateMetadata(prevUser.conversationRole, null);
      }

      if (user?.email && user?.email !== prevUser?.email) {
        const existingUserForRole = modalData.team.find(
          (member) => member.conversationRole === user.conversationRole,
        );
        if (existingUserForRole) {
          modalData.team = filterTeamByRole(user.conversationRole);
        }
        modalData.team = filterTeamByEmail(user.email);

        modalData.team.push({
          user: {
            email: user.email,
            name: user.name,
            photoURL: user.photoURL,
          },
          conversationRole: user.conversationRole,
        });

        updateMetadata(user.conversationRole, user.email);
      }

      const updatedTranscripts = transcripts.map((transcript) => {
        if (transcript.id === id) {
          transcript.team = modalData.team;
          if (user?.conversationRole) {
            transcript[user.conversationRole] = user.email;
          }
          if (prevUser?.conversationRole) {
            transcript[prevUser.conversationRole] = prevUser.email;
          }
        }
        return transcript;
      });

      setModalData(modalData);
      updateTranscriptToDb(organization, id, updates);
      setTranscripts(updatedTranscripts);
      setAddUser(false);
    };

    const confirmReplacement = (existingUserForRole) => {
      Modal.confirm({
        title: `Replace ${existingUserForRole.user.email}?`,
        content: (
          <div>
            Are you sure you want to replace{" "}
            <b>{existingUserForRole.user.email}</b> with <b>{user.email}</b> as{" "}
            {user.conversationRole}?
          </div>
        ),
        onOk: applyChanges,
        onCancel: () => setAddUser(false),
        okButtonProps: {
          style: { backgroundColor: color.orange, borderColor: color.orange },
        },
        cancelButtonProps: {
          style: { color: color.orange, borderColor: color.orange },
        },
      });
    };

    if (user?.email && user?.email !== prevUser?.email) {
      const existingUserForRole = modalData.team.find(
        (member) => member.conversationRole === user.conversationRole,
      );
      const existingUserSameEmail = modalData.team.find(
        (member) => member.user.email === user.email,
      );

      if (existingUserSameEmail) {
        message.error(
          `${user.email} is already in the team. You can only change their role.`,
        );
        setAddUser(false);
        return;
      }

      existingUserForRole
        ? confirmReplacement(existingUserForRole)
        : applyChanges();
    } else {
      applyChanges();
    }
  };

  const handleRoleChange = (newRole, currentAssignedUser) => {
    const filterTeamByRole = (role) =>
      modalData.team.filter((member) => member.conversationRole !== role);

    const filterTeamByEmail = (email) =>
      modalData.team.filter((member) => member.user.email !== email);

    const updateMetadata = (role, email) => {
      let updates = {};
      updates[`metadata.${role}`] = email;
      modalData[role] = email;
      return updates;
    };

    const existingUserForNewRole = modalData.team.find(
      (member) => member.conversationRole === newRole,
    );

    const applyRoleChange = () => {
      let updates = {};

      modalData.team = filterTeamByRole(currentAssignedUser.conversationRole);
      updates = {
        ...updates,
        ...updateMetadata(currentAssignedUser.conversationRole, null),
      };

      if (existingUserForNewRole) {
        modalData.team = filterTeamByEmail(existingUserForNewRole.user.email);
        updates = { ...updates, ...updateMetadata(newRole, null) };
      }

      modalData.team.push({
        user: {
          email: currentAssignedUser.email,
          name: currentAssignedUser.name,
          photoURL: currentAssignedUser.photoURL,
        },
        conversationRole: newRole,
      });

      updates = {
        ...updates,
        ...updateMetadata(newRole, currentAssignedUser.email),
      };

      const updatedTranscripts = transcripts.map((transcript) => {
        if (transcript.id === id) {
          transcript.team = modalData.team;
          transcript[newRole] = currentAssignedUser.email;
        }
        return transcript;
      });

      setModalData(modalData);
      updateTranscriptToDb(organization, id, updates);
      setTranscripts(updatedTranscripts);
    };

    if (existingUserForNewRole) {
      Modal.confirm({
        title: `Replace ${existingUserForNewRole.user.email}?`,
        content: (
          <div>
            Changing <b>{currentAssignedUser.email}</b>'s role to{" "}
            <b>{newRole}</b> will remove{" "}
            <b>{existingUserForNewRole.user.email}</b> from this role. Are you
            sure you want to proceed?
          </div>
        ),
        onOk: applyRoleChange,
        onCancel: () => setAddUser(false),
        okButtonProps: {
          style: { backgroundColor: color.orange, borderColor: color.orange },
        },
        cancelButtonProps: {
          style: { color: color.orange, borderColor: color.orange },
        },
      });
    } else {
      applyRoleChange();
    }
  };

  return (
    <>
      {contextHolder}
      <List
        dataSource={
          editPermission
            ? [...team, { isAddButton: true }] // Add a dummy item at the end if editPermission is true
            : [...team] // Only display the team members if editPermission is false
        }
        renderItem={(assignedUser: any, index: number) => {
          if (assignedUser.isAddButton) {
            return (
              <List.Item onClick={() => setAddUser(true)}>
                <List.Item.Meta
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: color.orange,
                      }}
                      icon={<PlusOutlined />}
                    />
                  }
                  title={
                    addUser && (
                      <UserSelect
                        key={addUser}
                        handleUser={handleUser}
                        defaultUser={null}
                      />
                    )
                  }
                  description={
                    <span
                      style={{
                        color: color.orange,
                        padding: `0px 11px`,
                      }}
                    >
                      Add Teammate
                    </span>
                  }
                />
              </List.Item>
            );
          }

          const currentAssignedUser = assignedUser?.user;

          const itemVerified = verifications.includes(
            currentAssignedUser?.email,
          );
          const userType = assignedUser?.conversationRole;
          const isCalibrator = isReviewerInCalibration(
            calibration,
            currentAssignedUser,
          );
          const canEdit =
            editPermission &&
            (userType !== ACCESS_TYPE.reviewer ||
              (userType === ACCESS_TYPE.reviewer && !isCalibrator));

          return (
            <List.Item
              actions={[
                <Tooltip
                  title={itemVerified ? "Unverify" : "Verify"}
                  style={{
                    color: `${itemVerified ? color.red : "inherit"}`,
                    textDecoration: "underline",
                  }}
                >
                  {itemVerified ? (
                    <CloseSquareOutlined
                      onClick={() =>
                        handleVerification(!itemVerified, currentAssignedUser)
                      }
                      style={{
                        color: color.red,
                      }}
                    />
                  ) : (
                    <CheckSquareOutlined
                      onClick={() =>
                        handleVerification(!itemVerified, currentAssignedUser)
                      }
                      style={{
                        color: color.green,
                      }}
                    />
                  )}
                </Tooltip>,
                canEdit && (
                  <Tooltip
                    title={"Remove User"}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    {DeleteWithPopConfirm(
                      "Remove Teammate?",
                      "Are you sure you want to remove this user from conversation? ",
                      (e) => {
                        e.stopPropagation();
                        handleUser(null, {
                          ...currentAssignedUser,
                          conversationRole: userType,
                        });
                      },
                      false,
                    )}
                  </Tooltip>
                ),
                canEdit && (
                  <Tooltip
                    title={"Edit User Role Only in Conversation"}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    <EditOutlined onClick={() => handleEditClick(index)} />
                  </Tooltip>
                ),
              ]}
            >
              <List.Item.Meta
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                avatar={
                  <CollaboratorAvatar
                    key={index}
                    collaborator={currentAssignedUser}
                  />
                }
                title={
                  <ParticipantsContainer
                    onClick={() => {
                      navigate(
                        `?user=${encodeURIComponent(currentAssignedUser.email)}`,
                      );
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: fontSize.bodyLarge,
                      }}
                    >
                      {currentAssignedUser?.name}
                      <Tooltip title={itemVerified ? "Verified" : "Unverified"}>
                        {itemVerified ? (
                          <Verified
                            style={{
                              color: color.olive,
                              marginLeft: "8px",
                            }}
                          />
                        ) : (
                          <Dangerous
                            style={{
                              color: color.red,
                              marginLeft: "8px",
                            }}
                          />
                        )}
                      </Tooltip>
                    </div>
                  </ParticipantsContainer>
                }
                // Editable role selector
                description={
                  editUserRoleIndex === index ? (
                    <Select
                      value={userType}
                      style={{ width: "100px" }}
                      onChange={(value) =>
                        handleRoleChange(value, {
                          ...currentAssignedUser,
                          conversationRole: userType,
                        })
                      }
                    >
                      {[
                        ACCESS_TYPE.agent,
                        ACCESS_TYPE.lead,
                        ACCESS_TYPE.reviewer,
                      ].map((role) => (
                        <Select.Option key={role} value={role}>
                          {humanize(role)}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    humanize(userType)
                  )
                }
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};
