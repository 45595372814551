import React from "react";
import { SectionContainer } from "./LandingPage.styles";
import { StandardLandingPage } from "./LandingPage";
import {
  AnimatedParagraph,
  AnimatedTitle,
  BlogContainer,
} from "./BlogPage/BlogPage.styles";
import { color, fontSize, spacing } from "src/styles/variables";
import { Card, List, Row, Col, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { OrangeButton } from "src/components";
import { TRUCO_CAL_LINK } from "src/utils/variables";

const PricingPlans = () => {
  const pricingOptions = [
    {
      title: "Pro",
      description:
        "Designed to scale quality assurance, deliver insights, and manage AI workflows efficiently. Ideal for teams managing up to 50 agents.",
      include: "What's included:",
      features: [
        "Auto QA with unlimited custom scorecards",
        "AI-driven sentiment, intent analysis, and summaries",
        "Analytics for customers, workforce, and products",
        "Comprehensive user role and permission management",
        "Automated assignments, fail policies, and custom category weights",
        "Calibration tools",
        "Dedicated support via Slack channel",
        "Integration with up to 2 external systems",
        "Usage-based pricing, best for teams up to 50 agents",
      ],
    },
    {
      title: "Enterprise",
      description:
        "Offers advanced, enterprise-grade features with white-glove support for larger teams or those needing customized solutions.",
      include: "Everything in Pro, plus:",
      features: [
        "Advanced AI Conversational Insights",
        "AI-driven triaging and data enrichment",
        "Support for an unlimited number of agents, billed per seat",
        "White-glove onboarding and support",
        "Custom-tuned AI models for specific needs",
        "Capability for unlimited integrations",
        "Legal review services",
        "Priority customer support",
      ],
    },
  ];
  return (
    <Row
      gutter={[48, 48]}
      justify="center"
      style={{ margin: `${spacing.xxxl} 0` }}
    >
      {pricingOptions &&
        pricingOptions.map((option) => (
          <Col xl={8} xs={24}>
            <Card
              bordered={false}
              style={{
                boxShadow:
                  "0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography.Title level={2}> {option.title} </Typography.Title>
              <Typography.Paragraph>{option?.description}</Typography.Paragraph>
              <Typography.Paragraph
                strong={true}
                style={{ margin: `${spacing.lg} 0` }}
              >
                {option.include}
              </Typography.Paragraph>
              <List
                dataSource={option?.features}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <CheckCircleOutlined style={{ color: color.green }} />
                      }
                      title={<span>{item}</span>}
                    />
                  </List.Item>
                )}
              />
              <OrangeButton
                text={"Contact us"}
                size={"small"}
                url={TRUCO_CAL_LINK}
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "left",
                  margin: `${spacing.lg} 0`,
                }}
              />
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export const PricingPage = () => {
  const metadata = {
    title: "Truco Pricing",
    keywords:
      "truco, pricing, enterprise, growth, customer support, quality assurance, sentiment analysis, insights, ai driven, summaries, transcripts, support, integrations",
    thumbnail_paragraph: "Truco's pricing",
  };
  return (
    <StandardLandingPage
      headercolor={color.olive}
      content={
        <>
          <BlogContainer>
            <AnimatedTitle>Pricing</AnimatedTitle>
            <AnimatedParagraph
              style={{ fontSize: fontSize.displayXXL, textAlign: "center" }}
              center
            >
              Straightforward pricing for quality assurance and insights. <br />{" "}
              Plans designed to fit any organization, any integration scenario,
              at any scale.
            </AnimatedParagraph>
          </BlogContainer>
          <SectionContainer>
            <PricingPlans />
          </SectionContainer>
        </>
      }
      metadata={metadata}
    />
  );
};
