import { useState, useEffect } from "react";
import { useUserState } from "src/state/UserState";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import useFetchCollection from "./useFetchCollection";
import { evaluationFormsTransform } from "src/utils/converter";

const useFetchCalibrations = () => {
  const { user } = useUserState();
  const [calibrationConditions, setCalibrationConditions] = useState([]);

  useEffect(() => {
    if (user?.accessType === ACCESS_TYPE.reviewer) {
      setCalibrationConditions([
        {
          comparisonField: "isDone",
          comparisonOperator: "==",
          value: true,
        },
      ]);
    }
  }, [user]);

  const { items: calibrations, fetchItems: fetchCalibrations } =
    useFetchCollection(
      COLLECTION_DATA.CALIBRATIONS,
      evaluationFormsTransform,
      calibrationConditions,
    );

  return { calibrations, fetchCalibrations };
};

export default useFetchCalibrations;
