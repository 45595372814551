import { useState } from "react";
import { SidebarFooter } from "../Evaluation.styles";
import { CustomizedEvaluationModal } from "src/components/Profile/Profile.styles";
import { Button } from "antd";

const EvaluationFooterButtons = ({
  isEditMode,
  handleSubmit,
  disabled,
  handleCancelEdit,
  hasChanges,
  toggleEditMode,
}) => {
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const handleClose = () => {
    setOpenCancelModel(false);
  };
  const handleCancelButton = () => {
    if (hasChanges) {
      setOpenCancelModel(true);
    } else {
      toggleEditMode();
    }
  };

  return (
    <>
      {!disabled && (
        <SidebarFooter>
          {isEditMode && <Button onClick={handleCancelButton}>Cancel</Button>}
          <Button
            onClick={() => handleSubmit(false)}
            type="primary"
            style={{ marginLeft: "8px" }}
          >
            Save
          </Button>
        </SidebarFooter>
      )}
      <CustomizedEvaluationModal
        width={"40vw"}
        key={`Collaborators-Modal`}
        title={`Are you sure you want to cancel your changes ?`}
        open={openCancelModel}
        onCancel={handleClose}
        closable={true}
        footer={null}
      >
        <SidebarFooter>
          <Button onClick={handleClose}>No</Button>

          <Button
            onClick={() => {
              handleCancelEdit();
              handleClose();
            }}
            type="primary"
            style={{ marginLeft: "8px" }}
          >
            Yes
          </Button>
        </SidebarFooter>
      </CustomizedEvaluationModal>
    </>
  );
};

export default EvaluationFooterButtons;
