import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import {
  Home,
  SignIn,
  SignUp,
  LandingPage,
  PrivacyPolicy,
  CustomerStory,
  Profile,
  Messenger,
  BlogRoutes,
  Assignments,
  Calibrations,
  PricingPage,
  BusinessInsights,
  TrucoChat,
  TrucoSearch,
  DragDropFiles,
  CustomersDashboard,
  AgentsDashboard,
  Dashboard,
  Terms,
  Signals,
  Evaluation,
} from "./components";
import { UserProvider, useUserState } from "./state/UserState";
import {
  OrganizationProvider,
  useOrganizationState,
} from "./state/OrganizationState";
import { CollectOrganizationData } from "./hooks/CollectOrganizationData";

import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import { FiltersProvider } from "./state/FiltersState";
import Hotjar from "@hotjar/browser";
import { isLocalHost } from "./utils/utils";
import {
  AdminRoute,
  OrganizationRoute,
  PublicOnlyRoute,
  TrucoAdminRoute,
} from "./RouteCheckingComponents";
import { ConfigProvider } from "antd";
import { color } from "./styles/variables";
import { CostCalculatorPage } from "./components/LandingPage/ROICalculator";
import { Playlist } from "./components/Playlist/Playlist";
import PlaylistDetails from "./components/Playlist/PlaylistDetails/PlaylistDetails";
import { ModalProvider } from "./state/UseModal";
import { DateAgentSelectionProvider } from "./state/UseAgentDateSelection";
import ModalManager from "./components/ModalManger/ModalManager";
import { ManageOrganizations } from "./components/ManageOrganizations/ManageOrganizations";
import { PaymentStatus } from "./components/Profile/Components/SubscriptionCard/PaymentStatus";
import dayjs from "dayjs";
import {
  EmptySubscriptionBackground,
  SubscriptionEndedModal,
} from "./components/Home/SubscriptionEndedModal";
import { AccountsDashboard } from "./components/AccountsDashboard/AccountsDashboard";

const App = () => {
  const { user } = useUserState();
  const { organizationSettings } = useOrganizationState(); // Assuming organization details are available in user state

  const home = user ? <Home /> : <LandingPage />;
  const [isSubscriptionValid, setIsSubscriptionValid] = useState(true); // State to track subscription status

  useEffect(() => {
    if (organizationSettings) {
      const isTrialOver = organizationSettings?.stripe?.free_trial_end
        ? dayjs().isAfter(dayjs(organizationSettings?.stripe?.free_trial_end)) // today is after free_trial_end
        : false;
      const isActiveSubscription =
        organizationSettings?.stripe?.subscription_status === "active";

      // Set state based on the logic
      setIsSubscriptionValid(isActiveSubscription || !isTrialOver);
    }
  }, [organizationSettings]);

  CollectOrganizationData();

  return (
    <Router>
      <ModalProvider>
        <ModalManager />
        {!isSubscriptionValid ? (
          <>
            <SubscriptionEndedModal />
            <Routes>
              <Route path="*" element={<EmptySubscriptionBackground />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={home} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />

            <Route
              path="/ergeon-case-study"
              element={<CustomerStory company="ergeon" />}
            />

            <Route element={<Home />}>
              <Route path="/conversations" element={<Dashboard />} />
              <Route path="/agents" element={<AgentsDashboard />} />
              <Route path="/customers" element={<CustomersDashboard />} />
              <Route path="/accounts" element={<AccountsDashboard />} />
              <Route path="/insights" element={<BusinessInsights />} />
              <Route path="/chat" element={<TrucoChat header={false} />} />
              <Route path="/search" element={<TrucoSearch />} />
              <Route path="/upload" element={<DragDropFiles />} />
              <Route path="/playlist" element={<Playlist />} />
              <Route
                path="/playlist/:encryptedOrganizationId/:encryptedPlaylistId"
                element={<PlaylistDetails />}
              />
              <Route
                path="manageOrgs"
                element={
                  <TrucoAdminRoute>
                    <ManageOrganizations />
                  </TrucoAdminRoute>
                }
              />
            </Route>
            <Route
              path="/payment-status"
              element={
                <OrganizationRoute>
                  <PaymentStatus />
                </OrganizationRoute>
              }
            />

            <Route
              key={"/pricing"}
              path={"/pricing"}
              element={<PricingPage />}
            />
            <Route
              key={"/roi"}
              path={"/roi"}
              element={<CostCalculatorPage />}
            />
            <Route path="/blogs/*" element={<BlogRoutes />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />

            <Route
              path="/signup"
              element={
                <PublicOnlyRoute>
                  <SignUp />
                </PublicOnlyRoute>
              }
            />
            <Route
              path="/signin"
              element={
                <PublicOnlyRoute>
                  <SignIn />
                </PublicOnlyRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <OrganizationRoute>
                  <Profile />
                </OrganizationRoute>
              }
            />

            <Route
              path="/messenger"
              element={
                <OrganizationRoute>
                  <Messenger />
                </OrganizationRoute>
              }
            />
            <Route
              path="evaluation/:id?"
              element={
                <AdminRoute>
                  <Evaluation />
                </AdminRoute>
              }
            />
            <Route
              path="signals/:id?"
              element={
                <AdminRoute>
                  <Signals />
                </AdminRoute>
              }
            />
            <Route
              path="assignments/:id?"
              element={
                <AdminRoute>
                  <Assignments />
                </AdminRoute>
              }
            />
            <Route
              path="calibrations/:id?"
              element={
                <AdminRoute>
                  <Calibrations />
                </AdminRoute>
              }
            />
          </Routes>
        )}
      </ModalProvider>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
if (!isLocalHost()) {
  Hotjar.init(Number(process.env.REACT_APP_HOTJAR_ID), 6);
}
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: color.orange,
        colorLink: color.orange,
      },
    }}
  >
    <UserProvider>
      <OrganizationProvider>
        <FiltersProvider>
          <DateAgentSelectionProvider>
            <App />
          </DateAgentSelectionProvider>
        </FiltersProvider>
      </OrganizationProvider>
    </UserProvider>
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
