import { Col, Flex, Row } from "antd";
import { useEffect, useState } from "react";
import { Filters } from "src/components";
import { useUserState } from "src/state/UserState";
import { FilterContainer, StyledFilterButton } from "../Dashboard.styles";
import { DateFilter } from "src/components/Filters/DateFilter";
import { useOrganizationState } from "src/state/OrganizationState";
import { QA_TYPES } from "src/utils/enums";
import { useLocation } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import { MobileOnly } from "src/styles/stylingComponents";
import { useMediaQuery } from "react-responsive";
import { deviceSize } from "src/styles/variables";

export enum DASHBOARD_TYPE {
  CONVERSATIONS = "conversations",
  INSIGHTS = "insights",
  SIGNALS = "signals",
}
interface DashboardActionsProps {
  transcripts: any[];
  filteredData?: any[];
  handleFilterChange: (filter: any) => void;
  showFiltersTranscripts?: boolean;
  onFilterShowClick?: () => void;
  type?: DASHBOARD_TYPE;
}

export const DashboardActions: React.FC<DashboardActionsProps> = ({
  transcripts,
  filteredData = null,
  handleFilterChange,
  showFiltersTranscripts = true,
  onFilterShowClick,
  type = DASHBOARD_TYPE.CONVERSATIONS,
}) => {
  const isMobile = useMediaQuery({
    maxWidth: `${deviceSize.small_phone_size}`,
  });
  const [showFilter, setShowFilter] = useState(!isMobile);
  const { user } = useUserState();
  const [filtersApplied, setFiltersApplied] = useState(false);
  const { evaluationForms } = useOrganizationState();
  const [firstClick, setFirstClick] = useState(true);
  const location = useLocation();
  const [selectedValues, setSelectedValues] = useState({
    evaluation_title: [],
    user:
      type === DASHBOARD_TYPE.INSIGHTS ||
      type == DASHBOARD_TYPE.SIGNALS ||
      (type === DASHBOARD_TYPE.CONVERSATIONS && location?.state?.removeUser)
        ? []
        : [user?.email],
    team: [],
    qaType: undefined,
    analysisKeys: [],
  });
  useEffect(() => {
    setShowFilter(!isMobile);
  }, [isMobile]);
  useEffect(() => {
    const filtersApplied =
      selectedValues.evaluation_title.length > 0 ||
      selectedValues.user.length > 0 ||
      selectedValues.team.length > 0 ||
      selectedValues.qaType !== undefined;
    setFiltersApplied(filtersApplied);
  }, [selectedValues]);
  useEffect(() => {
    const applyFilters = (updatedValues) => {
      let newData = transcripts || null;
      newData = newData?.filter(
        (transcript) =>
          (updatedValues?.evaluation_title?.length === 0 ||
            updatedValues.evaluation_title.includes(
              transcript?.evaluation_form?.title,
            )) &&
          (updatedValues?.user?.length === 0 ||
            updatedValues.user.some((email) =>
              transcript?.participants.some(
                (participant) => participant.email === email,
              ),
            ) ||
            updatedValues.user.includes(transcript?.reviewer?.email) ||
            updatedValues.user.includes(transcript?.agent?.email) ||
            updatedValues.user.includes(transcript?.lead?.email)) &&
          (updatedValues?.team?.length === 0 ||
            updatedValues?.team?.filter((t) =>
              evaluationForms?.[
                transcript?.evaluation_form?.id
              ]?.teams?.includes(t),
            )?.length > 0) &&
          ([null, QA_TYPES.ALL, undefined].includes(updatedValues?.qaType) ||
            (updatedValues?.qaType === QA_TYPES.MANUAL_QA &&
              transcript?.evaluation?.verified) ||
            (updatedValues?.qaType === QA_TYPES.AUTO_QA &&
              !transcript?.evaluation?.verified)),
      );

      handleFilterChange(newData);
    };
    applyFilters(selectedValues);
  }, [selectedValues, transcripts]);

  return (
    <FilterContainer>
      <Row justify={"space-between"}>
        <Col span={12}>
          {showFilter && showFiltersTranscripts && (
            <Filters
              transcripts={transcripts}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              setShowFilter={setShowFilter}
            />
          )}
        </Col>
        <Col span={12}>
          <Flex justify="flex-end" align="center" gap={"15px"}>
            <DateFilter
              type={
                type == DASHBOARD_TYPE.SIGNALS
                  ? DASHBOARD_TYPE.CONVERSATIONS
                  : type
              }
            />
            <MobileOnly>
              <StyledFilterButton
                filtersApplied={filtersApplied}
                icon={<FilterOutlined />}
                onClick={() => {
                  if (firstClick) {
                    onFilterShowClick?.();
                    setFirstClick(false);
                  }
                  setShowFilter(!showFilter);
                }}
              ></StyledFilterButton>
            </MobileOnly>
            {/* {filteredData && (
              <DownloadCSV data={filteredData} fileName={"data_truco"} />
            )} */}
          </Flex>
        </Col>
      </Row>
    </FilterContainer>
  );
};
