import { Progress } from "antd";
import styled from "styled-components";
import { color, deviceSize } from "src/styles/variables";

export const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 84px; /* Aspect ratio 16:9 for rectangular look */
`;

export const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    position: absolute;
  }
  .ant-progress-circle-path {
    stroke: ${(props) => (props.percent === 100 ? color.orange : color.orange)};
  }
`;

export const PlayPauseButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 30px;
  color: ${color.orange};
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: 25px;
  }
`;
