import styled from "styled-components";
import { spacing, color } from "../../styles/variables";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  > h4 {
    color: ${color.blackLight};
    line-height: ${spacing.lg};
    padding: 5px 10px 10px 0px;
  }
`;
