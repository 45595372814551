import {
  Button,
  Input,
  List,
  Popover,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { color, elementSize, spacing } from "src/styles/variables";
import {
  SearchOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  BorderlessSelect,
  CustomOption,
  CustomizedEvaluationModal,
  ResponsiveDescription,
  SpaceBetweenDiv,
  StyledInput,
  StyledInputGroup,
} from "../../Profile.styles";
import { InvitedUser, UserType } from "src/utils/types";
import { useOrganizationState } from "src/state/OrganizationState";
import { capitalizeFirstLetter, isValidEmail } from "src/utils/utils";
import { DeleteWithPopConfirm } from "src/components";
import { CustomizedText } from "../../../User/User.styles";
import { ACCESS_TYPE, COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { useUserState } from "src/state/UserState";
import {
  addDocumentWithId,
  auth,
  db,
  hardDeleteDocInFireStore,
  updateDocInFireStore,
} from "src/firebaseAuth";
import {
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useMemo, useState } from "react";
import { LogoContainer } from "../../../TrucoLogo/TrucoLogo.styles";
import {
  query,
  collection,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { RoleDescriptions } from "src/utils/variables";
import { CollaboratorAvatar } from "src/components";
import { PendingActions } from "@mui/icons-material";
import { updateNumberOfSeats } from "src/utils/organization";

const { Option } = Select;

type ManageCollaboratorsAccessProps = {
  collaborators: UserType[];
  pendingCollaborators: InvitedUser[];
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
};

export const ManageCollaboratorsAccess: React.FC<
  ManageCollaboratorsAccessProps
> = ({ collaborators, pendingCollaborators, openModal, setOpenModal }) => {
  const { organization, organizationSettings, setOrganizationSettings } =
    useOrganizationState();
  const { user } = useUserState();
  const [addEmail, setAddEmail] = useState("");
  const [newRole, setNewRole] = useState(ACCESS_TYPE.agent);
  const [, setVisibleAddPeople] = useState(false);
  const [inputEmailError, setInputEmailError] = useState(false);
  const [sendingEmailInvite, setSendingEmailInvite] = useState(false);
  const [loadingAddingAgent, setLoadingAddingAgent] = useState(null);
  const [inviteSentMsg, setInviteSentMsg] = useState({
    color: "",
    msg: "",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const hasAccessToEdit =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;

  // Only recalculate when these arrays change
  const allUsers = useMemo(
    () => [...(collaborators ?? []), ...(pendingCollaborators ?? [])],
    [collaborators, pendingCollaborators],
  );

  const isInvitedUser = (
    object: UserType | InvitedUser,
  ): object is InvitedUser => {
    return (object as InvitedUser).pendingInvitation !== undefined;
  };

  const sendInvitationEmail = async () => {
    if (!isValidEmail(addEmail)) {
      setInputEmailError(true);
      return;
    }
    if (allUsers.some((user) => user.email === addEmail)) {
      setInviteSentMsg({
        msg: `User Already Exists in ${organizationSettings?.name}`,
        color: color.red,
      });
      return;
    }
    let emailSentSuccessfully = false;
    setSendingEmailInvite(true);
    let continueUrl = `${window.location.origin}/signin`;

    fetchSignInMethodsForEmail(auth, addEmail)
      .then(async (signInMethods) => {
        if (signInMethods.length !== 0) {
          // Email already in use
          const q = query(
            collection(db, "users"),
            where("email", "==", addEmail),
          );

          try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs.length > 0) {
              const userDocRef = querySnapshot?.docs[0].ref;
              await updateDoc(userDocRef, {
                organization: organization,
                accessType: newRole,
              });
              emailSentSuccessfully = true;
              setInviteSentMsg({
                msg: `User is now added to ${organizationSettings?.name}`,
                color: color.yellow,
              });
            }
          } catch (error) {
            console.error("Error fetching users: ", error);
          }
        } else {
          // Email doesn't exist then create an invitation
          const invitedUser: InvitedUser = {
            email: addEmail,
            accessType: newRole,
            organization: organization,
            pendingInvitation: true,
            name: addEmail.split("@")[0],
            sendInvitation: true,
          };
          const invitationId = await addDocumentWithId(
            `/${COLLECTION_DATA.INVITATIONS}`,
            invitedUser,
          );
          continueUrl += `?${QUERY_PARAMS.INVITATION_CODE}=${invitationId}`;
        }

        sendSignInLinkToEmail(auth, addEmail, {
          url: continueUrl,
          handleCodeInApp: true,
        })
          .then(async () => {
            setInviteSentMsg({
              msg: "Email sent successfully",
              color: color.lightGreen,
            });
            await updateNumberOfSeats(
              `/${COLLECTION_DATA.ORGANIZATIONS}/${organization}`,
              1,
              setOrganizationSettings,
            );
            emailSentSuccessfully = true;
          })
          .catch(async () => {
            setInviteSentMsg({
              msg: "Email failed to send",
              color: color.red,
            });
            if (continueUrl.match(/invitationCode=([^&]+)/)) {
              await updateDocInFireStore(
                `/${COLLECTION_DATA.INVITATIONS}/${continueUrl.match(/invitationCode=([^&]+)/)[1]}`,
                {
                  sendInvitation: false,
                },
              );
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });

    setTimeout(() => {
      if (emailSentSuccessfully) {
        setAddEmail("");
        setNewRole(ACCESS_TYPE.agent);
      }
      setInviteSentMsg({
        msg: "",
        color: "",
      });
      setSendingEmailInvite(false); // End loading
    }, 3000);
  };

  const sendInvitationEmailToAgent = async (invited: InvitedUser) => {
    setLoadingAddingAgent(invited.email);
    let continueUrl = `${window.location.origin}/signin?${QUERY_PARAMS.INVITATION_CODE}=${invited.id}`;
    sendSignInLinkToEmail(auth, invited.email, {
      url: continueUrl,
      handleCodeInApp: true,
    })
      .then(async () => {
        updateDocInFireStore(`/${COLLECTION_DATA.INVITATIONS}/${invited.id}`, {
          sendInvitation: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    setTimeout(() => {
      setLoadingAddingAgent(null);
    }, 3000);
  };

  const updateUserAccessType = async (
    currentUser: UserType,
    newAccessType: ACCESS_TYPE,
  ) => {
    if (currentUser && currentUser.accessType === newAccessType) {
      return;
    }

    await updateDocInFireStore(`/users/${currentUser.uid}`, {
      accessType: newAccessType, // Update the accessType field
    });
  };
  const removeUserFromOrganization = async (uid: string) => {
    await updateDocInFireStore(`/users/${uid}`, {
      organization: null, // Update the accessType field
    });
    await updateNumberOfSeats(
      `/${COLLECTION_DATA.ORGANIZATIONS}/${organization}`,
      -1,
      setOrganizationSettings,
    );
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleVisibleAddPeopleChange = (visible) => {
    setVisibleAddPeople(visible);
    if (!visible) {
      setAddEmail("");
      setInviteSentMsg({
        msg: "",
        color: "",
      });
      setNewRole(ACCESS_TYPE.agent);
    }
  };

  const selectAfter = (
    <BorderlessSelect
      style={{ width: elementSize.xxxxl }}
      defaultValue={capitalizeFirstLetter(ACCESS_TYPE.agent)}
      value={capitalizeFirstLetter(newRole)}
      popupMatchSelectWidth={false}
      onChange={(role) => setNewRole(role)}
    >
      {Object.entries(RoleDescriptions).map(([role, description]) => (
        <Option key={role} value={role}>
          <div>
            <CustomOption>
              <div className="role-title">{capitalizeFirstLetter(role)}</div>
              <ResponsiveDescription>{description}</ResponsiveDescription>
            </CustomOption>
          </div>
        </Option>
      ))}
    </BorderlessSelect>
  );

  const filteredCollaborators = useMemo(() => {
    if (!searchTerm.trim()) return allUsers;

    const searchTermLower = searchTerm.toLowerCase().trim();

    return allUsers.filter((user) =>
      user.name.toLowerCase().includes(searchTermLower),
    );
  }, [searchTerm, allUsers]);

  const determineActions = (item) => {
    if (isInvitedUser(item)) {
      const actions = [
        <Tooltip
          title={`${!hasAccessToEdit ? "Send Invitation email" : "Only owners can send invitation link"}`}
        >
          <SendOutlined
            disabled={!hasAccessToEdit}
            style={{ cursor: "pointer" }}
            onClick={() => sendInvitationEmailToAgent(item)}
          />
        </Tooltip>,
        DeleteWithPopConfirm(
          `Remove ${item.name}? `,
          `By this you will remove ${item.name} from ${organizationSettings?.name}`,
          () => {
            hardDeleteDocInFireStore(
              `/${COLLECTION_DATA.INVITATIONS}/${item.id}`,
            );
          },
          !hasAccessToEdit,
        ),
      ];

      if (item.sendInvitation) {
        // Actions for users with a sent invitation but pending
        return [
          <LogoContainer style={{ gap: spacing.xs }}>
            <span style={{ color: color.grayDark }}>Pending invitation</span>
            <PendingActions />
          </LogoContainer>,
          ...actions,
        ];
      } else {
        // Actions for users without a sent invitation but pending
        return loadingAddingAgent === item.email
          ? [<Spin spinning={loadingAddingAgent === item.email} />]
          : [
              <Input
                value={capitalizeFirstLetter(item.accessType)}
                style={{ width: "70px", textAlign: "center" }}
                disabled={true}
              />,
              ...actions,
            ];
      }
    } else {
      return [
        <Select
          disabled={!hasAccessToEdit}
          defaultValue={item.accessType}
          style={{ width: 110 }}
          onChange={(newAccessType) =>
            updateUserAccessType(item, newAccessType)
          }
          value={capitalizeFirstLetter(item?.accessType)}
          popupMatchSelectWidth={false}
        >
          {Object.entries(RoleDescriptions).map(([role, description]) => (
            <Option key={role} value={role}>
              <div>
                <CustomOption>
                  <div className="role-title">
                    {capitalizeFirstLetter(role)}
                  </div>
                  <div className="role-description">{description}</div>
                </CustomOption>
              </div>
            </Option>
          ))}
        </Select>,
        DeleteWithPopConfirm(
          `Remove ${item.name}? `,
          `By this you will remove ${item.name} from ${organizationSettings?.name}`,
          () => {
            removeUserFromOrganization(item.uid);
          },
          !hasAccessToEdit,
        ),
      ];
    }
  };

  return (
    <CustomizedEvaluationModal
      key={`Collaborators-Modal`}
      title={`Collaborators`}
      open={openModal}
      onCancel={handleClose}
      closable={true}
      footer={null}
    >
      <SpaceBetweenDiv
        style={{ margin: `${elementSize.ml} 0px ${elementSize.ml} 0px` }}
      >
        <StyledInput
          placeholder="Find a member by name..."
          prefix={<SearchOutlined />}
          style={{
            width: "60%",
          }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {hasAccessToEdit && (
          <Popover
            style={{ width: "15rem" }}
            trigger="click"
            placement="bottomRight"
            onOpenChange={handleVisibleAddPeopleChange}
            title={
              inviteSentMsg.msg.length > 0 ? (
                <LogoContainer
                  style={{ gap: spacing.xs, marginRight: spacing.xs }}
                >
                  <InfoCircleOutlined style={{ color: inviteSentMsg.color }} />
                  {inviteSentMsg.msg}
                </LogoContainer>
              ) : (
                `Invite a new member to ${organizationSettings?.name}`
              )
            }
            content={
              <Space style={{ width: "100%" }}>
                <StyledInputGroup error={inputEmailError}>
                  <Input
                    placeholder="Add Email"
                    value={addEmail}
                    onChange={(e) => {
                      setAddEmail(e.target.value);
                      if (inviteSentMsg.msg.length > 0)
                        setInviteSentMsg({ msg: "", color: "" });
                      if (inputEmailError) setInputEmailError(false);
                    }}
                    addonAfter={selectAfter}
                  />
                </StyledInputGroup>
                <Button
                  type="primary"
                  loading={sendingEmailInvite}
                  style={{ backgroundColor: color.olive, boxShadow: "none" }}
                  onClick={sendInvitationEmail}
                >
                  Invite
                </Button>
              </Space>
            }
          >
            <Button
              type="primary"
              style={{ backgroundColor: color.orange, boxShadow: "none" }}
            >
              Add people
            </Button>{" "}
          </Popover>
        )}
      </SpaceBetweenDiv>
      <CustomizedText
        style={{ display: "flex", margin: `0px 0px ${elementSize.sm} 0px` }}
      >
        {filteredCollaborators.length} people in {organizationSettings?.name}
      </CustomizedText>
      <List
        itemLayout="horizontal"
        dataSource={filteredCollaborators}
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
        }}
        renderItem={(item, index) => (
          <List.Item actions={determineActions(item)}>
            <List.Item.Meta
              avatar={<CollaboratorAvatar key={index} collaborator={item} />}
              title={item.name}
              description={
                <ResponsiveDescription>{item.email}</ResponsiveDescription>
              }
            />
          </List.Item>
        )}
      />
    </CustomizedEvaluationModal>
  );
};
