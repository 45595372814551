import { Button, Card } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  width: 200px;
  min-height: 150px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const ContainerCard = styled(Card)`
  width: 300px;
  min-height: 200px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin-top: 25px;
  border-radius: 5px;
`;
