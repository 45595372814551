import React from "react";
import { Menu, Dropdown, Spin, Select, Tooltip } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import UnsubscribeManager from "src/utils/UnsubscribeManager";
import { CollectAllExistingOrganizations } from "src/hooks/CollectOrganizationData";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CollaboratorAvatar } from "../Global";
import { useUserState } from "src/state/UserState";
import {
  OrganizationNameSelect,
  SelectContainer,
  StyledDropdownButton,
} from "./Filters.style";
import { color } from "src/styles/variables";

export const OrganizationSelect = ({ type = "sidebar" }) => {
  const {
    organizationSettings,
    setOrganization,
    setCustomers,
    setTranscripts,
    setInsights,
    setCollaborators,
    setOrganizationSettings,
    setPendingCollaborators,
    setEvaluationForms,
    allExistingOrganizations,
    setConversationFeatures,
  } = useOrganizationState();
  const { user } = useUserState();

  if (!allExistingOrganizations) {
    CollectAllExistingOrganizations();
  }
  const location = useLocation();

  const organizationClear = () => {
    UnsubscribeManager.getInstance().clearUnsubs();
    location.state = null;
    setTranscripts(null);
    setConversationFeatures({
      lastDoc: null,
      isLastBatch: true,
    });
    setCustomers({ data: null, hasMore: false, lastDoc: null });
    setCollaborators(null);
    setPendingCollaborators(null);
    setEvaluationForms(null);
    setOrganizationSettings(null);
    setInsights(null);
  };

  const handleMenuClick = (selectedOption) => {
    organizationClear();
    setOrganization(selectedOption.key);
  };

  const handleSelectClick = (selectedOption) => {
    organizationClear();
    setOrganization(selectedOption);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: "70vh", overflowY: "hidden" }}
    >
      <Menu.Item key="intro" disabled>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "10px 0",
            borderBottom: `1px solid ${color.gray}`,
            alignItems: "center",
          }}
        >
          <CollaboratorAvatar
            style={{ width: 35, height: 35, marginBottom: 5 }}
            collaborator={user}
          />
          <div style={{ fontWeight: "bold", color: color.grayDark }}>
            {organizationSettings?.name}
          </div>
        </div>
      </Menu.Item>
      <Menu.ItemGroup
        title="Organizations"
        style={{ overflowY: "auto", maxHeight: "calc(60vh - 80px)" }}
      >
        {allExistingOrganizations?.map((existingOrg) => (
          <Menu.Item key={existingOrg.id}>{existingOrg.name}</Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );
  const options = allExistingOrganizations?.map((existingOrgs) => ({
    label: existingOrgs.name,
    value: existingOrgs.id,
  }));

  return (
    <>
      {type === "profile" ? (
        <Select
          placeholder={organizationSettings?.name}
          style={{ width: 150 }}
          onChange={handleSelectClick}
          options={options}
        />
      ) : (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          disabled={!organizationSettings?.name}
        >
          <StyledDropdownButton
            disabled={!organizationSettings?.name}
            icon={
              <CollaboratorAvatar
                style={{
                  width: 18,
                  height: 18,
                }}
                collaborator={user}
              />
            }
          >
            <SelectContainer>
              <Tooltip title={organizationSettings?.name}>
                <OrganizationNameSelect>
                  {organizationSettings?.name || <Spin />}
                </OrganizationNameSelect>
              </Tooltip>
              {organizationSettings?.name && (
                <ExpandMoreIcon
                  style={{
                    fontSize: 16,
                  }}
                />
              )}
            </SelectContainer>
          </StyledDropdownButton>
        </Dropdown>
      )}
    </>
  );
};
