import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Typography } from "antd";
import { color } from "src/styles/variables";

const { Title, Paragraph } = Typography;

export const PaymentStatus: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const isSuccess = queryParams.get("success") === "true";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        style={{
          width: 400,
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          borderRadius: "10px",
        }}
      >
        <Title
          level={2}
          style={{ color: isSuccess ? `${color.orange}` : `${color.red}` }}
        >
          Payment {isSuccess ? "Successful" : "Failed"}
        </Title>
        {isSuccess ? (
          <Paragraph>
            Your payment was successful. Thank you for your purchase!
          </Paragraph>
        ) : (
          <Paragraph>
            Unfortunately, your payment could not be processed. Please try
            again.
          </Paragraph>
        )}

        <Button onClick={() => navigate("/")} style={{ marginTop: 20 }}>
          Go Home
        </Button>
      </Card>
    </div>
  );
};
