import { GradientOrangeSpan } from "../LandingPage/LandingPage.styles";
import { Logo, LogoContainer, LogoText } from "./TrucoLogo.styles";

interface TrucoLogoProps {
  size?: "small" | "medium";
}

const TrucoLogo: React.FC<TrucoLogoProps> = ({ size = "medium" }) => {
  return (
    <LogoContainer>
      <Logo
        size={size}
        src={process.env.PUBLIC_URL + "/logo512.png"}
        alt="Company Logo"
      />
      <LogoText size={size}>
        <GradientOrangeSpan>Truco</GradientOrangeSpan>
      </LogoText>
    </LogoContainer>
  );
};

export default TrucoLogo;
