import { AnimatedSvgContainer } from "./Loading.styles";
import { TrucoIcon } from "./icons";

export const Loading = ({ text = "Loading" }) => {
  return (
    <AnimatedSvgContainer>
      <TrucoIcon />
      {text}
    </AnimatedSvgContainer>
  );
};
