import {
  CardBody,
  CardImg,
  CardItem,
  CardSubTitle,
  CardTitle,
} from "./CustomizedDotCard.styles";

const CustomizedDotCard = ({
  imgSrc,
  title,
  text,
  marginLeft = "0px",
  ...props
}) => (
  <CardItem key={props.key} marginLeft={marginLeft} {...props}>
    <CardImg>
      <img src={imgSrc} alt={title} />
    </CardImg>
    <CardBody>
      <CardTitle>{title}</CardTitle>
      <CardSubTitle>{text}</CardSubTitle>
    </CardBody>
  </CardItem>
);

export default CustomizedDotCard;
