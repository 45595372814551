import styled from "styled-components";
import { color, fontSize, hexToRgba } from "src/styles/variables";
import { Layout, Menu } from "antd";
const { Sider } = Layout;

export const CustomSider = styled(Sider)`
  background-color: white !important;
  border-right: 1px solid ${color.grayLight};

  .ant-layout-sider-trigger {
    border-right: 1px solid ${color.grayLight};
    background-color: ${color.white};
    color: ${color.black};
  }
`;
// Styled CustomMenu with flex styling
export const CustomMenu = styled(Menu)`
  border-inline-end: none !important;

  .ant-menu-item {
    transition: background-color 0.3s;
    font-size: ${fontSize.bodyLarge};
  }

  .ant-menu-item-selected {
    background-color: ${hexToRgba(color.orange, 1.0)} !important;
    color: ${color.white};

    &:hover {
      background-color: ${hexToRgba(color.orange, 1.0)} !important;
      color: ${color.white};
    }
  }
`;

// Spacer styled component to push bottom items to the end
export const Spacer = styled.div`
  flex-grow: 1;
`;

export const OrgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const DrawerContainer = styled.div`
  position: absolute;
  top: 150;
  left: 0;
  z-index: 1000;
  cursor: pointer;
  background-color: ${color.lightOrange};
  padding: 10px;
`;
