import React, { useState } from "react";
import { Dropdown, Input, Menu, Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import {
  LabelContainer,
  MenuContainer,
  PillButton,
  TagContainer,
} from "./Filters.style";

interface OptionType {
  value: string;
  label: string;
}

interface DropdownWithPillButtonProps {
  options: OptionType[];
  selectedKeys: string[] | string | undefined; // Include this to reflect the actual selection state
  onSelectionChange: (selectedKeys: string[] | string | undefined) => void;
  icon: React.ReactNode;
  text: string;
  multiple?: boolean;
  limit?: number;
  search?: boolean;
}

const DropdownWithPillButton: React.FC<DropdownWithPillButtonProps> = ({
  options,
  selectedKeys,
  onSelectionChange,
  icon,
  text,
  multiple = false,
  limit,
  search = false,
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleMenuClick = (key: string) => {
    if (multiple && Array.isArray(selectedKeys)) {
      if (!limit || selectedKeys.length < limit || selectedKeys.includes(key)) {
        const newSelectedKeys = selectedKeys.includes(key)
          ? selectedKeys.filter((k) => k !== key)
          : [...selectedKeys, key];
        onSelectionChange(newSelectedKeys);
        setOpen(true);
      }
    } else {
      const newKey = selectedKeys === key ? undefined : key;
      onSelectionChange(newKey);
      setOpen(false);
    }
  };
  const handleRemoveTag = (key: string) => {
    if (multiple) {
      const newSelectedKeys = Array.isArray(selectedKeys)
        ? selectedKeys.filter((k) => k !== key)
        : [];
      onSelectionChange(newSelectedKeys);
    } else {
      onSelectionChange(undefined);
    }
  };

  const renderSelectedItems = () => {
    const itemsToRender = Array.isArray(selectedKeys)
      ? selectedKeys
      : selectedKeys
        ? [selectedKeys]
        : [];

    if (itemsToRender.length > 0) {
      return (
        <TagContainer>
          {itemsToRender.map((key) => (
            <Tag
              key={key}
              closable
              onClose={(e) => {
                e.preventDefault();
                handleRemoveTag(key);
              }}
            >
              {options.find((option) => option.value === key)?.label || key}
            </Tag>
          ))}
        </TagContainer>
      );
    }
    return null;
  };

  const filteredOptions = options.filter((option) =>
    option?.label?.toLowerCase().includes(searchText?.toLowerCase()),
  );

  const menuItems = filteredOptions.map((option) => ({
    label: (
      <LabelContainer>
        {option.label}
        {Array.isArray(selectedKeys)
          ? selectedKeys.includes(option.value) && <CheckOutlined />
          : selectedKeys === option.value && <CheckOutlined />}
      </LabelContainer>
    ),
    key: option.value,
    onClick: () => handleMenuClick(option.value),
  }));

  return (
    <Dropdown
      open={open}
      onOpenChange={(newOpen) => setOpen(newOpen)}
      trigger={["click"]}
      dropdownRender={() => (
        <MenuContainer>
          {search && (
            <Input
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ marginBottom: "8px" }}
              allowClear
            />
          )}
          {renderSelectedItems()}
          <Menu
            items={menuItems}
            style={{
              borderRadius: (
                multiple ? selectedKeys.length > 0 : !!selectedKeys
              )
                ? "0 0 8px 8px"
                : "8px",
              maxHeight: "220px",
              overflowY: "auto",
            }}
          />
        </MenuContainer>
      )}
    >
      <PillButton
        active={multiple ? selectedKeys.length > 0 : !!selectedKeys}
        onClick={() => {
          setSearchText("");
          setOpen(!open);
        }}
      >
        {icon}
        {text}
      </PillButton>
    </Dropdown>
  );
};

export default DropdownWithPillButton;
