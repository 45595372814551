import { useEffect } from "react";
import {
  onSnapshot,
  collection,
  DocumentData,
  query,
  where,
  orderBy,
  limit,
  FirestoreDataConverter,
  QueryConstraint,
} from "firebase/firestore";
import { db } from "src/firebaseAuth";
import { useFiltersState } from "src/state/FiltersState";
import { useOrganizationState } from "src/state/OrganizationState";
import { QueryConditions } from "src/utils/types";
import UnsubscribeManager from "src/utils/UnsubscribeManager";

export const useCollection = (
  queryPath: string,
  setFunction: (data: any) => void,
  converterFunction?: FirestoreDataConverter<DocumentData>,
  queryConditions: QueryConditions[] = [],
  setLoading?: (loading: boolean) => void,
  transformFunction?: (data: DocumentData[]) => any,
  returnCountOnly: boolean = false,
  limitDocs?: number,
  setExtraPaginationFeatures?, //  for paginated queries
): void => {
  const { filterDates } = useFiltersState();
  const { organization } = useOrganizationState();

  useEffect(() => {
    if (!queryPath) return;

    const collectionRef = query(collection(db, queryPath));
    let queryConstraints: QueryConstraint[] = [];

    queryConditions.forEach((queryCondition) => {
      if (queryCondition?.comparisonField) {
        if (queryCondition?.value !== undefined) {
          queryConstraints.push(
            where(
              queryCondition.comparisonField,
              queryCondition.comparisonOperator,
              queryCondition.value,
            ),
          );
        }
        if (queryCondition?.order) {
          queryConstraints.push(
            orderBy(queryCondition.comparisonField, queryCondition.order),
          );
        }
      }
    });
    if (limitDocs) {
      queryConstraints.push(limit(limitDocs));
    }

    const finalQuery = converterFunction
      ? query(collectionRef, ...queryConstraints).withConverter(
          converterFunction,
        )
      : query(collectionRef, ...queryConstraints);

    if (setLoading) setLoading(true);

    const unsub = onSnapshot(
      finalQuery,
      (snapshot) => {
        if (snapshot.empty) {
          if (setLoading) setLoading(false);
          setFunction(returnCountOnly ? 0 : []);
          if (setExtraPaginationFeatures) {
            setExtraPaginationFeatures({
              lastDoc: null,
              isLastBatch: true,
            });
          }
          return;
        }
        if (returnCountOnly) {
          setFunction(snapshot.docs.length);
        } else {
          const newData = snapshot.docs.map((doc) => ({
            ...(doc.data() as DocumentData),
            id: doc.id,
          }));

          // Append non-duplicate data only
          // setFunction((prevData: any[]) => {
          //   const transformedData = transformFunction
          //     ? transformFunction(newData)
          //     : newData;

          //   // Filter out any data that already exists in prevData
          //   const filteredNewData = transformedData.filter(
          //     (newDoc) => !prevData?.some((prevDoc) => prevDoc.id === newDoc.id)
          //   );

          //   // Append new data if paginated, otherwise replace
          //   return isPaginated
          //     ? [...(prevData || []), ...filteredNewData] // Append new data
          //     : transformedData; // Replace if not paginated
          // });
          setFunction(transformFunction ? transformFunction(newData) : newData);
        }
        if (setLoading) setLoading(false);

        if (setExtraPaginationFeatures) {
          setExtraPaginationFeatures({
            lastDoc:
              snapshot.docs.length < limitDocs
                ? null
                : snapshot.docs[snapshot.docs.length - 1],
            isLastBatch: snapshot.docs.length < limitDocs,
          });
        }
      },
      (err) => {
        console.error("Error fetching data:", err);
        if (setLoading) setLoading(false);
      },
    );

    UnsubscribeManager.getInstance().addUnsub(unsub); // Register the unsubscribe function

    return () => {
      unsub();
      UnsubscribeManager.getInstance().clearUnsubs();
    };
  }, [queryPath, filterDates, organization]);
};
