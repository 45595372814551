import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

import { Spin, Menu, Tooltip } from "antd";
import {
  CustomControls,
  TimeDisplay,
  PlayPauseButton,
  MediaContainer,
  VolumeControl,
  ControlButton,
  ActionContainer,
  SpeedControlDropdown,
  SpeedMenu,
  ProgressBarContainer,
  ClipMarker,
  StyledProgressBar,
  Container,
} from "./ClipPlayer.styles";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { color, fontSize } from "src/styles/variables";
import { Sidebar } from "./SideBarChapters";
import {
  FullVideoIcon,
  HighlightsIcon,
} from "src/components/Dashboard/Dashboard.styles";

interface ClipPlayerProps {
  url: string;
  timeline: { start: number; end: number }[];
  mimeType: string;
  handleDeleteTimelineItemFromPlaylistClip?: any;
  signalPlaylistUser?: any;
  setShowHighlights?: any;
  showHighlights?: boolean;
  updatedUtteranceIndex?: (time: any) => void;
  start?: number;
  enableHighlight?: boolean;
}

export interface ClipPlayerRef {
  pause: () => void;
}

const ClipPlayer: ForwardRefRenderFunction<ClipPlayerRef, ClipPlayerProps> = (
  {
    url,
    timeline,
    mimeType,
    handleDeleteTimelineItemFromPlaylistClip = null,
    signalPlaylistUser = null,
    setShowHighlights = null,
    showHighlights = false,
    updatedUtteranceIndex,
    start = 0,
    enableHighlight = true,
  },
  ref,
) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [adjustSize, setAdjustSizes] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [currentClipIndex, setCurrentClipIndex] = useState(0);
  const mediaRef = useRef<HTMLVideoElement | HTMLAudioElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentSpeed, setCurrentSpeed] = useState(1);
  const [totalDuration, setTotalDuration] = useState(0);
  const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

  const isVideo = mimeType.includes("video");

  useEffect(() => {
    const updateHeight = () => {
      if (mediaRef.current) {
        setMediaContainerHeight(mediaRef.current.offsetHeight + 36);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [isFullscreen, isVideo, adjustSize]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    const media = mediaRef.current;
    if (media && start) {
      media.currentTime = start;
    }
  }, [start]);

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      const handleTimeUpdate = () => {
        const clip = timeline[currentClipIndex];
        if (media.currentTime < clip.start) {
          media.currentTime = clip.start;
        } else if (media.currentTime > clip.end) {
          if (currentClipIndex < timeline.length - 1) {
            setCurrentClipIndex(currentClipIndex + 1);
            media.currentTime = timeline[currentClipIndex + 1].start;
          } else {
            media.pause();
            media.currentTime = clip.end;
            setIsPlaying(false);
          }
        }
        setCurrentTime(media.currentTime);
        updatedUtteranceIndex?.(media.currentTime);
      };

      media.addEventListener("timeupdate", handleTimeUpdate);
      return () => media.removeEventListener("timeupdate", handleTimeUpdate);
    }
  }, [currentClipIndex, timeline]);

  useEffect(() => {
    if (mediaRef.current) {
      mediaRef.current.playbackRate = currentSpeed;
    }
  }, [currentSpeed]);

  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      const handleTimeUpdate = () => {
        const currentClip = timeline[currentClipIndex];
        if (media.currentTime < currentClip.start) {
          media.currentTime = currentClip.start;
        } else if (media.currentTime >= currentClip.end) {
          if (currentClipIndex < timeline.length - 1) {
            setCurrentClipIndex(currentClipIndex + 1);
            media.currentTime = timeline[currentClipIndex + 1].start;
          } else {
            media.pause();
            media.currentTime = currentClip.end;
            setIsPlaying(false);
          }
        }
        setCurrentTime(media.currentTime);
        updatedUtteranceIndex?.(media.currentTime);
      };

      media.addEventListener("timeupdate", handleTimeUpdate);
      return () => media.removeEventListener("timeupdate", handleTimeUpdate);
    }
  }, [currentClipIndex, timeline]);

  useEffect(() => {
    const totalDuration = timeline.reduce(
      (sum, clip) => sum + (clip.end - clip.start),
      0,
    );
    setTotalDuration(totalDuration);
  }, [timeline]);

  React.useImperativeHandle(ref, () => ({
    pause: () => {
      const mediaElement = mediaRef.current;
      mediaElement.pause();
      setIsPlaying(false);
    },
  }));

  const handleLoadedData = () => {
    setLoading(false);
    if (mediaRef.current) {
      mediaRef.current.currentTime = timeline[currentClipIndex].start;
    }
  };

  const speedOptions = [0.5, 1, 1.25, 1.5, 2, 3].map((speed) => ({
    label: `${speed}x`,
    value: speed,
  }));

  const handleSpeedChange = ({ key }: { key: string }) => {
    const newSpeed = parseFloat(key);
    setCurrentSpeed(newSpeed);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (containerRef.current?.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if ((containerRef.current as any).webkitRequestFullscreen) {
        // Safari
        (containerRef.current as any).webkitRequestFullscreen();
      } else if ((containerRef.current as any).msRequestFullscreen) {
        // IE11
        (containerRef.current as any).msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        // Safari
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        // IE11
        (document as any).msExitFullscreen();
      }
    }
  };

  const togglePlayPause = () => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.pause();
      } else {
        if (mediaRef.current.currentTime >= timeline[currentClipIndex].end) {
          if (currentClipIndex < timeline.length - 1) {
            setCurrentClipIndex(currentClipIndex + 1);
            mediaRef.current.currentTime = timeline[currentClipIndex + 1].start;
          } else {
            mediaRef.current.currentTime = timeline[0].start;
          }
        }
        mediaRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (mediaRef.current) {
      mediaRef.current.volume = newVolume;
      setIsMuted(newVolume === 0);
    }
  };

  const toggleMute = () => {
    if (mediaRef.current) {
      mediaRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const progress = parseFloat(e.target.value);
    let accumulatedDuration = 0;
    let targetClipIndex = 0;
    let targetTime = 0;

    for (let i = 0; i < timeline.length; i++) {
      const clipDuration = timeline[i].end - timeline[i].start;
      if (accumulatedDuration + clipDuration > progress) {
        targetClipIndex = i;
        targetTime = timeline[i].start + (progress - accumulatedDuration);
        break;
      }
      accumulatedDuration += clipDuration;
    }

    setCurrentClipIndex(targetClipIndex);
    if (mediaRef.current) {
      mediaRef.current.currentTime = targetTime;
    }
  };

  const getCurrentProgress = () => {
    let accumulatedDuration = 0;
    for (let i = 0; i < currentClipIndex; i++) {
      accumulatedDuration += timeline[i].end - timeline[i].start;
    }
    return (
      accumulatedDuration + (currentTime - timeline[currentClipIndex].start)
    );
  };

  const renderClipMarkers = () => {
    let accumulatedDuration = 0;
    return timeline.map((clip, index) => {
      const clipDuration = clip.end - clip.start;
      const markerPosition =
        ((accumulatedDuration + clipDuration) / totalDuration) * 100;
      if (markerPosition === 100) return;
      accumulatedDuration += clipDuration;
      return (
        <ClipMarker
          key={index}
          style={{
            left: `${markerPosition}%`,
          }}
        />
      );
    });
  };

  const formatTime = (time: number) => {
    const minutes = Math.max(0, Math.floor(time / 60));
    const seconds = Math.max(0, Math.floor(time % 60));
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleChapterClick = (clip, index) => {
    if (mediaRef.current) {
      setLoading(true);
      mediaRef.current.currentTime = clip.start;
      setCurrentClipIndex(index); // Update the current clip index
      setIsPlaying(true);
      setTimeout(() => {
        setLoading(false);
        mediaRef.current.play();
      }, 300);
    }
  };

  return (
    <Spin spinning={loading || !url}>
      <Container isVideo={isVideo}>
        <MediaContainer
          isFullscreen={isFullscreen}
          ref={containerRef}
          isVideo={isVideo}
        >
          {isVideo ? (
            <video
              ref={mediaRef as React.RefObject<HTMLVideoElement>}
              onLoadStart={handleLoadedData}
              onLoadedMetadata={() => setAdjustSizes(true)}
              onContextMenu={handleContextMenu}
              controls={false}
              preload="auto"
            >
              <source src={url} type={mimeType} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <audio
              ref={mediaRef as React.RefObject<HTMLAudioElement>}
              onLoadStart={handleLoadedData}
              onLoadedMetadata={() => setAdjustSizes(true)}
              preload="auto"
            >
              <source src={url} type={mimeType} />
              Your browser does not support the audio element.
            </audio>
          )}
          {setShowHighlights &&
            enableHighlight &&
            (showHighlights ? (
              <Tooltip placement="rightTop" title="View Full Recording">
                <FullVideoIcon
                  isAudio={!isVideo}
                  onClick={() => setShowHighlights(false)}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="rightTop" title="View Highlights">
                <HighlightsIcon
                  isAudio={!isVideo}
                  onClick={() => setShowHighlights(true)}
                />
              </Tooltip>
            ))}

          <CustomControls isVideo={isVideo}>
            <PlayPauseButton onClick={togglePlayPause}>
              {isPlaying ? (
                <PauseOutlined style={{ fontSize: "18px" }} />
              ) : (
                <CaretRightOutlined style={{ fontSize: "18px" }} />
              )}
            </PlayPauseButton>

            <ProgressBarContainer>
              <StyledProgressBar
                type="range"
                min={0}
                max={totalDuration}
                value={getCurrentProgress()}
                onChange={handleProgressChange}
              />
              {renderClipMarkers()}
            </ProgressBarContainer>
            <TimeDisplay>
              {formatTime(getCurrentProgress())} / {formatTime(totalDuration)}
            </TimeDisplay>
            <ActionContainer>
              <ControlButton onClick={toggleMute}>
                {isMuted ? (
                  <VolumeOffIcon
                    style={{
                      color: color.grayLight,
                      fontSize: fontSize.bodyLarge,
                    }}
                  />
                ) : (
                  <VolumeUpIcon
                    style={{
                      color: color.grayLight,
                      fontSize: fontSize.bodyLarge,
                    }}
                  />
                )}
              </ControlButton>
              <VolumeControl
                type="range"
                min={0}
                max={1}
                step={0.1}
                value={volume}
                onChange={handleVolumeChange}
              />
            </ActionContainer>
            <ActionContainer>
              <SpeedControlDropdown
                overlay={
                  <SpeedMenu
                    onClick={handleSpeedChange}
                    selectedKeys={[currentSpeed.toString()]}
                  >
                    {speedOptions.map((option) => (
                      <Menu.Item key={option.value}>{option.label}</Menu.Item>
                    ))}
                  </SpeedMenu>
                }
                placement="topCenter"
                trigger={["hover"]}
              >
                <SpeedIcon
                  style={{
                    color: color.grayLight,
                    fontSize: fontSize.bodyLarge,
                  }}
                />
              </SpeedControlDropdown>
            </ActionContainer>

            {isVideo && (
              <ControlButton onClick={toggleFullScreen}>
                <AspectRatioIcon
                  style={{ color: color.grayLight, fontSize: fontSize.body }}
                />
              </ControlButton>
            )}
          </CustomControls>
        </MediaContainer>

        {timeline?.length > 1 && enableHighlight && showHighlights && (
          <Spin spinning={!adjustSize}>
            <Sidebar
              timeline={timeline}
              onChapterClick={handleChapterClick}
              signalPlaylistUser={signalPlaylistUser}
              handleDeleteTimelineItemFromPlaylistClip={
                handleDeleteTimelineItemFromPlaylistClip
              }
              currentClipIndex={currentClipIndex}
              mimeType={mimeType}
              url={url}
              height={isVideo ? mediaContainerHeight : "35vh"}
            />
          </Spin>
        )}
      </Container>
    </Spin>
  );
};

export default forwardRef<ClipPlayerRef, ClipPlayerProps>(ClipPlayer);
