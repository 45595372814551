import styled from "styled-components";
import { color, elementSize } from "src/styles/variables";
import { Collapse } from "antd";

export const ParticipantsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${elementSize.xs};
  cursor: pointer;
  &:hover {
    color: ${color.orange};
  }
`;

export const FeedbackCollapse = styled(Collapse)`
  .ant-collapse-item {
    .ant-collapse-header {
      display: flex;
      align-items: center;
    }

    .ant-collapse-expand-icon {
      order: 1;
    }

    .ant-collapse-header-text {
      flex: 1;
      order: 2;
    }
  }
  .ant-menu-light {
    background-color: #fafafa;
  }
`;
