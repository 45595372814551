import styled, { css } from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tag, Typography } from "antd";
import { color, spacing, hexToRgba, deviceSize } from "src/styles/variables";

export const DivLink = styled.div`
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${(props) => (props.hoverColor ? props.hoverColor : "")};
  }
`;

export const TooltipIcon = styled(InfoCircleOutlined)`
  color: ${color.gray}; // Adjust as needed
  margin-left: ${spacing.xs};
`;

export const ErrorMsg = styled.div`
  color: ${color.red}; // Assuming you have defined this color in your variables
  margin-top: ${spacing.sl};
`;

export const Backdrop = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 50; // Below the sidebar but above everything else
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent background
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export const StyledBorderedTag = styled(Tag)`
  &&.ant-tag .ant-tag-close-icon {
    color: ${(props) => (props.color ? props.color : color.orange)} !important;
  }
  &&.ant-tag {
    color: ${(props) => (props.color ? props.color : color.orange)} !important;
    background: ${(props) =>
      hexToRgba(props.color ? props.color : color.orange, 0.05)} !important;
    border-color: ${(props) =>
      props.color ? props.color : color.orange} !important;
  }
`;

export const ScrollableDiv = styled.div`
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar
  }
  @media (min-width: ${deviceSize.large_phone}) {
    overflow-y: auto;
    max-height: 600px;
  }
`;

export const TrucoTitle = styled(Typography.Title)`
  ${(props) =>
    props.maxNumLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${props.maxNumLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export const TrucoParagraph = styled(Typography.Paragraph)`
  ${(props) =>
    props.maxNumLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${props.maxNumLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;
