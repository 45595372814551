import React, { Fragment } from "react";
import { arrayUnion, arrayRemove } from "firebase/firestore";
import { updateDocInFireStore } from "src/firebaseAuth";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { Tag, Space, Tooltip, Progress } from "antd";
import { PlaylistPlay } from "@mui/icons-material";
import {
  ClockCircleOutlined,
  CalendarOutlined,
  FormOutlined,
} from "@ant-design/icons";

import {
  cleanObject,
  decryptId,
  humanize,
  isReviewerInCalibration,
  unhumanize,
} from "src/utils/utils";
import {
  ANALYSIS_LABEL,
  ANALYSIS_TYPE,
  COLLECTION_DATA,
  QUERY_PARAMS,
  RATINGS,
  RATING_VALUES,
  SENTIMENT,
} from "src/utils/enums";
import {
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import { color } from "src/styles/variables";
import { Container, HoveredSpan, ItemContainer } from "./Dashboard.styles";
import {
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { INTEGRATION_ICONS, YesNoIcon, User } from "src/components";
import { fetchEvaluationHistory } from "src/utils/apiCalls";
import axios from "axios";
// import AnalysisFilters from "./AnalysisFilters";

export const ARRAY_SEPARATOR = "\n";

export const updateTranscriptToDb = async (
  organization,
  transcriptId,
  transcriptUpdate,
) => {
  const cleanedTranscriptUpdate = cleanObject(transcriptUpdate);
  await updateDocInFireStore(
    `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${transcriptId}`,
    cleanedTranscriptUpdate,
  );
};

export const markFeedbackAsReadToDB = (
  userEmail,
  evaluation,
  organization,
  id,
) => {
  if (!evaluation?.unreadBy?.includes(userEmail)) {
    return evaluation;
  }

  evaluation["unreadBy"] = (evaluation?.["unreadBy"] || []).filter(
    (item) => item !== userEmail,
  );
  updateTranscriptToDb(organization, id, {
    "evaluation.unreadBy": arrayRemove(userEmail),
  });
  return evaluation;
};

export const handleFeedbackDelete = async (
  messageIndex,
  setMessages,
  organization,
  fileId,
  user,
  evaluation,
  calibration?,
) => {
  const isCalibrator = isReviewerInCalibration(calibration, user);

  // Create a new array with the message marked as deleted
  setMessages((prevMessages) => {
    if (!Array.isArray(prevMessages)) return []; // Ensure prevMessages is an array

    // Use map to create a new array without directly mutating prevMessages
    const updatedMessages = prevMessages.map((msg, index) => {
      if (index === messageIndex) {
        return { ...msg, deleted: true };
      }
      return msg;
    });

    return updatedMessages;
  });

  const newMessages = await setMessages;

  // Now perform the async operations
  if (isCalibrator) {
    const copiedEvaluation = cloneDeep(evaluation);
    copiedEvaluation["feedback"] = newMessages; // Use the updated messages
    const updatesToSave = {
      evaluation: copiedEvaluation,
    };
    calibration["details"][user.email] = updatesToSave;
    const calibratorUpdates = { calibration };
    await updateTranscriptToDb(organization, fileId, calibratorUpdates);
  } else {
    await updateTranscriptToDb(organization, fileId, {
      [`evaluation.feedback`]: newMessages,
    });
  }
};

export const handleFeedbackUpdateToDB = (
  message,
  evaluation,
  organization,
  id,
  user,
  calibration,
  mentions = [],
) => {
  const newUserEmail = message?.email;
  const isCalibrator = isReviewerInCalibration(calibration, user);
  const participants = Array.from(
    new Set([...evaluation.participants, newUserEmail, ...mentions]),
  );
  const unreadBy = participants.filter((email) => email !== newUserEmail);

  // Add feedback message, add user to participants
  if (evaluation?.feedback && evaluation.participants) {
    evaluation.feedback.push(message);
    evaluation.participants = participants;
    evaluation["unreadBy"] = unreadBy;

    if (isCalibrator) {
      let updatedEvaluation = {
        ...evaluation,
        feedback: arrayUnion(...evaluation.feedback),
        participants: arrayUnion(...participants),
        unreadBy: evaluation["unreadBy"],
        lastFeedbackTime: new Date().toISOString(),
      };
      if (evaluation.calibration) {
        updatedEvaluation = {
          feedback: arrayUnion(...evaluation.feedback),
          participants: arrayUnion(...participants),
          unreadBy: evaluation["unreadBy"],
          lastFeedbackTime: new Date().toISOString(),
        };
      }
      calibration["details"][user.email] ??= {};
      calibration["details"][user.email]["evaluation"] = updatedEvaluation;
      const calibratorUpdates = { calibration };
      updateTranscriptToDb(organization, id, calibratorUpdates);
      updateTranscriptToDb(organization, id, {
        "evaluation.participants": arrayUnion(...participants),
        "evaluation.lastFeedbackTime": new Date().toISOString(),
      });
    } else {
      const calibrationEmails = calibration
        ? Object.keys(calibration.details)
        : [];
      const copiedUnreadBy = [...evaluation["unreadBy"]];

      calibrationEmails.forEach((email) => {
        const index = copiedUnreadBy.indexOf(email);
        if (index !== -1) {
          copiedUnreadBy.splice(index, 1);
        }
      });
      updateTranscriptToDb(organization, id, {
        "evaluation.feedback": arrayUnion(message),
        "evaluation.participants": arrayUnion(...participants),
        "evaluation.unreadBy": copiedUnreadBy,
        "evaluation.lastFeedbackTime": new Date().toISOString(),
      });
    }
  } else {
    evaluation["participants"] = participants;
    evaluation["unreadBy"] = [];
    evaluation["feedback"] = [message];
    evaluation["lastFeedbackTime"] = new Date().toISOString();
    if (isCalibrator) {
      calibration["details"][user.email] ??= {};
      if (evaluation.calibration) {
        let updatedEvaluation = {
          feedback: evaluation.feedback,
          participants: evaluation.participants,
          unreadBy: evaluation["unreadBy"],
          lastFeedbackTime: new Date().toISOString(),
        };

        calibration["details"][user.email]["evaluation"] = updatedEvaluation;
      } else {
        calibration["details"][user.email]["evaluation"] = evaluation;
      }
      const calibratorUpdates = { calibration };
      updateTranscriptToDb(organization, id, calibratorUpdates);
    } else {
      updateTranscriptToDb(organization, id, {
        evaluation: evaluation,
      });
    }
  }
  return evaluation;
};

export const TruncateCell = ({ cell, numLines = 1 }) => {
  const cellValue = cell.getValue();

  if (typeof cellValue === "boolean") {
    return <YesNoIcon value={cellValue} />;
  } else if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    "name" in cellValue &&
    "email" in cellValue &&
    "conversationRole" in cellValue &&
    "photoURL" in cellValue
  ) {
    return <User user={cellValue} />;
  }

  const lines =
    cellValue === null
      ? [""]
      : typeof cellValue === "string"
        ? cellValue.split("\n")
        : typeof cellValue === "object"
          ? cellValue
          : [cellValue];

  return (
    <HoveredSpan numLines={numLines}>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </HoveredSpan>
  );
};

export const getOrderedKeys = (obj) => {
  const keys = Object.keys(obj);
  const idKeys = keys.filter((key) =>
    ["file_name", "duration", "created_at"].includes(key),
  );
  const stringKeys = keys.filter(
    (key) =>
      (typeof obj[key] === "string" || typeof obj[key] === "object") &&
      !idKeys.includes(key),
  );
  const notStringKeys = keys.filter(
    (key) => !stringKeys.includes(key) && !idKeys.includes(key),
  );
  // Sort string keys by the length of their associated values
  stringKeys.sort((key1, key2) => {
    const value1 =
      typeof obj[key1] === "string" ? obj[key1] : JSON.stringify(obj[key1]);
    const value2 =
      typeof obj[key2] === "string" ? obj[key2] : JSON.stringify(obj[key2]);
    return value1.length - value2.length;
  });
  return [...idKeys, ...notStringKeys, ...stringKeys];
};

const filterUsers = (row, id, filterValue) => {
  const uniqueUsers = new Set();
  ["agent", "lead", "reviewer"].forEach((userType) => {
    if (row.original[userType]?.name) {
      uniqueUsers.add(row.original[userType]?.name.toLowerCase());
    }
  });
  return [...uniqueUsers].some((user: any) =>
    user.includes(filterValue.toLowerCase()),
  );
};
const filterEvaluationOrPlaylist = (row, id, filterValue) => {
  const { playlists } = row.original;
  const evaluation_form = row.original?.evaluation_form?.title;
  const evaluationMatch = evaluation_form
    .toLowerCase()
    .includes(filterValue.toLowerCase());
  const playlistMatch = playlists?.some((playlist) =>
    playlist?.title?.toLowerCase()?.includes(filterValue?.toLowerCase()),
  );
  return evaluationMatch || playlistMatch;
};

export const getColumns = (data, showRisk, conversationsCustomizedFields?) => {
  const allAnalysisKeys = new Map();
  // Loop through all records to gather unique analysis columns
  data.forEach((record) => {
    const analysis = record?.analysis || [];

    analysis.forEach((analysisGroup, analysisIdx) => {
      analysisGroup?.value?.forEach((metricGroup, metricIdx) => {
        const key = `${analysisIdx}-${metricIdx}`;

        if (
          !allAnalysisKeys.has(key) &&
          ![...allAnalysisKeys.values()].some(
            (column) => column.header === metricGroup?.label,
          )
        ) {
          allAnalysisKeys.set(key, {
            id: `${key}-${unhumanize(metricGroup?.label)}`,
            header: metricGroup?.label,
            accessorFn: (row) =>
              row.analysis?.[analysisIdx]?.value?.[metricIdx]?.value === "N/A"
                ? ""
                : row.analysis?.[analysisIdx]?.value?.[metricIdx]?.value,
            Cell: ({ cell }) => (
              <TruncateCell
                cell={{ getValue: () => cell.getValue() }}
                numLines={5}
              />
            ),
            enableColumnFilter: true, // Enable filter for this column
            filterVariant: "select", // Use the built-in select filter
            filterSelectOptions: [
              { text: "All", value: "" },
              { text: "Not Empty", value: "not_empty" },
            ],
            initialState: {
              filterValue: "not_empty", // Set the initial filter value to "Not Empty"
            },
            filterFn: (row, id, filterValue) => {
              const value =
                row.original.analysis?.[analysisIdx]?.value?.[metricIdx]?.value;
              // If filterValue is empty, return all rows
              if (!filterValue) {
                return true;
              }
              if (filterValue === "not_empty") {
                return (
                  value !== null &&
                  value !== undefined &&
                  value !== "" &&
                  value !== "N/A"
                );
              }
              return true;
            },
            size: 200,
            isDynamic: true, // Mark these columns as dynamic (i.e., from analysis)
          });
        }
      });
    });
  });

  const restKeys = [
    {
      header: "Time Info",
      accessorFn: (row) => ({
        created_at: row.created_at,
        duration: row.duration,
      }),
      Cell: ({ cell }) => (
        <Container>
          <Tooltip title="Created At">
            <ItemContainer>
              <CalendarOutlined
                style={{ marginRight: "8px", color: "#52c41a" }}
              />
              <TruncateCell
                cell={{ getValue: () => cell.getValue().created_at }}
              />
            </ItemContainer>
          </Tooltip>
          <Tooltip title="Duration">
            <ItemContainer>
              <ClockCircleOutlined
                style={{ marginRight: "8px", color: "#1890ff" }}
              />
              <TruncateCell
                cell={{ getValue: () => cell.getValue().duration }}
              />
            </ItemContainer>
          </Tooltip>
        </Container>
      ),
      size: 30,
      isDynamic: false, // Default columns are not dynamic
    },
    {
      header: "Evaluation & Playlists",
      accessorFn: (row) => ({
        evaluation_form: row.evaluation_form?.title,
        playlists: row.playlists,
      }),
      Cell: ({ cell }) => {
        const { evaluation_form, playlists } = cell.getValue();
        return (
          <Container>
            <Tooltip title="Evaluation Form">
              <ItemContainer>
                <FormOutlined
                  style={{ marginRight: "9px", color: "#52c41a" }}
                />
                <TruncateCell cell={{ getValue: () => evaluation_form }} />
              </ItemContainer>
            </Tooltip>
            {playlists?.length > 0 && (
              <Tooltip title="Playlists">
                <ItemContainer>
                  <PlaylistPlay style={{ color: "#1890ff" }} />
                  {playlists.map((playlist, index) => (
                    <span key={playlist.id}>
                      {playlist.title}
                      {index < playlists.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </ItemContainer>
              </Tooltip>
            )}
          </Container>
        );
      },
      size: 30,
      filterFn: filterEvaluationOrPlaylist,
      isDynamic: false, // Default columns are not dynamic
    },
  ];

  const teamKey = {
    header: "Users",
    accessorKey: "users",
    Cell: ({ row }) => {
      const uniqueUsers = new Map();

      // Add userType names and emails to the map
      ["agent", "lead", "reviewer"].forEach((userType) => {
        const name = row.original[userType]?.name;
        const email = row.original[userType]?.email;

        if (email) {
          // If the email is already in the map, we skip adding a duplicate.
          if (!uniqueUsers.has(email)) {
            uniqueUsers.set(email, name || email);
          }
        }
      });

      // Add participants' names and emails to the map
      row.original.participants?.forEach((participant) => {
        const name = participant?.name;
        const email = participant?.email;

        if (email) {
          // Only add to the map if the email doesn't already exist
          if (!uniqueUsers.has(email)) {
            uniqueUsers.set(email, name || email);
          }
        } else {
          // Add participants without an email based on their available identifier
          uniqueUsers.set(
            name || participant?.phone || participant?.customer,
            name || participant?.phone || participant?.customer,
          );
        }
      });

      // Render the unique users as tags
      return (
        <>
          {[...uniqueUsers.values()].map((user: string) => (
            <Tag key={user}>{user}</Tag>
          ))}
        </>
      );
    },
    filterFn: filterUsers,
    size: 200,
    isDynamic: false, // Default column
  };

  const analysisKey = {
    header: `${showRisk ? "Risk" : "Score"}`,
    Cell: ({ row }) => {
      const score = row.original?.analysis?.[0]?.score;
      const percent =
        score != null && score !== "N/A"
          ? showRisk
            ? Math.round((1 - score) * 100)
            : Math.round(score * 100)
          : 0;
      const format =
        score != null && score !== "N/A"
          ? (percent) => {
              const successPercent = showRisk ? 0 : 100;
              const failPercent = showRisk ? 100 : 0;
              const normalPercent =
                percent !== failPercent && percent !== successPercent;
              return normalPercent ? (
                `${percent}%`
              ) : (
                <YesNoIcon value={percent === successPercent} />
              );
            }
          : () => "N/A";
      return (
        <Progress
          type="dashboard"
          steps={5}
          percent={percent}
          strokeColor={showRisk ? color.orange : color.olive}
          trailColor="rgba(0, 0, 0, 0.06)"
          size={40}
          strokeWidth={10}
          format={format}
        />
      );
    },
    size: 20,
    isDynamic: false, // Default column
  };

  const fileNameColumn = {
    header: "File Name",
    accessorKey: "file_name",
    size: 200,
    Cell: ({ cell, row }) => {
      const fileSource = row.original?.file_source;
      const Icon = INTEGRATION_ICONS?.[fileSource];

      return (
        <Space>
          {Icon}
          <TruncateCell cell={cell || ""} numLines={2} />
        </Space>
      );
    },
    isDynamic: false, // Default column
  };

  const verifiedColumn = {
    header: "Verified",
    accessorKey: "evaluation.verified",
    size: 20,
    Cell: ({ cell }) => <TruncateCell cell={cell} />,
    filterVariant: "select",
    filterSelectOptions: [
      { text: "Verified", value: true },
      { text: "Not Verified", value: false },
    ],
    filterFn: (rows, id, filterValue) => {
      if (filterValue === undefined) return rows;
      return rows.original.evaluation.verified === filterValue;
    },
    isDynamic: false, // Default column
  };
  const customFieldColumns = conversationsCustomizedFields.map((field) => ({
    header: field.fieldName, // Header label for the field
    accessorKey: `metadata.${field.key}`, // Path to access the value in row.metadata
    size: 100, // Fixed size for custom fields
    isDynamic: true, // Flag to identify custom/dynamic fields
    isCustomField: true,
    id: `metadata.${field.key}`, // Unique ID based on metadata key
    columnDefType: "data", // Column type as data
    Cell: ({ cell }) => <TruncateCell cell={cell} numLines={3} />, // Cell rendering component
  }));

  // Combine default and dynamic columns
  return [
    fileNameColumn,
    ...restKeys,
    teamKey,
    analysisKey,
    verifiedColumn,
    ...customFieldColumns,
    ...allAnalysisKeys.values(), // Spread the dynamic columns gathered from all data
  ];
};

export const getKeyValueFromObject = (data) => {
  return Object.entries(data).map(([key, value]) => {
    let newKey = humanize(key);
    return { key: newKey, value };
  });
};

export const nestedObjectToTableData = (data, mainKey = null) => {
  return Object.entries(data).map(([key, value]) => {
    let newKey = humanize(key);
    if (typeof value === "object" && !Array.isArray(value)) {
      let defaultValue = "";
      //       if (mainKey && value[mainKey]) {
      //          defaultValue = value[mainKey];
      //       }
      return {
        key: newKey,
        value: defaultValue,
        subRows: nestedObjectToTableData(value, mainKey),
      };
    } else {
      const newValue = typeof value === "boolean" ? value.toString() : value;
      return { key: newKey, value: newValue };
    }
  });
};

export const convertType = (value, toType) => {
  if (value === null || value === "") {
    return null;
  } else if (Array.isArray(value)) {
    return value.map((value_i) => convertType(value_i, toType));
  }

  let convertedValue;
  switch (toType) {
    case "string":
      convertedValue = value.toString();
      break;
    case "number":
      convertedValue = Number(value);
      break;
    case "boolean":
      convertedValue = Boolean(value);
      break;
    default:
      console.error("Unsupported type, expecting ", value, toType);
  }
  return convertedValue;
};

export const sentimentType = (sentiment: SENTIMENT) => {
  switch (sentiment) {
    case SENTIMENT.POSITIVE:
      return <SentimentSatisfiedAlt style={{ color: color.green }} />;
    case SENTIMENT.NEGATIVE:
      return <SentimentVeryDissatisfied style={{ color: color.red }} />;
    case SENTIMENT.NEUTRAL:
    default:
      return <></>;
  }
};

export const sentimentColor = (sentiment: SENTIMENT) => {
  switch (sentiment) {
    case SENTIMENT.POSITIVE:
      return color.green;
    case SENTIMENT.NEGATIVE:
      return color.red;
    case SENTIMENT.NEUTRAL:
      return color.grayLight;
    default:
      return "";
  }
};

export function updateAnalysisGroupScore(analysisGroup) {
  const validMetrics = analysisGroup?.value.filter(
    (metricGroup) => metricGroup?.rating !== null,
  );
  const newScore = validMetrics?.length
    ? parseFloat(
        (
          validMetrics.reduce((acc: number, metricGroup): number => {
            return metricGroup?.rating + acc;
          }, 0) / validMetrics?.length
        ).toFixed(2),
      )
    : "N/A";
  analysisGroup["score"] = newScore;
  return analysisGroup;
}

export function updateAnalysisScore(
  analysis,
  overallGroupIndex,
  evaluationForm,
) {
  let validMetricsWeightedCount = 0.0;
  let validMetrics = analysis.reduce((acc, analysisGroup, analysisIdx) => {
    const analysisGroupWeight =
      evaluationForm?.analysisStructures?.[analysisIdx - 1]?.weight ?? 1.0;
    let filteredMetrics = analysisGroup.value
      .filter((metric) => metric.rating !== null)
      .map((metric) => ({
        ...metric,
        weight: analysisGroupWeight,
      }));

    validMetricsWeightedCount += filteredMetrics?.length * analysisGroupWeight;
    return acc.concat(filteredMetrics);
  }, []);

  const newOverallScore = validMetrics?.length
    ? parseFloat(
        (
          validMetrics.reduce((acc: number, metricGroup): number => {
            return metricGroup?.rating * metricGroup?.weight + acc;
          }, 0) / validMetricsWeightedCount
        ).toFixed(2),
      )
    : "N/A";

  analysis[overallGroupIndex]["score"] = newOverallScore;
  return analysis;
}

function removeReason(analysis) {
  analysis?.length &&
    analysis.forEach((analysisGroup, analysisIndex) => {
      analysisGroup?.value.forEach((metric, metricIndex) => {
        delete metric?.reason;
      });
    });
  return analysis;
}
export function updateScoresRemoveReason(analysis, evaluationForm) {
  let overallIndex = null;
  analysis?.length &&
    analysis.forEach((analysisGroup, analysisIndex) => {
      const analysisType = analysisGroup.id;
      if (analysisType === "overall") {
        overallIndex = analysisIndex;
        return;
      }
      analysis[analysisIndex] = updateAnalysisGroupScore(analysisGroup);
    });

  if (overallIndex !== null) {
    analysis = updateAnalysisScore(analysis, overallIndex, evaluationForm);
  }
  return removeReason(analysis);
}

export function updateEvaluation(evaluation, newAnalysis, oldAnalysis, user) {
  newAnalysis.forEach((newAnalysisGroup, analysisIndex) => {
    newAnalysisGroup?.value.forEach((metric, metricIndex) => {
      const metricNameForUpdates = newAnalysisGroup?.id + "." + metric?.id;

      const newMetricValue = newAnalysis[analysisIndex]?.value[metricIndex];
      const oldMetricValue = oldAnalysis[analysisIndex]?.value[metricIndex];

      const evaluationValue = evaluation.updates?.[metricNameForUpdates];

      if (
        isEqual(newMetricValue?.value, oldMetricValue?.value) &&
        newMetricValue?.rating === oldMetricValue?.rating
      ) {
        return;
      } else if (
        isEqual(evaluationValue?.old_value, newMetricValue?.value) &&
        evaluationValue?.old_rating === newMetricValue?.rating
      ) {
        delete evaluation.updates?.[metricNameForUpdates];
      } else {
        const newUpdate = {
          time: new Date().toISOString(),
          name: user?.name || "",
          email: user?.email || "",

          old_value: evaluationValue?.old_value || oldMetricValue?.value,
          updated_value: newMetricValue?.value,

          old_rating: evaluationValue?.old_rating || oldMetricValue?.rating,
          updated_rating: newMetricValue?.rating,

          reason: newMetricValue?.reason || "",
        };
        evaluation["updates"][metricNameForUpdates] = newUpdate;
      }
    });
  });

  return evaluation;
}

export const getFileIcon = (filename) => {
  const style = { fontSize: "20px" };
  if (filename.endsWith(".pdf")) {
    return <FilePdfOutlined style={{ ...style, color: "#FF0000" }} />;
  } else if (filename.endsWith(".docx") || filename.endsWith(".doc")) {
    return <FileWordOutlined style={{ ...style, color: "#2A5699" }} />;
  } else if (filename.endsWith(".txt")) {
    return <FileTextOutlined style={{ ...style, color: "#333333" }} />;
  } else {
    return <FileOutlined style={{ ...style, color: "#666666" }} />;
  }
};

export const generateDataSourceWithCalibration = (
  analysis,
  organizationSettings,
  baseline?,
  details?,
) => {
  let dataSource = [];

  analysis.forEach((analysisGroup, analysisIndex) => {
    const analysisType = analysisGroup.id;

    let groupHeader: any = {
      key: `header-${analysisType}`,
      rating:
        analysisGroup?.[ANALYSIS_LABEL.SCORE] !== RATING_VALUES.NONE
          ? (organizationSettings?.showRisk
              ? (1 - analysisGroup?.[ANALYSIS_LABEL.SCORE]) * 100
              : analysisGroup?.[ANALYSIS_LABEL.SCORE] * 100
            ).toFixed(0) + "%"
          : "",
      analysisIndex: analysisIndex,
      analysisType: analysisType,
      label: analysisGroup?.label
        ? analysisGroup?.label
        : humanize(analysisType),
      isGroupHeader: true,
    };
    if (baseline || details) {
      if (baseline) {
        const baselineGroup = baseline.analysis.find(
          (group) => group.id === analysisType,
        );
        const rating =
          baselineGroup[ANALYSIS_LABEL.SCORE] !== RATING_VALUES.NONE
            ? (organizationSettings?.showRisk
                ? (1 - baselineGroup?.[ANALYSIS_LABEL.SCORE]) * 100
                : baselineGroup?.[ANALYSIS_LABEL.SCORE] * 100
              ).toFixed(0) + "%"
            : "";
        groupHeader.baseline = {
          rating: rating,
          analysisIndex: analysisIndex,
          analysisType: analysisType,
        };
      }

      Object.keys(details).forEach((email) => {
        const emailGroup = details[email].analysis.find(
          (group) => group.id === analysisType,
        );
        const emailRating =
          emailGroup[ANALYSIS_LABEL.SCORE] !== RATING_VALUES.NONE
            ? (organizationSettings?.showRisk
                ? (1 - emailGroup?.[ANALYSIS_LABEL.SCORE]) * 100
                : emailGroup?.[ANALYSIS_LABEL.SCORE] * 100
              ).toFixed(0) + "%"
            : "";
        groupHeader[email] = {
          rating: emailRating,
          isSameAsBaseline: groupHeader?.baseline?.rating === emailRating,
          analysisIndex: analysisIndex,
        };
      });
    }
    // Add a group header
    dataSource.push(groupHeader);
    analysisGroup?.value.forEach((metric, metricIndex) => {
      const metricType = metric?.id;

      if (metric?.value !== null) {
        let metricData: any = {
          key: `${analysisType}-${metricType}`,
          value: metric?.value,
          rating: metric?.rating,
          analysisIndex: analysisIndex,
          analysisType: analysisType,
          label: metric?.label ? metric?.label : humanize(metricType),
          isGroupHeader: false,
          metricIndex: metricIndex,
        };

        // Add calibration data for each metric, if available
        if (baseline || details) {
          if (baseline) {
            metricData.baseline = baseline.analysis
              .map((group) =>
                group.value.find((m) => m.label === metric?.label),
              )
              .filter((m) => m)[0] || { value: null, rating: null };

            metricData.baseline = {
              ...metricData.baseline,
              analysisIndex: analysisIndex,
              metricIndex: metricIndex,
              analysisType: analysisType,
            };
          }

          Object.keys(details).forEach((email) => {
            metricData[email] = details[email].analysis
              .map((group) =>
                group.value.find((m) => m.label === metric?.label),
              )
              .filter((m) => m)[0] || { value: null, rating: null };
            metricData[email] = {
              ...metricData[email],
              isSameAsBaseline:
                metricData[email].rating === metricData?.baseline?.rating,
              analysisIndex: analysisIndex,
              metricIndex: metricIndex,
            };
          });
        }

        dataSource.push(metricData);
      }
    });
  });

  return dataSource;
};

export const updateMetric = (
  analysis,
  analysisIndex,
  metricIndex,
  updateKey,
  updateValue,
) => {
  if (updateKey === ANALYSIS_TYPE.RATING) {
    updateValue = convertType(updateValue, "number");
  } else if (
    Array.isArray(analysis[analysisIndex].value[metricIndex][updateKey])
  ) {
    updateValue = convertType(
      updateValue.split(ARRAY_SEPARATOR),
      typeof analysis[analysisIndex].value[metricIndex][updateKey][0],
    );
  }
  if (
    analysis &&
    analysis[analysisIndex] &&
    analysis[analysisIndex].value &&
    analysis[analysisIndex].value[metricIndex]
  ) {
    analysis[analysisIndex].value[metricIndex][updateKey] = updateValue;

    if (updateKey === ANALYSIS_TYPE.RATING) {
      switch (true) {
        case updateValue === RATINGS.NONE:
          analysis[analysisIndex].value[metricIndex][ANALYSIS_TYPE.VALUE] =
            RATING_VALUES.NONE;
          break;
        case updateValue === RATINGS.ONE:
          if (
            analysis[analysisIndex].value[metricIndex][ANALYSIS_TYPE.VALUE] ===
              RATING_VALUES.ZERO ||
            analysis[analysisIndex].value[metricIndex][ANALYSIS_TYPE.VALUE] ===
              RATING_VALUES.NONE
          ) {
            analysis[analysisIndex].value[metricIndex][ANALYSIS_TYPE.VALUE] =
              RATING_VALUES.ONE;
          }
          break;
        case updateValue === RATINGS.ZERO:
          analysis[analysisIndex].value[metricIndex][ANALYSIS_TYPE.VALUE] =
            RATING_VALUES.ZERO;
          break;
      }
    }
  }
  return analysis;
};

export const handleAnalysisEdit = (
  newValue,
  analysisIndex,
  metricIndex,
  updateKey = ANALYSIS_TYPE.VALUE,
  editing,
  setEditing,
) => {
  if (
    editing?.[analysisIndex]?.value?.[metricIndex]?.[updateKey] !== newValue
  ) {
    setEditing((prevEditing) => {
      let newEditing = [...prevEditing];
      // Update the value in the new copy
      newEditing = updateMetric(
        newEditing,
        analysisIndex,
        metricIndex,
        updateKey,
        newValue,
      );
      // Return the updated copy
      return newEditing;
    });
  }
};

export const handleAnalysisBulkEdit = (
  clipboardText,
  startingAnalysisIndex,
  startingMetricIndex,
  updateKey,
  setEditing,
) => {
  let newValues = clipboardText.replace(/\r\n/g, "\n").split("\n");
  setEditing((prevEditing) => {
    let newEditing = [...prevEditing];
    let skip = true;
    let index = 0;
    newEditing.forEach((analysisGroup, analysisIndex) => {
      if (skip && analysisIndex !== startingAnalysisIndex) {
        return;
      }
      analysisGroup?.value.forEach((metric, metricIndex) => {
        if (skip && metricIndex !== startingMetricIndex) {
          return;
        }
        if (index >= newValues.length) {
          return;
        }
        skip = false;
        newEditing = updateMetric(
          newEditing,
          analysisIndex,
          metricIndex,
          updateKey,
          newValues[index],
        );
        index += 1;
      });
    });
    return newEditing;
  });
};

export const validateNewAnalysis = (newAnalysis) => {
  const errors = [];
  newAnalysis.forEach((analysisGroup, analysisIndex) => {
    const analysisType = analysisGroup.id;
    newAnalysis[analysisIndex].value.forEach((metric, metricIndex) => {
      if (
        ![0, 1, null].includes(
          newAnalysis[analysisIndex]?.value?.[metricIndex]?.rating,
        )
      ) {
        errors.push([analysisType, metric.id]);
      }
    });
  });
  return errors;
};

export const fetchEvaluationFormHistory = async (
  organization,
  evaluation_id,
  setEvaluationForms,
  currentEvaluationForm,
) => {
  let evaluationFormHistory = currentEvaluationForm?.history;
  if (!evaluationFormHistory) {
    evaluationFormHistory = await fetchEvaluationHistory(
      organization,
      evaluation_id,
      setEvaluationForms,
    );
  }
  return evaluationFormHistory;
};

export const getCurrentEvaluationForm = async (
  organization,
  evaluation_id,
  evaluation_form,
  evaluationForms,
  setEvaluationForms,
) => {
  let currentEvaluationForm = evaluationForms?.[evaluation_id];
  if (
    evaluation_form?.version &&
    evaluation_form?.version !== evaluationForms?.[evaluation_id]?.version
  ) {
    const evaluationFormHistory = await fetchEvaluationFormHistory(
      organization,
      evaluation_id,
      setEvaluationForms,
      currentEvaluationForm,
    );
    const matchingVersion = evaluationFormHistory?.find(
      (h) => h.version === evaluation_form.version,
    );
    if (matchingVersion) {
      currentEvaluationForm = matchingVersion;
    }
  }
  return currentEvaluationForm;
};

export async function runAnalysis(fileId, organization, evaluationId) {
  const formData = new FormData();
  formData.append("file_id", fileId);
  formData.append("organization", organization);
  if (evaluationId != null)
    formData.append("evaluation_form", JSON.stringify({ id: evaluationId }));
  const response = await axios.post(
    process.env.REACT_APP_TRUCO_BACKEND + "/run_analysis",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data;
}

export const fetchFileUrl = async (fileId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRUCO_BACKEND}/get_file_url`,
      { file_id: fileId },
      { headers: { "Content-Type": "application/json" } },
    );
    return response.data.url;
  } catch (error) {
    console.error("Error fetching file URL:", error);
    throw error;
  }
};

export const formatTooltipFromSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

export const generateUtteranceTopics = (
  utterances,
  holds,
  findUtteranceIndexWithTime,
) => {
  return [
    ...(utterances?.flatMap(({ start, end, topics }, index) =>
      topics?.length
        ? [{ start, end, topic: topics.at(-1)?.topic, index }]
        : [],
    ) ?? []),

    ...(holds?.segments?.map(({ start, end }) => ({
      start,
      end,
      topic: "Hold",
      index: findUtteranceIndexWithTime(start),
    })) ?? []),
  ];
};

export function getConversationScopes() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const encodedScope = params.get(QUERY_PARAMS.CONVERSATION_SCOPE);

  if (encodedScope) {
    const scope = decodeURIComponent(encodedScope);

    const [encodedOrganization, encodedScopeDetails] = scope.split("&");

    if (encodedOrganization) {
      const decodedOrganization = decryptId(encodedOrganization);
      const scopeDetails = encodedScopeDetails
        ? decryptId(encodedScopeDetails).split(",")
        : [];

      return {
        decodedOrganization,
        scopeDetails,
      };
    } else {
      console.error("Invalid scope format.");
      return { decodedOrganization: null, scopeDetails: [] };
    }
  }

  return { decodedOrganization: null, scopeDetails: [] };
}
export function checkOrganizationAndScope(
  conversationOrganization,
  userOrganization,
  scopeDetails,
  currentScope,
) {
  if (!conversationOrganization) {
    return true;
  }
  if (conversationOrganization === userOrganization) {
    return true;
  } else {
    if (scopeDetails.includes(currentScope)) {
      return true;
    } else {
      return false;
    }
  }
}
