import { useEffect, useRef, useState } from "react";
import GenericForm from "./GenericForm";
// import { StandardPage } from "../Home";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { ACCESS_TYPE, FREE_TRIAL_STATUS } from "src/utils/enums";
import { message, Modal } from "antd";
import { IntegrationsCard } from "src/components/Profile/Components/Intergrations/IntegrationsCard";
import { USER_INTEGRATIONS } from "src/components/Profile";
import { SubscriptionCard } from "src/components/Profile/Components/SubscriptionCard/SubscriptionCard";
import {
  createOrUpdateDocInFirestore,
  addDocumentWithId,
} from "src/firebaseAuth";
import { GlowingButton } from "../Home.styles";

const FreeTrial = () => {
  const { setOrganization, organizationSettings, setOrganizationSettings } =
    useOrganizationState();
  const { user, setUser } = useUserState();
  const [company, setCompany] = useState(
    user?.organization ? organizationSettings.name : "",
  );
  const [currentStep, setCurrentStep] = useState(() => {
    const status = organizationSettings?.stripe?.free_trial;
    if (
      status === FREE_TRIAL_STATUS.awaitingIntegration ||
      status === FREE_TRIAL_STATUS.awaitingStripe
    ) {
      return 1; // Step 1 corresponds to these statuses
    } else if (status === FREE_TRIAL_STATUS.set) {
      return 2; // Step 2 corresponds to the "done" status
    }
    return 0; // Default step (if no status matches)
  });
  const hasSelectedIntegration =
    USER_INTEGRATIONS.some((integration) => user?.[integration]) &&
    organizationSettings?.stripe?.free_trial ===
      FREE_TRIAL_STATUS.awaitingIntegration;

  const [loading, setLoading] = useState(
    !user || (user && user.organization && !organizationSettings.name),
  );
  let content;
  const modalTitle =
    organizationSettings?.stripe?.free_trial ===
    FREE_TRIAL_STATUS.awaitingIntegration
      ? "Connect at least 1 User Integration with Truco"
      : "Subscribe to Truco";

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();

    try {
      const newOrganizationId = await addDocumentWithId("/organization", {
        name: company,
        numberOfSeats: 0,
        stripe: {
          free_trial: FREE_TRIAL_STATUS.awaitingIntegration,
        },
      });
      setOrganization(newOrganizationId);
      setOrganizationSettings({
        ...organizationSettings,
        name: company,
        stripe: {
          free_trial: FREE_TRIAL_STATUS.awaitingIntegration,
        },
        numberOfSeats: 0,
      });

      await createOrUpdateDocInFirestore(`/users/${user.uid}`, {
        organization: newOrganizationId,
        accessType: ACCESS_TYPE.owner,
      });

      setUser({
        ...user,
        organization: newOrganizationId,
        accessType: ACCESS_TYPE.owner,
      });
      // Move to the next step
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      // Show error message for any unexpected error
      message.error(`Error initiating free trial: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleGoToStripe = async () => {
    // First update the Firestore document
    setLoading(true);
    try {
      await createOrUpdateDocInFirestore(`/organization/${user.organization}`, {
        stripe: {
          free_trial: FREE_TRIAL_STATUS.awaitingStripe,
        },
      });

      // Once Firestore is updated, update the organization settings locally
      setOrganizationSettings({
        ...organizationSettings,
        stripe: {
          free_trial: FREE_TRIAL_STATUS.awaitingStripe,
        },
      });
    } catch (error) {
      message.error(`Failed to update organization: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  const nextButtonRef = useRef(null); // Create a ref for the "Next" button

  // Effect to scroll to the Next button when it's rendered
  useEffect(() => {
    if (hasSelectedIntegration && nextButtonRef.current) {
      nextButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hasSelectedIntegration]); // Trigger when the condition for showing the "Next" button is met

  const stepsData = [
    { title: "Fill the form" },
    { title: "Add Integrations" },
    { title: "Truco's Playground" },
  ];

  const items = [
    {
      label: "Company Name",
      setFunction: setCompany,
      value: company,
      required: true,
      disabled: !!user?.organization, // Disable the field so it can't be edited
    },
  ];

  content = (
    <>
      <GenericForm
        title="30 Days Free "
        firstSubtitle="Let’s get you started with a free trial of "
        secondSubtitle="Get started in minutes!"
        stepsData={stepsData}
        items={items}
        currentStep={currentStep}
        handleSubmit={handleSubmit}
        buttonTitle="Next"
        trucoInTitleAtEnd={false}
        loading={loading}
      />
      <Modal
        loading={loading}
        width={`85vw`}
        title={modalTitle}
        open={
          organizationSettings?.stripe?.free_trial ===
            FREE_TRIAL_STATUS.awaitingIntegration ||
          organizationSettings?.stripe?.free_trial ===
            FREE_TRIAL_STATUS.awaitingStripe
        }
        footer={
          hasSelectedIntegration && (
            <GlowingButton
              type="primary"
              ref={nextButtonRef}
              onClick={handleGoToStripe}
            >
              Next
            </GlowingButton>
          )
        }
        closable={false}
      >
        {organizationSettings?.stripe?.free_trial ===
          FREE_TRIAL_STATUS.awaitingIntegration && <IntegrationsCard />}

        {organizationSettings?.stripe?.free_trial ===
          FREE_TRIAL_STATUS.awaitingStripe && (
          <>
            <p>
              You're almost there! It's a free trial, and you can cancel anytime
              within the 30 days.
            </p>
            <SubscriptionCard isFreeTrial={true} />
          </>
        )}
      </Modal>
    </>
  );
  return content;
};

export default FreeTrial;
