import ReactMarkdown from "react-markdown";
import {
  color,
  deviceSize,
  fontSize,
  spacing,
  borderRadius,
} from "src/styles/variables";
import styled, { keyframes } from "styled-components";
import { Typography } from "antd";
import { BorderlessCard } from "./../LandingPage.styles";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Apply the animation to the title
export const BlogTitleContainer = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 24px;
  @media (max-width: ${deviceSize.regular_phone}) {
    padding: 16px;
  }
`;

// Apply the animation to the title
export const AnimatedTitle = styled(Typography.Title)`
  animation: ${fadeIn} 2s ease-out;
  font-size: ${fontSize.displayXXXXLL} !important;
  margin-top: 0;
  color: ${color.white} !important;
`;

// Apply the animation to the paragraph
export const AnimatedParagraph = styled(Typography.Paragraph)`
  animation: ${fadeIn} 2.5s ease-out;
  color: ${color.white} !important;
`;

// Styled div container
export const BlogContainer = styled.div`
  background-color: ${color.olive};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
`;

export const BlogPostCard = styled(BorderlessCard)`
  h3,
  h4 {
    color: ${color.olive};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    transition: transform 0.3s ease;
    filter: grayscale(100%);
  }

  //   Create the orange overlay using a pseudo-element
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 115, 77, 0.3); // Orange color with opacity
    mix-blend-mode: overlay;
    transition: opacity 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05); // Make the image "jump"
    &::after {
      background-color: none;
    }
  }
`;

export const BlogListDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 2 columns of equal width */
  gap: ${spacing.xl};
  @media (max-width: ${deviceSize.large_phone}) {
    display: block;
  }
  margin: ${spacing.xl} auto;
`;

export const StyledReactMarkdown = styled(ReactMarkdown)`
  img {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    border-radius: ${borderRadius.xs};
    margin: ${spacing.lg} auto;
  }
`;
