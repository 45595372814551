import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { TextField, Button } from "@mui/material";
import { Spin, Steps, Typography } from "antd";
import { GrayCard, FormContainer } from "../Home.styles"; // Assuming you have these styles
import { color, fontSize, spacing } from "src/styles/variables";
import { orangeTheme } from "src/utils/utils";
import {
  BorderlessCard,
  CenteredDiv,
  GradientOrangeSpan,
} from "src/components/LandingPage/LandingPage.styles";

const GenericForm = ({
  title,
  firstSubtitle,
  secondSubtitle,
  stepsData,
  items,
  handleSubmit,
  currentStep = 0, // Default value of 0 if not provided
  buttonTitle,
  trucoInTitleAtEnd, // Add this prop to control position
  loading = false, // Add this prop to control position
}) => {
  return (
    <CenteredDiv style={{ padding: `0px ${spacing.xl}` }}>
      <BorderlessCard center={"true"}>
        <Typography.Title
          level={1}
          style={{
            fontSize: fontSize.displayXXXXXL,
          }}
        >
          {trucoInTitleAtEnd ? (
            <>
              {title} <br /> with <GradientOrangeSpan>Truco</GradientOrangeSpan>
            </>
          ) : (
            <>
              <GradientOrangeSpan>Truco</GradientOrangeSpan> {title}
            </>
          )}{" "}
        </Typography.Title>

        <Typography.Paragraph
          style={{
            fontSize: fontSize.displayXXL,
          }}
        >
          {firstSubtitle} <GradientOrangeSpan>Truco.ai</GradientOrangeSpan>
          <br />
          {secondSubtitle}
        </Typography.Paragraph>
        <br />
        <Steps current={currentStep} items={stepsData} />
      </BorderlessCard>
      <FormContainer>
        <Spin spinning={loading}>
          <GrayCard>
            <ThemeProvider theme={orangeTheme}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                {items.map((item, idx) => {
                  const { setFunction, width, ...textFieldProps } = item;
                  return (
                    <TextField
                      key={idx}
                      onChange={(e) => setFunction(e.target.value)}
                      variant="outlined"
                      style={{
                        backgroundColor: `${color.white}`,
                        borderRadius: `4px`,
                        ...(width && { width }), // Apply width only if it's provided
                      }}
                      sx={{ mb: 3 }}
                      fullWidth={!width} // Use fullWidth if width is not provided
                      {...textFieldProps}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: spacing.sm,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    sx={{
                      color: color.orange,
                      borderColor: color.orange,
                    }}
                  >
                    {buttonTitle}
                  </Button>
                </div>
              </form>
            </ThemeProvider>
          </GrayCard>
        </Spin>
      </FormContainer>
    </CenteredDiv>
  );
};

export default GenericForm;
