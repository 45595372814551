import { useState, useContext, createContext, useEffect } from "react";
import { auth, getUser, addUser } from "../firebaseAuth";
import { onAuthStateChanged } from "firebase/auth";
import { COOKIE_TYPE, LOCALSTORAGE_TYPES } from "src/utils/enums";
import cookies from "js-cookie";
import { UserType, UserChat } from "src/utils/types";
import { filterUserFields, isLocalHost } from "src/utils/utils";
import Hotjar from "@hotjar/browser";

const UserContext = createContext({
  user: null as UserType,
  setUser: null,
  userStatistics: null,
  setUserStatistics: null,
  needsToBookDemo: false,
  setNeedsToBookDemo: null,
  userChats: null as UserChat[],
  setUserChats: null,
});

export const UserProvider = ({ children }) => {
  const userCookie = cookies.get(COOKIE_TYPE.USER_COOKIE)
    ? JSON.parse(cookies.get(COOKIE_TYPE.USER_COOKIE))
    : null;
  const [user, setUser] = useState(userCookie);
  const [needsToBookDemo, setNeedsToBookDemo] = useState(
    !!localStorage.getItem(LOCALSTORAGE_TYPES.NEEDS_DEMO),
  );

  const [userStatistics, setUserStatistics] = useState(null);
  const [userChats, setUserChats] = useState(null);
  useEffect(() => {
    const setUserWithFirebaseUser = async (user) => {
      const userDataFromDb = await getUser(user);

      const newUserData = filterUserFields({
        ...(userDataFromDb ?? {}),
        ...user,
      });

      if (userCookie !== newUserData) {
        cookies.set(COOKIE_TYPE.USER_COOKIE, JSON.stringify(newUserData));
        setUser(newUserData);
      }
      // @todo remove soon, redundant
      if (userDataFromDb?.photoURL !== user?.photoURL) {
        addUser(user, userDataFromDb?.authProvider);
      }

      if (newUserData && !isLocalHost()) {
        Hotjar.identify(newUserData.uid, {
          email: newUserData.email, // And any other user fields you deem necessary
          organization: newUserData?.organization,
        });
      }
    };

    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUserWithFirebaseUser(firebaseUser);
      } else {
        setUser(null);
        cookies.remove(COOKIE_TYPE.USER_COOKIE);
        localStorage.removeItem(LOCALSTORAGE_TYPES.ORGANIZATION_SETTINGS);
        localStorage.removeItem(LOCALSTORAGE_TYPES.NEEDS_DEMO);
      }
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userStatistics,
        setUserStatistics,
        needsToBookDemo,
        setNeedsToBookDemo,
        userChats,
        setUserChats,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserState = () => useContext(UserContext);
