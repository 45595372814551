import { updateDocInFireStore } from "src/firebaseAuth";
import { COLLECTION_DATA } from "src/utils/enums";
import { InvitedUser } from "src/utils/types";

export { User, UserSelect, UserOption } from "./User";
export { SignIn, OrangeButton, SignInButton } from "./SignIn";
export { SignUp } from "./SignUp";
export { DesktopSignOut, handleSignOut } from "./SignOut";
export { Button } from "./User.styles";

export const updateUserDataBasedOnInvitation = async (
  invitationData: InvitedUser,
  res,
  invitationCode: string,
) => {
  if (invitationData) {
    if (res.user.email === invitationData.email) {
      res.user["organization"] = invitationData.organization;
      res.user["accessType"] = invitationData.accessType;
      await updateDocInFireStore(
        `/${COLLECTION_DATA.INVITATIONS}/${invitationCode}`,
        {
          pendingInvitation: false,
        },
      );
    } else {
      console.log("User email does not match invitation data.");
    }
  }
};
