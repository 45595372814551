import React, { useState } from "react";

import { Breadcrumb, Modal, Spin } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { PlaylistClips } from "src/components/Playlist/PlaylistDetails/PlaylistClips";
import { useFetchTagClips } from "src/hooks/useFetchData";
import { useDeleteClip } from "src/hooks/usePostData";

export const TagModal = ({ playlistId, selectedTag, setSelectedTag }) => {
  const { organization } = useOrganizationState();
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const deleteClip = useDeleteClip();
  const {
    data: themeClips,
    loading: clipsLoading,
    refetch: refetchThemeClips,
  } = useFetchTagClips(organization, playlistId, selectedTag);

  const handleDeleteClip = async (clipId) => {
    await deleteClip(clipId, organization, selectedPlaylist?.id);
    refetchThemeClips();
  };
  const resetSelectedTag = () => {
    setSelectedTag(null);
    setSelectedPlaylist(null);
  };

  return (
    <Modal
      title={`${selectedTag}`}
      width={800}
      open={selectedTag !== null}
      onOk={resetSelectedTag}
      onCancel={resetSelectedTag}
      onClose={resetSelectedTag}
    >
      <Breadcrumb style={{ cursor: "pointer" }}>
        <Breadcrumb.Item key={"playlist"} onClick={resetSelectedTag}>
          <span> {selectedPlaylist?.title} </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={clipsLoading}>
        <PlaylistClips
          clips={themeClips}
          handleDeleteClip={handleDeleteClip}
          playlistId={selectedPlaylist?.id}
          disabledTags={true}
        />
      </Spin>
    </Modal>
  );
};
