import { useState, useEffect } from "react";

export const useImageLoader = (imageUrl) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (!imageUrl) {
      setIsLoading(false); // Not loading if there's no image URL
      return;
    }

    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setIsLoading(false);
      setImageError(false);
    };

    img.onerror = () => {
      setIsLoading(false);
      setImageError(true);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  return { isLoading, imageError, imageUrl: imageError ? undefined : imageUrl };
};
