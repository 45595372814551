import React, { useState, useRef, useEffect, useCallback } from "react";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import {
  PlayPauseButton,
  StyledProgress,
  ThumbnailWrapper,
} from "./Thumbnail.styles";
import { color } from "src/styles/variables";
import { Spin } from "antd";

interface ThumbnailProps {
  url: string;
  start: number;
  end: number;
  type: string;
  clipId: string;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  url,
  start,
  end,
  type,
  clipId,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loadingVideoThumbnail, setLoadingVideoThumbnail] = useState(true);
  const isAudio = type?.includes("audio");
  const [activeAudio, setActiveAudio] = useState<string | null>(null);

  const handleTimeUpdate = useCallback(() => {
    const mediaElement = isAudio ? audioRef.current : videoRef.current;
    if (mediaElement) {
      const currentTime = mediaElement.currentTime - start;
      const percent = (currentTime / end) * 100;
      setProgress(Math.min(percent, 100));

      if (currentTime >= end) {
        mediaElement.pause();
        setIsPlaying(false);
        setActiveAudio(null);
      }
    }
  }, [start, end, setActiveAudio, type]);

  useEffect(() => {
    const mediaElement = isAudio ? audioRef.current : videoRef.current;

    if (mediaElement) {
      mediaElement.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (mediaElement) {
        mediaElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [handleTimeUpdate, type]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const mediaElement = isAudio ? audioRef.current : videoRef.current;
      if (mediaElement && !mediaElement.contains(event.target as Node)) {
        if (isPlaying) {
          mediaElement.pause();
          setIsPlaying(false);
          setActiveAudio(null);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isPlaying, setActiveAudio, type]);

  useEffect(() => {
    const mediaElement = isAudio ? audioRef.current : videoRef.current;
    if (activeAudio && activeAudio !== clipId && isPlaying) {
      mediaElement.pause();
      setIsPlaying(false);
    }
  }, [activeAudio, clipId, isPlaying, type]);

  const handlePlayPause = () => {
    const mediaElement = isAudio ? audioRef.current : videoRef.current;
    if (!mediaElement) return;
    if (isPlaying) {
      mediaElement.pause();
      setIsPlaying(false);
      setActiveAudio(null);
    } else {
      if (activeAudio) {
        setActiveAudio(null); // Ensure other media stop playing
      }
      // mediaElement.currentTime = start;
      mediaElement.play().catch((error) => {
        console.error("Playback failed", error);
        setIsPlaying(false);
      });
      setIsPlaying(true);
      setActiveAudio(clipId);
    }
  };

  return (
    <ThumbnailWrapper
      onMouseEnter={handlePlayPause}
      onMouseLeave={handlePlayPause}
    >
      {!url ? (
        <img
          src="/playlist.jpeg"
          alt="no url"
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <>
          {isAudio && (
            <>
              <audio ref={audioRef} src={url} preload="metadata"></audio>
              <StyledProgress
                type="circle"
                percent={progress}
                size={50}
                strokeColor={color.orange}
                format={() => ""}
              />
              <PlayPauseButton>
                {isPlaying ? (
                  <PauseOutlined />
                ) : (
                  <CaretRightOutlined style={{ paddingLeft: "3px" }} />
                )}
              </PlayPauseButton>
            </>
          )}
          {type?.includes("video") && (
            <>
              <Spin spinning={loadingVideoThumbnail}>
                <video
                  style={{ height: "84px" }}
                  ref={videoRef}
                  src={url}
                  preload="metadata"
                  onLoadedData={() => setLoadingVideoThumbnail(false)}
                  onTimeUpdate={handleTimeUpdate}
                ></video>
              </Spin>
              {/* <StyledProgress
              style={{ position: "absolute", bottom: "-3px" }}
              percent={progress}
              type="circle"
              width={50}
              size="small"
              strokeColor={color.orange}
              format={() => ""}
            /> */}
              <StyledProgress
                type="circle"
                percent={progress}
                width={50}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: " 50%",
                  transform: "translate(-50%, -50%)",
                }}
                strokeColor={color.orange}
                format={() => ""}
              />
              <PlayPauseButton>
                {isPlaying ? (
                  <PauseOutlined />
                ) : (
                  <CaretRightOutlined style={{ paddingLeft: "3px" }} />
                )}
              </PlayPauseButton>
            </>
          )}
        </>
      )}
    </ThumbnailWrapper>
  );
};

export default Thumbnail;
