import styled, { keyframes } from "styled-components";
import {
  color,
  borderRadius,
  hexToRgba,
  spacing,
  deviceSize,
  fontSize,
  elementSize,
  grayBoxShadow,
  hexToHex,
} from "src/styles/variables";
import { Card, Typography, Layout } from "antd";

export const BorderlessCard = styled(Card)`
  border: none;
  background-color: inherit;
  @media (max-width: ${deviceSize.regular_phone}) {
    .ant-card-body {
      padding: 16px;
    }
  }

  ${(props) => {
    if (props.center) {
      return `
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
    }
  }}
`;

export const StyledHeader = styled(Layout.Header)`
  background-color: ${(props) =>
    props.headercolor == null ? "#fff" : props.headercolor};
  top: 0;
  z-index: 1;
  height: 100%;
  ${(props) => {
    if (props.headercolor != null) {
      return `
        border-bottom: none;
        background-color: ${props.headercolor};
       
      `;
    } else {
      return `
        border-bottom: 2px solid ${hexToRgba(color.grayLight, 0.3)};
        
      `;
    }
  }}
  @media (max-width: ${deviceSize.large_phone}) {
    padding: 0px 24px;
  }
`;

// Step 2: Define the keyframes for sliding
const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); // Assuming each card takes up 50% of the width
  }
`;

// Styled component for the wrapper
export const SlidingWrapper = styled.div`
  display: block;
  margin-top: ${spacing.xxll};

  // Apply the animation only on screens larger than a certain width
  @media (min-width: 480px) {
    display: flex;
    animation: ${slide} 20s linear infinite;
  }
`;

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${deviceSize.large_phone}) {
    flex-direction: column;
  }
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${deviceSize.large_phone}) {
    padding-left: ${spacing.xxxxxll};
    gap: ${spacing.md};
  }
`;

export const WhyTrucoImg = styled.img`
  width: 100%;
  max-width: 210px;
  @media (max-width: ${deviceSize.large_phone}) {
    max-width: 175px;
  }
`;

export const SectionContainer = styled.div`
  background: ${(props) => (props.background ? props.background : color.white)};
  min-height: 70vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  padding-bottom: ${spacing.xl};
  overflow: hidden;
`;

export const GradientOrangeSpan = styled.span`
  background: linear-gradient(
    70deg,
    ${color.orange} -29.24%,
    ${color.lightOrange} 73.49%,
    ${color.lightYellow} 141.81%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
`;
export const InsightsImage = styled.img`
  width: 80%;
  max-width: 1200px;
  display: block;
  margin: auto;

  @media (max-width: ${deviceSize.large_phone}) {
    width: 95%;
  }
`;

export const CustomerAvatarCard = styled(Card.Meta)`
  .ant-avatar-image {
    width: 64px;
    height: 64px;
  }
  .ant-card-meta-title {
    margin-bottom: 0px !important;
    font-size: ${fontSize.displayXXL};
  }
  .ant-card-meta-description {
    font-size: ${fontSize.displayXL};
  }
`;

export const ReviewText = styled(Typography.Paragraph)`
  display: -webkit-box;
  -webkit-line-clamp: 10; // Number of lines to show
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${fontSize.displayXXL};
  @media (min-width: ${deviceSize.large_phone}) {
    text-align: justify;
    font-size: ${fontSize.bodyLarge};
  }
`;

export const ArrowButtonsContainer = styled.div`
  display: flex;
  padding-top: 24px;
  gap: 16px;
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;

export const ArrowButtonStyle = styled.span`
  color: ${hexToRgba(color.olive, 0.7)};
  border: 1.5px solid ${hexToRgba(color.olive, 0.7)};
  cursor: pointer;
  &:hover {
    color: ${color.white};
    background-color: ${color.olive};
  }
  border-radius: 50%;
  padding: 8px;
`;

export const StyledSwiper = styled.div`
  max-width: 500px;
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${borderRadius.xs};
    color: #fff;
    height: 400px;
    background-color: ${color.white};
  }

  @media (max-width: ${deviceSize.large_phone}) {
    padding-top: ${spacing.xxl};
    max-width: 350px;
  }
`;

export const FadeContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const fadeOutUp = keyframes`
  0% {
    transform: translateY(50%); // Start halfway down
  }
  100% {
    transform: translateY(-100%); // Ends halfway up, overlapping restart
  }
`;

export const Fade = styled.span`
  position: absolute;
  font-size: ${fontSize.displayXXLL};
  text-align: center;
  animation: ${fadeOutUp} 17s linear infinite;
  color: ${hexToRgba(color.black, 0.8)};
`;

export const OrangeBlobBackground = styled.div`
  position: absolute;
  left: -5%;
  z-index: 1;
  transform: scaleX(-1.5) scaleY(1.5); /* Flip horizontally */
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const InsightCard = styled(Card)`
  flex: 1 0 200px;
  max-width: 240px;
  height: 250px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${color.white};
  box-shadow: ${grayBoxShadow};
  border-radius: ${borderRadius.xs};
  text-align: center;
  z-index: 2;
  & .ant-typography {
    font-size: ${fontSize.displayXL};
    color: ${color.grayDark};
  }
`;

export const IconContainer = styled.div`
  background-color: ${hexToHex(color.orange, 0.14)};
  border-radius: 50%;
  padding: ${elementSize.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  margin: 0 auto ${elementSize.xs};
`;

export const CostCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  @media (min-width: 768px) {
    border: 1px solid ${color.grayLight};
    padding: ${elementSize.xxl} ${elementSize.xxxl};
    border-radius: ${borderRadius.xs};
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CalculatorCard = styled.div`
  min-width: 300px;
  background: linear-gradient(
    to top,
    ${color.olive} ${(props) => props.fill}%,
    ${hexToRgba(color.olive, 0.8)} ${(props) => props.fill}%
  );
  padding: ${elementSize.md} ${elementSize.md} ${elementSize.xxl}
    ${elementSize.md};
  color: ${color.white};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.5s ease;
`;
