import { useEffect, useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";
import { PLAYLIST_ACCESS, PLAYLIST_TYPE } from "src/utils/enums";
import {
  message,
  Menu,
  Select,
  Modal,
  Button,
  Spin,
  Space,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  LinkOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useUserState } from "src/state/UserState";
import { elementSize } from "src/styles/variables";
import { CollaboratorAvatar } from "src/components/Global";
import { generateAccessOptions, getVisibilityIcon } from "../Playlist.utils";
import { PlayList } from "src/utils/types";
import { useAddOrUpdatePlaylist } from "src/hooks/usePostData";
const { Option } = Select;

interface PlaylistMenuProps {
  currentPlaylist: any;
  linkToCopy: string;
  redirectToPlaylist: boolean;
  refetchPlaylists?: () => void;
}

export const PlaylistMenu: React.FC<PlaylistMenuProps> = ({
  currentPlaylist,
  linkToCopy,
  redirectToPlaylist,
  refetchPlaylists,
}) => {
  const navigate = useNavigate();
  const { user } = useUserState();
  const { organizationSettings, collaborators, organization, reloadPlaylists } =
    useOrganizationState();

  const collaboratorsOptions = collaborators?.filter(
    (data) => data.email !== user.email,
  );
  const { generalAccessOptions, editAccessOptions } = generateAccessOptions(
    organizationSettings?.name,
  );
  const [playlist, _] = useState<PlayList>(currentPlaylist);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibility, setVisibility] = useState<PLAYLIST_ACCESS | undefined>();
  const [editability, setEditability] = useState<PLAYLIST_ACCESS | undefined>();
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>(
    [],
  );
  const addOrUpdatePlaylist = useAddOrUpdatePlaylist();

  const handleDeletePlaylist = async () => {
    message.info("Playlist is being deleted");
    await addOrUpdatePlaylist({
      playlistId: currentPlaylist.playlistId,
      organizationId: organization,
      deleted: true,
    });
    reloadPlaylists();
    if (redirectToPlaylist) navigate("/playlist");
  };
  useEffect(() => {
    if (playlist) {
      setVisibility(playlist?.visibility);
      setEditability(playlist?.editability);
      setSelectedCollaborators(playlist?.customList || []);
    }
  }, [playlist]);

  const handleVisibilityChange = async (value) => {
    setModalLoading(true);
    try {
      setVisibility(value);
      await addOrUpdatePlaylist({
        playlistId: playlist.playlistId,
        visibility: value,
        organizationId: organization,
      });

      message.success("Visibility updated successfully");
    } catch (error) {
      message.error("Failed to update visibility");
      console.error("Error updating visibility: ", error);
    } finally {
      setModalLoading(false);
    }
  };
  const handleEditabilityChange = async (value) => {
    setModalLoading(true);
    try {
      setEditability(value);

      const updatedPlaylist = {
        ...playlist,
        editability: value,
        ...(value !== PLAYLIST_ACCESS.CUSTOM && {
          customList: [],
        }),
      };

      if (value !== PLAYLIST_ACCESS.CUSTOM) {
        setSelectedCollaborators([]);
      }

      await addOrUpdatePlaylist({
        playlistId: playlist.playlistId,
        editability: value,
        organizationId: organization,
      });
      message.success("edit access updated successfully");
    } catch (error) {
      message.error("Failed to update edit");
      console.error("Error updating edot access: ", error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleCollaboratorChange = async (selectedItems) => {
    setModalLoading(true);
    setSelectedCollaborators(selectedItems);
    try {
      await addOrUpdatePlaylist({
        playlistId: playlist.playlistId,
        customList: selectedItems,
        organizationId: organization,
      });

      message.success("Updated successfully");
    } catch (error) {
      message.error("Failed to update edit access");
      console.error("Error updating edit access: ", error);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <>
      <Menu>
        {playlist?.createdBy === user?.email && (
          <>
            {playlist?.type !== PLAYLIST_TYPE.SIGNAL && (
              <Menu.Item
                key="delete"
                icon={<DeleteOutlined />}
                onClick={handleDeletePlaylist}
              >
                Delete
              </Menu.Item>
            )}
            <Menu.Item
              key="share"
              icon={<ShareAltOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
              Share
            </Menu.Item>
          </>
        )}
        {[PLAYLIST_ACCESS.ORGANIZATION, PLAYLIST_ACCESS.PUBLIC].includes(
          visibility,
        ) && (
          <Menu.Item
            key="copy"
            icon={<LinkOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(linkToCopy).then(() => {
                message.success("Link Copied to clipboard");
              });
            }}
          >
            Copy link
          </Menu.Item>
        )}
      </Menu>

      <Modal
        title="Share Playlist"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
          <Button
            key="done"
            type="primary"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Done
          </Button>,
        ]}
      >
        <Spin spinning={modalLoading}>
          <Typography.Title level={5}>Owner</Typography.Title>
          <Space>
            <CollaboratorAvatar collaborator={user} />
            <>{playlist?.createdBy}</>
          </Space>
          <Typography.Title level={5}>General Access</Typography.Title>
          <Select
            value={visibility}
            onChange={handleVisibilityChange}
            style={{ width: "100%" }}
          >
            {generalAccessOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {getVisibilityIcon(option.icon, {
                  marginRight: elementSize.xs,
                })}{" "}
                {option.label}
              </Option>
            ))}
          </Select>

          {playlist?.type !== PLAYLIST_TYPE.SIGNAL && (
            <>
              <Typography.Title level={5}>Edit Access</Typography.Title>
              <Select
                value={editability}
                onChange={handleEditabilityChange}
                style={{ width: "100%" }}
              >
                {editAccessOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {getVisibilityIcon(option.icon, {
                      marginRight: elementSize.xs,
                    })}{" "}
                    {option.label}
                  </Option>
                ))}
              </Select>
            </>
          )}

          {editability === PLAYLIST_ACCESS.CUSTOM && (
            <>
              <Typography.Title level={5}>
                Select Specific People
              </Typography.Title>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select collaborators"
                value={selectedCollaborators}
                onChange={handleCollaboratorChange}
                optionLabelProp="label"
              >
                {collaboratorsOptions?.map((collaborator) => (
                  <Option
                    key={collaborator.email}
                    value={collaborator.email}
                    label={collaborator.name}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CollaboratorAvatar collaborator={collaborator} />
                      <span style={{ marginLeft: 8 }}>
                        {collaborator.name} ({collaborator.email})
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </>
          )}

          {(visibility === PLAYLIST_ACCESS.PUBLIC ||
            visibility === PLAYLIST_ACCESS.ORGANIZATION) && (
            <div style={{ marginTop: "16px" }}>
              <Button
                icon={<LinkOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(linkToCopy).then(() => {
                    message.success("Link Copied to clipboard");
                  });
                }}
              >
                Copy Link
              </Button>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};
