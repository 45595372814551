import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { getFirebaseIdToken } from "src/firebaseAuth";

const BASE_URL = process.env.REACT_APP_TRUCO_BACKEND;

const validateParams = (params, requiredParams = ["organizationId"]) => {
  for (let param of requiredParams) {
    if (!params[param]) {
      return `${param} is required.`;
    }
  }
  return null;
};

// Custom hook to manage fetch state and data with refetch capability
const useFetchData = (url, params, requiredParams?) => {
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null,
  });

  // Memoize headers to avoid re-computation on every render
  const headers = useMemo(async () => {
    const token = await getFirebaseIdToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  }, []);

  // Define a function to fetch data
  const fetchData = useCallback(async () => {
    const error = validateParams(params, requiredParams);
    if (error) {
      setState({
        data: null,
        loading: false,
        error,
      });
      return;
    }

    setState({ data: null, loading: true, error: null });

    try {
      const response = await axios.get(url, {
        headers: await headers,
        params,
      });

      setState({
        data: response.data.ERROR ? [] : response.data,
        loading: false,
        error: null,
      });
    } catch (err) {
      const isCancel = axios.isCancel(err);
      console.error(
        `Error fetching from ${url}:`,
        isCancel ? "Request canceled" : err,
      );

      setState({
        data: [],
        loading: false,
        error: isCancel ? null : err,
      });
    }
  }, [url, params, headers]);

  // Fetch data on component mount or when dependencies change
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { ...state, refetch: fetchData };
};

// Custom hook to fetch grouped clips
export const useFetchGroupedClips = (filterConditions, orderByConditions) => {
  const params = useMemo(
    () => ({
      filter_conditions: JSON.stringify(filterConditions || []),
      order_by: orderByConditions || "",
    }),
    [filterConditions, orderByConditions],
  );

  return useFetchData(`${BASE_URL}/clips/grouped`, params);
};

// Custom hook to fetch tag trends
export const useFetchTagTrends = (
  playlistId,
  organizationId,
  startDate,
  endDate,
) => {
  const params = useMemo(
    () => ({
      playlistId,
      organizationId,
      startDate,
      endDate,
    }),
    [playlistId, organizationId, startDate, endDate],
  );

  return useFetchData(`${BASE_URL}/clips/tag-trends`, params, [
    "organizationId",
    "playlistId",
  ]);
};

// Custom hook to fetch playlist trends
export const useFetchPlaylistsTrends = (organizationId, startDate, endDate) => {
  const params = useMemo(
    () => ({
      organizationId,
      startDate,
      endDate,
    }),
    [organizationId, startDate, endDate],
  );

  return useFetchData(`${BASE_URL}/clips/signal-trends`, params);
};

// Custom hook to fetch signals daily clips count
export const useFetchSignalsDailyClipsCount = (organizationId) => {
  const params = useMemo(() => ({ organizationId }), [organizationId]);

  return useFetchData(`${BASE_URL}/clips/daily_counts`, params);
};

// Custom hook to fetch playlists
export const useFetchPlaylists = (organizationId) => {
  const params = useMemo(() => ({ organizationId }), [organizationId]);

  return useFetchData(`${BASE_URL}/playlists`, params);
};

export const useFetchPlaylistTags = (
  organizationId,
  playlistId,
  page = 1,
  pageSize = 10,
  search = "",
) => {
  const params = useMemo(
    () => ({ organizationId, playlistId, page, pageSize, search }),
    [organizationId, playlistId, page, pageSize, search],
  );

  return useFetchData(`${BASE_URL}/playlist_tags`, params);
};

export const useFetchPlaylist = (organizationId, playlistId) => {
  const params = useMemo(
    () => ({ organizationId, playlistId }),
    [organizationId, playlistId],
  );

  return useFetchData(`${BASE_URL}/playlist`, params, [
    "organizationId",
    "playlistId",
  ]);
};

export const useFetchPlaylistDetails = (organizationId, playlistId) => {
  const params = useMemo(
    () => ({ organizationId, playlistId }),
    [organizationId, playlistId],
  );

  return useFetchData(`${BASE_URL}/playlist/details`, params, [
    "organizationId",
    "playlistId",
  ]);
};

export const useFetchPlaylistDailyCount = (
  organizationId,
  playlistId,
  numberOfDays,
) => {
  const params = useMemo(
    () => ({ organizationId, playlistId, numberOfDays }),
    [organizationId, playlistId, numberOfDays],
  );

  return useFetchData(`${BASE_URL}/playlist/daily_counts`, params, [
    "organizationId",
    "playlistId",
    "numberOfDays",
  ]);
};
export const useFetchPlaylistClips = (
  organizationId,
  playlistId,
  page = 1,
  pageSize = 10,
  search = "",
) => {
  const params = useMemo(
    () => ({ organizationId, playlistId, page, pageSize, search }),
    [organizationId, playlistId, page, pageSize, search],
  );

  return useFetchData(`${BASE_URL}/playlist/clips`, params, [
    "organizationId",
    "playlistId",
  ]);
};

// Custom hook to fetch clip
export const useFetchClip = (organizationId, clipId) => {
  const params = useMemo(
    () => ({
      organizationId,
      clipId,
    }),
    [organizationId, clipId],
  );

  return useFetchData(`${BASE_URL}/clip`, params);
};

// Custom hook to fetch Tags
export const useFetchTags = (
  organizationId: string,
  playlistId?: string,
  clipId: string | null = null,
  currentPage = 1,
  pageSize = 10,
  searchValue = "",
) => {
  const params = useMemo(
    () => ({
      organizationId,
      playlistId,
      clipId,
      currentPage,
      pageSize,
      searchValue,
    }),
    [organizationId, playlistId, clipId, currentPage, pageSize, searchValue],
  );

  return useFetchData(`${BASE_URL}/tags`, params);
};

export const useFetchTagClips = (
  organizationId: string,
  playlistId?: string,
  tagName?: string,
) => {
  const params = useMemo(
    () => ({
      organizationId,
      playlistId,
      tagName,
    }),
    [organizationId, playlistId, tagName],
  );

  return useFetchData(`${BASE_URL}/tag_clips`, params, [
    "organizationId",
    "playlistId",
    "tagName",
  ]);
};
