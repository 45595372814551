import { Collapse, Form } from "antd";
import { PlusCircleFilled, EditFilled } from "@ant-design/icons";
import { unhumanize } from "src/utils/utils";
import { color, elementSize, spacing } from "src/styles/variables";
import {
  FloatingLabelInput,
  FloatingLabelInputNumber,
} from "src/components/FloatingInputText/FloatingInputText";
import {
  SpaceBetweenDiv,
  StyledTextArea,
} from "src/components/Profile/Profile.styles";
import { AddOptions, AddText, EvaluationSubTitle } from "../Evaluation.styles";
import EvaluationRules from "./EvaluationRules/EvaluationRules";
import {
  handleAddCategory,
  handleAddConditionalCondition,
  handleAddSubCategory,
  handleChangeInEvaluation,
  handleDelete,
} from "../Evaluation.utils";
import { DeleteWithPopConfirm } from "src/components";

const Panel = Collapse.Panel;

const EvaluationCategories = ({
  analysisStructure,
  setAnalysisStructures,
  setCurrentSelectedEvaluation,
  disableInputs,
  errors,
  setErrors,
}) => {
  return (
    <div style={{ padding: `${spacing.md} 0px` }}>
      <Collapse
        accordion
        expandIconPosition="end"
        style={{
          marginBottom: spacing.md,
        }}
      >
        {analysisStructure.map((category, categoryIndex) => (
          <Panel
            extra={
              analysisStructure.length > 1 &&
              !disableInputs &&
              DeleteWithPopConfirm(
                "Delete Category?",
                "Are you sure you want to delete this category? ",
                (e) => {
                  e.stopPropagation();
                  handleDelete(
                    "analysisStructures",
                    categoryIndex,
                    setCurrentSelectedEvaluation,
                  );
                },
                disableInputs,
              )
            }
            header={
              <FloatingLabelInput
                id={`category-${categoryIndex}`}
                label={"Category Name"}
                backgroundcolor={"#fafafa"}
                showLabelOnDisable={false}
                inputValue={category.label}
                onClick={(e) => e.stopPropagation()} // Add this to stop propagation
                onChangeInput={(e) => {
                  handleChangeInEvaluation(
                    `analysisStructures[${categoryIndex}].label`,
                    e.target.value,
                    setCurrentSelectedEvaluation,
                    `category-${categoryIndex}`,
                    errors,
                    setErrors,
                  );

                  handleChangeInEvaluation(
                    `analysisStructures[${categoryIndex}].id`,
                    unhumanize(e.target.value),
                    setCurrentSelectedEvaluation,
                  );
                }}
                required={true}
                error={errors[`category-${categoryIndex}`]}
                style={{
                  width: "98%",
                }}
                disabled={disableInputs}
              />
            }
            key={categoryIndex.toString()} // Key should be a string
            id={`category-${categoryIndex}`}
          >
            <SpaceBetweenDiv style={{ paddingBottom: spacing.md }}>
              <EvaluationSubTitle>Metrics</EvaluationSubTitle>
            </SpaceBetweenDiv>

            <Collapse
              accordion
              expandIconPosition="end"
              style={{
                marginBottom: spacing.md,
              }}
            >
              {category.value.map((subCategory, subCategoryIndex) => (
                <Panel
                  extra={
                    category.value.length > 1 &&
                    !disableInputs &&
                    DeleteWithPopConfirm(
                      "Delete SubCategory?",
                      `Are you sure you want to delete this subCategory? `,
                      (e) => {
                        e.stopPropagation();
                        handleDelete(
                          `analysisStructures[${categoryIndex}].value`,
                          subCategoryIndex,
                          setCurrentSelectedEvaluation,
                        );
                      },
                      disableInputs,
                    )
                  }
                  header={
                    <FloatingLabelInput
                      id={`subcategory-${categoryIndex}-${subCategoryIndex}`}
                      label={"Metric Name"}
                      showLabelOnDisable={false}
                      backgroundcolor={"#fafafa"}
                      inputValue={subCategory.label}
                      required={true}
                      onClick={(e) => e.stopPropagation()} // Add this to stop propagation
                      onChangeInput={(e) => {
                        handleChangeInEvaluation(
                          `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].label`,
                          e.target.value,
                          setCurrentSelectedEvaluation,
                          `subcategory-${categoryIndex}-${subCategoryIndex}`,
                          errors,
                          setErrors,
                        );
                        handleChangeInEvaluation(
                          `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].id`,
                          unhumanize(e.target.value),
                          setCurrentSelectedEvaluation,
                          `subcategory-${categoryIndex}-${subCategoryIndex}`,
                          errors,
                          setErrors,
                        );
                      }}
                      disabled={disableInputs}
                      placeholder="Subcategory Name"
                      style={{
                        width: "98%",
                      }}
                      error={
                        errors[
                          `subcategory-${categoryIndex}-${subCategoryIndex}`
                        ]
                      }
                    />
                  }
                  key={`${categoryIndex}-${subCategoryIndex}`} // Key should be a string
                  id={`subcategory-${categoryIndex}-${subCategoryIndex}`}
                >
                  <label
                    style={{
                      marginRight: "10px",
                      color: color.olive,
                      minWidth: "11vw",
                    }}
                  >
                    Knowledge:
                  </label>
                  {disableInputs ? (
                    <>{subCategory.value.knowledge}</>
                  ) : (
                    <StyledTextArea
                      value={subCategory.value.knowledge}
                      onChange={(e) =>
                        handleChangeInEvaluation(
                          `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.knowledge`,
                          e.target.value,
                          setCurrentSelectedEvaluation,
                          `description-${categoryIndex}-${subCategoryIndex}`,
                          errors,
                          setErrors,
                        )
                      }
                      placeholder="ex: Wooden gates can have fence frames such as Nail Up (also referred as privacy, standard, regular, common)"
                      error={
                        errors[
                          `description-${categoryIndex}-${subCategoryIndex}`
                        ]
                      }
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      style={{
                        margin: `${elementSize.sm} 0px`,
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{
                        marginRight: "10px",
                        color: color.olive,
                        minWidth: "11vw",
                      }}
                    >
                      Rules:
                    </label>
                    <EvaluationRules
                      EvaluationRules={subCategory.value.conditions}
                      handleAddConditionalCondition={
                        handleAddConditionalCondition
                      }
                      categoryIndex={categoryIndex}
                      subCategoryIndex={subCategoryIndex}
                      handleChange={handleChangeInEvaluation}
                      errors={errors}
                      setErrors={setErrors}
                      disabled={disableInputs}
                      handleDelete={handleDelete}
                      setCurrentSelectedEvaluation={
                        setCurrentSelectedEvaluation
                      }
                    />
                  </div>
                </Panel>
              ))}
            </Collapse>
            {!disableInputs && (
              <AddText
                onClick={() => {
                  if (!disableInputs) {
                    handleAddSubCategory(
                      categoryIndex,
                      analysisStructure,
                      setAnalysisStructures,
                    );
                  }
                }}
              >
                <PlusCircleFilled /> Add Metric
              </AddText>
            )}

            <AddOptions
              bordered={false}
              disabled={disableInputs}
              expandIconPosition="left"
              expandIcon={({ isActive }) => <EditFilled />}
            >
              <Panel
                style={{ paddingLeft: "0px" }}
                header="Advanced Options"
                key="1"
              >
                <div style={{ margin: "10px 0" }}>
                  <Form.Item
                    name={`category-${categoryIndex}-weight`}
                    style={{
                      marginTop: spacing.lg,
                      marginBottom: "0px",
                    }}
                  >
                    <FloatingLabelInputNumber
                      id={`category-${categoryIndex}-weight`}
                      label={"Category Weight"}
                      backgroundcolor={"#fafafa"}
                      inputValue={category?.weight ?? 1.0}
                      step={0.1}
                      min={0}
                      onClick={(e) => e.stopPropagation()} // Add this to stop propagation
                      onChangeInput={(e) => {
                        handleChangeInEvaluation(
                          `analysisStructures[${categoryIndex}].weight`,
                          e,
                          setCurrentSelectedEvaluation,
                          `category-${categoryIndex}-weight`,
                          errors,
                          setErrors,
                        );
                      }}
                      required={true}
                      error={errors[`category-${categoryIndex}-weight`]}
                      style={{
                        width: "98%",
                      }}
                      disabled={disableInputs}
                      showLabelOnDisable={true}
                    />
                  </Form.Item>
                </div>
              </Panel>
            </AddOptions>
          </Panel>
        ))}
      </Collapse>
      {!disableInputs && (
        <AddText
          onClick={() => {
            if (!disableInputs) {
              handleAddCategory(analysisStructure, setAnalysisStructures);
            }
          }}
        >
          <PlusCircleFilled /> Add Category
        </AddText>
      )}
    </div>
  );
};

export default EvaluationCategories;
