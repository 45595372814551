import { Button, Modal } from "antd";
import { SubscriptionCard } from "../Profile/Components/SubscriptionCard/SubscriptionCard";
import { useState } from "react";
import { elementSize } from "src/styles/variables";
import { StandardPage } from "./Home";

export const SubscriptionEndedModal = () => {
  const [showSubscriptionCard, setShowSubscriptionCard] = useState(false);

  return (
    <Modal
      open={true}
      closable={false}
      width={"70vw"}
      centered={true}
      footer={
        !showSubscriptionCard ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => setShowSubscriptionCard(true)}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Subscribe
            </Button>
          </div>
        ) : null
      }
    >
      <div style={{ textAlign: "center", padding: elementSize.ml }}>
        <h2>Your subscription has ended :( </h2>
        <p>
          We’re happy that you have given Truco a try! To get back into Truco
          and continue getting insights, QA, and more, hit “Subscribe” below.
        </p>
        {showSubscriptionCard && <SubscriptionCard />}
      </div>
    </Modal>
  );
};

export const EmptySubscriptionBackground = () => {
  return <StandardPage content={<></>} />;
};
