import React, { useEffect, useState } from "react";
import {
  Tabs,
  Card,
  Row,
  Col,
  Spin,
  Descriptions,
  Divider,
  DescriptionsProps,
  Select,
  Tag,
} from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  getRecordFromFireStore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import {
  AccountConverter,
  customerConverter,
  transcriptConverter,
} from "src/utils/converter";
import { useOrganizationState } from "src/state/OrganizationState";
import { HoverContainer } from "../CustomersDashboard/Customers.styles";
import { COLLECTION_DATA } from "src/utils/enums";
import { useModal } from "src/state/UseModal";
import {
  capitalizeFirstLetters,
  formatDateToHumanReadable,
  getColorForValue,
  getUsersEmailDictionary,
  humanize,
} from "src/utils/utils";
import { useUserState } from "src/state/UserState";
import {
  FieldRenderer,
  getCustomerIdentity,
} from "../CustomersDashboard/Customers.utils";
import { updateAccountInfo } from "./AccountsDashboard.utilis";
import { ConversationTable } from "../Dashboard/ConversationTable";
import { useNavigate } from "react-router-dom";
import { TableBoardDashboard } from "../Dashboard/TableBoardDashboard/TableBoardDashboard";
import { User } from "../User";
import { AccountAvatar } from "./AccountsDashboard";

const { TabPane } = Tabs;

export const AccountModalContent = () => {
  const {
    organization,
    organizationSettings,
    evaluationForms,
    pendingCollaborators,
    collaborators,
    setAccounts,
    setCustomers: SetAllCustomers,
  } = useOrganizationState();
  const [accountInfo, setAccountInfo] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const { customerStages, accountsCustomFields, showRisk } =
    organizationSettings;
  const navigate = useNavigate();

  const { modalState } = useModal();
  const accountId = modalState.id;
  const { user } = useUserState();

  const customerColumns = [
    {
      accessorFn: (row) => getCustomerIdentity(row),
      enableEditing: false,
      header: "Customer Details",
      size: 200,
      Cell: ({ row }) => (
        <HoverContainer
          onClick={() =>
            navigate(`?customer=${encodeURIComponent(row.original.id)}`)
          }
        >
          <User user={row.original} usePhoneIcon={true} />
        </HoverContainer>
      ),
    },
    {
      header: "Update At",
      enableEditing: false,
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 150, // Adjust column width for better visibility
      Cell: ({ cell }) => {
        return formatDateToHumanReadable(cell.getValue());
      },
      enableColumnFilter: false,
    },
    {
      header: "# of Conversations",
      enableEditing: false,
      accessorKey: "conversations", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        const conversations = cell.getValue();
        return conversations?.length;
      },
      size: 100, // Adjust column width for better visibility
    },
    {
      header: "Stage",
      accessorKey: "currentStage",
      enableEditing: false,
      Cell: ({ cell }) => {
        const stage = cell.getValue();
        return stage ? (
          <Tag color={getColorForValue(stage)}>
            {capitalizeFirstLetters(stage)}
          </Tag>
        ) : (
          ""
        );
      },
    },
  ];

  useEffect(() => {
    const fetchAccountInfo = async () => {
      const accountData = await getRecordFromFireStore(
        `organization/${organization}/accounts/${accountId}`,
        AccountConverter,
      );
      setAccountInfo(accountData);
    };

    const fetchCustomers = async () => {
      setLoadingCustomers(true);
      const customerDocs = await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
        [
          {
            comparisonField: "accountId",
            comparisonOperator: "==",
            value: accountId,
          },
          {
            comparisonField: "updatedAt",
            order: "desc",
          },
        ],
        customerConverter,
      );
      setCustomers(customerDocs.data);
      setLoadingCustomers(false);
    };

    fetchAccountInfo();
    fetchCustomers();
  }, [accountId, organization]);

  useEffect(() => {
    if (customers.length > 0) {
      const fetchConversations = async () => {
        setLoadingConversations(true);

        const transcriptsDocsPromises = customers.flatMap((customer) =>
          customer.conversations.map((conversationId) => {
            const decodedConversationId = decodeURIComponent(conversationId);
            return getRecordFromFireStore(
              `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${decodedConversationId}`,
              transcriptConverter(
                evaluationForms,
                getUsersEmailDictionary(collaborators, pendingCollaborators),
                user,
              ),
            ).then((docData) => {
              // Ensure the document includes its ID
              if (docData && !docData.id) {
                docData.id = decodedConversationId;
              }
              return docData;
            });
          }),
        );

        // Wait until all conversation documents are fetched and processed
        const transcriptsDocs = await Promise.all(transcriptsDocsPromises);
        console.log(transcriptsDocs);
        // Filter out any null or undefined docs (e.g., deleted or missing docs)
        const validConversations = transcriptsDocs.filter(
          (conv) => conv && !conv.deleted,
        );
        const sortedConversations = validConversations.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );

        setConversations(sortedConversations);
        setLoadingConversations(false);
      };

      fetchConversations();
    } else {
      setLoadingConversations(false);
    }
  }, [customers, organization]);

  const handleSave = async () => {
    setLoadingCustomers(true);
    const updatedAccount = {
      ...accountInfo,
      updatedAt: new Date().toISOString(),
    };
    await updateAccountInfo(
      organization,
      accountId,
      updatedAccount,
      setAccounts,
      undefined,
      customers,
      SetAllCustomers,
    );
    setLoadingCustomers(false);

    setEditMode(false);
  };
  const assignStage = async (value: string) => {
    const updateData: any = {
      currentStage: value,
    };

    if (accountInfo.currentStage !== value && accountInfo.currentStage) {
      updateData.stageHistory = [
        {
          stage: accountInfo.currentStage,
          updatedBy: user.email,
          timestamp: new Date().toISOString(),
        },
        ...(accountInfo.stageHistory ?? []),
      ];
    }

    // Update local state for the account
    setAccountInfo((prevState) => ({
      ...prevState,
      ...updateData,
    }));
  };

  const AccountDescriptions: DescriptionsProps["items"] = [
    {
      key: "2",
      label: "Account Stage",
      children: editMode ? (
        <Select
          style={{ width: "150px" }}
          showSearch
          onChange={(value) => {
            assignStage(value.toLowerCase());
          }}
          value={capitalizeFirstLetters(accountInfo?.currentStage)}
          placeholder="Select a stage"
          filterOption={(input, option) => {
            return option.value
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
        >
          <Select.Option value="">None</Select.Option>
          {customerStages?.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      ) : accountInfo?.currentStage ? (
        <Tag
          style={{
            fontSize: "15px",
          }}
          color={getColorForValue(accountInfo.currentStage)}
        >
          {capitalizeFirstLetters(accountInfo.currentStage)}
        </Tag>
      ) : (
        "None"
      ),
    },
    {
      label: "Updated At",
      children: formatDateToHumanReadable(accountInfo?.updatedAt) || "",
    },
    ...accountsCustomFields.map((field) => ({
      label: humanize(field.key),
      children: (
        <FieldRenderer
          field={field}
          value={accountInfo?.[field.key]}
          onChange={(key, value) =>
            setAccountInfo((prevState) => ({
              ...prevState,
              [key]: value,
            }))
          }
          editMode={editMode && field.editable}
        />
      ),
    })),
  ];
  const handleTranscriptClick = async (transcript) => {
    navigate(`?conversation=${encodeURIComponent(transcript.id)}`);
  };
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Spin spinning={loadingCustomers}>
      <Row gutter={16} style={{ padding: "20px" }}>
        <Col xs={24} md={7}>
          <Card title="Account">
            <AccountAvatar account={accountInfo} />
            <Divider />
            <Descriptions
              size={"small"}
              column={1}
              title="Details"
              items={AccountDescriptions}
              extra={
                editMode ? (
                  <SaveOutlined
                    style={{
                      marginLeft: "8px",
                    }}
                    onClick={handleSave}
                  />
                ) : (
                  <EditOutlined
                    style={{
                      marginLeft: "8px",
                    }}
                    onClick={toggleEditMode}
                  />
                )
              }
            />
          </Card>
        </Col>

        <Col xs={24} md={17}>
          <Card>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Customers" key="1">
                <TableBoardDashboard
                  columns={customerColumns}
                  data={{ data: customers, hasMore: false }}
                  searchKey="customer"
                />
              </TabPane>
              <TabPane tab="Conversations" key="2">
                {loadingConversations ? (
                  <Spin />
                ) : (
                  <ConversationTable
                    showRisk={showRisk}
                    data={conversations}
                    handleRowClick={handleTranscriptClick}
                    pageSize={4}
                  />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
