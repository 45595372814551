import axios from "axios";

export async function processFile(
  file,
  organization,
  user,
  storageType,
  accessToken,
  evaluationId,
  onProgress = (event) => {},
) {
  const formData = new FormData();
  formData.append("file", JSON.stringify(file));
  formData.append("storage_type", storageType);
  formData.append("organization", organization);
  formData.append("user", user?.email);
  if (accessToken != null) formData.append("accessToken", accessToken);
  if (evaluationId != null)
    formData.append("evaluation_form", JSON.stringify({ id: evaluationId }));
  const response = await axios.post(
    process.env.REACT_APP_TRUCO_BACKEND + "/process_file",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted =
          10 + (progressEvent.loaded / progressEvent.total) * 30;
        console.log(`Upload Progress: ${percentCompleted}%`);
        onProgress({ percent: percentCompleted });
        // Update your progress bar here with percentCompleted
      },
      onDownloadProgress: (progressEvent) => {
        const percentCompleted =
          40 + (progressEvent.loaded / progressEvent.total) * 60;
        onProgress({ percent: percentCompleted });
        console.log(`Download Progress: ${percentCompleted}%`);
      },
    },
  );
  return response.data;
}
export async function processTrial(
  file,
  userEmail,
  evaluation_labels,
  onProgress = (event) => {},
  organization?,
) {
  const formData = new FormData();
  const fileData = {
    file_id: file.file_id,
    name: file.name,
    mime_type: file?.type,
  };
  formData.append("file", JSON.stringify(fileData));
  formData.append("user", userEmail);
  formData.append("agent", userEmail);
  formData.append("reviewer", userEmail);
  formData.append("lead", userEmail);
  formData.append("evaluation_labels", JSON.stringify(evaluation_labels));
  organization && formData.append("organization", JSON.stringify(organization));

  const response = await axios.post(
    process.env.REACT_APP_TRUCO_BACKEND + "/process_trial",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted =
          10 + (progressEvent.loaded / progressEvent.total) * 30;
        console.log(`Upload Progress: ${percentCompleted}%`);
        onProgress({ percent: percentCompleted });
      },
      onDownloadProgress: (progressEvent) => {
        const percentCompleted =
          40 + (progressEvent.loaded / progressEvent.total) * 60;
        onProgress({ percent: percentCompleted });
        console.log(`Download Progress: ${percentCompleted}%`);
      },
    },
  );
  return response.data;
}

export async function uploadFileToGCS(
  file,
  signedUrl,
  onError = (event, body) => {},
  onProgress = (event) => {},
  handleUploadProgress?,
  abortUpload?,
) {
  try {
    const response = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted =
          (progressEvent.loaded / progressEvent.total) * 10;
        onProgress({ percent: percentCompleted });
        handleUploadProgress &&
          handleUploadProgress(percentCompleted.toFixed(2));
      },
      signal: abortUpload,
    });

    if (response.status === 200) {
      console.log("File uploaded successfully to GCS.");
    } else {
      console.error("Error uploading file to GCS.");
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Upload canceled.");
    } else {
      onError(error.message, error.response?.data);
      console.error("Error uploading file to GCS.");
    }
  }
}
