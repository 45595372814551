// UnsubscribeManager.ts
class UnsubscribeManager {
  private static instance: UnsubscribeManager | null = null;
  private unsubs: (() => void)[] = [];

  private constructor() {}

  static getInstance(): UnsubscribeManager {
    if (!UnsubscribeManager.instance) {
      UnsubscribeManager.instance = new UnsubscribeManager();
    }
    return UnsubscribeManager.instance;
  }

  addUnsub(unsub: () => void): void {
    this.unsubs.push(unsub);
  }

  clearUnsubs(): void {
    this.unsubs.forEach((unsub) => unsub());
    this.unsubs = [];
  }
}

export default UnsubscribeManager;
