import React, { useEffect, useState } from "react";
import { Card, Tag, Tooltip, Space, Select } from "antd";
import {
  BankOutlined,
  LinkOutlined,
  PhoneOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { User, AccountAvatar } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { COLLECTION_DATA, LOCALSTORAGE_TYPES } from "src/utils/enums";
import {
  createOrUpdateDocInFirestore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetters,
  formatDateToHumanReadable,
  getColorForValue,
  humanize,
} from "src/utils/utils";
import {
  FieldRenderer,
  getCustomerIdentity,
  updateCustomerInfo,
} from "./Customers.utils";
// import { CustomersDrawerSettings } from "./CustomersDrawerSettings";
import { HoverContainer } from "./Customers.styles";
import { useUserState } from "src/state/UserState";
import { TableBoardDashboard } from "../Dashboard/TableBoardDashboard/TableBoardDashboard";

export const CustomersDashboard = () => {
  const {
    organization,
    customers,
    setCustomers,
    organizationSettings,
    setOrganizationSettings,
  } = useOrganizationState();
  const { user } = useUserState();

  const customersCustomFields =
    organizationSettings?.customersCustomFields || [];
  const { customerStages } = organizationSettings;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(!customers?.data);

  const [stageData, setStageData] = useState({});
  const [tableConfig, setTableConfig] = useState(
    JSON.parse(
      localStorage.getItem(LOCALSTORAGE_TYPES.CUSTOMERS_TABLE_CONFIG),
    ) || {},
  );

  // Toggle drawer visibility
  const saveConfigToLocalStorage = (config) => {
    localStorage.setItem(
      LOCALSTORAGE_TYPES.CUSTOMERS_TABLE_CONFIG,
      JSON.stringify(config),
    );
    setTableConfig(config);
  };

  // Handle switch change for each custom field
  const handleSwitchChange = (fieldKey, checked) => {
    const updatedConfig = { ...tableConfig, [fieldKey]: checked };
    saveConfigToLocalStorage(updatedConfig);
  };
  const assignStage = async (value, rowId, oldStage, selectedCustomer) => {
    const updateData: any = {
      currentStage: value,
    };

    if (oldStage !== value && Boolean(oldStage)) {
      updateData.stageHistory = [
        {
          stage: oldStage,
          updatedBy: user.email,
          timestamp: new Date().toISOString(),
        },
        ...(selectedCustomer.stageHistory ?? []),
      ];
    }

    updateCustomerInfo(organization, rowId, updateData, setCustomers, {
      key: "currentStage",
    });
  };

  const columns = [
    {
      accessorFn: (row) => getCustomerIdentity(row),
      enableEditing: false,
      header: "Customer Details",
      size: 200,
      Cell: ({ row }) => (
        <HoverContainer onClick={() => handleCustomerClick(row.original)}>
          <User user={row.original} usePhoneIcon={true} />
        </HoverContainer>
      ),
    },
    {
      header: "Account",
      enableEditing: false,
      accessorFn: (row) => ({
        company: row.company,
        companyDomain: row.companyDomain,
      }),
      size: 200, // Adjust column width for better visibility
      Cell: ({ cell }) => (
        <AccountAvatar
          account={{
            name: cell.getValue().company,
            domain: cell.getValue().companyDomain,
          }}
        />
      ),
    },
    {
      header: "Update At",
      enableEditing: false,
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 150, // Adjust column width for better visibility
      Cell: ({ cell }) => {
        return formatDateToHumanReadable(cell.getValue());
      },
      enableColumnFilter: false,
    },
    {
      header: "# of Conversations",
      enableEditing: false,
      accessorKey: "conversations", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        const conversations = cell.getValue();
        return conversations?.length;
      },
      size: 100, // Adjust column width for better visibility
    },
    {
      header: "Stage",
      accessorKey: "currentStage",
      enableEditing: true, // Enable editing for this column
      Edit: ({ cell, row, table }) => {
        const stage = cell.getValue();
        const [selectedStage, setSelectedStage] = useState(stage);

        const handleChange = (value) => {
          setSelectedStage(value);
          assignStage(value, row.original.id, stage, row.original); // Pass row ID for proper updates
        };

        const handleBlur = () => {
          // Exit edit mode after losing focus
          table.setEditingCell(null);
        };

        return (
          <Select
            value={selectedStage || undefined}
            showSearch
            onChange={handleChange}
            onBlur={handleBlur}
            dropdownStyle={{ width: "20%" }} // Set dropdown width to auto
          >
            <Select.Option value="">None</Select.Option>
            {customerStages?.map((option) => (
              <Select.Option key={option} value={option}>
                {capitalizeFirstLetters(option)}
              </Select.Option>
            ))}
          </Select>
        );
      },
      Cell: ({ cell }) => {
        const stage = cell.getValue();
        return stage ? (
          <Tag color={getColorForValue(stage)}>
            {capitalizeFirstLetters(stage)}
          </Tag>
        ) : (
          ""
        );
      },
    },
    ...customersCustomFields
      .filter(
        (field) => field.key !== "company" && tableConfig[field.key] !== false,
      )
      .map((field) => ({
        header: humanize(field.key),
        accessorKey: field.key,
        enableEditing: field.editable,
        size: field.type === "string" ? 200 : 100, // Adjust column width for better visibility
        Edit: ({ cell, row, table }) => {
          // This renders in edit mode
          const [inputValue, setInputValue] = useState(cell.getValue());

          return (
            <FieldRenderer
              field={field}
              value={inputValue}
              onChange={(key, value) => {
                row.original[key] = value;
                setInputValue(value);
              }}
              isRowSetup={true}
              onSave={(key, value) => {
                // Immediately save the value
                row.original[key] = value;
                updateCustomerInfo(
                  organization,
                  row.original.id,
                  { [key]: value },
                  setCustomers,
                  field,
                );

                // Exit edit mode
                table.setEditingCell(null);
              }}
              editMode={true}
            />
          );
        },
        Cell: ({ cell, row, table }) => {
          return (
            <Tooltip title={"Double click to edit"} arrow placement="top">
              {cell.getValue()}
            </Tooltip>
          );
        },
      })),
  ];

  const [paginationPerStage, setPaginationPerStage] = useState({});

  const fetchCustomers = async (stage = null) => {
    setLoading(true);

    const paginationState = stage
      ? paginationPerStage[stage] || { lastDoc: null, hasMore: true }
      : {
          lastDoc: customers?.lastDoc || null,
          hasMore: customers?.hasMore ?? true,
        };

    const filters: any = [
      {
        comparisonField: "updatedAt",
        order: "desc",
      },
    ];

    if (stage) {
      if (stage === "Unassigned Stage") {
        filters.push({
          comparisonField: "currentStage",
          comparisonOperator: "==",
          value: "",
        });
      } else {
        filters.push({
          comparisonField: "currentStage",
          comparisonOperator: "in",
          value: [capitalizeFirstLetters(stage), stage.toLowerCase()],
        });
      }
    }

    const docs = await getRecordsFromFireStore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
      filters,
      customerConverter,
      undefined,
      50,
      paginationState.lastDoc,
    );

    if (stage) {
      // Update stage data

      const check = docs.data.filter(
        (newCustomer) =>
          !customers.data.find((customer) => customer.id === newCustomer.id),
      );

      const newData = [...customers.data, ...check];

      // Update overall customers list to include new customers
      setCustomers((prevState) => {
        const newCustomers = docs.data.filter(
          (newCustomer) =>
            !prevState.data.find((customer) => customer.id === newCustomer.id),
        );

        return {
          ...prevState,
          data: [...prevState.data, ...newCustomers],
        };
      });
      setPaginationPerStage((prevPagination) => ({
        ...prevPagination,
        [stage]: {
          lastDoc: docs.lastDoc || null, // Update the specific lastDoc for this stage
          hasMore: docs.data.length >= 50 && docs.data.length > 0, // Check if there are more data
          // hasMore: true,
        },
      }));
      updateStageData(newData);
    } else {
      setCustomers((prevState) => {
        const filteredNewData = docs?.data?.filter(
          (newCustomer) =>
            !prevState?.data?.find(
              (customer) => customer.id === newCustomer.id,
            ),
        );
        const newData = [...(prevState.data || []), ...filteredNewData];
        const hasMore = docs.data.length >= 50 && docs.data.length > 0;

        if (!hasMore) {
          setPaginationPerStage((prevPagination) => {
            const updatedPagination = { ...prevPagination };

            Object.keys(updatedPagination).forEach((stage) => {
              updatedPagination[stage] = {
                hasMore: false,
                lastDoc: null,
              };
            });
            return updatedPagination;
          });
        }

        return {
          ...prevState,
          data: newData.length > 0 ? newData : [],
          hasMore: hasMore,
          lastDoc: docs.lastDoc || null,
        };
      });
      // Update stage data
      updateStageData(docs.data);
    }

    setLoading(false);
  };

  const updateStageData = (customersData) => {
    const newStages = {
      "Unassigned Stage": [],
      ...organizationSettings?.customerStages
        ?.map((stage) => stage.toLowerCase())
        .reduce((acc, stage) => {
          acc[stage] = [];
          return acc;
        }, {}),
    };
    customersData?.forEach((customer) => {
      const stage = customer?.currentStage?.toLowerCase() || "Unassigned Stage";
      if (newStages[stage]) {
        newStages[stage].push(customer);
      }
    });

    setStageData(newStages);
    if (Object.keys(paginationPerStage).length === 0) {
      setPaginationPerStage((prevPagination) => {
        const updatedPagination = { ...prevPagination };

        Object.keys(newStages).forEach((stage) => {
          if (!updatedPagination[stage]) {
            updatedPagination[stage] = {
              hasMore: true,
              lastDoc: null,
            };
          }
        });
        return updatedPagination;
      });
    }
  };

  useEffect(() => {
    if (!organizationSettings || !organization) return;
    if (!customers?.data) {
      fetchCustomers();
    } else {
      updateStageData(customers.data);
    }
  }, [organization, customers?.data, organizationSettings]);

  const loadMoreCustomers = async () => {
    if (!customers.lastDoc) return;
    setLoading(true);
    try {
      fetchCustomers();
      setTimeout(() => {
        const lastBeforeNew = document.getElementById(
          `Customer-${customers.data[customers.data.length - 2]?.id}`,
        );
        if (lastBeforeNew) {
          lastBeforeNew.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setLoading(false);
    }
  };
  const loadMoreCustomersPerStage = (stage) => {
    fetchCustomers(stage);
  };

  const handleCustomerClick = (customer) => {
    navigate(`?customer=${encodeURIComponent(customer.id)}`);
  };
  const onDragEnd = async (result) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) return;

    const sourceStage = source.droppableId;
    const destinationStage = destination.droppableId;

    if (
      sourceStage === destinationStage &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "Stage") {
      const stagesArray = Object.keys(stageData);
      const [removed] = stagesArray.splice(source.index, 1);
      stagesArray.splice(destination.index, 0, removed);
      // remove first unassigned stage

      setStageData((prevData) => {
        const updatedData = stagesArray.reduce((acc, stage) => {
          acc[stage] = prevData[stage];
          return acc;
        }, {});
        return updatedData;
      });

      stagesArray.splice(0, 1);

      const lowerCaseStages = stagesArray.map((stage) => stage.toLowerCase());

      setOrganizationSettings((prevSettings) => {
        return { ...prevSettings, customerStages: lowerCaseStages };
      });

      await createOrUpdateDocInFirestore(`/organization/${organization}`, {
        customerStages: stagesArray,
      });

      return;
    }

    const customerData = customers.data.find(
      (customer) => customer.id === draggableId,
    );
    const updatedCustomer = {
      ...customerData,
      currentStage:
        destinationStage === "Unassigned Stage" ? "" : destinationStage,
      updatedAt: new Date().toISOString(),
    };

    setCustomers((prevstate) => {
      const updatedCustomers = prevstate.data.map((customer) => {
        if (customer.id === draggableId) {
          return updatedCustomer;
        }
        return customer;
      });

      updatedCustomers.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });

      return {
        ...prevstate,
        data: updatedCustomers,
      };
    });

    createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${draggableId}`,
      updatedCustomer,
    );
  };

  const renderCustomerCard = (customer) => {
    return (
      <Card hoverable onClick={() => handleCustomerClick(customer)}>
        <User user={getCustomerIdentity(customer)} />
        <Space wrap style={{ marginTop: "8px" }}>
          <Tooltip title="# of Conversations">
            <PhoneOutlined style={{ marginRight: "8px", color: "#52c41a" }} />
            {customer.conversations?.length || 0}
          </Tooltip>
          <Tooltip title="Updated At">
            <CalendarOutlined
              style={{ marginRight: "8px", color: "#52c41a" }}
            />
            {formatDateToHumanReadable(customer.updatedAt)}
          </Tooltip>
          {customer.company && (
            <Tooltip title="Company">
              <BankOutlined style={{ marginRight: "8px", color: "#52c41a" }} />
              {customer.company}
            </Tooltip>
          )}
          {customer.companyDomain && (
            <Tooltip title="Company Domain">
              <LinkOutlined style={{ marginRight: "8px", color: "#1890ff" }} />
              {customer.companyDomain}
            </Tooltip>
          )}
        </Space>
      </Card>
    );
  };

  return (
    <TableBoardDashboard
      tableConfig={tableConfig}
      handleSwitchChange={handleSwitchChange}
      loading={loading}
      columns={columns}
      loadMoreFunction={loadMoreCustomers}
      loadMorePerStage={loadMoreCustomersPerStage}
      onDragEnd={onDragEnd}
      data={customers}
      searchKey={"customer"}
      stageData={stageData}
      paginationPerStage={paginationPerStage}
      renderCustomerCard={renderCustomerCard}
      customFields={customersCustomFields}
    />
  );
};
