import {
  borderRadius,
  color,
  elementSize,
  hexToRgba,
} from "src/styles/variables";
import styled from "styled-components";

export const PlaylistCardContainer = styled.div`
  padding: ${elementSize.sm};
  background: ${color.white};
`;

export const PlaylistImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  overflow: hidden;
  border-radius: ${borderRadius.xxs};
  margin-bottom: ${elementSize.xs};
  cursor: pointer;
`;

export const PlaylistImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const PlaylistVideoThumbnail = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PlaylistTitle = styled.h3`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  ::first-letter {
    text-transform: capitalize;
  }
`;

export const PlaylistDescription = styled.div`
  margin-top: ${elementSize.xs};
  font-size: 0.9em;
  color: ${color.grayMedium};
  cursor: pointer;
`;

export const EditIconContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: ${hexToRgba(color.grayDark, 0.8)};
  padding: ${borderRadius.xxs};
  border-radius: ${borderRadius.xxs};
  display: flex;
  align-items: center;
  justify-content: center;
`;
