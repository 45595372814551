import { createOrUpdateDocInFirestore } from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { humanize } from "src/utils/utils";
import { SettingComponent } from "./SettingComponent";
import { SETTINGS_DESCRIPTION } from "../../Profile.enums";
import { tagTypeMapping } from "../../Profile.constants";
export const settingsWithModel = [
  "customersCustomFields",
  "conversationProperties",
  "accountsCustomFields",
];
export const settingsWithTags = ["teams"];

export const updateSetting = async (
  settingName: string,
  newValue,
  setOrganizationSettings,
  setLoadingSetting,
  organization: string,
) => {
  if (settingName === "customerStages") {
    newValue = newValue.map((stage) => stage.toLowerCase());
  }
  setOrganizationSettings((prevSettings) => {
    // @todo persist this in db as well!
    return { ...prevSettings, [settingName]: newValue };
  });
  setLoadingSetting(true);
  await createOrUpdateDocInFirestore(`/organization/${organization}`, {
    [settingName]: newValue,
  });
  setTimeout(() => {
    setLoadingSetting(false);
  }, 500);
};

export const OrganizationSettingComponent = ({
  settingsKey,
  hasAccessToEdit,
  setLoadingSetting,
}) => {
  const { organization, organizationSettings, setOrganizationSettings } =
    useOrganizationState();

  let tags,
    switchValue = null;

  if (!settingsWithModel.includes(settingsKey)) {
    if (settingsWithTags.includes(settingsKey)) {
      tags = organizationSettings[settingsKey].map((property) =>
        humanize(property),
      );
    } else {
      switchValue = organizationSettings[settingsKey];
    }
  }
  return (
    <SettingComponent
      settingsKey={settingsKey}
      hasAccessToEdit={hasAccessToEdit}
      title={settingsKey
        .replace(/([A-Z])/g, " $1") // insert a space before all caps
        .replace(/^./, (str) => str.toUpperCase()) // capitalize the first letter
        .trim()}
      description={SETTINGS_DESCRIPTION[settingsKey]}
      tags={tags}
      tagType={tagTypeMapping[settingsKey]}
      switchValue={switchValue}
      onChange={async (newValue) => {
        const updatedValue = newValue;
        await updateSetting(
          settingsKey,
          updatedValue,
          setOrganizationSettings,
          setLoadingSetting,
          organization,
        );
      }}
    />
  );
};
