import { COLLECTION_DATA } from "src/utils/enums";
import { evaluationFormsTransform } from "src/utils/converter";
import useFetchCollection from "./useFetchCollection";

const useFetchSignals = () => {
  const { items: signals, fetchItems: fetchSignals } = useFetchCollection(
    COLLECTION_DATA.SIGNALS,
    evaluationFormsTransform,
  );

  return { signals, fetchSignals };
};

export default useFetchSignals;
