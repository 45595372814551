import React from "react";
import { Space, Typography } from "antd";
import { OrangeButton } from "../../components";
import { SectionContainer, BorderlessCard } from "./LandingPage.styles";
import { color } from "../../styles/variables";
import { TRUCO_CAL_LINK } from "src/utils/variables";

export const CTASection = () => {
  return (
    <SectionContainer background={color.olive}>
      <BorderlessCard
        style={{
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Space direction="vertical" size="large">
          <img
            src={process.env.PUBLIC_URL + "/ready_icon.png"}
            alt="landingPage"
            style={{ width: "max(30%, 300px)" }}
          />
          <Typography.Title
            level={1}
            style={{ fontWeight: "bold", color: "#fff", margin: "auto" }}
          >
            {" "}
            Ready to upgrade your <br />
            customer service experience?{" "}
          </Typography.Title>
          <Typography.Title
            level={4}
            style={{ color: "#fff", fontWeight: "normal", margin: "auto" }}
          >
            Experience the product for yourself to see why our customers love
            us!
          </Typography.Title>
          <br />
          <OrangeButton
            text={"Get a Demo"}
            size={"large"}
            url={TRUCO_CAL_LINK}
            style={{ display: "flex", alignItems: "center" }}
          />
        </Space>
      </BorderlessCard>
    </SectionContainer>
  );
};
