import styled from "styled-components";
import {
  color,
  elementSize,
  fontSize,
  hexToRgba,
  fontWeight,
  deviceSize,
} from "src/styles/variables";
import { Card } from "antd";

export const OuterContainer = styled.div`
  background: ${color.white};
  display: flex;
  justify-content: center; /* Align horizontally */
  align-items: center; /* Align vertically */
  min-height: 70vh;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 70vh;
  background: ${hexToRgba(color.orange, 0.9)};
  padding: ${elementSize.lg} ${elementSize.xxl};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${deviceSize.regular_phone}) and (max-width: ${deviceSize.tablet}) {
    margin-bottom: 40%;
    min-height: 62vh;
    padding: ${elementSize.lg};
  }
  @media (max-width: ${deviceSize.large_phone}) {
    margin-bottom: 60%;
    padding: ${elementSize.lg};
  }
  @media (max-width: ${deviceSize.regular_phone}) {
    margin-bottom: 75%;
    padding: ${elementSize.lg};
  }
`;

export const EllipseLarge = styled.div`
  position: absolute;
  top: -300px;
  left: -550px;
  width: 700px;
  height: 700px;
  background: ${hexToRgba(color.white, 0.1)};
  border-radius: 50%;
  z-index: 0;
`;

export const EllipseSmall = styled.div`
  position: absolute;
  top: 135px;
  left: -250px;
  width: 400px;
  height: 500px;
  background: ${hexToRgba(color.white, 0.04)};
  border-radius: 50%;
  z-index: 0;
`;

export const HeadingContainer = styled.div`
  max-width: 35%;
  margin: ${elementSize.xxxl} 10rem ${elementSize.xxxl};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
  @media (min-width: ${deviceSize.xxl}) {
    margin: ${elementSize.xxxl} 0px ${elementSize.xxxl} 20rem;
  }
  @media (max-width: ${deviceSize.tablet}) {
    max-width: 80%;
    margin: ${elementSize.lg} auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    text-align: center;
  }
`;
export const FirstProductCard = styled.div`
  z-index: 1;
  position: absolute;
  top: 5.5rem;
  left: 56%;
  @media (min-width: ${deviceSize.xxl}) {
    left: 53%;
  }
  @media (max-width: ${deviceSize.tablet}) {
    scale: 0.9;
  }
  @media (min-width: ${deviceSize.regular_phone}) and (max-width: ${deviceSize.tablet}) {
    scale: 0.9;
    left: 25%;
    top: 78%;
  }

  @media (max-width: ${deviceSize.regular_phone}) {
    left: 3%;
    scale: 0.9;
    top: 65%;
  }
  @media (max-width: 380px) {
    scale: 0.75;
    top: 75%;
  }
`;
export const SeocndProductCard = styled.div`
  z-index: 2;
  position: absolute;
  top: 36.8%;
  right: 13rem;
  @media (min-width: ${deviceSize.xxl}) {
    right: 23rem;
  }
  @media (max-width: ${deviceSize.tablet}) {
    scale: 0.85;
  }
  @media (min-width: ${deviceSize.regular_phone}) and (max-width: ${deviceSize.tablet}) {
    scale: 0.9;
    left: 45%;
    top: 96%;
  }

  @media (max-width: ${deviceSize.regular_phone}) {
    left: 17%;
    scale: 0.85;
    top: 86%;
  }
  @media (max-width: 380px) {
    scale: 0.75;
    top: 89%;
  }
`;

export const GetDemoButton = styled.button`
  background: ${color.white};
  color: ${color.orange};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: ${fontSize.displayXL};
  font-weight: ${fontWeight.semiBold};
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  margin-top: 10px;

  &:hover {
    background: ${hexToRgba(color.white, 0.9)};
  }
`;

export const ArrowDiv = styled.div`
  position: absolute;
  right: -38%;
  top: 15%;
`;
export const AnimatedTextCard = styled(Card)`
  width: 328px;
  height: 389px;
  background-color: ${color.white};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5.01px 35.69px 0px;
  overflow: hidden;
  position: relative;
`;

export const WaveWrapper = styled.div`
  bottom: -213px;
  position: absolute;
  right: 9%;
  paddingbottom: 20px;
  @media (min-width: ${deviceSize.xxl}) {
    right: 20rem;
  }
  @media (max-width: ${deviceSize.xl}) {
    display: none;
  }
`;
