import styled, { keyframes } from "styled-components";

export const growAndFade = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const AnimatedSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  align-items: center;
  flex-direction: column;
  animation: ${growAndFade} 3s infinite; // You can adjust the duration as needed
  color: #436057;
  > svg > g {
    fill: #436057;
  }
`;
