import { Card, List } from "antd";
import { color, deviceSize } from "src/styles/variables";
import styled, { keyframes } from "styled-components";

export const SlidePanel = styled.div`
  transition: transform 0.3s ease-out;
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0)" : "translateX(-100%)"};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: ${deviceSize.large_phone}) {
    position: relative;
    transform: none;
  }
`;

export const CardWithStyledHeader = styled(Card)`
  &.ant-card .ant-card-head {
    @media (max-width: ${deviceSize.large_phone}) {
      background-color: ${color.grayLight};
      border-bottom: ${color.gray} 1px solid;
    }
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const AnimatedListItem = styled(List.Item)`
  animation: ${fadeIn} 0.5s ease-out forwards;
`;
