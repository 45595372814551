import React from "react";
import type { UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, Space } from "antd";
import { color } from "src/styles/variables";
import { uploadFileToGCS } from "./DragDropFiles.utils";

const { Dragger } = Upload;

interface DragDropFilesProps {
  handleUploadProgress?: any;
  uploadedFiles?: any;
  setUploadFile?: any;
  setActiveAudio?: (fileName: string) => void; // Optional function,
  setAbortController?: (controller: AbortController | null) => void; // Add this line;
}

export const DragDropFilesV2: React.FC<DragDropFilesProps> = ({
  setActiveAudio,
  setUploadFile,
  handleUploadProgress,
  uploadedFiles,
  setAbortController,
}) => {
  const abort = new AbortController();
  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: "audio/*,video/*",
    fileList: uploadedFiles,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(0))}%`,
    },

    customRequest: ({ file, onSuccess, onError, onProgress }) => {
      if (typeof file === "string") {
        return;
      }
      if (!("name" in file)) {
        return;
      }
      setAbortController(abort);

      let fileId = `${Date.now()}_${file?.name}`;
      setActiveAudio(file?.name);
      fetch(`${process.env.REACT_APP_TRUCO_BACKEND}/get_file_url`, {
        method: "POST",
        body: JSON.stringify({
          file_id: fileId,
          method: "PUT",
          content_type: file?.type,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("get_file_url failed");
          }
          return response.json();
        })
        .then(({ url: signedUrl }) =>
          uploadFileToGCS(
            file,
            signedUrl,
            onError,
            onProgress,
            handleUploadProgress,
            abort.signal,
          ),
        )
        .then((response) => {
          onSuccess("done");
          handleUploadProgress(100);
          file["file_id"] = fileId;
          setUploadFile(abort.signal.aborted ? [] : [file]);
        })
        .catch((error) => {
          onError(error);
          handleUploadProgress(-1);
          setActiveAudio(null);
          console.log(error);
        });
    },
    onChange(info) {
      setUploadFile(info.fileList);
    },
    onDrop(e) {
      setUploadFile((prevFiles) => {
        return [...prevFiles, ...e.dataTransfer.files];
      });
    },
  };

  return (
    <Dragger
      {...props}
      style={{
        backgroundColor: "#fff",
        border: "none",
      }}
    >
      <Space direction="vertical">
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: color.orange }} />
        </p>
        <p className="ant-upload-text">
          Click or drag files to this area to upload
        </p>
      </Space>
    </Dragger>
  );
};
