import { useState } from "react";
import { Avatar, Button, Card, Spin, Space } from "antd";
import { EditOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { color } from "src/styles/variables";
import { ManageCollaboratorsAccess } from "./Collaborators";
import { CollaboratorAvatar } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { ProfileTabs, ProfileDocs } from "../../Profile.enums";
export const CollaboratorsCard = () => {
  const { collaborators, pendingCollaborators } = useOrganizationState();
  const [openCollaboratorsModel, setOpenCollaboratorsModel] = useState(false);
  return (
    <>
      <Card
        title={
          <Space>
            {ProfileTabs.COLLABORATORS}
            <InfoCircleTwoTone
              twoToneColor={color.orange}
              onClick={() => window.open(ProfileDocs.COLLABORATORS, "_blank")}
            />
          </Space>
        }
        extra={
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => setOpenCollaboratorsModel(true)}
          />
        }
      >
        <Spin spinning={!collaborators && !pendingCollaborators}>
          {collaborators?.length > 0 ? (
            <Avatar.Group
              max={{
                count: 15,
                style: {
                  color: color.black,
                  backgroundColor: color.gray,
                },
              }}
            >
              {collaborators.map((collaborator, index) => (
                <CollaboratorAvatar key={index} collaborator={collaborator} />
              ))}
            </Avatar.Group>
          ) : (
            "You haven't invited any collaborators yet"
          )}
        </Spin>
      </Card>
      {openCollaboratorsModel && (
        <ManageCollaboratorsAccess
          collaborators={collaborators}
          pendingCollaborators={pendingCollaborators}
          openModal={openCollaboratorsModel}
          setOpenModal={setOpenCollaboratorsModel}
        />
      )}
    </>
  );
};
