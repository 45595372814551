import { Badge, Card, List, Popover, Switch } from "antd";
import { AddRemoveTags } from "src/components";
import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { color } from "src/styles/variables";
import { settingsWithModel } from "./OrganizationSettingComponent";
import { CustomFieldModal } from "./CustomField/CustomFieldModal";

const currentNewKeys = ["customersCustomFields", "accountsCustomFields"];

export const SettingComponent = ({
  settingsKey,
  title,
  description,
  hasAccessToEdit,
  onChange,
  tags = null,
  tagType,
  switchValue = null,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Check if the setting requires a modal (like customersCustomFields)
  const requiresModal = settingsWithModel.includes(settingsKey);

  return (
    <>
      {currentNewKeys.includes(settingsKey) && (
        <Badge.Ribbon
          text="New"
          placement="start"
          color={color.orange}
        ></Badge.Ribbon>
      )}
      <Card.Grid style={{ width: "100%" }} hoverable={false}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <List.Item style={{ width: "65%", textAlign: "start" }}>
            <List.Item.Meta title={title} description={description} />
          </List.Item>
          <Popover
            content={
              !hasAccessToEdit
                ? "Only owners/admins have access to change setting"
                : ""
            }
          >
            {tags !== null && (
              <AddRemoveTags
                tagType={tagType}
                tags={tags}
                onChange={(newValue) => onChange(newValue)}
                style={{ justifyContent: "flex-end" }}
              />
            )}
            {switchValue !== null && (
              <Switch
                checked={switchValue}
                onChange={() => onChange(!switchValue)}
                disabled={!hasAccessToEdit}
              />
            )}
            {requiresModal && (
              <EditOutlined
                style={{ fontSize: "18px", cursor: "pointer" }}
                onClick={showModal}
              />
            )}
          </Popover>
        </div>
      </Card.Grid>

      {requiresModal && (
        <CustomFieldModal
          modalType={settingsKey} // Pass modalType here
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};
