import { spacing } from "src/styles/variables";
import { Tabs } from "antd";
import { useState } from "react";
import type { TabsProps } from "antd";
import { StyledAudioCard, SandboxTabCard } from "./TrucoSandbox.styles";
import { AudioOutlined } from "@ant-design/icons";
import { useUserState } from "src/state/UserState";
import { DragDropFilesV2 } from "../DragDropFiles/DragDropFilesv2";
import { StyledBorderedTag } from "src/components";
import ChangeAudioComponent, {
  SANDBOX_TABS,
  SampleAudioFile,
} from "./TrucoSandbox.utils";

interface UploadPlayground {
  showTrials?: boolean;
  uploadedFile: any[];
  setUploadFile: any;
  loadingRun;
  activeTab;
  setActiveTab;
  setTranscript;
}

export const UploadPlayground: React.FC<UploadPlayground> = ({
  showTrials = true,
  uploadedFile,
  setUploadFile,
  loadingRun,
  activeTab,
  setActiveTab,
  setTranscript,
}) => {
  const [activeRecordName, setActiveRecordName] = useState(null);
  const { user } = useUserState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const handleUploadProgress = (progress) => {
    setUploadProgress(progress);
    setIsUploading(progress === -1 ? false : progress < 100);
  };

  const resetUpload = () => {
    setActiveRecordName(null);
    setUploadProgress(0);
    setIsUploading(false);
    setUploadFile([]);
    setTranscript(null);
    if (abortController) {
      abortController.abort(); // Use the stored AbortController to cancel the request
    }
  };

  const onChange = (key: SANDBOX_TABS) => {
    setActiveTab(key);
  };

  const TrialsLeftComponent = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <StyledBorderedTag
        style={{
          marginBottom: spacing.sm,
        }}
      >{`Trials Left :${user.trialsLeft}`}</StyledBorderedTag>
    </div>
  );

  const items: TabsProps["items"] = [
    {
      key: SANDBOX_TABS.SAMPLE_FILE,
      label: SANDBOX_TABS.SAMPLE_FILE,
      children: (
        <SandboxTabCard extra={showTrials && TrialsLeftComponent}>
          {activeRecordName ? (
            <ChangeAudioComponent
              activeRecordName={activeRecordName}
              isUploading={isUploading}
              uploadProgress={uploadProgress}
              loadingRun={loadingRun}
              resetUpload={resetUpload}
            />
          ) : (
            <StyledAudioCard
              hoverable
              id={SampleAudioFile.file_id}
              onClick={() => {
                setActiveRecordName(SampleAudioFile.name);
                setUploadFile([SampleAudioFile]);
              }}
            >
              <AudioOutlined />
              <p>{SampleAudioFile.name}</p>
            </StyledAudioCard>
          )}
        </SandboxTabCard>
      ),
    },
    {
      key: SANDBOX_TABS.OWN_FILE,
      label: SANDBOX_TABS.OWN_FILE,
      children: (
        <SandboxTabCard extra={showTrials && TrialsLeftComponent}>
          {activeRecordName ? (
            <ChangeAudioComponent
              activeRecordName={activeRecordName}
              isUploading={isUploading}
              uploadProgress={uploadProgress}
              loadingRun={loadingRun}
              resetUpload={resetUpload}
            />
          ) : (
            <DragDropFilesV2
              handleUploadProgress={handleUploadProgress}
              setUploadFile={setUploadFile}
              uploadedFiles={uploadedFile}
              setActiveAudio={setActiveRecordName}
              setAbortController={setAbortController}
            />
          )}
        </SandboxTabCard>
      ),
    },
  ];

  return (
    <Tabs defaultActiveKey={activeTab} items={items} onChange={onChange} />
  );
};
