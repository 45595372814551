import { Typography } from "antd";
import { elementSize, lineHeight, spacing } from "src/styles/variables";
import styled from "styled-components";

export const Logo = styled.img`
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => {
    switch (props.size) {
      case "small":
        return `${elementSize.xl}`;
      default:
        return `${elementSize.xlll}`;
    }
  }};
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoText = styled(Typography.Title)`
  font-size: ${(props) => {
    switch (props.size) {
      case "small":
        return `${elementSize.lg} `;
      default:
        return `${elementSize.xlll} `;
    }
  }} !important;
  letter-spacing: ${lineHeight.xxxs};
  margin: 0 !important;
  padding-left: ${spacing.xxxs};
`;
