import styled from "styled-components";
import { TimePicker } from "antd";
import {
  borderRadius,
  color,
  deviceSize,
  elementSize,
  spacing,
} from "src/styles/variables";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

export const SettingCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 15rem;
  height: 6.5rem;
  padding: ${elementSize.sm} ${elementSize.sm} 0px ${elementSize.sm};
  background-color: ${color.grayLight};
  border-radius: ${borderRadius.xxs};
`;
export const ScrollableContainer = styled.div`
  overflow-x: auto; // Enables horizontal scrolling
  width: 100%; // Ensure this container spans the entire width available to it
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none; // Optionally hide scrollbar for a cleaner look
  }
`;

export const SettingsContainer = styled.div`
  display: inline-flex; // Use inline-flex to naturally respect the combined width of children
  min-width: min-content; // Ensure container respects the natural width of its content
  gap: ${elementSize.md};
  padding: ${spacing.md} 0 ${spacing.xll};
  @media (max-width: ${deviceSize.large_phone}) {
    flex-direction: column;
  }
`;

export const TimeDisplay = styled.div`
  display: ${(props) =>
    props.visible
      ? "block"
      : "none"}; // Only display when the toggle is enabled
`;

export const StyledTimePicker = styled(TimePicker)`
  @media (max-width: ${deviceSize.large_phone}) {
    width: 5.3rem !important;
    padding: 1px 9px 1px;
  }
`;

export const ArrowLeft = styled(LeftCircleOutlined)`
  margin-right: 8px;
  cursor: pointer;
  color: ${color.orange};
  font-size: 24px;
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;

export const ArrowRight = styled(RightCircleOutlined)`
  margin-left: 8px;
  cursor: pointer;
  color: ${color.orange};
  font-size: 24px;
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;
