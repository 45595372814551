import React from "react";
import { NavigationBar } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { HomeContainer } from "./Home.styles";
import { StyledHeader } from "../LandingPage/LandingPage.styles";

import TabbedContainer from "./TabbedContainer/TabbedContainer";
import { useLocation, useParams } from "react-router-dom";
import PlaylistDetails from "../Playlist/PlaylistDetails/PlaylistDetails";
import FreeTrial from "./Forms/FreeTrialForm";

export const StandardPage = ({ content }) => {
  return (
    <HomeContainer>
      <StyledHeader>
        <NavigationBar />
      </StyledHeader>
      {content}
    </HomeContainer>
  );
};

export const Home = () => {
  const { organization, organizationSettings } = useOrganizationState();
  // const { user, setUser, needsToBookDemo } = useUserState();
  const location = useLocation();
  const { encryptedOrganizationId, encryptedPlaylistId } = useParams();
  const freeTrialStatus = organizationSettings?.stripe?.free_trial;

  let content;
  if (
    organization &&
    !(freeTrialStatus && freeTrialStatus.includes("awaiting"))
  ) {
    content = <TabbedContainer />;
  } else if (
    location.pathname.startsWith("/playlist") &&
    encryptedOrganizationId &&
    encryptedPlaylistId
  ) {
    content = <PlaylistDetails />;
  } else {
    content = <FreeTrial />;
  }

  return <StandardPage content={content} />;
};
