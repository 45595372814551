import { COLLECTION_DATA } from "src/utils/enums";
import { evaluationFormsTransform } from "src/utils/converter";
import useFetchCollection from "./useFetchCollection";

const useFetchAssignments = () => {
  const { items: assignments, fetchItems: fetchAssignments } =
    useFetchCollection(COLLECTION_DATA.ASSIGNMENTS, evaluationFormsTransform);

  return { assignments, fetchAssignments };
};

export default useFetchAssignments;
