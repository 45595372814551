import { DatePicker, notification } from "antd";
import { useState } from "react";
import { NotificationType } from "src/utils/enums";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { QueryConditions } from "src/utils/types";
import { useFiltersState } from "src/state/FiltersState";
import { useOrganizationState } from "src/state/OrganizationState";
import UnsubscribeManager from "src/utils/UnsubscribeManager";
import { DASHBOARD_TYPE } from "../Dashboard/DashboardActions/DashboardActions";

const { RangePicker } = DatePicker;

interface DateFilterProps {
  type: DASHBOARD_TYPE;
}

export const DateFilter: React.FC<DateFilterProps> = ({ type }) => {
  const [api] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    msgTitle: string,
    msgContent: string,
  ) => {
    api[type]({
      message: msgTitle,
      description: msgContent,
    });
  };

  const { filterDates, setFilterDate, setTranscriptsCondition } =
    useFiltersState();
  const { setInsights } = useOrganizationState();
  const [open, setOpen] = useState(false);
  const [emptyDate, setEmptyDate] = useState(false);
  const [selectedDates, setSelectedDates] = useState<
    [Dayjs | null, Dayjs | null] | null
  >(null);

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      const startOfWeek = dates[0].startOf("week");
      const endOfWeek = dates[1].endOf("week");

      setSelectedDates([startOfWeek, endOfWeek]); // Set the adjusted dates to state

      const newStartDate = startOfWeek.toISOString();
      const newEndDate = endOfWeek.toISOString();

      setEmptyDate(false);
      setOpen(false);

      const transcriptsConditionArray: QueryConditions[] = [
        {
          comparisonField: "transcript_data.metadata.transcriber.created",
          comparisonOperator: ">=",
          value: startOfWeek.startOf("day").toISOString(),
          order: "desc",
        },
        {
          comparisonField: "transcript_data.metadata.transcriber.created",
          comparisonOperator: "<=",
          value: endOfWeek.endOf("day").toISOString(),
        },
      ];

      UnsubscribeManager.getInstance().clearUnsubs(); // Clear all subscriptions
      setInsights((prevInsights) => ({
        ...prevInsights,
        loading: true,
      }));

      setTranscriptsCondition(type, transcriptsConditionArray);
      setFilterDate(type, { startDate: newStartDate, endDate: newEndDate });
    } else {
      !open && setOpen(true);
      setEmptyDate(true);
      openNotificationWithIcon(
        "error",
        "Please enter date range",
        "Start date and End date can't be empty",
      );
    }
  };

  const handleOpenChange = (status: boolean) => {
    setOpen(emptyDate ? true : status);
  };

  const handleCalendarChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      const startOfWeek = dates[0].startOf("week");
      const endOfWeek = dates[1].endOf("week");
      setSelectedDates([startOfWeek, endOfWeek]);
    }
  };

  return (
    <RangePicker
      picker="week"
      onChange={handleDateChange}
      onCalendarChange={handleCalendarChange}
      open={open}
      onOpenChange={handleOpenChange}
      value={
        selectedDates ?? [
          filterDates[type].startDate
            ? dayjs(filterDates[type].startDate)
            : undefined,
          filterDates[type].endDate
            ? dayjs(filterDates[type].endDate)
            : undefined,
        ]
      }
    />
  );
};
