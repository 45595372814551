import React, { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { getColumns } from "./Dashboard.utils";
import { Checkbox, Col, Divider, Drawer, Input, Row, message } from "antd";
import { useLocation } from "react-router-dom";
import { useOrganizationState } from "src/state/OrganizationState";
import { LOCALSTORAGE_TYPES } from "src/utils/enums";
import { color } from "src/styles/variables";
import { ControlOutlined } from "@ant-design/icons";
interface ConversationTableProps {
  data: any[];
  columns?: any[];
  showRisk: boolean;
  sx?: React.CSSProperties;
  handleRowClick?: (record: any) => void;
  pageSize?: number;
  enablePagination?: boolean;
}

export const ConversationTable: React.FC<ConversationTableProps> = ({
  data,
  columns = null,
  showRisk = true,
  sx = {},
  handleRowClick,
  pageSize = 15,
  enablePagination = true,
}) => {
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { organizationSettings } = useOrganizationState(); // Assuming organization details are available in user state

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.selectedColumn) {
      message.info(
        `Showing data regarding ${location.state.selectedColumn}.`,
        3,
      );
    }
  }, [location?.state?.selectedColumn]);

  const [selectedColumns, setSelectedColumns] = useState(() => {
    // Get initial selected column from location if available
    const locationColumn = location?.state?.selectedColumn
      ? [location.state.selectedColumn]
      : [];

    // Get saved columns from localStorage
    const savedColumns =
      JSON.parse(
        localStorage.getItem(LOCALSTORAGE_TYPES.CONVERSATION_TABLE_CONFIG),
      ) || [];

    // Combine and remove duplicates
    const uniqueColumns = Array.from(
      new Set([...locationColumn, ...savedColumns]),
    );

    return uniqueColumns;
  });

  columns =
    (!columns && data?.length > 0
      ? getColumns(data, showRisk, organizationSettings?.conversationProperties)
      : columns) || [];
  const defaultColumns = columns.filter((col) => !col.isDynamic);
  const analysisColumns = columns.filter((col) => col.isDynamic);
  const displayedColumns = [
    ...defaultColumns,
    ...analysisColumns.filter((col) => selectedColumns.includes(col.header)),
  ];

  const filteredColumns = analysisColumns.filter(
    (col) =>
      col.header && col.header.toLowerCase().includes(searchText.toLowerCase()),
  );

  const handleColumnSelectionChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
    localStorage.setItem(
      LOCALSTORAGE_TYPES.CONVERSATION_TABLE_CONFIG,
      JSON.stringify(checkedValues),
    );
  };

  return (
    <>
      <Drawer
        title="Select Conversation Columns"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        getContainer={false}
      >
        <Input
          placeholder="Search columns..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        {/* Custom Field Columns Section */}
        <Divider>Custom Fields</Divider>
        <Checkbox.Group
          options={filteredColumns
            .filter((col) => col.isCustomField) // Only custom field columns
            .map((col) => ({
              label: col.header,
              value: col.header,
            }))}
          value={selectedColumns}
          onChange={handleColumnSelectionChange}
        />
        {/* Analysis Columns Section */}
        <Divider>Analysis Columns</Divider>
        <Checkbox.Group
          options={filteredColumns
            .filter((col) => !col.isCustomField) // Only analysis columns
            .map((col) => ({
              label: col.header,
              value: col.header,
            }))}
          value={selectedColumns}
          onChange={handleColumnSelectionChange}
        />
      </Drawer>
      <MaterialReactTable
        enableStickyHeader
        columns={displayedColumns}
        data={data || []}
        enableRowSelection={false}
        enableTopToolbar
        enablePagination={enablePagination}
        onPaginationChange={enablePagination ? setPagination : undefined}
        onSortingChange={setSorting}
        rowCount={data?.length || 0}
        renderTopToolbar={() => (
          <Row justify="end" style={{ width: "100%" }}>
            <Col>
              <ControlOutlined
                onClick={() => setDrawerVisible(true)}
                rotate={90}
                style={{
                  fontSize: "26px",
                  cursor: "pointer",
                  color: color.orange,
                }}
              />
            </Col>
          </Row>
        )}
        muiTablePaperProps={{
          elevation: 0,
          sx: { boxShadow: "0" },
        }}
        state={{
          ...(enablePagination && { pagination }),
          sorting,
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowClick(row.original),
          sx: { cursor: "pointer", boxShadow: "0 0 0 0!important", ...sx },
        })}
      />
    </>
  );
};
