import React, { useState } from "react";
import { Space, Drawer } from "antd";
import { getOptions } from "src/utils/utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { notification } from "antd";
import {
  PaperClipOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import DropdownWithPillButton from "./DropdownWithPillButton";
import { QA_TYPES } from "src/utils/enums";
import { useMediaQuery } from "react-responsive";
import { deviceSize } from "src/styles/variables";

interface FiltersProps {
  transcripts?: any[];
  selectedValues?: any;
  setSelectedValues?: any;
  setShowFilter: (value: boolean) => void;
}

export const Filters: React.FC<FiltersProps> = ({
  transcripts,
  selectedValues,
  setSelectedValues,
  setShowFilter,
}) => {
  const [, contextHolder] = notification.useNotification();
  const { organizationSettings } = useOrganizationState();
  const [drawerVisible, setDrawerVisible] = useState(true);

  const isMobile = useMediaQuery({
    maxWidth: `${deviceSize.small_phone_size}`,
  });

  const handleSelectChange = (fieldName: string, selectedOption: any) => {
    setSelectedValues((prevSelectedValues) => {
      const updatedValues = {
        ...prevSelectedValues,
        [fieldName]: selectedOption,
      };

      return updatedValues;
    });
  };

  const getUniqueItems = (path) => {
    return transcripts
      ?.map((transcript) => {
        return path
          .split(".")
          .reduce(
            (acc, part) => (acc && acc[part] ? acc[part] : undefined),
            transcript,
          );
      })
      .filter(
        (value, index, self) =>
          value !== undefined && self.indexOf(value) === index,
      );
  };

  const userOptions = getOptions(
    getUniqueItems("agent")
      .concat(getUniqueItems("lead"))
      .concat(getUniqueItems("reviewer"))
      .filter((value) => {
        return value.email !== null;
      }),
    (user) => ({ label: user?.name, value: user?.email }),
  );

  const renderFilters = () => (
    <>
      <DropdownWithPillButton
        icon={<PaperClipOutlined />}
        text={"Evaluation Form"}
        selectedKeys={selectedValues?.evaluation_title}
        multiple
        options={getOptions(
          getUniqueItems("evaluation_form.title"),
          (title) => ({
            label: title || "Default",
            value: title,
          }),
        )}
        onSelectionChange={(selectedOption) =>
          handleSelectChange("evaluation_title", selectedOption)
        }
      />

      <DropdownWithPillButton
        multiple
        icon={<UserOutlined />}
        text={"User"}
        selectedKeys={selectedValues?.user}
        options={userOptions}
        onSelectionChange={(selectedOption) =>
          handleSelectChange("user", selectedOption)
        }
        limit={10}
        search
      />

      <DropdownWithPillButton
        multiple
        icon={<UsergroupAddOutlined />}
        selectedKeys={selectedValues?.team}
        text={"Team"}
        options={getOptions(organizationSettings?.teams)}
        onSelectionChange={(selectedOption) =>
          handleSelectChange("team", selectedOption)
        }
      />

      <DropdownWithPillButton
        icon={<SettingOutlined />}
        selectedKeys={selectedValues?.qaType}
        text={"Quality Type"}
        options={getOptions(Object.values(QA_TYPES))}
        onSelectionChange={(selectedOption) =>
          handleSelectChange("qaType", selectedOption)
        }
      />
    </>
  );

  return (
    <>
      {!isMobile && (
        <Space wrap style={{ padding: "8px 0px" }}>
          {contextHolder}

          {renderFilters()}
        </Space>
      )}
      {isMobile && (
        <>
          <Drawer
            title="Filters"
            placement="right"
            onClose={() => {
              setDrawerVisible(false);
              setShowFilter(false);
            }}
            open={drawerVisible}
          >
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              {renderFilters()}
            </Space>
          </Drawer>
        </>
      )}
    </>
  );
};
