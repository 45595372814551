import { Modal, Input, Button, message } from "antd";
import { useState, useEffect } from "react";
import { useUserState } from "src/state/UserState";
import { createBot } from "./Calendar.utils";

const InstantBotModal = ({ visible, onClose }) => {
  const [meetingUrl, setMeetingUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useUserState();

  const handleUrlChange = (e) => {
    setMeetingUrl(e.target.value);
  };

  const handleSubmit = async () => {
    if (!meetingUrl) {
      message.error("Please provide a meeting URL.");
      return;
    }

    setLoading(true);

    const botMetadata = {
      organization: user.organization,
      user: user.email, // Agent is the user's email
    };
    const metadata = {
      join_at: new Date().toISOString(), // Assume this is the structure, you can adjust it
    };

    try {
      // Call the scheduleInstantBot function
      const { success, error } = await createBot(
        meetingUrl, // Meeting URL provided by the user
        metadata, // Start time (current time or event time)
        botMetadata, // Metadata for the bot
        user.name, // Operator name from user data
      );

      if (success) {
        message.success("Bot scheduled successfully.");
        onClose(); // Close the modal if successful
      } else {
        console.log(error);
        message.error(
          `Failed to schedule bot: ${error?.meeting_url?.[0] || error}`,
        );
      }
    } catch (error) {
      message.error(`Failed to schedule bot: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  //   const handleSubmit = () => {
  //     console.log("Meeting URL submitted:", meetingUrl);
  //     onClose(); // Close the modal after submission
  //   };

  // Reset the URL when the modal is closed
  useEffect(() => {
    if (!visible) {
      setMeetingUrl(""); // Clean up the input when modal is closed
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      title={"Add Instant Bot to Meeting"}
      onCancel={onClose}
      footer={null}
    >
      <p>
        Paste your meeting URL from Zoom, Microsoft Teams or Google Meet below
        to add an instant bot.
      </p>
      <Input
        placeholder="Paste meeting URL"
        value={meetingUrl}
        onChange={handleUrlChange}
        style={{ marginBottom: "16px" }}
      />
      <Button type="primary" onClick={handleSubmit} loading={loading}>
        Add Truco NoteTaker to Meeting
      </Button>
    </Modal>
  );
};

export default InstantBotModal;
