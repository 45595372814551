import React from "react";
import { Card, List } from "antd";
import { TrucoParagraph, SearchLink } from "src/components";
import { color, spacing } from "src/styles/variables";

export interface Citation {
  id: string;
  url: string;
  title?: string;
  transcript?: string;
}

export const Citations: React.FC<{
  citations: Citation[];
  size?: string;
  deduplication_key?: string;
}> = ({ citations, size = "large", deduplication_key = "id" }) => {
  if (!citations) return <></>;
  if (deduplication_key) {
    citations = citations.filter(
      (citation, index, self) =>
        index ===
        self.findIndex(
          (c) => c?.[deduplication_key] === citation?.[deduplication_key],
        ),
    );
  }

  return (
    <>
      {size === "large" ? (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            lg: 2,
            xl: 3,
            xxl: 5,
          }}
          dataSource={citations}
          renderItem={(item) => (
            <List.Item>
              <Card>
                <List.Item.Meta
                  title={
                    <SearchLink
                      id={item.id}
                      url={item.url}
                      title={item.title}
                    />
                  }
                  description={
                    <TrucoParagraph maxNumLines={3}>
                      {item.transcript}
                    </TrucoParagraph>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <div style={{ display: "flex", gap: spacing.xxs }}>
          {citations.map((item, index) => (
            <div
              key={index}
              style={{
                width: spacing.lg,
                height: spacing.lg,
                color: color.white,
                borderRadius: "50%",
                backgroundColor: color.orange,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => (window.location.href = item.url)}
            >
              {index + 1}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
