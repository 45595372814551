import { addDocumentWithId, db, updateDocInFireStore } from "src/firebaseAuth";
import { COLLECTION_DATA } from "src/utils/enums";
import {
  doc,
  writeBatch,
  deleteDoc as firestoreDeleteDoc,
  deleteField,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { Calibration } from "src/utils/types";

export async function updateConversationsWithCalibration(
  organization: string,
  calibration: Calibration,
  calibrationId?: string,
) {
  const batch = writeBatch(db);
  const conversationsArray = calibration.conversations;

  for (const [convIndex, conversation] of conversationsArray.entries()) {
    const conversationRef = doc(
      db,
      `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${conversation.id}`,
    );

    if (calibrationId) {
      batch.update(conversationRef, {
        calibration: {
          id: calibrationId,
          reviewers: calibration.reviewers ?? [],
          excluded_reviewers: calibration.excludeReviewers ?? [],
          details: {},
        },
      });
    } else {
      const conversationDoc = await getDoc(conversationRef);
      const calibrationDocRef = doc(
        db,
        `/organization/${organization}/${COLLECTION_DATA.CALIBRATIONS}/${calibration.id}`,
      );
      if (conversationDoc.exists()) {
        const conversationData = conversationDoc.data();
        const calibrationData = conversationData?.calibration?.details;

        conversationsArray[convIndex] = {
          ...conversationsArray[convIndex],
          details: calibrationData,
        };

        await updateDoc(calibrationDocRef, {
          conversations: conversationsArray,
        });

        batch.update(conversationRef, {
          calibration: deleteField(),
        });
      }
    }
  }

  await batch.commit();
  console.log("Batch operation completed.");
}
export async function handleCalibration(
  api,
  isEditMode,
  organization,
  id,
  calibrationForm,
  navigate,
  toggleEditMode,
) {
  try {
    if (!isEditMode) {
      const newCalibrationId = await addDocumentWithId(
        `/organization/${organization}/${COLLECTION_DATA.CALIBRATIONS}`,
        calibrationForm,
      );
      try {
        await updateConversationsWithCalibration(
          organization,
          calibrationForm,
          newCalibrationId,
        );
        api.success({
          message: "Calibration Created Successfully",
        });
        navigate(`/calibrations/${newCalibrationId}`);
      } catch (error) {
        // Rollback the creation of the calibration document if the conversation update fails
        await firestoreDeleteDoc(
          doc(
            db,
            `/organization/${organization}/${COLLECTION_DATA.CALIBRATIONS}/${newCalibrationId}`,
          ),
        );
        throw new Error(
          "Failed to update conversations, calibration rollback. Try again",
        );
      }
    }
    toggleEditMode();
  } catch (err) {
    console.log(err);
    api.error({
      message: "Calibration Failed to save",
      description: err.message,
    });
  }
}

export async function updateCalibrationToDb(
  organization,
  calibration,
  api,
  update,
) {
  try {
    await updateDocInFireStore(
      `/organization/${organization}/${COLLECTION_DATA.CALIBRATIONS}/${calibration.id}`,
      update,
    );

    // clean up calibration details from conversations, keep only in calibration
    if ("isDone" in update) {
      await updateConversationsWithCalibration(organization, calibration);
    }

    api.success({
      message: "Calibration updated",
    });
  } catch (err) {
    console.log(err);
    api.error({
      message: "Calibration failed to update",
      description: err.message,
    });
  }
}

export const getRandomConversations = (
  numConversations,
  availableTranscripts,
) => {
  const randomConversations = availableTranscripts
    .sort(() => 0.5 - Math.random())
    .slice(0, numConversations)
    .map((row) => ({
      id: row.id,
      file_name: row.file_name,
      evaluation_form: {
        ...row.evaluation_form,
        id: row.evaluation_form?.id ?? null,
      },
    }));
  return randomConversations;
};
