import { Card, Col, Row, Tooltip } from "antd";
import { CustomizedEvaluationModal } from "src/components/Profile/Profile.styles";
import {
  createNewCategoryWithSubCategories,
  handleAddCategoryWithSubCategories,
  preDefinedCategories,
} from "../Evaluation.utils";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";
import { color, elementSize } from "src/styles/variables";
import {
  EvaluationPresetCategoryCard,
  PresetsScrollableContainer,
} from "../Evaluation.styles";

const { Meta } = Card;

type PresetCategoriesProps = {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  analysisStructure: any;
  setAnalysisStructures: any;
};

export const EvaluationPresetCategoriesModal: React.FC<
  PresetCategoriesProps
> = ({ openModal, setOpenModal, analysisStructure, setAnalysisStructures }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <CustomizedEvaluationModal
      width={"55vw"}
      key={`Categories-Preset-Modal`}
      title={`Preset Categories`}
      open={openModal}
      onCancel={handleClose}
      closable={true}
      footer={null}
    >
      <PresetsScrollableContainer>
        <Row gutter={[16, 16]}>
          {preDefinedCategories.map((preDefinedCategory) => {
            const { description, ...category } = preDefinedCategory;

            const isAdded = analysisStructure.some(
              (analysisCategory) => analysisCategory.id === category.id,
            );
            const metricsDescription = `${
              description ? description : ""
            } Metrics included: ${category.value
              .map((metric) => metric.label)
              .join(" - ")}`;

            return (
              <Col key={category.id} xs={24} sm={12} lg={8}>
                <EvaluationPresetCategoryCard
                  added={isAdded}
                  actions={[
                    isAdded ? (
                      <CheckOutlined
                        style={{ padding: elementSize.xs, color: color.orange }}
                      />
                    ) : (
                      <PlusOutlined
                        style={{ padding: elementSize.xs }}
                        onClick={() =>
                          handleAddCategoryWithSubCategories(
                            analysisStructure,
                            setAnalysisStructures,
                            createNewCategoryWithSubCategories(category),
                          )
                        }
                      />
                    ),
                  ]}
                >
                  <Tooltip title={metricsDescription}>
                    <Meta
                      title={category.label}
                      description={<span>{metricsDescription}</span>}
                    />
                  </Tooltip>
                </EvaluationPresetCategoryCard>
              </Col>
            );
          })}
        </Row>
      </PresetsScrollableContainer>
    </CustomizedEvaluationModal>
  );
};
