import React, { useState } from "react";
import { Typography, Input, Spin, Button, Form, message } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import MediaPlaylist from "./MediaPlaylist";

import { addClipToPlaylist } from "src/components/Playlist/Playlist.clip.utils";

import { UtterancesList } from "../ConversationModalBody/UtterancesList";
import { CONVERSATION_SUBMODAL } from "../ConversationModalContent";
import { addDocumentWithId } from "src/firebaseAuth";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import ShareLinkModal from "../ShareConversation/ShareClipPopUp";
import TagsDropDown from "./components/tagsDropdown";
import PlaylistsDropDown from "./components/playlistsDropdown";

interface AddToPlaylistContentProps {
  modalData: any;
  onClose: () => void;
  type: CONVERSATION_SUBMODAL;
}

const AddToPlaylistContent: React.FC<AddToPlaylistContentProps> = ({
  modalData,
  type = CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST,
  onClose,
}) => {
  const { organization, playlists } = useOrganizationState();

  const { user } = useUserState();
  const shouldFetchPlaylists = type === CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST;

  const ActionTitleMap: { [key: string]: string } = {
    [CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST]: "Add to Playlist",
    [CONVERSATION_SUBMODAL.CLIP]: "Clip Conversation",
  };
  const ButtonText: { [key: string]: string } = {
    [CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST]: "Add to Playlist",
    [CONVERSATION_SUBMODAL.CLIP]: "Share Clip",
  };

  const formattedTime = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date());
  const inputDefaultValue = `${modalData.file_name}-${formattedTime}`;

  const [inputValue, setInputValue] = useState<string>("");

  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(
    null,
  );

  const [disableButton, setDisableButton] = useState<boolean>(
    modalData.mimeType.includes("audio") || modalData.mimeType.includes("video")
      ? true
      : false,
  );
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [selectedUtterances, setSelectedUtterances] = useState<number[]>([]);
  const [summaryValue, setSummaryValue] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSummaryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setSummaryValue(event.target.value);
  };

  const getCommonData = (values: { title: string }) => {
    const { title } = values;
    const defaultTitle =
      !title || title.trim() === "" ? inputDefaultValue : title.trim();

    const participants = [
      modalData?.agent?.email,
      modalData?.lead?.email,
      modalData?.reviewer?.email,
    ]
      .filter((email) => !!email) // Filter out undefined or null values
      .filter((email, index, self) => self.indexOf(email) === index); // Ensure uniqueness

    const data: any = {
      title: defaultTitle,
      start: startTime,
      end: endTime,
      conversationId: modalData.id,
      user: user.email,
      mimeType: modalData.mimeType,
      updatedAt: new Date().toISOString(),
      participants: participants,
    };

    if (modalData?.mimeType.includes("text")) {
      const utterances = modalData?.utterances?.filter((_, index) =>
        selectedUtterances.includes(index),
      );
      data.utterances = utterances;
    }
    return data;
  };

  const resetModal = () => {
    setLoading(false);
    setInputValue("");
    setStartTime(0);
    setEndTime(0);
    setSelectedPlaylistId(null);
    onClose();
  };
  const handlePlaylistSubmit = (values: {
    title: string;
    playlistId: string;
    tags: string[];
  }) => {
    const { playlistId } = values;
    const data = getCommonData(values);
    data.summary = summaryValue;
    data.tags = selectedTags;
    setLoading(true);
    addClipToPlaylist(
      organization,
      modalData.id,
      playlistId,
      data.title,
      data,
    ).then(() => {
      resetModal();
    });
  };
  const handleShareClipSumbit = async (values: { title: string }) => {
    const data = getCommonData(values);
    data.organizationId = modalData?.organizationId || organization;
    if (
      modalData.mimeType.includes("audio") ||
      modalData.mimeType.includes("video")
    ) {
      const epsilon = 0.01; // Add a small tolerance for floating-point precision

      data.utterances = modalData?.utterances.filter(
        (utterance) =>
          utterance.start >= startTime - epsilon &&
          utterance.end <= endTime + epsilon,
      );
    }
    setLoading(true);
    try {
      const clipId = await addDocumentWithId(`${COLLECTION_DATA.CLIPS}`, data);
      // Construct the shareable URL
      const shareUrl = `${window.location.origin}/playlist?${QUERY_PARAMS.CLIP}=${clipId}&${QUERY_PARAMS.SHAREABLE_CLIP}=true`;

      // Close the current modal
      resetModal();
      ShareLinkModal({ shareUrl });
    } catch (error) {
      console.error("Error adding clip:", error);
      message.error("Failed to add clip. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleSelectChange = (index: number, checked: boolean) => {
    setSelectedUtterances((prev) => {
      let updatedSelection;
      if (checked) {
        updatedSelection = [...prev, index].sort((a, b) => a - b);
      } else {
        updatedSelection = prev.filter((i) => i !== index);
      }
      return updatedSelection;
    });
  };

  const handleUtteranceClick = (index: number) => {
    setSelectedUtterances((prev) => {
      const isAlreadySelected = prev.includes(index);
      let updatedSelection;
      if (isAlreadySelected) {
        updatedSelection = prev.filter((i) => i !== index);
      } else {
        updatedSelection = [...prev, index].sort((a, b) => a - b);
      }
      return updatedSelection;
    });
  };

  const getButtonDisabledState = () => {
    switch (type) {
      case CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST:
        return !playlists || disableButton;
      case CONVERSATION_SUBMODAL.CLIP:
      default:
        return disableButton;
    }
  };
  return (
    <Spin spinning={loading}>
      <Typography.Title level={4}>{ActionTitleMap[type]}</Typography.Title>

      {(modalData.mimeType.includes("audio") ||
        modalData.mimeType.includes("video")) && (
        <MediaPlaylist
          data={modalData}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          setDisable={setDisableButton}
        />
      )}
      <Form
        layout="vertical"
        initialValues={{
          title: inputDefaultValue,
          playlistId: selectedPlaylistId,
        }}
        onFinish={
          type === CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST
            ? handlePlaylistSubmit
            : handleShareClipSumbit
        }
      >
        {modalData.mimeType.includes("text") && (
          <Form.Item
            name="utterances"
            rules={[
              {
                validator: () =>
                  selectedUtterances.length > 0
                    ? Promise.resolve()
                    : Promise.reject("Please select at least one utterance"),
              },
            ]}
          >
            <UtterancesList
              utterances={modalData?.utterances}
              action={false}
              checkAction={true}
              setSelectedItems={setSelectedUtterances}
              handleSelectChange={handleSelectChange}
              selectedItems={selectedUtterances}
              handleUtteranceClick={handleUtteranceClick}
            />
          </Form.Item>
        )}

        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Title is required!" }]}
        >
          <Input value={inputValue} onChange={handleInputChange} />
        </Form.Item>

        {shouldFetchPlaylists && (
          <>
            <PlaylistsDropDown type={type} />
            <TagsDropDown
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
            />

            <Form.Item name="summary" label="Summary">
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                value={summaryValue}
                onChange={handleSummaryChange}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={getButtonDisabledState()}
          >
            {ButtonText[type]}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddToPlaylistContent;
