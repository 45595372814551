import React, { useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";
import { Row, Col, Spin, Tooltip, Dropdown, Button, Tabs } from "antd";
import {
  PlaylistCardContainer,
  PlaylistDescription,
  PlaylistTitle,
} from "./Playlist.styles";
import { MoreOutlined } from "@ant-design/icons";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { grayBoxShadow } from "src/styles/variables";
import { DeskTopOnly } from "src/styles/stylingComponents";
import { NewPlaylistModal } from "./AddPlaylist";
import { generatePlaylistShareableLink } from "./Playlist.utils";
import { formatDateToHumanReadable } from "src/utils/utils";
import { useNavigate } from "react-router-dom";
import { PlaylistMenu } from "./PlaylistDetails/PlaylistMenu";
import { SignalCard, PlaylistImageCard } from "./SignalCard";
import ClipsCard from "./ClipsCard";
import { useAddOrUpdatePlaylist } from "src/hooks/usePostData";

interface PlaylistCardProps {
  playlist: any;
}
const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlist }) => {
  const { organization } = useOrganizationState();
  const navigate = useNavigate();

  return (
    <PlaylistCardContainer>
      <PlaylistImageCard playlist={playlist} />
      <SpaceBetweenDiv>
        <Tooltip title={playlist.title}>
          <PlaylistTitle
            onClick={() => {
              navigate(
                `${generatePlaylistShareableLink(organization, playlist.playlistId)}`,
              );
            }}
          >
            {playlist.title}
          </PlaylistTitle>
        </Tooltip>
        <Dropdown
          overlay={
            <PlaylistMenu
              currentPlaylist={playlist}
              redirectToPlaylist={false}
              linkToCopy={`${window.location.origin}${generatePlaylistShareableLink(organization, playlist.playlistId)}`}
            />
          }
          trigger={["click"]}
          overlayStyle={{ boxShadow: grayBoxShadow }}
        >
          <MoreOutlined style={{ cursor: "pointer", fontSize: "16px" }} />
        </Dropdown>
      </SpaceBetweenDiv>
      <PlaylistDescription
        onClick={() => {
          navigate(
            `${generatePlaylistShareableLink(organization, playlist.playlistId)}`,
          );
        }}
      >
        Updated {formatDateToHumanReadable(playlist.updatedAt)}
      </PlaylistDescription>
    </PlaylistCardContainer>
  );
};

export const Playlist = () => {
  const { playlists, reloadPlaylists, loadingPlaylists } =
    useOrganizationState();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const addOrUpdatePlaylist = useAddOrUpdatePlaylist();

  const createPlaylist = async (newPlaylist) => {
    await addOrUpdatePlaylist(newPlaylist);
    reloadPlaylists();
  };

  const tabItems = [
    {
      key: "1",
      label: "Signal Playlists",
      children: (
        <Row gutter={[16, 16]}>
          <SignalCard />
        </Row>
      ),
    },
    {
      key: "2",
      label: "All Playlists",
      children: (
        <Spin spinning={loadingPlaylists}>
          <Row gutter={[16, 16]}>
            {playlists?.map((playlist) => (
              <Col
                key={playlist.playlistId}
                xs={12}
                sm={12}
                md={8}
                lg={6}
                xl={4}
              >
                <PlaylistCard playlist={playlist} />
              </Col>
            ))}
          </Row>
        </Spin>
      ),
    },
    {
      key: "3",
      label: "Your Clips",
      children: <ClipsCard />,
    },
  ];

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        tabBarStyle={{ paddingLeft: "12px", paddingRight: "12px" }} // Add padding to the tab navigation
        tabBarExtraContent={{
          right: (
            <SpaceBetweenDiv style={{ padding: "0px 12px" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <Button type="primary" onClick={() => setAddModalVisible(true)}>
                  + <DeskTopOnly>New Playlist</DeskTopOnly>
                </Button>
              </div>
            </SpaceBetweenDiv>
          ),
        }}
      />
      <NewPlaylistModal
        visible={addModalVisible}
        onCreate={createPlaylist}
        setAddModalVisible={setAddModalVisible}
      />
    </>
  );
};
