import { Typography } from "antd";
import {
  borderRadius,
  color,
  deviceSize,
  elementSize,
  fontSize,
  grayBoxShadow,
  lineHeight,
  spacing,
  hexToRgba,
} from "src/styles/variables";
import styled from "styled-components";

export const CardItem = styled.div`
  display: flex;
  border-radius: ${borderRadius.sm};
  align-items: center;
  text-align: start;
  margin-top: ${spacing.md};
  padding-left: ${spacing.xl};
  position: relative;
  background: ${color.white};
  box-shadow: ${grayBoxShadow};
  max-width: 60vw;
  min-width: 40vw;

  margin-left: ${(props) => props.marginLeft && props.marginLeft};

  @media (max-width: ${deviceSize.large_phone}) {
    &::before {
      display: none;
    }
    margin-left: 0px;
    max-width: 90vw;
    align-self: center;
  }

  @media (min-width: ${deviceSize.large_phone}) {
    @keyframes slideInFromRight {
      0% {
        transform: translateX(100%); /* Start off-screen to the right */
        opacity: 0;
      }
      100% {
        transform: translateX(0); /* Move into its final position */
        opacity: 1;
      }
    }
    opacity: 0; /* Initially hidden */
    animation-name: slideInFromRight;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards; /* Keeps the element in the final state after animation completes */
  }

  &::before {
    content: "";
    background-color: transparent;
    position: absolute;
    top: calc(50% - 4px);
    left: -${spacing.xxxll};
    width: ${elementSize.xxxxxl};
    height: 0;
    border-top: ${borderRadius.xs} dotted ${color.orange};
    z-index: 2;
  }
`;

export const CardBody = styled.div`
  padding: ${spacing.md};

  @media (max-width: ${deviceSize.large_phone}) {
    &::after {
      display: none;
    }
  }

  &::after {
    content: "";
    background-color: ${hexToRgba(color.orange, 0.5)};
    position: absolute;
    top: calc(50% - 10px);
    left: ${spacing.xxss};
    width: ${elementSize.ml};
    height: ${elementSize.ml};
    border-radius: 50%;
    z-index: 1;
  }
`;

export const CardImg = styled.div`
  img {
    width: ${(props) => props.width || elementSize.xxll};
  }
`;

export const CardTitle = styled(Typography.Title)`
  font-size: ${fontSize.displayXXL} !important;
  margin-bottom: ${lineHeight.sm} !important;
  color: ${color.orange} !important;

  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXXXL} !important;
  }
`;

export const CardSubTitle = styled(Typography.Paragraph)`
  font-size: ${fontSize.bodyLarge} !important;
  color: ${color.grayDark};
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXL} !important;
  }
`;
