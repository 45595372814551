import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "next-share";

import { ButtonsContainer } from "./SocialShareButtons.styles";

export const SocialShareButtons = ({ url, title, size = 24 }) => {
  const hashtags = "truco";

  return (
    <ButtonsContainer>
      <h4> Share this post</h4>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={size} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={title} hashtag={hashtags}>
        <FacebookIcon size={size} round />
      </FacebookShareButton>

      <WhatsappShareButton url={url} title={title} separator=":: ">
        <WhatsappIcon size={size} round />
      </WhatsappShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={size} round />
      </LinkedinShareButton>
    </ButtonsContainer>
  );
};
