import styled from "styled-components";
import {
  spacing,
  color,
  fontSize,
  borderRadius,
  hexToRgba,
  grayBoxShadow,
  deviceSize,
} from "src/styles/variables";
import { Button, Card } from "antd";

export const HomeContainer = styled.div`
  display: block;
  margin: auto;
`;

export const PageContainer = styled.div`
  border: 1px solid ${color.grayLight};
  min-height: 600px;
`;
export const NavContainer = styled.div`
  display: block;
  margin: ${spacing.xxll} 0px 0px 0px;
`;

export const NavItemContainer = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  margin: auto;
  border: 1px solid ${color.grayLight};
  align-items: center;
  padding: 0;
`;

export const NavItem = styled.li`
  font-size: ${fontSize.bodyLarge};
  padding: ${spacing.sm} ${spacing.md};

  > a[role="button"] {
    display: block;
    text-decoration: none;
    padding: ${spacing.sm};
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: ${borderRadius.sm};

    &:hover {
      background-color: ${hexToRgba(color.orange, 0.4)};
      color: ${color.white};
    }
    &.current {
      background-color: ${hexToRgba(color.orange, 1.0)};
      color: ${color.white};
    }
    @media (max-width: 500px) {
      font-size: 14px;
      padding: ${spacing.xs};
    }
  }
  @media (max-width: 500px) {
    padding: ${spacing.xs};
  }
`;

export const FormContainer = styled.div`
  padding: ${spacing.xl};
  > h3 {
    margin: ${spacing.xl} 0;
  }
`;

export const GrayCard = styled(Card)`
  background-color: ${hexToRgba(color.grayLight, 0.8)};
  box-shadow: ${grayBoxShadow};
  padding-top: ${spacing.xl};
  padding-bottom: ${spacing.xl};
`;

export const IframeDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
export const CloseButtonIcon = styled.button`
  position: absolute;
  top: -30px; // Adjust as needed to move outside of the iframe
  background-color: ${color.gray};
  z-index: 11;
  border: none;
  font-size: 24px;
  cursor: pointer;
  right: -30px;
  border-radius: 50%;
  box-shadow: ${grayBoxShadow};
  padding: ${spacing.xxs};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${deviceSize.large_phone}) {
    position: absolute;
    top: 2vh;
    padding: ${spacing.xs};
    right: 2vw;
    color: ${color.black};
    left: auto;
  }
`;
export const GlowingButton = styled(Button)`
  animation: glowing 1.5s infinite;

  @keyframes glowing {
    0% {
      box-shadow:
        0 0 5px ${color.grayMedium},
        0 0 10px ${color.grayMedium},
        0 0 20px ${color.grayMedium};
    }
    50% {
      box-shadow:
        0 0 20px ${color.grayMedium},
        0 0 30px ${color.grayMedium},
        0 0 40px ${color.grayMedium};
    }
    100% {
      box-shadow:
        0 0 5px ${color.grayMedium},
        0 0 10px ${color.grayMedium},
        0 0 20px ${color.grayMedium};
    }
  }
`;
