import {
  elementSize,
  fontSize,
  spacing,
  color,
  deviceSize,
} from "src/styles/variables";
import styled from "styled-components";

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoLabel = styled.div`
  font-size: ${fontSize.bodyMl};
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXLL};
  }
`;

export const InfoValue = styled.div`
  margin-bottom: ${elementSize.sm};
  font-size: ${fontSize.body};
  color: ${color.grayDark};
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXL};
  }
`;
