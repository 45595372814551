import { color, elementSize, fontWeight } from "src/styles/variables";
import { RATINGS, CONDITION_TYPES } from "src/utils/enums";
import { EvaluationFormData, UploadedFileType } from "src/utils/types";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { AudioFileIcon } from "../Loading/icons";
import { TrucoProgress } from "./TrucoSandbox.styles";
import { DivLink } from "../Global";
import { DeskTopOnly } from "src/styles/stylingComponents";
import { ExpandAltOutlined } from "@ant-design/icons";
export enum SANDBOX_TABS {
  OWN_FILE = "OWN FILE",
  SAMPLE_FILE = "SAMPLE FILE",
}

export const SampleAudioFile: UploadedFileType = {
  file_id: "1713719055214_sampleInboundCall.mp3",
  type: "audio/mpeg",
  name: "Sample Inbound Call",
};

// move to db when stable
export const SampleEvaluationFile: EvaluationFormData = {
  title: "Trial Evaluation Title",
  description: "Trial Evaluation Description",
  teams: ["Inbound Customer Support"],
  deleted: false,
  holdTime: 60,
  sentiment: true,
  talkRatio: 43,
  longestMonologue: 138,
  interactivity: 5,
  version: 1,
  analysisStructures: [
    {
      id: "customer_experience",
      label: "Customer Experience",
      weight: 1,
      value: [
        {
          id: "tone",
          label: "Tone",
          value: {
            type: "string",
            knowledge: "",
            conditions: [
              {
                condition:
                  "If the customer support agent used a friendly and polite tone with customer",
                value: "Positive",
                rating: RATINGS.ONE,
                type: CONDITION_TYPES.RETURN,
              },
              {
                condition:
                  "If the customer support agent did not use a friendly and polite tone with customer",
                value: "Negative",
                rating: RATINGS.ZERO,
                type: CONDITION_TYPES.RETURN,
              },
            ],
          },
        },
        {
          id: "greeting",
          label: "Greeting",
          value: {
            type: "string",
            knowledge: "",
            conditions: [
              {
                condition:
                  "Did customer support agent introduce themselves with name?",
                value: "Return the name",
                rating: RATINGS.ONE,
                type: CONDITION_TYPES.EXTRACT,
              },
              {
                condition: "If the agent didn't introduce themselves",
                value: "0",
                rating: RATINGS.ZERO,
                type: CONDITION_TYPES.RETURN,
              },
            ],
          },
        },
      ],
    },

    {
      id: "process_adherence",
      label: "Process Adherence",
      weight: 1,
      value: [
        {
          id: "customer_number",
          label: "Customer Number",
          value: {
            type: "string",
            knowledge: "",
            conditions: [
              {
                condition:
                  "Did agent confirm where the customer is calling from? ie phone number",
                value: "customer's number",
                rating: RATINGS.ONE,
                type: CONDITION_TYPES.EXTRACT,
              },
              {
                condition: "If the agent didn't confirm customer's number",
                value: "0",
                rating: RATINGS.ZERO,
                type: CONDITION_TYPES.RETURN,
              },
            ],
          },
        },
        {
          id: "customer_name",
          label: "Customer Name",
          value: {
            type: "string",
            knowledge: "",
            conditions: [
              {
                condition: "Did agent confirm customer's name?",
                value: "customer's name",
                rating: RATINGS.ONE,
                type: CONDITION_TYPES.EXTRACT,
              },
              {
                condition: "If the agent didn't confirm customer's name",
                value: "0",
                rating: RATINGS.ZERO,
                type: CONDITION_TYPES.RETURN,
              },
            ],
          },
        },
        {
          id: "customer_zipcode",
          label: "Customer Zipcode",
          value: {
            type: "string",
            knowledge: "",
            conditions: [
              {
                condition: "Did agent confirm customer's zip code?",
                value: "customer's zipcode",
                rating: RATINGS.ONE,
                type: CONDITION_TYPES.EXTRACT,
              },
              {
                condition: "If the agent didn't confirm customer's zipcode",
                value: "0",
                rating: RATINGS.ZERO,
                type: CONDITION_TYPES.RETURN,
              },
            ],
          },
        },
        {
          id: "customer_social",
          label: "Customer Social Security",
          value: {
            type: "string",
            knowledge: "",
            conditions: [
              {
                condition:
                  "Did agent confirm customer's last four digits of social security?",
                value: "customer's last four digits of social security",
                rating: RATINGS.ONE,
                type: CONDITION_TYPES.EXTRACT,
              },
              {
                condition:
                  "If the agent didn't confirm last four digits of social security",
                value: "0",
                rating: RATINGS.ZERO,
                type: CONDITION_TYPES.RETURN,
              },
            ],
          },
        },
      ],
    },
  ],
};

export const ChangeAudioComponent = ({
  activeRecordName,
  isUploading,
  uploadProgress,
  loadingRun,
  resetUpload,
}) => {
  return (
    <SpaceBetweenDiv style={{ gap: elementSize.xs }}>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: elementSize.xs,
          }}
        >
          <AudioFileIcon />
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "60vw",
            }}
          >
            {activeRecordName}
          </div>
        </div>
        {isUploading && (
          <TrucoProgress
            percent={uploadProgress}
            size="small"
            status="active"
          />
        )}
      </div>

      <DivLink
        style={{
          display: "flex",
          gap: elementSize.xs,
          color: color.orange,
          opacity: loadingRun ? "0.5" : "1",
          fontWeight: fontWeight.semiBold,
          cursor: loadingRun ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!loadingRun) {
            resetUpload();
          }
        }}
      >
        <DeskTopOnly>Change Audio File</DeskTopOnly>
        <ExpandAltOutlined />
      </DivLink>
    </SpaceBetweenDiv>
  );
};

export default ChangeAudioComponent;
