import React, { useMemo, useState } from "react";
import {
  Spin,
  Empty,
  Space,
  Typography,
  Progress,
  Breadcrumb,
  Select,
  Modal,
  Table,
  Tooltip,
} from "antd";
import { MergeCellsOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";

import { capitalizeFirstLetter } from "src/utils/utils";
import { color } from "src/styles/variables";
import { PlaylistClips } from "./PlaylistClips";
import { useFetchPlaylistTags, useFetchTagClips } from "src/hooks/useFetchData";
import {
  useDeleteClip,
  useDeleteTag,
  useMergeTags,
} from "src/hooks/usePostData";
import { DeleteWithPopConfirm } from "src/components/Global";

const { Option } = Select;

const TagsView = ({
  organizationId,
  playlistId,
  clips,
  playlistTitle,
  searchValue,
}) => {
  const {
    data: tags,
    loading,
    refetch,
  } = useFetchPlaylistTags(organizationId, playlistId);

  const [selectedTheme, setSelectedTheme] = useState(null);
  const [isMergeModalVisible, setIsMergeModalVisible] = useState(false);
  const [sourceTag, setSourceTag] = useState(null);
  const [targetTag, setTargetTag] = useState(null);

  const deleteClip = useDeleteClip();
  const deleteTag = useDeleteTag();
  const mergeTags = useMergeTags();

  const {
    data: themeClips,
    loading: clipsLoading,
    refetch: refetchThemeClips,
  } = useFetchTagClips(organizationId, playlistId, selectedTheme?.tag);

  const handleDeleteClip = async (clipId) => {
    await deleteClip(clipId, organizationId, playlistId);
    refetch();
    refetchThemeClips();
  };

  const handleTagClick = (theme) => {
    setSelectedTheme(theme);
  };

  const handleDeleteTag = async (tagName) => {
    await deleteTag(tagName, organizationId, playlistId);
    refetch();
  };

  const handleMergeTag = async () => {
    await mergeTags(sourceTag, targetTag, organizationId, playlistId);
    refetch();
    setIsMergeModalVisible(false);
  };

  const filteredTags = useMemo(() => {
    return tags?.filter((tag) =>
      tag.tag.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, tags]);

  // Define table columns with title and summary combined
  const columns: ColumnsType<any> = [
    {
      title: "Details",
      dataIndex: "tag",
      key: "details",
      render: (_, theme) => (
        <Space direction="vertical">
          <Typography.Link onClick={() => handleTagClick(theme)}>
            {capitalizeFirstLetter(theme.tag)}
          </Typography.Link>
          <Typography.Text type="secondary">
            {capitalizeFirstLetter(theme.summary || "No summary")}
          </Typography.Text>
        </Space>
      ),
      align: "left",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      render: (count) => <Typography.Text>{count}</Typography.Text>,
      align: "center",
    },
    {
      title: "Percentage",
      dataIndex: "count",
      key: "percentage",
      render: (count) => (
        <Progress
          type="circle"
          percent={Math.floor((count / clips) * 100)}
          width={40}
          strokeColor="#fa8c16"
        />
      ),
      responsive: ["md"], // Only show on medium and larger screens
      align: "center",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, theme) => (
        <Space>
          <Tooltip title="Delete themes">
            {DeleteWithPopConfirm(
              "Delete Tag",
              "Are you sure you want to delete this tag?",
              (e) => {
                e.stopPropagation();
                e.preventDefault();
                handleDeleteTag(theme.tag);
              },
            )}
          </Tooltip>

          <Tooltip title="Merge one or more themes">
            <MergeCellsOutlined
              style={{ color: color.orange }}
              onClick={() => {
                setIsMergeModalVisible(true);
                setSourceTag(theme.tag);
              }}
            />
          </Tooltip>
        </Space>
      ),
      align: "center",
    },
  ];

  return (
    <>
      <Spin spinning={loading || clipsLoading}>
        {filteredTags?.length === 0 ? (
          <Empty description="No Tags found!" />
        ) : selectedTheme ? (
          <>
            <Breadcrumb style={{ cursor: "pointer", marginBottom: 16 }}>
              <Breadcrumb.Item onClick={() => setSelectedTheme(null)}>
                {playlistTitle}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{selectedTheme.tag}</Breadcrumb.Item>
            </Breadcrumb>
            <PlaylistClips
              clips={themeClips}
              playlistId={playlistId}
              handleDeleteClip={handleDeleteClip}
            />
          </>
        ) : (
          <Table
            dataSource={filteredTags}
            columns={columns}
            rowKey={(theme) => theme.tag}
            pagination={false}
            size="small"
          />
        )}
      </Spin>

      {/* Modal for merging tags */}
      <Modal
        title={`Merge "${sourceTag}" Into`}
        visible={isMergeModalVisible}
        onOk={handleMergeTag}
        onCancel={() => setIsMergeModalVisible(false)}
      >
        <Select
          placeholder="Select a tag to merge into"
          style={{ width: "100%" }}
          onChange={(value) => setTargetTag(value)}
        >
          {tags
            ?.filter((tag) => tag.tag !== sourceTag)
            .map((tag) => (
              <Option key={tag.tag} value={tag.tag}>
                {tag.tag}
              </Option>
            ))}
        </Select>
      </Modal>
    </>
  );
};

export default TagsView;
