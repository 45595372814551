import { updateDocInFireStore, db } from "src/firebaseAuth";
import { playlistConverter } from "src/utils/converter";
import { COLLECTION_DATA, PLAYLIST_ACCESS, ACCESS_TYPE } from "src/utils/enums";
import {
  LockOutlined,
  ApartmentOutlined,
  GlobalOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { fetchFileUrl } from "../Dashboard/Dashboard.utils";
import {
  and,
  arrayUnion,
  collection,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { encryptId } from "src/utils/utils";

export const getVisibilityIcon = (visibility, style = {}) => {
  const icons = {
    [PLAYLIST_ACCESS.PRIVATE]: <LockOutlined style={style} />,
    [PLAYLIST_ACCESS.ORGANIZATION]: <ApartmentOutlined style={style} />,
    [PLAYLIST_ACCESS.PUBLIC]: <GlobalOutlined style={style} />,
    [PLAYLIST_ACCESS.CUSTOM]: <UserAddOutlined style={style} />,
  };

  return icons[visibility];
};

export const addPlaylistToTranscript = async (
  organization: string,
  conversationId: string,
  newPlaylist: {
    id: string;
    title: string;
    type: string;
    summary?: string;
    original_summary?: string;
    clipId: string;
  },
) => {
  const queryPath = `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${conversationId}`;
  try {
    await updateDocInFireStore(queryPath, {
      playlists: arrayUnion(newPlaylist),
    });
  } catch (error) {
    console.error("Error updating playlist in Firestore: ", error);
  }
};

export const getThumbnailSource = async (playlist) => {
  if (playlist.img) {
    return { type: "image", src: playlist.img };
  }
  const videoClip = playlist.clips?.find((clip) =>
    getMimeTypeFix(clip.mimeType).includes("video"),
  );
  if (videoClip) {
    if (videoClip.url) {
      return {
        type: videoClip.mimeType,
        src: `${videoClip.url}#t=${Math.floor(videoClip.start + 2)}`,
        // src: `${videoClip.url}#t=${Math.floor(
        //   (videoClip.end - videoClip.start) / 2,
        // )}`,
      };
    }
    try {
      const url = await fetchFileUrl(videoClip.conversationId);
      videoClip.url = url;
      return {
        type: videoClip.mimeType,
        src: `${url}#t=${Math.floor(videoClip.start + 2)}`,
        // src: `${url}#t=${Math.floor((videoClip.end - videoClip.start) / 2)}`,
      };
    } catch (error) {
      console.error("Error fetching file URL: ", error);
      return { type: "image", src: "/playlist.jpeg" };
    }
  }
  return { type: "image", src: "/playlist.jpeg" };
};

export const fetchClipUrl = async (clip) => {
  if (clip.url) {
    return clip;
  }
  try {
    const url = await fetchFileUrl(clip.conversationId);
    return { ...clip, url };
  } catch (error) {
    console.error(`Error fetching URL for clip ${clip.conversationId}:`, error);
    return clip;
  }
};

export const fetchAllClipUrls = async (clips) => {
  const batchSize = 5;
  const batches = [];

  for (let i = 0; i < clips.length; i += batchSize) {
    const batch = clips.slice(i, i + batchSize).map(fetchClipUrl);
    batches.push(Promise.all(batch));
  }

  const results = await Promise.all(batches);
  return results.flat();
};
export const getUserPlaylistsFromFireStore = async (
  organizationId,
  user,
  limitDocs = null,
  startAfterDoc = null, // Add this parameter to handle pagination
) => {
  try {
    const collectionPath = `organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}`;
    let collectionRef = collection(db, collectionPath).withConverter(
      playlistConverter,
    );
    const baseConditions = and(
      where("deleted", "==", false),
      or(
        where("createdBy", "==", user.email),
        where("visibility", "in", [
          PLAYLIST_ACCESS.PUBLIC,
          PLAYLIST_ACCESS.ORGANIZATION,
        ]),
      ),
    );
    let docQuery = query(
      collectionRef,
      baseConditions,
      orderBy("updatedAt", "desc"),
    );

    if (limitDocs) {
      docQuery = query(docQuery, limit(limitDocs));
    }
    if (startAfterDoc) {
      docQuery = query(docQuery, startAfter(startAfterDoc));
    }
    const documentSnapshots = await getDocs(docQuery);
    const data = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return {
      data,
      lastDoc: documentSnapshots.docs[documentSnapshots.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching playlists:", error);
    return {
      data: [],
      lastDoc: null,
    };
  }
};

export const generatePlaylistShareableLink = (organizationId, playlistId) => {
  const encryptedOrganizationId = encryptId(organizationId);
  const encryptedPlaylistId = encryptId(playlistId);
  return `/playlist/${encryptedOrganizationId}/${encryptedPlaylistId}`;
};

export const hasEditAccess = (playlist, user) => {
  const isCreator = !playlist?.createdBy || playlist?.createdBy === user?.email;
  const isOrgAccessible = playlist.editability === PLAYLIST_ACCESS.ORGANIZATION;
  const isPrivateAccessible =
    playlist.editability === PLAYLIST_ACCESS.PRIVATE && isCreator;
  const isCustomAccessible =
    playlist.editability === PLAYLIST_ACCESS.CUSTOM &&
    playlist.customList?.includes(user.email);
  return (
    isCreator || isOrgAccessible || isPrivateAccessible || isCustomAccessible
  );
};

export const filterPlaylists = (data, user) => {
  if (!data) return [];

  return data.filter((playlist) => hasEditAccess(playlist, user));
};

export const generateAccessOptions = (organizationName) => {
  const generalAccessOptions = [
    {
      value: PLAYLIST_ACCESS.PRIVATE,
      label: "Only You",
      icon: PLAYLIST_ACCESS.PRIVATE,
    },
    {
      value: PLAYLIST_ACCESS.ORGANIZATION,
      label: organizationName,
      icon: PLAYLIST_ACCESS.ORGANIZATION,
    },
    {
      value: PLAYLIST_ACCESS.PUBLIC,
      label: "Public",
      icon: PLAYLIST_ACCESS.PUBLIC,
    },
  ];

  const editAccessOptions = [
    {
      value: PLAYLIST_ACCESS.PRIVATE,
      label: "Only You",
      icon: PLAYLIST_ACCESS.PRIVATE,
    },
    {
      value: PLAYLIST_ACCESS.ORGANIZATION,
      label: organizationName,
      icon: PLAYLIST_ACCESS.ORGANIZATION,
    },
    {
      value: PLAYLIST_ACCESS.CUSTOM,
      label: "Custom",
      icon: PLAYLIST_ACCESS.CUSTOM,
    },
  ];

  return { generalAccessOptions, editAccessOptions };
};

export const hasPlaylistAccess = (user, organization, playlist) => {
  const hasAccess =
    playlist?.createdBy === user?.email ||
    playlist?.visibility === PLAYLIST_ACCESS.PUBLIC ||
    (playlist?.visibility === PLAYLIST_ACCESS.ORGANIZATION &&
      user?.organization === organization) ||
    user?.accessType === ACCESS_TYPE.trucoAdmin;

  return hasAccess;
};

// to remove
export function getMimeTypeFix(mimeType) {
  if (typeof mimeType === "string") {
    return mimeType;
  } else if (
    mimeType &&
    typeof mimeType === "object" &&
    "mime_type" in mimeType
  ) {
    return mimeType.mime_type;
  }
  return null; // Return null if no valid mimeType is found
}
