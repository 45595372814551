import React from "react";
import { Row, Col, Space, Typography, Divider } from "antd";
import styled from "styled-components";
import { color, spacing, maxWidth, hexToRgba } from "src/styles/variables";
import TrucoLogo from "src/components/TrucoLogo/TrucoLogo";
import { LinkedinOutlined, YoutubeOutlined } from "@ant-design/icons";

const { Text, Link, Title } = Typography;

const FooterContainer = styled.footer`
  padding: ${spacing.lg} ${spacing.xl};
  color: ${color.white};

  max-width: ${maxWidth};
  margin: auto;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  margin-top: ${spacing.md};

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const FooterSection = styled.div`
  margin-bottom: ${spacing.md};
  text-align: center;
  vertical-align: top;
`;

const InvestorLogo = styled.img`
  height: 30px;
  max-width: 200px;
  margin: ${spacing.xs};
  object-fit: contain;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 576px) {
    height: 40px;
    max-width: 120px;
    margin: ${spacing.xxs};
  }
`;

const SectionTitle = styled(Title)`
  &.ant-typography {
    color: white;
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const FooterLink = styled(Link)`
  &&& {
    color: rgba(255, 255, 255, 0.65);
    display: block;
    margin-bottom: 12px;

    &:hover {
      color: white;
    }
  }
`;

const SocialLinks = styled.a`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  text-align: center;
  line-height: 30px;
  margin-right: 8px;
  font-size: 20px;
`;

export const Footer = () => {
  const investorLogos = [
    {
      name: "AIX Ventures",
      src: `${process.env.PUBLIC_URL}/icons/AIXVenturesLogo.jpeg`,
      href: "https://www.aixventures.com/",
    },
    {
      name: "Firestreak Ventures",
      src: `${process.env.PUBLIC_URL}/icons/fs-logo.png`,
      href: "https://www.firestreak.com/",
    },
    {
      name: "Founders You Should Know",
      src: `${process.env.PUBLIC_URL}/icons/fysk-logo.jpeg`,
      href: "https://foundersysk.com/",
    },
    {
      name: "Team Ignite Ventures",
      src: `${process.env.PUBLIC_URL}/icons/team-ignite-logo.jpeg`,
      href: "https://www.teamignite.ventures/",
    },
  ];

  return (
    <FooterContainer>
      <Divider style={{ borderColor: hexToRgba(color.white, 0.2) }} />
      <Row gutter={[32, 32]}>
        <Col xs={24} md={6}>
          <LogoWrapper>
            <TrucoLogo size="medium" />
          </LogoWrapper>
          <Text
            style={{
              color: "rgba(255, 255, 255, 0.65)",
              display: "block",
              textAlign: window.innerWidth <= 768 ? "center" : "left",
              margin: `${spacing.md} 0`,
            }}
          >
            © {new Date().getFullYear()}. Truco Ventures, Inc.
          </Text>
          <div
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "left" }}
          >
            {[
              {
                href: "https://www.linkedin.com/company/truco-ai",
                icon: <LinkedinOutlined />,
              },
              {
                href: "https://www.youtube.com/@truco_ai",
                icon: <YoutubeOutlined />,
              },
            ].map((social, index) => (
              <SocialLinks
                key={index}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.icon}
              </SocialLinks>
            ))}
          </div>
        </Col>

        <Col xs={24} md={6}>
          <FooterSection>
            <SectionTitle level={5}>Site</SectionTitle>
            <FooterLink href="/blogs">Blog</FooterLink>
            <FooterLink href="https://docs.truco.ai/">Documentation</FooterLink>
            <FooterLink href="/signin">Sign In</FooterLink>
          </FooterSection>
        </Col>

        <Col xs={24} md={6}>
          <FooterSection>
            <SectionTitle level={5}>Resources</SectionTitle>
            <FooterLink href="mailto:swetha@truco.ai">Contact Us</FooterLink>
            <FooterLink href="/roi">ROI Calculator</FooterLink>
            <FooterLink href="/privacy">Privacy Policy</FooterLink>
          </FooterSection>
        </Col>

        <Col xs={24} md={6}>
          <FooterSection>
            <SectionTitle level={5}>Proudly Backed By</SectionTitle>
            <Space wrap>
              {investorLogos.map((investor, index) => (
                <a
                  href={investor.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InvestorLogo
                    key={index}
                    src={investor.src}
                    alt={investor.name}
                  />
                </a>
              ))}
            </Space>
          </FooterSection>
        </Col>
      </Row>
    </FooterContainer>
  );
};
