import { OrderByDirection } from "firebase/firestore/lite";

export enum COOKIE_TYPE {
  USER_COOKIE = "firebaseAuth",
  CALENDAR_INTERGATION_TOUR = "viewedCalendarTour",
}
export enum LOCALSTORAGE_TYPES {
  ORGANIZATION_SETTINGS = "organizationSettings",
  NEEDS_DEMO = "needsToBookDemo",
  CUSTOMERS_TABLE_CONFIG = "customers_table_config",
  ACCOUNTS_TABLE_CONFIG = "accounts_table_config",
  CONVERSATION_TABLE_CONFIG = "conversations_table_config",
}

export enum QUERY_PARAMS {
  SEND_FORGET_PASSWORD_MAIL = "sendForgetMail",
  FORGET_PASSWORD = "forgetPassword",
  INVITATION_CODE = "invitationCode",
  CONVERSATION = "conversation",
  CONVERSATION_SCOPE = "conversationScope",
  CUSTOMER = "customer",
  ACCOUNT = "account",
  CLIP = "clip",
  SHAREABLE_CLIP = "shareableClip",
  USER = "user",
}
export enum MODAL_TYPES {
  CONVERSATION = "conversation",
  CUSTOMER = "customer",
  ACCOUNT = "account",
  CLIP = "clip",
  USER = "user",
}

export enum COLLECTION_DATA {
  CONVERSATIONS = "conversations",
  ACCOUNTS = "accounts",
  CUSTOMERS = "customers",
  ANALYSIS = "analysis",
  EVALUATION_FORMS = "evaluationForms",
  HISTORY = "history",
  INVITATIONS = "invitations",
  USERS = "users",
  CHATS = "chats",
  INSIGHTS = "insights",
  ASSIGNMENTS = "assignments",
  CALIBRATIONS = "calibrations",
  PLAYLISTS = "playlists",
  SIGNALS = "signals",
  ORGANIZATIONS = "organization",
  CLIPS = "clips",
}

export enum TIME_INTERVAL {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  THIS_WEEK = "This Week",
  THIS_MONTH = "This Month",
  THIS_YEAR = "This Year",
  LAST_WEEK = "Last Week",
  LAST_MONTH = "Last Month",
  LAST_YEAR = "Last Year",
  PREVIOUS_30_DAYS = "Previous 30 Days",
  // ALL = "All",
}
export enum FREE_TRIAL_STATUS {
  awaitingIntegration = "awaitingIntegration",
  awaitingStripe = "awaitingStripe",
  set = "set",
}

export enum ACCESS_TYPE {
  agent = "agent",
  trucoAdmin = "trucoAdmin",
  owner = "owner",
  lead = "lead",
  reviewer = "reviewer",
}
export enum SENTIMENT {
  POSITIVE = "positive",
  NEUTRAL = "neutral",
  NEGATIVE = "negative",
}

export type NotificationType = "success" | "info" | "warning" | "error";
export type ComparisonType = "rating" | "value";

export enum DOCUMENT_STATE {
  EDIT = "Edit",
  ADD = "Add",
}

export enum CALENDAR_BOOKING_STATUS {
  BOOKING_SUCCESSFUL = "bookingSuccessful",
}

export enum RATINGS {
  ZERO = 0,
  ONE = 1,
  NONE = null,
}
export enum RATING_VALUES {
  ZERO = "0",
  ONE = "1",
  NONE = "N/A",
}

export enum CONDITION_TYPES {
  RETURN = "return",
  EXTRACT = "extract",
}

export enum TRANSCRIPT_LABELS {
  SENTIMENTS = "Sentiments",
  TOPICS = "Topics",
}

export enum ANALYSIS_TYPE {
  RATING = "rating",
  VALUE = "value",
}

export enum ANALYSIS_LABEL {
  SCORE = "score",
  OVERALL = "overall",
}

export enum ANALYSIS_ID {
  OVERALL = "Overall",
}

export enum EVALUATION_SETTINGS {
  HANDLE_TIME = "handleTime",
  HOLD_TIME = "holdTime",
  SENTIMENT = "sentiment",
  TALK_RATIO = "talkRatio",
  LONGEST_MONOLOGUE = "longestMonologue",
  INTERACTIVITY = "interactivity",
}

export enum STORAGE_TYPE {
  GCS = "gcs",
  GDRIVE = "gdrive",
}
export enum QA_TYPES {
  ALL = "All",
  AUTO_QA = "Auto QA",
  MANUAL_QA = "Manual QA",
}

export enum CONVERSATION_ACCESS {
  PUBLIC = "public",
  ORGANIZATION = "organization",
}

export enum PLAYLIST_ACCESS {
  PUBLIC = "public",
  PRIVATE = "private",
  ORGANIZATION = "organization",
  CUSTOM = "custom",
}

export enum PLAYLIST_TYPE {
  STANDARD = "standard",
  SIGNAL = "signal", // private
}

export const ORDER_BY_DIRECTION = {
  DESC: "desc" as OrderByDirection,
  ASC: "asc" as OrderByDirection,
};
