import { Input, InputNumber } from "antd";
import { color, spacing } from "src/styles/variables";
import styled from "styled-components";

export const FloatingLabelInputWrapper = styled.div`
  position: relative;
`;

export const StyledAntInput = styled(Input)`
  &.ant-input {
    height: auto;
    padding: ${spacing.xss} ${spacing.md};
    border-color: ${(props) => (props.error ? color.red : "")} !important;
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
  &:focus {
    border-color: ${color.olive};
    box-shadow: none;
  }
  &:hover {
    border-color: ${color.olive};
  }
  &:focus + label {
    ${color.orange}
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -8px;
    font-size: 12px;
    color: ${color.grayDark};
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
  &.ant-input-disabled,
  :where(.css-dev-only-do-not-override-pr0fja).ant-input[disabled] {
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
`;

export const StyledAntInputNumber = styled(InputNumber)`
  &.ant-input-number {
    border-color: ${(props) => (props.error ? color.red : "")} !important;
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
  &:focus,
  &.ant-input-number-focused {
    border-color: ${color.olive} !important;
    box-shadow: none;
  }
  &:hover {
    border-color: ${color.olive};
  }
  &:focus + label {
    ${color.orange}
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -8px;
    font-size: 12px;
    color: ${color.grayDark};
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
  &.ant-input-disabled,
  :where(.css-dev-only-do-not-override-pr0fja).ant-input[disabled] {
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
`;

export const StyledAntTextArea = styled(Input.TextArea)`
  &.ant-input {
    padding: ${spacing.s} ${spacing.md};
    border-color: ${(props) => (props.error ? color.red : "")} !important;
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
  &:focus {
    border-color: ${color.olive};
    box-shadow: none;
  }
  &:hover {
    border-color: ${color.olive};
  }
  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -8px;
    font-size: 12px;
    color: ${color.grayDark};
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
  &.ant-input-disabled,
  :where(.css-dev-only-do-not-override-pr0fja).ant-input[disabled] {
    background-color: ${(props) =>
      props.backgroundcolor ? props.backgroundcolor : color.white};
  }
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 0.5rem;
  left: 11px;
  color: ${color.gray};
  transition: all 0.3s;
  pointer-events: none;
  font-size: 14px;
  padding: 0 4px;
`;

export const DivFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxs};
  color: ${color.black};
`;
