import React, { useState, useEffect } from "react";

import { Row, Col, Card, Typography, Avatar, Rate } from "antd";
import {
  SectionContainer,
  GradientOrangeSpan,
  CustomerAvatarCard,
  ReviewText,
  ArrowButtonStyle,
  ArrowButtonsContainer,
  StyledSwiper,
} from "./LandingPage.styles";
import { fontSize, color, spacing } from "src/styles/variables";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { BlogPostPage } from "./BlogPage/BlogPage";
import { processBlogs } from "./BlogPage/BlogPage.utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

interface CustomerStoryDetails {
  title: string;
  description: string;
  photoURL: string;
  review: string;
  rating: number;
  href: string;
}

export const CustomerStories = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const data: CustomerStoryDetails[] = [
    {
      title: "Jose Carranza",
      description: "Director of Sales, Ergeon",
      photoURL: "/jose_carranza.jpeg",
      review: `Truco gives us deep visibility into sales performance, customer intent, and compliance. We now have data-backed insights to
      identify coaching opportunities for our sales team, optimize our
      sales processes, and ensure we are meeting our customers' needs.
      Truco platform has been easy to use, personalize and extremely
      valuable. Excited to continue leveraging it to drive improvement
      in sales effectiveness and the customer experience.`,
      rating: 5,
      href: "/ergeon-case-study",
    },
    {
      title: "Jeremy Davidson",
      description: "Head of Customer Experience",
      photoURL: "/jeremy_davidson.jpeg",
      rating: 5,
      href: "/playlist/U2FsdGVkX1%2BAEWWKEowfB2Y4kOuatFkyqhfncOylbnMmUXidtL50e5ogVM64JXlx/U2FsdGVkX18z3oMkmMq%2FP9rHQwPk1%2Fl23h0ET%2Fb6RQXUt4ortT1LE%2B33OOSsycT4?clip=106636e4-be8b-40f6-936c-cf53c4b65786",
      review:
        "If you haven't checked out Truco, you are leaving $$ on the table!!! For the cost of 1/2 a QA Analyst, their AI engine can review EVERY support case created and provide insights that can drive both customer success efforts as well as product enhancements! It's so cool!",
    },
    {
      title: "Neftali Belmonte",
      description: "Senior QA analyst, Ergeon",
      photoURL: "/neftali_belmonte.jpeg",
      review: `We use Truco to assess sales conversations and confirm that leads are aligned with contractual expectations, automatically. It improved our QA coverage by 5x, saved us hundreds of hours while reducing risk. It’s game changing!`,
      rating: 5,
      href: "/ergeon-case-study",
    },
  ];
  return (
    <SectionContainer>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          padding: "10px",
        }}
      >
        <Col xl={10} md={22} xs={22}>
          <Typography.Title
            level={4}
            style={{
              fontSize: fontSize.displayXXL,
            }}
          >
            Customer Success Stories
          </Typography.Title>

          <Typography.Title
            level={1}
            style={{
              fontSize: fontSize.displayXXXXL,
              marginTop: 0,
            }}
          >
            Unlock agent excellence and visibility with{" "}
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </Typography.Title>

          <Typography.Link
            href={data[activeIndex]?.href}
            style={{
              fontSize: fontSize.displayXXL,
            }}
          >
            Read full customer story &rarr;{" "}
          </Typography.Link>
          {data.length > 1 && (
            <ArrowButtonsContainer>
              <ArrowButtonStyle className="swiper-button-prev">
                <ArrowBack />
              </ArrowButtonStyle>
              <ArrowButtonStyle className="swiper-button-next">
                <ArrowForward />
              </ArrowButtonStyle>
            </ArrowButtonsContainer>
          )}
        </Col>
        <Col xl={8} md={22} xs={22}>
          <StyledSwiper>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards, Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              className="mySwiper"
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              {data.map((item, index) => (
                <SwiperSlide key={`swiper-${index}`}>
                  <Card style={{ height: "400px" }}>
                    <CustomerAvatarCard
                      avatar={
                        <Avatar src={process.env.PUBLIC_URL + item.photoURL} /> // because this is a path we don't need the other avatar
                      }
                      title={item.title}
                      description={item.description}
                      style={{ marginBottom: spacing.md }}
                    />
                    <ReviewText>{item.review}</ReviewText>
                    <Rate
                      defaultValue={item.rating}
                      disabled
                      style={{ color: color.lightYellow }}
                    />
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </StyledSwiper>
        </Col>
      </Row>
    </SectionContainer>
  );
};

export const CustomerStory = (company) => {
  const [customerStories, setCustomerStories] = useState([]);

  useEffect(() => {
    const customerStoriesRaw = [
      {
        image: {
          thumbnail_url: process.env.PUBLIC_URL + "/icons/ergeon_icon.png",
          description: "Ergeon and Truco partnership",
          redirect_url: "https://www.ergeon.com/",
        },
        date: "February 6th, 2024",
        title:
          "Ergeon unlocks agent excellence and operational efficiency with Truco",
        url: "/ergeon-case-study",
        markDownPath: "/blogs/ergeon-case-study.md",
        statistics: [
          {
            title: "Coverage",
            value: "5",
            increasing: true,
            suffix: "x",
          },
          {
            title: "Hours saved / week",
            value: "100",
            increasing: true,
            suffix: "+",
          },
          {
            title: "Cost",
            value: "90",
            increasing: false,
            suffix: "%",
          },
        ],
      },
    ];
    processBlogs(customerStoriesRaw).then(setCustomerStories);
  }, []);

  return customerStories.length ? (
    <BlogPostPage blogs={customerStories} idx={0} />
  ) : null;
};
