import { unhumanize } from "src/utils/utils";

export const initialExistingCustomerCustomFields = [
  { fieldName: "First Name", key: "firstName", type: "string", editable: true },
  { fieldName: "Last Name", key: "lastName", type: "string", editable: true },
  { fieldName: "Address", key: "address", type: "string", editable: true },
  { fieldName: "Notes", key: "notes", type: "string", editable: true },
  {
    fieldName: "Customer Owner",
    key: "customerOwner",
    type: "user",
    editable: true,
  },
];
export const initialExistingAccontsCustomFields = [
  { fieldName: "Phone", key: "phone", type: "string", editable: true },
  { fieldName: "Notes", key: "notes", type: "string", editable: true },
];

export const initialExistingConversationFields = [
  { fieldName: "Order Id", key: "order_id", type: "string", editable: true },
  { fieldName: "Customer", key: "customer", type: "string", editable: true },
];
export const defaultCustomerCustomFields = [
  { fieldName: "Email", key: "email", type: "string", editable: false },
  { fieldName: "Phone", key: "phone", type: "string", editable: false },
  { fieldName: "Company", key: "company", type: "string", editable: false },
  {
    fieldName: "Updated At",
    key: "updatedAt",
    type: "string",
    editable: false,
  },
  {
    fieldName: "Customer Stages",
    key: "customerStages",
    type: "select",
    editable: true,
  },
];
export const defaultAccountsCustomFields = [
  { fieldName: "Domain", key: "domain", type: "string", editable: false },
  {
    fieldName: "Updated At",
    key: "updatedAt",
    type: "string",
    editable: false,
  },
  {
    fieldName: "Account Stages",
    key: "customerStages",
    type: "select",
    editable: true,
  },
  { fieldName: "Name", key: "name", type: "string", editable: true },
];

export const trucoDataTypes = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "CheckBox" },
  { value: "array", label: "Array" },
  { value: "timestamp", label: "Date" },
  { value: "currency", label: "Currency" },
  { value: "select", label: "Select" },
  { value: "user", label: "User" },
];

export const generateKey = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const getKey = (modalType, fieldName) => {
  return modalType === "conversationProperties"
    ? unhumanize(fieldName)
    : generateKey(fieldName);
};

export const getModalTitle = (modalType) => {
  switch (modalType) {
    case "conversationProperties":
      return "Conversation Properties";
    case "customersCustomFields":
      return "Customer Properties";
    case "accountsCustomFields":
      return "Account Properties";
    default:
      return "";
  }
};

export const getCustomFieldsKey = (modalType) => {
  switch (modalType) {
    case "conversationProperties":
      return "conversationProperties";
    case "customersCustomFields":
      return "customersCustomFields";
    case "accountsCustomFields":
      return "accountsCustomFields";
    default:
      return null;
  }
};

export const getDefaultFields = (modalType) => {
  switch (modalType) {
    case "conversationProperties":
      return [];
    case "customersCustomFields":
      return defaultCustomerCustomFields;
    case "accountsCustomFields":
      return defaultAccountsCustomFields;
    default:
      return [];
  }
};
export const getInitialExistingFields = (modalType) => {
  switch (modalType) {
    case "conversationProperties":
      return initialExistingConversationFields;
    case "customersCustomFields":
      return initialExistingCustomerCustomFields;
    case "accountsCustomFields":
      return initialExistingAccontsCustomFields;
    default:
      return null;
  }
};
