import React, { createContext, useContext, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

interface DateSelectionContextType {
  date: Dayjs;
  setDate: (date: Dayjs) => void;
}
const defaultState: DateSelectionContextType = {
  date: dayjs().startOf("week").startOf("day"),
  setDate: null,
};

const DateSelectionContext = createContext(defaultState);

export const DateAgentSelectionProvider = ({ children }) => {
  const [date, setDate] = useState(dayjs().startOf("week").startOf("day"));

  return (
    <DateSelectionContext.Provider value={{ date, setDate }}>
      {children}
    </DateSelectionContext.Provider>
  );
};

export const useAgentDateSelection = () => useContext(DateSelectionContext);
