import React, { useState } from "react";
import { Input, Spin, Typography } from "antd";
import Client from "@elastic/search-application-client";
import { useOrganizationState } from "src/state/OrganizationState";
import { SearchResults } from "./SearchResults";
import { elementSize, spacing } from "src/styles/variables";
import { MainSearchContainer } from "./Search.styles";
const { Search } = Input;

const PAGE_SIZE = 10;

export const TrucoSearch = () => {
  const { organization } = useOrganizationState();
  const [searchResults, setSearchResults] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [, setNumHits] = useState(null);
  const [fromResult, setFromResult] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);

  const request = Client(
    process.env.REACT_APP_ELASTIC_SEARCH_INDEX,
    process.env.REACT_APP_ELASTIC_SEARCH_URL,
    process.env.REACT_APP_ELASTIC_SEARCH_API_KEY,
  );

  const performSearch = async (searchValue, from) => {
    setLoading(true);

    try {
      const results = await request()
        .addParameter("query_string", searchValue)
        .addParameter("organization", organization)
        .addParameter("from", from)
        .addParameter("size", PAGE_SIZE)
        .search();
      if (results?.hits?.hits) {
        setSearchResults((prevSearchResults) => [
          ...(prevSearchResults ?? []),
          ...results?.hits?.hits?.map((item) => item._source),
        ]);
        setFromResult((prevValue) => prevValue + results?.hits?.hits?.length);
      }

      setNumHits(
        typeof results?.hits?.total === "object"
          ? results?.hits?.total?.value
          : results?.hits?.total,
      );
    } catch (error) {
      console.error("Search failed:", error);
    }
    setLoading(false);
  };
  const onSearch = async (value) => {
    setIsSearchPerformed(true);
    if (value !== searchQuery) {
      setSearchQuery(value);
      setSearchResults(null);
      setFromResult(0);
      setNumHits(null);
      await performSearch(value, 0);
    } else {
      await performSearch(value, fromResult);
    }
  };

  return (
    <MainSearchContainer isSearchPerformed={isSearchPerformed}>
      {!isSearchPerformed && (
        <Typography.Title
          level={3}
          style={{ textAlign: "center", paddingBottom: spacing.md }}
        >
          Where Customer Voices Shape Insights
        </Typography.Title>
      )}
      <Search
        placeholder="Ask anything..."
        allowClear
        size="large"
        onSearch={onSearch}
        loading={loading}
        style={{ paddingBottom: spacing.md }}
      />
      <Spin
        spinning={searchResults === null && searchQuery}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: elementSize.ml,
        }}
      />
      {searchResults !== null && (
        <>
          <SearchResults searchResults={searchResults} />
        </>
      )}
    </MainSearchContainer>
  );
};
