import { useState } from "react";
import { Select, Form, Modal, Input, Button, Space } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { useFetchTags } from "src/hooks/useFetchData";
import { PlusOutlined } from "@ant-design/icons";
import { useAddOrUpdateTag } from "src/hooks/usePostData";

const { Option } = Select;

const TagsDropDown = ({ setSelectedTags, selectedTags }) => {
  const { organization } = useOrganizationState();
  const { data: tags, loading: loadingTags } = useFetchTags(organization);
  const addNewTag = useAddOrUpdateTag();

  const [showModal, setShowModal] = useState(false);
  const [modalTagName, setModalTagName] = useState("");
  const [modalDescription, setModalDescription] = useState("");

  const handleTagChange = (value) => {
    setSelectedTags(value);
  };

  const openModal = () => {
    setShowModal(true);
    setModalTagName(""); // Clear any previous values
    setModalDescription("");
  };

  const handleModalConfirm = async () => {
    if (modalTagName && !selectedTags.includes(modalTagName)) {
      const updatedTags = [...selectedTags, modalTagName];
      setSelectedTags(updatedTags);

      // Optionally add the new tag to the tags list if needed
      if (!tags.find((tag) => tag === modalTagName)) {
        tags.push(modalTagName);
      }
      await addNewTag({
        tagName: modalTagName,
        organizationId: organization,
        description: modalDescription,
      });
      setModalTagName("");
      setModalDescription("");
      setShowModal(false);
    }
  };

  return (
    <>
      <Form.Item name="tags" label="Tags">
        <Space style={{ width: "100%" }}>
          <Select
            mode="tags"
            placeholder="Select or create tags"
            style={{ width: "100%", minWidth: "50vw" }}
            value={selectedTags}
            onChange={handleTagChange}
            dropdownRender={(menu) => <>{menu}</>}
          >
            {tags?.map((tag) => (
              <Option key={tag} value={tag}>
                {tag}
              </Option>
            ))}
          </Select>
          <Button icon={<PlusOutlined />} onClick={openModal}>
            Create New Tag
          </Button>
        </Space>
      </Form.Item>

      <Modal
        open={showModal}
        title="Add New Tag"
        onCancel={() => {
          setShowModal(false);
          setModalTagName("");
          setModalDescription("");
        }}
        footer={[
          <Button key="cancel" onClick={() => setShowModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalConfirm}>
            Add Tag
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Tag Name" required>
            <Input
              value={modalTagName}
              onChange={(e) => setModalTagName(e.target.value)}
              placeholder="Enter tag name"
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input.TextArea
              value={modalDescription}
              onChange={(e) => setModalDescription(e.target.value)}
              placeholder="Enter description (optional)"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TagsDropDown;
