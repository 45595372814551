import React, { useEffect, useState } from "react";
import {
  Typography,
  Select,
  Spin,
  Button,
  message,
  Checkbox,
  Space,
  Input,
  Row,
  Divider,
} from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import {
  COLLECTION_DATA,
  CONVERSATION_ACCESS,
  QUERY_PARAMS,
} from "src/utils/enums";
import { getVisibilityIcon } from "src/components/Playlist/Playlist.utils";
import { elementSize } from "src/styles/variables";
import { LinkOutlined } from "@ant-design/icons";
import { updateDocInFireStore } from "src/firebaseAuth";
import { SHARE_SCOPE } from "../../Dashboard";
import { encryptId, humanize } from "src/utils/utils";

const { Option } = Select;

interface ShareConversation {
  modalData: any;
}

const ShareConversation: React.FC<ShareConversation> = ({ modalData }) => {
  const { organization, organizationSettings } = useOrganizationState();
  const [loading, setLoading] = useState(false);
  const [scopes, setScopes] = useState<string[]>([]);
  const allScopes = Object.values(SHARE_SCOPE);

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  queryParams.set(QUERY_PARAMS.CONVERSATION, modalData?.id);
  url.search = queryParams.toString();
  const encodedOrganization = encryptId(organization);

  const [conversationUrl, setConversationUrl] = useState(url.href);

  const generalAccessOptions = [
    {
      value: CONVERSATION_ACCESS.ORGANIZATION,
      label: organizationSettings?.name,
      icon: CONVERSATION_ACCESS.ORGANIZATION,
    },
    {
      value: CONVERSATION_ACCESS.PUBLIC,
      label: "Public",
      icon: CONVERSATION_ACCESS.PUBLIC,
    },
  ];
  const [visibility, setVisibility] = useState<CONVERSATION_ACCESS>(
    modalData.visibility,
  );

  useEffect(() => {
    if (visibility === CONVERSATION_ACCESS.PUBLIC) {
      const encodedScopes = encryptId(scopes.join(","));
      // setConversationUrl(
      //   `${url.href}&${QUERY_PARAMS.CONVERSATION_SCOPE}=${encodedOrganization}&${encodedScopes}`
      // );
      const concatenatedScopes = `${encodedOrganization}&${encodedScopes}`;
      const encodedConcatenatedScopes = encodeURIComponent(concatenatedScopes);

      const newUrl = new URL(url.href);
      newUrl.searchParams.set(
        QUERY_PARAMS.CONVERSATION_SCOPE,
        encodedConcatenatedScopes,
      );
      setConversationUrl(newUrl.href);
    } else {
      const newUrl = new URL(url.href);
      newUrl.searchParams.delete(QUERY_PARAMS.CONVERSATION_SCOPE);
      setConversationUrl(newUrl.href);
    }
  }, [visibility, scopes]);

  const handleVisibilityChange = async (value) => {
    setLoading(true);
    try {
      setVisibility(value);
      await updateDocInFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${modalData.id}`,
        { visibility: value },
      );

      message.success("Visibility updated successfully");
    } catch (error) {
      message.error("Failed to update visibility");
      console.error("Error updating visibility: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleScopeChange = (checkedValues) => {
    setScopes(checkedValues);
  };
  const handleCheckAllChange = (e) => {
    setScopes(e.target.checked ? allScopes : []);
  };

  return (
    <Spin spinning={loading}>
      <Typography.Title style={{ marginBottom: elementSize.sm }} level={5}>
        Share Conversation With
      </Typography.Title>

      <Select value={visibility} onChange={handleVisibilityChange}>
        {generalAccessOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {getVisibilityIcon(option.icon, {
              marginRight: elementSize.xs,
            })}
            {option.label}
          </Option>
        ))}
      </Select>
      {visibility === CONVERSATION_ACCESS.PUBLIC && (
        <div style={{ marginTop: elementSize.md }}>
          <Typography.Title style={{ marginBottom: elementSize.sm }} level={5}>
            Select Scopes
          </Typography.Title>
          <Checkbox
            indeterminate={
              scopes.length > 0 && scopes.length < allScopes.length
            }
            onChange={handleCheckAllChange}
            checked={scopes.length === allScopes.length}
          >
            Check All
          </Checkbox>
          <Divider style={{ margin: `${elementSize.xs} 0px` }} />
          <Checkbox.Group
            options={Object.values(SHARE_SCOPE).map((scope) => ({
              label: humanize(scope),
              value: scope,
            }))}
            value={scopes}
            onChange={handleScopeChange}
            style={{ display: "flex", flexDirection: "column" }}
          />
        </div>
      )}
      <Row>
        <Space.Compact style={{ width: "100%", marginTop: elementSize.sm }}>
          <Input value={conversationUrl} />
          <Button
            type="primary"
            icon={<LinkOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(conversationUrl).then(() => {
                message.success("Link Copied to clipboard");
              });
            }}
          >
            Copy Link
          </Button>
        </Space.Compact>
      </Row>
    </Spin>
  );
};

export default ShareConversation;
