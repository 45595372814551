import React from "react";

import { Col, Typography, Layout } from "antd";

import { NavigationBar, OrangeButton } from "src/components";
import { Footer } from "./Footer";
import {
  SectionContainer,
  BorderlessCard,
  SlidingWrapper,
  StyledHeader,
  GradientOrangeSpan,
  CenteredDiv,
  WhyTrucoImg,
  CardList,
} from "./LandingPage.styles";
import { CustomerStories } from "./CustomerStories";
import {
  color,
  deviceSize,
  hexToRgba,
  fontSize,
  spacing,
} from "src/styles/variables";
import { CTASection } from "./CTASection";
import CustomizedDotCard from "./CustomizedDotCard/CustomizedDotCard";
import { Helmet } from "react-helmet";
import { TRUCO_CAL_LINK } from "src/utils/variables";
import { ProductDemo } from "./ProductDemo/ProductDemo";
import { ProductInsights } from "./ProductInsights";

export const StandardLandingPage = ({
  content,
  metadata = null,
  headercolor = null,
}) => {
  return (
    <div>
      {metadata && (
        <Helmet>
          <title>{metadata.title}</title>
          <meta name="description" content={metadata.thumbnail_paragraph} />
          <meta name="keywords" content={metadata.keywords} />
          <meta name="author" content="Truco" />
          <meta property="og:title" content={metadata.title} />
          <meta
            property="og:description"
            content={metadata.thumbnail_paragraph}
          />
          <meta property="og:image" content={metadata?.image?.thumbnail_url} />
          <meta property="og:url" content={metadata?.image?.thumbnail_url} />
          <meta name="twitter:title" content={metadata.title} />
          <meta
            name="twitter:description"
            content={metadata.thumbnail_paragraph}
          />
          <meta name="twitter:image" content={metadata?.image?.thumbnail_url} />
          <meta name="twitter:card" content={metadata?.image?.description} />
        </Helmet>
      )}
      <Layout>
        <StyledHeader headercolor={headercolor}>
          <NavigationBar headercolor={headercolor} />
        </StyledHeader>
        <Layout.Content>
          {content}
          <CTASection />
        </Layout.Content>
        <Layout.Footer style={{ backgroundColor: color.olive }}>
          <Footer />
        </Layout.Footer>
      </Layout>
    </div>
  );
};

export const LandingPage = () => {
  const whyTrucoCards = [
    {
      icon: "/icons/ai.svg",
      title: "Personalized AI",
      description:
        "Seamlessly monitor quality, user sentiment and call compliance as tailored to your business.",
    },
    {
      icon: "/icons/improvement.svg",
      title: "Continuous improvement",
      description:
        "Gain immediate visibility and continuously learn to improve your customer experience.",
    },

    {
      icon: "/icons/integration.svg",
      title: "Custom integrations",
      description:
        "Integrate with your existing CRM, telephony, internal knowledge bases, workflows and more.",
    },
  ];

  const valueCards = [
    ["Instant feedback for agents", "/icons/clock.svg"],
    ["Analyze and QA 100% of conversations", "/icons/video.svg"],
    ["Measure BPO quality and success", "/icons/tech-support.svg"],
    ["Flow of insights from Support to Product", "/icons/rocket.svg"],
  ];
  const continuousValueCards = [...valueCards, ...valueCards];

  const content = (
    <>
      <SectionContainer>
        <BorderlessCard center={"true"}>
          <Typography.Title
            level={1}
            style={{
              fontSize: fontSize.displayXXXXXXL,
            }}
          >
            Assess Every <br /> Customer Conversation <br /> With{" "}
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </Typography.Title>

          <Typography.Paragraph
            style={{
              fontSize: fontSize.displayXXXL,
            }}
          >
            Increase call visibility, improve agent performance, and measure
            team quality.
            <br /> Instantly and at a fraction of the cost.
          </Typography.Paragraph>

          <OrangeButton
            text={"Get a Demo"}
            size={"large"}
            url={TRUCO_CAL_LINK}
            style={{ display: "flex", alignItems: "center" }}
          />
          <Typography.Title
            level={4}
            style={{ fontWeight: "normal", margin: 0 }}
          >
            <br />
            Try free for 30 days!
          </Typography.Title>

          <SlidingWrapper>
            {continuousValueCards.map((item, idx) => {
              if (
                window.innerWidth <=
                  parseInt(deviceSize.small_phone_size, 10) &&
                idx > 4
              )
                return null;
              return (
                <Col
                  xl={{ span: 4, offset: 2 }}
                  md={{ span: 4, offset: 2 }}
                  xs={{ span: 22, offset: 1 }}
                  key={"value_" + idx}
                >
                  <BorderlessCard center={"true"}>
                    <img
                      alt={item?.[0]}
                      style={{ width: "64px" }}
                      src={process.env.PUBLIC_URL + item?.[1]}
                    />
                    <Typography.Title level={3}>{item?.[0]}</Typography.Title>
                  </BorderlessCard>
                </Col>
              );
            })}
          </SlidingWrapper>
        </BorderlessCard>
      </SectionContainer>
      <ProductDemo />
      <ProductInsights />
      <SectionContainer
        background={`linear-gradient(90deg, ${hexToRgba(
          color.white,
          0.1,
        )} 0%, ${hexToRgba(color.mediumGreen, 0.1)} 100%)`}
      >
        <BorderlessCard center={"true"}>
          <CenteredDiv>
            <div>
              <Typography.Title
                level={1}
                style={{
                  fontSize: fontSize.displayXXXL,
                  color: color.black,
                  marginBottom: spacing.xl,
                }}
              >
                Listening to Users <br />
                Has Never been Easier
              </Typography.Title>
              <WhyTrucoImg src="qa-icon2.png" alt="Delivery Person" />
            </div>{" "}
            <CardList>
              {whyTrucoCards.map(({ icon, title, description }, index) => (
                <CustomizedDotCard
                  key={index}
                  imgSrc={icon}
                  title={title}
                  text={description}
                  style={{
                    animationDelay: `${index * 0.3}s`,
                  }}
                />
              ))}
            </CardList>
          </CenteredDiv>
        </BorderlessCard>
      </SectionContainer>
      <CustomerStories />
    </>
  );
  return <StandardLandingPage content={content} />;
};
