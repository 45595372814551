import { useEffect, useMemo, useState } from "react";
import {
  StandardPage,
  Chat,
  CollaboratorAvatar,
  DivLink,
  DeletedMessage,
} from "src/components";

import { Avatar, Card, List, Typography, Row, Col, Spin } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { useUserState } from "src/state/UserState";
import { useCollection } from "src/hooks/useCollection";
import { useOrganizationState } from "src/state/OrganizationState";
import {
  notDeletedConversations,
  latestFeedbackConditions,
} from "src/utils/variables";
import {
  getParticipatedChatsCondition,
  formatDateToHumanReadable,
} from "src/utils/utils";

import { chatConverter } from "src/utils/converter";
import {
  color,
  spacing,
  borderRadius,
  elementSize,
} from "src/styles/variables";
import {
  DeskTopOnly,
  DivLimitText,
  MobileOnly,
} from "src/styles/stylingComponents";
//@todo move all data operations to one file
import {
  handleFeedbackUpdateToDB,
  markFeedbackAsReadToDB,
  handleFeedbackDelete,
} from "src/components/Dashboard/Dashboard.utils";

import { ArrowBackIos, Forum, OpenInNew } from "@mui/icons-material";
import {
  AnimatedListItem,
  CardWithStyledHeader,
  SlidePanel,
} from "./Messenger.styles";
import { COLLECTION_DATA } from "src/utils/enums";
import { useNavigate } from "react-router-dom";

export const Messenger = () => {
  const { user, userChats, setUserChats } = useUserState();
  const { organization, evaluationForms } = useOrganizationState();
  const [activeChat, setActiveChat] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const navigate = useNavigate();

  const handleModalClick = (id) => {
    navigate(`?conversation=${encodeURIComponent(id)}`);
  };

  const chatsIndex = useMemo(() => {
    return new Map(userChats?.map((chat) => [chat.id, chat]));
  }, [userChats]);

  // Use this index to quickly find the active chat when needed
  useEffect(() => {
    if (activeChat && chatsIndex.has(activeChat.id)) {
      setActiveChat(chatsIndex.get(activeChat.id));
    }
  }, [activeChat, chatsIndex]);

  const handleChatSelect = (chat) => {
    setActiveChat(chat);
    setIsChatVisible(true); // Trigger to show the chat details on mobile
  };
  const handleBackToChats = () => {
    setIsChatVisible(false); // Return to chat list on mobile
    setTimeout(() => setActiveChat(null), 300); // Ensure this matches the duration of the transition
  };

  const chatConditions = [
    notDeletedConversations,
    latestFeedbackConditions,
    getParticipatedChatsCondition(user?.email),
  ];
  useCollection(
    organization && evaluationForms
      ? `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}`
      : undefined,
    setUserChats,
    chatConverter(user),
    chatConditions,
  );

  const handleNewMessage = (
    message,
    allMessages = null,
    setMessages = null,
    setTyping = null,
    mentions?,
  ) => {
    handleFeedbackUpdateToDB(
      message,
      activeChat,
      organization,
      activeChat?.id,
      user,
      activeChat?.calibration,
      mentions,
    );
    return null;
  };

  const ChatListComponent = (
    <SlidePanel isVisible={!isChatVisible}>
      <Card style={{ height: "100%" }}>
        <Typography.Title level={3}> Chats </Typography.Title>
        <br />
        <List
          dataSource={userChats}
          renderItem={(item) => {
            const unread = item.unreadBy.includes(user.email);
            const notification = unread ? (
              <HeartFilled style={{ color: color.orange }} />
            ) : null;
            const latestMessage = item?.feedback[item.feedback.length - 1];
            const isActiveChat = activeChat?.id === item?.id;
            return (
              <AnimatedListItem
                onClick={() => {
                  handleChatSelect(item);
                }}
                style={{
                  backgroundColor: isActiveChat ? color.grayLight : "inherit",
                  padding: `${spacing.sl} 0px ${spacing.sl} ${spacing.sm}`,
                  borderRadius: borderRadius.xs,
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar.Group max={{ count: 2 }} style={{ width: "52px" }}>
                      <CollaboratorAvatar
                        style={{
                          zIndex: 2,
                          marginTop:
                            item?.participants?.length > 1 && borderRadius.sm,
                          width:
                            item?.participants?.length === 1 && elementSize.xxl,
                          height:
                            item?.participants?.length === 1 && elementSize.xxl,
                        }}
                        key={item.feedback.length - 1}
                        collaborator={latestMessage}
                      />
                      {item?.participants?.length > 1 && (
                        <Avatar
                          style={{
                            background: color.grayMedium,
                            marginLeft: `-${borderRadius.sm}`,
                          }}
                        >
                          +{item?.participants?.length - 1}
                        </Avatar>
                      )}
                    </Avatar.Group>
                  }
                  title={
                    <DivLimitText
                      style={{
                        fontWeight: isActiveChat || unread ? "bold" : "normal",
                      }}
                    >
                      {item.file_name}
                    </DivLimitText>
                  }
                  description={
                    latestMessage && (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <DivLimitText style={{ marginRight: borderRadius.xxs }}>
                          {latestMessage?.deleted ? (
                            <DeletedMessage />
                          ) : (
                            `${latestMessage.text} . `
                          )}
                        </DivLimitText>
                        <DivLimitText style={{ minWidth: "25%" }}>
                          {formatDateToHumanReadable(latestMessage.time)}
                        </DivLimitText>
                      </div>
                    )
                  }
                  style={{ alignItems: "center" }}
                />
                {notification}
              </AnimatedListItem>
            );
          }}
        />
      </Card>
    </SlidePanel>
  );

  const ChatComponent = (
    <SlidePanel isVisible={isChatVisible}>
      {activeChat === null ? (
        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "77vh",
            }}
          >
            <Forum
              style={{
                width: elementSize.xxll,
                height: elementSize.xxll,
                marginBottom: spacing.md,
              }}
            />
            <Typography.Title level={4}>No chats selected</Typography.Title>
          </div>
        </Card>
      ) : (
        <CardWithStyledHeader
          title={
            <div
              style={{ display: "flex", alignItems: "center", gap: spacing.sm }}
            >
              <MobileOnly>
                <ArrowBackIos
                  onClick={handleBackToChats}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    width: elementSize.lg,
                    height: elementSize.lg,
                  }}
                />
              </MobileOnly>
              <DivLimitText>{activeChat.file_name}</DivLimitText>
            </div>
          }
          extra={
            <DivLink
              hoverColor={color.grayMedium}
              onClick={() => handleModalClick(activeChat.id)}
            >
              <DeskTopOnly>Open Conversation</DeskTopOnly>
              <MobileOnly>
                <OpenInNew
                  style={{
                    display: "flex",
                    color: color.olive,
                    width: elementSize.lg,
                    height: elementSize.lg,
                  }}
                />
              </MobileOnly>
            </DivLink>
          }
        >
          <Chat
            header={false}
            initialMessages={activeChat?.feedback || []}
            padding={0}
            height={"70vh"}
            border={false}
            key={activeChat?.feedback?.length}
            onNewMessage={handleNewMessage}
            onDeleteMessage={(messageIndex, setMessages) =>
              handleFeedbackDelete(
                messageIndex,
                setMessages,
                organization,
                activeChat?.id,
                user,
                activeChat,
                activeChat?.calibration,
              )
            }
            markAsRead={() =>
              markFeedbackAsReadToDB(
                user.email,
                activeChat,
                organization,
                activeChat?.id,
              )
            }
          />
        </CardWithStyledHeader>
      )}
    </SlidePanel>
  );

  const MessengerComponent = (
    <>
      <Spin spinning={!userChats}>
        {userChats?.length > 0 ? (
          <Row
            gutter={[
              { sm: 0, md: 16 },
              { sm: 0, md: 32 },
            ]}
          >
            <Col xs={24} sm={24} md={6} span={6}>
              {ChatListComponent}
            </Col>
            <Col xs={24} sm={24} md={18} span={18}>
              {ChatComponent}
            </Col>
          </Row>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "65vh",
              padding: spacing.ml,
            }}
          >
            <Forum
              style={{
                width: elementSize.xxll,
                height: elementSize.xxll,
                marginBottom: spacing.md,
              }}
            />
            <Typography.Title level={4} style={{ textAlign: "center" }}>
              You haven't participated in a conversation yet!
            </Typography.Title>
          </div>
        )}
      </Spin>
    </>
  );

  return <StandardPage content={MessengerComponent} />;
};
