// ColumnVisibilitySettings.js
import React from "react";
import { Drawer, List, Switch } from "antd";
import { capitalizeFirstLetters } from "src/utils/utils"; // Update with your utils path
const getDefaultColumns = (type) => {
  if (type === "customer") {
    return [
      { label: "Customer", key: "customer" },
      { label: "Account", key: "account" },
      { label: "Stage", key: "stage" },
      { label: "# of Conversations", key: "conversations" },
      { label: "Updated At", key: "updatedAt" },
    ];
  } else if (type === "account") {
    return [
      { label: "Account Identity", key: "accountIdentity" },
      { label: "Updated At", key: "updatedAt" },
      { label: "Stage", key: "stage" },
      { label: "# of Customers", key: "customerCount" },
    ];
  } else {
    return [];
  }
};

export const CustomersDrawerSettings = ({
  visible,
  onClose,
  customersCustomFields,
  tableConfig,
  handleSwitchChange,
  type,
}) => {
  const defaultColumns = getDefaultColumns(type); // or "account"

  const filteredCustomersCustomFields = customersCustomFields.filter(
    (field) => field.key !== "company",
  );
  return (
    <Drawer
      title="Column Visibility Settings"
      placement="right"
      onClose={onClose}
      open={visible}
    >
      <List
        size="small"
        bordered
        dataSource={[...defaultColumns, ...filteredCustomersCustomFields]}
        renderItem={(field) => {
          const isDefaultColumn = defaultColumns.some(
            (defaultCol) => defaultCol.key === field.key,
          );

          return (
            <List.Item>
              <span style={isDefaultColumn ? { opacity: 0.5 } : {}}>
                {capitalizeFirstLetters(field.label || field.key)}
              </span>
              <Switch
                checked={tableConfig[field.key] !== false}
                onChange={(checked) => handleSwitchChange(field.key, checked)}
                disabled={isDefaultColumn}
                style={isDefaultColumn ? { opacity: 0.5 } : {}}
              />
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
};
