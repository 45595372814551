import { Select } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";

const { Option } = Select;

export const EvaluationSelector = ({ handleEvaluationId }) => {
  const { organization, evaluationForms } = useOrganizationState();

  return (
    <Select
      placeholder="Select an evaluation type"
      onChange={handleEvaluationId}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {organization === "ergeon" && (
        <Option key={"Default"} value={"Default"} label={"Default"}>
          <div>{"Default"}</div>
        </Option>
      )}
      {evaluationForms &&
        Object.values(evaluationForms).length > 0 &&
        Object.values(evaluationForms)
          .filter((form) => !form.deleted)
          .map((item, index) => (
            <Option key={index} value={item.id} label={item.title}>
              <div>{item.title}</div>
            </Option>
          ))}
    </Select>
  );
};
