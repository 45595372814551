export enum ClipFields {
  Summary = "summary",
  Participants = "participants",
  UpdatedAt = "updatedAt",
  ClippedBy = "user",
}

export const fieldsToDisplay = [
  { key: ClipFields.Summary, label: "Summary" },
  { key: ClipFields.Participants, label: "Participants" },
  { key: ClipFields.UpdatedAt, label: "Updated At" },
  { key: ClipFields.ClippedBy, label: "Clipped By" },
];
