import { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Row, Col, Segmented, Table, Select, Spin } from "antd";
import { useUserState } from "src/state/UserState";
import { Chart } from "react-google-charts";
import { DownloadCSV, Leaderboard, Loading } from "src/components";

import { getUsersEmailDictionary, isEmptyObject } from "src/utils/utils";
import dayjs from "dayjs";

import {
  TopMetricsComponent,
  trucoMetricColumns,
  WeeklyOverViewGraphs,
  getWeeklyGraphData,
  getAggregateValueFromTrancripts,
  getAnalysisScores,
  getUserMetrics,
  getTrucoMetrics,
  getQAAgentMetrics,
  EvaluationSummaryCard,
  transformResultsToRawData,
} from "./BusinessInsights.utils";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import { useFiltersState } from "src/state/FiltersState";
import { getRecordsFromFireStore } from "src/firebaseAuth";
import { transformInsights } from "src/utils/converter";
import {
  DashboardActions,
  DASHBOARD_TYPE,
} from "../Dashboard/DashboardActions/DashboardActions";
import {
  useFetchPlaylistsTrends,
  useFetchTagTrends,
} from "src/hooks/useFetchData";

export const MetricTable = ({ title, data, metricColumns }) => {
  return (
    <Col xl={{ span: 12 }} xs={24}>
      <Card
        title={title}
        style={{ overflow: "auto", height: "500px" }}
        extra={[<DownloadCSV data={data} fileName={title} />]}
      >
        <Table
          dataSource={data}
          columns={metricColumns}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </Card>
    </Col>
  );
};

export const StyledSegmented = styled(Segmented)`
  .ant-segmented-item-label {
    height: 100%;
  }
`;
export const BusinessInsights = () => {
  const { user } = useUserState();
  const {
    organization,
    insights,
    setInsights,
    organizationSettings,
    collaborators,
    pendingCollaborators,
    transcripts,
  } = useOrganizationState();
  const showRisk = organizationSettings?.showRisk;
  const { filterDates, transcriptsConditions } = useFiltersState();
  const [currentInsightData, setCurrentInsightData] = useState(
    insights?.selectedDateInsights,
  );

  const [filteredTranscripts, setFilteredTranscripts] = useState(transcripts);

  const [playlistsTrend, setPlaylistsTrend] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const handleFilterChange = (filteredData) => {
    setFilteredTranscripts(filteredData);
    if (insights?.showFilterTranscripts) {
      setCurrentInsightData(getInsightsFromTranscripts(filteredData));
    }
  };
  const [selectedPlaylist, setSelectedPlaylist] = useState<any>();
  const { Option } = Select;
  const { data: tagTrends, loading: loadingTagTrends } = useFetchTagTrends(
    selectedPlaylist,
    organization,
    dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.startDate).format("YYYY-MM-DD"),
    dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.endDate).format("YYYY-MM-DD"),
  );
  const { data: playlistsTrendFetched, loading: loadingPlaylistTrends } =
    useFetchPlaylistsTrends(
      organization,
      dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.startDate).format(
        "YYYY-MM-DD",
      ),
      dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.endDate).format("YYYY-MM-DD"),
    );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryConstraints = transcriptsConditions[
          DASHBOARD_TYPE.INSIGHTS
        ].map((item, index) => ({
          ...item,
          comparisonField: "__name__",
          order: index === 0 && "asc",
          value:
            index === 0
              ? dayjs(item.value).subtract(4, "week").format("YYYY-MM-DD")
              : dayjs(item.value).add(4, "week").format("YYYY-MM-DD"),
        }));
        const docs = (
          await getRecordsFromFireStore(
            `organization/${organization}/${COLLECTION_DATA.INSIGHTS}`,
            queryConstraints,
          )
        ).data;
        const startDate = dayjs(
          filterDates[DASHBOARD_TYPE.INSIGHTS]?.startDate,
        ).format("YYYY-MM-DD");
        const endDate = filterDates[DASHBOARD_TYPE.INSIGHTS]?.endDate
          ? dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.endDate).format(
              "YYYY-MM-DD",
            )
          : null;

        const InsightsOfSelectedDates = docs.filter((insight) => {
          return insight.id >= startDate && (!endDate || insight.id <= endDate);
        });
        if (playlistsTrendFetched) {
          const { currentTrendData, currentPlaylists, currentCategory } =
            transformResultsToRawData(playlistsTrendFetched);
          setPlaylists(currentPlaylists ?? []);
          setPlaylistsTrend(currentTrendData);
          setSelectedPlaylist(currentCategory);
        }
        const selectedDateInsight = transformInsights(
          InsightsOfSelectedDates,
          getUsersEmailDictionary(collaborators, pendingCollaborators),
          showRisk,
        );
        setCurrentInsightData(selectedDateInsight);
        setInsights({
          filterDates: filterDates[DASHBOARD_TYPE.INSIGHTS],
          organization,
          allInsightsData: docs,
          selectedDateInsights: selectedDateInsight,
          Loading: false,
          showFilterTranscripts: false,
        });
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    };
    if (
      !insights ||
      !insights.allInsightsData ||
      insights.loading ||
      insights.organization !== organization ||
      insights.filterDates?.startDate !==
        filterDates?.[DASHBOARD_TYPE.INSIGHTS]?.startDate ||
      insights.filterDates?.endDate !==
        filterDates?.[DASHBOARD_TYPE.INSIGHTS]?.endDate
    ) {
      setCurrentInsightData(null);
      organizationSettings && fetchData();
    }
  }, [filterDates, organization, organizationSettings]);

  useEffect(() => {
    if (playlistsTrendFetched) {
      const { currentTrendData, currentPlaylists, currentCategory } =
        transformResultsToRawData(playlistsTrendFetched);
      setPlaylists(currentPlaylists);

      setPlaylistsTrend(currentTrendData);

      setSelectedPlaylist(currentPlaylists?.[0]?.id);
    }
  }, [playlistsTrendFetched]);

  const getInsightsFromTranscripts = (filteredTranscripts) => {
    const { totalDuration, totalVerifiedCount, sentiments } =
      getAggregateValueFromTrancripts(filteredTranscripts);
    const analysisScores = getAnalysisScores(filteredTranscripts);
    let trucoMetrics = null;
    let agentMetrics = [];
    let qaMetrics = [];
    let leadMetrics = [];
    if (user?.accessType !== ACCESS_TYPE.agent) {
      agentMetrics = getUserMetrics(
        filteredTranscripts,
        showRisk,
        ACCESS_TYPE.agent,
      );
      trucoMetrics = getTrucoMetrics(filteredTranscripts, analysisScores);
      qaMetrics = getQAAgentMetrics(filteredTranscripts);
      leadMetrics = getUserMetrics(
        filteredTranscripts,
        showRisk,
        ACCESS_TYPE.lead,
      );
    }
    return {
      totalTranscripts: filteredTranscripts.length,
      totalDuration,
      totalVerifiedCount,
      sentiments,
      analysisScores,
      agentMetrics,
      qaMetrics,
      leadMetrics,
      trucoMetrics,
    };
  };
  const handleDataFromInsights = () => {
    setCurrentInsightData(getInsightsFromTranscripts(filteredTranscripts));

    setInsights((prevInsights) => ({
      ...prevInsights,
      showFilterTranscripts: true,
    }));
  };

  let leaderboardMetrics;
  const scoreType = showRisk ? "Risk" : "Score";

  if (user?.accessType !== ACCESS_TYPE.agent) {
    leaderboardMetrics = [
      [currentInsightData?.agentMetrics, scoreType, "Agents"],
      [currentInsightData?.leadMetrics, scoreType, "Team Leads"],
      [currentInsightData?.qaMetrics, "Verified", "Reviewers"],
    ];
  }
  const { overallScoreData } = getWeeklyGraphData(insights, showRisk);

  return (
    <>
      {!currentInsightData || insights?.loading ? (
        <Loading />
      ) : (
        <Card>
          <DashboardActions
            type={DASHBOARD_TYPE.INSIGHTS}
            transcripts={transcripts}
            handleFilterChange={handleFilterChange}
            showFiltersTranscripts={
              transcripts ? insights?.showFilterTranscripts : false
            }
            onFilterShowClick={handleDataFromInsights}
          />
          <Row gutter={[16, 32]}>
            {currentInsightData.totalTranscripts > 0 && (
              <TopMetricsComponent
                insights={currentInsightData}
                showRisk={showRisk}
              />
            )}
            {playlistsTrend?.length > 1 && (
              <>
                {" "}
                <Col xl={{ span: 12 }} xs={24} key={"Signal Count"}>
                  <Card
                    title="Signals"
                    style={{ overflow: "auto", height: "500px" }}
                  >
                    <Chart
                      chartType="LineChart"
                      data={playlistsTrend}
                      options={{
                        title: "Playlist Trends",
                        hAxis: { title: "Date" },
                        vAxis: { title: "Signal Count", minValue: 0 },
                        legend: { position: "top" },
                        series: {
                          0: { color: "#4285F4" },
                          1: { color: "#DB4437" },
                        },
                      }}
                      width="100%"
                      height="350px"
                      legendToggle
                    />
                  </Card>
                </Col>
                <Col xl={{ span: 12 }} xs={24} key={"Signal Count"}>
                  <Card
                    title={"Themes Trends"}
                    style={{ overflow: "auto", height: "500px" }}
                  >
                    <Select
                      defaultValue={playlists?.[0]?.title}
                      style={{ width: 200, marginBottom: "5px" }}
                      onChange={async (value) => {
                        setSelectedPlaylist(value);
                      }}
                    >
                      {playlists?.map((playlist, index) => (
                        <Option
                          key={playlist.id}
                          value={playlist.id}
                          keu={`${index}`}
                        >
                          {playlist.title}
                        </Option>
                      ))}
                    </Select>
                    <Spin spinning={loadingTagTrends}>
                      {tagTrends?.length > 1 && (
                        <Chart
                          chartType="LineChart"
                          data={tagTrends}
                          options={{
                            hAxis: { title: "Clip Count", minValue: 0 },
                            legend: { position: "top" },
                          }}
                          width="100%"
                          height="350px"
                          legendToggle
                        />
                      )}
                    </Spin>
                  </Card>
                </Col>
              </>
            )}
            {!isEmptyObject(currentInsightData.analysisScores) && (
              <EvaluationSummaryCard
                currentInsightData={currentInsightData}
                showRisk={showRisk}
              />
            )}
            {!insights?.showFilterTranscripts && (
              <WeeklyOverViewGraphs
                // sentiments={sentimentsData}
                overallScoreData={overallScoreData}
                scoreType={scoreType}
              />
            )}
            {leaderboardMetrics &&
              leaderboardMetrics.map(([metrics, scoreType, title]) => {
                if (metrics.length <= 1) return null;
                return (
                  <Col xl={{ span: 12 }} xs={24} key={title + "leaderboard"}>
                    <Card title={title + " Leader Board"}>
                      {metrics.length > 0 ? (
                        <Leaderboard
                          leaderboardUsers={metrics}
                          scoreType={scoreType}
                        />
                      ) : (
                        `No ${title} are in the leaderboard`
                      )}
                    </Card>
                  </Col>
                );
              })}
            {[ACCESS_TYPE.owner, ACCESS_TYPE.trucoAdmin].includes(
              user?.accessType,
            ) &&
              currentInsightData.trucoMetrics?.length > 0 && (
                <MetricTable
                  title={"Truco Verified Metrics"}
                  data={currentInsightData.trucoMetrics}
                  metricColumns={trucoMetricColumns}
                />
              )}
          </Row>
        </Card>
      )}
    </>
  );
};
