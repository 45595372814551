import { useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";
import { useCollection } from "./useCollection";

const useFetchCollection = (
  collectionType: string,
  transformData: any,
  additionalConditions: any[] = [],
) => {
  const { organization } = useOrganizationState();
  const [items, setItems] = useState(undefined);
  const [shouldFetch, setShouldFetch] = useState(false);

  const fetchItems = () => {
    if (organization) {
      setShouldFetch(true);
    }
  };

  useCollection(
    shouldFetch ? `/organization/${organization}/${collectionType}` : null,
    (data) => {
      setItems(data);
      setShouldFetch(false);
    },
    undefined,
    additionalConditions,
    undefined,
    transformData,
    false,
  );

  return { items, fetchItems };
};

export default useFetchCollection;
