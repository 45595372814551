import { unhumanize } from "src/utils/utils";

export interface BlogPostProps {
  title: string;
  name?: string;
  date: string;
  keywords: string;
  url?: string;

  image?: {
    thumbnail_url: string;
    description: string;
    redirect_url?: string;
  };

  thumbnail_paragraph?: string;
  paragraphs?: {
    heading: string;
    body: string;
  }[];
  markDownPath?: string;
  markdown?: string;
}
export function truncateString(str, num) {
  str = removeTags(str);
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function processBlogs(blogs) {
  return Promise.all(
    blogs.map((blog) => {
      blog["url"] = "/blogs/" + unhumanize(blog.title);
      blog["name"] = unhumanize(blog.title);
      if (blog.markDownPath) {
        return fetch(process.env.PUBLIC_URL + blog.markDownPath)
          .then((response) => response.text())
          .then((text) => {
            blog["markdown"] = text;
            blog["thumbnail_paragraph"] = truncateString(
              text
                .replace(/[#>*_`[\]!()-]/g, "")
                .split(/\s+/)
                .join(" "),
              220,
            );

            return blog;
          });
      } else if (blog?.paragraphs?.[0]?.body) {
        blog["thumbnail_paragraph"] = truncateString(
          blog?.paragraphs[0]?.body || "",
          220,
        );
        return Promise.resolve(blog);
      } else {
        return Promise.resolve(blog);
      }
    }),
  );
}

export const blogs: BlogPostProps[] = [
  {
    date: "August 15th, 2024",
    title: "Truco vs. Klaus: Navigating Quality Assurance Software",
    keywords:
      "quality assurance, quality management software, truco, klaus, omni channel, AI QA, AI scorecards",
    markDownPath: "/blogs/truco_vs_klaus.md",
    image: {
      thumbnail_url: "/blogs/truco_vs_klaus.png",
      description: "Truco vs. Klaus: Navigating Quality Assurance Software",
    },
  },
  {
    date: "July 25th, 2024",
    title: "Truco vs. Gong: Which Is Best For You?",
    keywords:
      "revenue intelligence, customer intelligence, truco, gong, notetaker, recordings, record, sales",
    markDownPath: "/blogs/gong_vs_truco.md",
    image: {
      thumbnail_url: "/blogs/gong_vs_truco.png",
      description: "Truco vs. Gong: Which Is Best For You?",
    },
  },
  {
    date: "April 5th, 2024",

    title: "Embracing AI for Enhanced Customer Support Quality Assurance",
    keywords:
      "customer service quality assurance, customer support qa, ai for quality assurance, ai for support operations",
    markDownPath:
      "/blogs/embracing_ai_for_enhanced_customer_support_quality_assurance.md",
    image: {
      thumbnail_url:
        "/blogs/embracing_ai_for_enhanced_customer_support_quality_assurance.png",
      description:
        "Embracing AI for Enhanced Customer Support Quality Assurance",
    },
  },
  {
    image: {
      thumbnail_url: "/blogs/hype_from_reality.jpg",
      description: "evluating ai vendors in customer support",
    },
    title:
      "Deciphering Hype from Reality: A Guide to Evaluating Customer Support AI Vendors",
    date: "April 7th, 2024",
    keywords: "ai hype, nyc chatbot ai, evaluating customer support vendors",
    markDownPath: "/blogs/hype_from_reality.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/custom_scorecard.png",
      description: "custom cx and qa scores",
    },
    title: "Secret to Stellar Customer Service is Custom CX and QA Scores",
    date: "June 17th, 2024",
    keywords:
      "qa score, cx score, cs score, custom quality assurance, iqs, internal quality score",
    markDownPath: "/blogs/stellar_customer_service.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/stop.png",
      description: "empowering support or sales agents",
    },
    title:
      " Auto-Fail in Customer Service QA: What It Means and When to Use It",
    date: "May 8th, 2024",
    keywords:
      "how to make a call fail, auto failed, google quality control calls, why do calls keep failing, auto fail, fail in, autofail, automatic fail, qaauto, fail means, on call auto, qa auto, why would a call fail, call center quality feedback examples",
    markDownPath: "/blogs/auto_fail.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/happy_agents.png",
      description: "empowering support or sales agents",
    },
    title:
      "Empowering Agents: Tactics for Stellar Customer Service and Retention",
    date: "June 8th, 2024",
    keywords:
      "empowering agents, calibrations, voice of agent, customer support tactics, great cx, empowering SDRs",
    markDownPath: "/blogs/empower_agents.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/iqs_aht.png",
      description:
        "average call handle time (AHT), internal quality score (IQS)",
    },
    title:
      "Average Handle Time (AHT): The Secret Sauce to Efficient Call Centers",
    date: "May 20th, 2024",
    keywords:
      "aht in call center, aht contact center, what is aht in a call center, how to calculate aht, average handle time formula, how to reduce average handle time, aht calculator, aht gpt, phone avg aht, what is aht mean, improve aht, call center aht, aht call center, what is aht, what is aht in call center, aht calculation, aht work center, average handling time, reduce aht, agyle time, iqs, internal quality score, what is iqs, call center metrics",
    markDownPath: "/blogs/aht_iqs.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/cost_per_call.png",
      description: "cost per call, call center cost",
    },
    title: "Call Center Cost Per Call: How to Calculate & Reduce It",
    date: "May 22th, 2024",
    keywords:
      "call center cost per call, long call calculator, a call center supervisor is reviewing her teams performance metrics, call center pricing model, how to calculate call cost per minute, how to calculate cost reduction, call contact center, call center staffing calculator, call center price, call volume calculator, call center pricing, call center calculator, call center cost breakdown, call center prices, average cost per contact call center, call center metrics calculations, how much do call centers pay, average call center pay, cost per call, cost per minute calculator, calculator call center, price to call, call center qa reviews, 5 9 call center, a factor that causes overhead costs is called a, as per phone call, cost per call call center, call center rates, call center fees, call center cost, in touch call center, call center tricks to avoid calls, call center costs, average cost per call inbound call center, call center cost reduction, calls per hour calculator, average calls per hour in a call center, cost per contact, call calculation, average calls per day in a call center, call call center, call center cost savings, reduce call center costs, call center average calls per day, call center average pay, call center contract",
    markDownPath: "/blogs/cost_per_call.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/positive_scripting.png",
      description: "positive scripting examples customer service",
    },
    title: "The Art of Positive Positioning in Customer Service",
    date: "May 23th, 2024",
    keywords:
      "best positioning statements, positive scripting, example positioning statements, personal positioning statement, positioning statement example for coffee shop, positive scripting for customer service, positioning strategy statement example, positive language customer service, empathy statements for angry customers, positive phrasing for customer service, empathy and assurance statements, de escalation script call center, assure statements in customer service, examples of positioning statement, example positioning statement, positive scripting samples, positive positioning, positive language in customer service, good positioning statement, great positioning statements, positive language for customer service, positive scripting customer service empathy statements, positioning statements, how to de escalate an angry customer script, positioning statement examples, example of positioning statement, customer service positive language, de escalation phrases call center, positive scripting examples, example of a positioning statement, examples of positioning statements, positioning statement example, sample product positioning statement, market positioning statement example, sample positioning statement",
    markDownPath: "/blogs/positive_scripting.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/bpo_qa.png",
      description: "contact center quality assurance best practices",
    },
    title: "BPO Call Centers: Best Practices for Quality Assurance",
    date: "May 24th, 2024",
    keywords:
      "contact center quality assurance best practices, what is bpo call center, what is bpo experience, bpo call centers, best call centers, call center quality assurance best practices, best practices for call centers, what is bpo and call center, contact center best practices, what is bpos, how to improve quality assurance in a call center, bpo center, call center customer experience best practices, call center bpo companies, call center best practices, bpo call center, bpo or call center, best practices call center, call center bpo, bpo contact center, bpo is call center, bpo tickets, bpo performance",
    markDownPath: "/blogs/bpo_qa.md",
  },
  {
    image: {
      thumbnail_url: "/blogs/building_scorecard.png",
      description: "building a scorecard quality assurance guide",
    },
    title:
      "Unleashing the Power of Quality Assurance: The Ultimate Call Center Scorecard Guide",
    date: "May 24th, 2024",
    keywords:
      "agent scorecard, quality scorecard, call monitoring form, contact center scorecard, boxing scorecard calculator, call center scorecard, contact center quality monitoring, call center qa scorecard, call center quality assurance forms, call center scorecard template, call center quality monitoring, qa monitoring, call center evaluation form, quality monitoring contact center, call center quality scorecard, call center agent performance scorecard, call quality monitoring best practices, quality call monitoring scorecard, call center scorecard template excel, call center email quality monitoring, call quality monitoring, call center quality monitoring scorecard, call center quality assurance scorecard, call quality monitoring form, call center agent scorecard, quality monitoring call center, quality call center monitoring, quality monitoring in call center, call center quality assurance checklist",
    markDownPath: "/blogs/building_scorecard.md",
  },
];

export function getBlogs() {
  return processBlogs(blogs);
}
