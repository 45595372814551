import { useOrganizationState } from "src/state/OrganizationState";
import { useFiltersState } from "src/state/FiltersState";
import { Loading } from "../Loading";
import Actions from "./DashboardTableAction";
import { DashboardTable } from "./DashboardTable";
import { useState } from "react";
import { useUserState } from "src/state/UserState";
import {
  CONVERSATION_FETCH_LIMIT,
  notDeletedConversations,
} from "src/utils/variables";
import { getRecordsFromFireStore } from "src/firebaseAuth";
import { transcriptConverter } from "src/utils/converter";
import { getUsersEmailDictionary } from "src/utils/utils";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { DASHBOARD_TYPE } from "./DashboardActions/DashboardActions";

export enum SHARE_SCOPE {
  SHOW_TOPICS = "topics",
  SHOW_TRANSCRIPTS = "transcripts",
  SHOW_HIGHLIGHTS = "highlights",
  SHOW_SENTIMENTS = "sentiments",
  SHOW_ORGANIZATION_PROPERTIES = "organizationProperties",
  SHOW_EVALUATION_ANALYSIS = "evaluationAnalysis",
  SHOW_TEAMS = "teams",
  SHOW_FEEDBACK = "feedback",
  SHOW_ASKTRUCO = "askTruco",
  SHOW_INTEGRATION_DETAILS = "integrationDetails",
}

export const Dashboard = () => {
  const { loading } = useFiltersState();
  const {
    transcripts,
    organizationSettings,
    setConversationFeatures,
    setTranscripts,
    conversationFeatures,
    evaluationForms,
    collaborators,
    pendingCollaborators,
    organization,
  } = useOrganizationState();
  const { user } = useUserState();
  const { transcriptsConditions } = useFiltersState();

  const [moreLoading, setMoreLoading] = useState(false);

  // Function to load more transcripts when called
  const loadMoreTranscripts = async () => {
    if (conversationFeatures.isLastBatch || !conversationFeatures.lastDoc)
      return;

    setMoreLoading(true);
    try {
      let agentQueryConditions = null;

      // Set agent-specific query condition if user is an agent
      if (user?.accessType === ACCESS_TYPE.agent) {
        agentQueryConditions = {
          comparisonField: "metadata.agent",
          comparisonOperator: "==",
          value: user?.email,
        };
      }

      // Combine base conditions and agent conditions
      const queryConditions = [
        ...transcriptsConditions[DASHBOARD_TYPE.CONVERSATIONS],
        notDeletedConversations,
        ...(agentQueryConditions ? [agentQueryConditions] : []), // Add agent condition if present
      ];

      // Fetch more transcripts from Firestore
      const moreTranscriptsResult = await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}`, // Replace with your Firestore path
        queryConditions,
        transcriptConverter(
          evaluationForms,
          getUsersEmailDictionary(collaborators, pendingCollaborators),
          user,
        ),
        undefined,
        CONVERSATION_FETCH_LIMIT, // Limit for pagination
        conversationFeatures.lastDoc, // Start from lastDoc for pagination
      );

      const moreTranscripts = moreTranscriptsResult.data;

      // Update transcripts state with new data
      setTranscripts((prev) => [...prev, ...moreTranscripts]);

      // Update pagination features (lastDoc and isLastBatch)
      setConversationFeatures({
        lastDoc:
          moreTranscripts.length < CONVERSATION_FETCH_LIMIT
            ? null
            : moreTranscriptsResult.lastDoc,
        isLastBatch: moreTranscripts.length < CONVERSATION_FETCH_LIMIT,
      });
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setMoreLoading(false);
    }
  };
  return !loading && transcripts ? (
    <>
      <Actions />
      <DashboardTable
        data={transcripts}
        download={true}
        showRisk={organizationSettings?.showRisk}
        loadMoreTranscripts={loadMoreTranscripts}
        moreLoading={moreLoading}
      />
    </>
  ) : (
    <Loading />
  );
};
