import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin, Card, Statistic, Row, Col, Pagination } from "antd";
import { PlaylistTitle, PlayListDetails } from "./PlaylistDetails.styles";
import { color, deviceSize, elementSize } from "src/styles/variables";
import { DeskTopOnly, MobileOnly } from "src/styles/stylingComponents";
import { decryptId } from "src/utils/utils";
import { spacing } from "src/styles/variables";
import { PlaylistActions } from "./PlaylistActions";
import TagsView from "./TagsView";
import { debounce } from "lodash";
import { PlaylistClips } from "./PlaylistClips";
import { useMediaQuery } from "react-responsive";
import {
  useFetchPlaylistClips,
  useFetchPlaylistDetails,
  useFetchPlaylistDailyCount,
} from "src/hooks/useFetchData";
import { useDeleteClip } from "src/hooks/usePostData";
import { PlaylistTrendCard } from "./PlaylistTrendCard";

export enum PLAYLIST_VIEW {
  CLIPS = "clips",
  TAGS = "tags",
}

const PAGE_SIZE = 10; // Number of clips per page

const PlaylistDetails = () => {
  const { encryptedOrganizationId, encryptedPlaylistId } = useParams();
  const organizationId = decryptId(encryptedOrganizationId);
  const playlistId = decryptId(encryptedPlaylistId);
  const [viewMode, setViewMode] = useState<PLAYLIST_VIEW>(PLAYLIST_VIEW.CLIPS);
  const isMobile = useMediaQuery({
    maxWidth: `${deviceSize.small_phone_size}`,
  });
  const deleteClip = useDeleteClip();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [clipsWithUrls, setClipsWithUrls] = useState([]);

  // Fetch playlist details
  const { data: playlist } = useFetchPlaylistDetails(
    organizationId,
    playlistId,
  );

  // Fetch daily counts
  const { data: dailyCountsData, loading: loadingDailyCounts } =
    useFetchPlaylistDailyCount(organizationId, playlistId, 90);

  // Fetch paginated clips
  const {
    data: clipsData,
    loading: loadingClips,
    refetch: refetchClips,
  } = useFetchPlaylistClips(
    organizationId,
    playlistId,
    currentPage,
    pageSize,
    searchValue,
  );

  const handleDeleteClip = useCallback(
    async (clipId) => {
      await deleteClip(clipId, organizationId, playlistId);
      refetchClips();
    },
    [deleteClip, organizationId, playlistId, refetchClips],
  );

  const handleSearch = useCallback(
    debounce((e) => {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    }, 400),
    [],
  );

  const onPaginationChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div
      style={{
        padding: elementSize.sm,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Playlist Actions */}
      <MobileOnly>
        <PlaylistActions
          viewMode={viewMode}
          playlist={playlist}
          setViewMode={setViewMode}
          setLoading={() => {}}
          handleSearch={handleSearch}
        />
      </MobileOnly>

      {/* Playlist Details */}
      {playlist && (
        <PlayListDetails
          style={{ padding: elementSize.xxs, marginBottom: elementSize.xs }}
        >
          <PlaylistTitle>{playlist?.title}</PlaylistTitle>
          <div style={{ color: color.grayMedium }}>{playlist.description}</div>
        </PlayListDetails>
      )}

      {/* Desktop Playlist Actions */}
      <DeskTopOnly>
        <PlaylistActions
          viewMode={viewMode}
          playlist={playlist}
          setViewMode={setViewMode}
          setLoading={() => {}}
          handleSearch={handleSearch}
        />
      </DeskTopOnly>

      {/* Stats Cards */}
      <Row
        gutter={[16, 16]}
        style={{
          margin: `${spacing.lg} 0`,
          textAlign: "center",
          justifyContent: "center",
          alignContent: "stretch",
        }}
      >
        <Col
          xl={{ span: 9 }}
          xs={24}
          style={{ paddingLeft: 0, display: "flex" }}
        >
          <Card
            key={"Volume"}
            title={"Volume"}
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            {playlist && (
              <Statistic title={"Total Clips"} value={playlist?.totalClips} />
            )}
          </Card>
        </Col>
        <Col xl={{ span: 15 }} xs={24} style={{ display: "flex" }}>
          <Card
            title={"Trend"}
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            {loadingDailyCounts ? (
              <Spin spinning={loadingDailyCounts} />
            ) : (
              <PlaylistTrendCard
                dailyCounts={dailyCountsData?.dailyCounts || []}
                numDays={isMobile ? 60 : 90}
              />
            )}
          </Card>
        </Col>
      </Row>

      {/* Clips List */}

      <div style={{ flex: 1 }}>
        {viewMode === PLAYLIST_VIEW.CLIPS && (
          <>
            {loadingClips ? (
              <Spin spinning={loadingClips}>Loading clips...</Spin>
            ) : (
              <>
                <PlaylistClips
                  playlistId={playlistId}
                  clips={clipsData?.clips}
                  handleDeleteClip={handleDeleteClip}
                />
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={clipsData?.pagination?.totalClips}
                  onChange={onPaginationChange}
                  showSizeChanger
                  onShowSizeChange={(current, size) => {
                    setPageSize(size);
                    setCurrentPage(1);
                  }}
                  style={{ textAlign: "center", padding: "16px" }}
                />
              </>
            )}
          </>
        )}
        {viewMode === PLAYLIST_VIEW.TAGS && (
          <TagsView
            organizationId={organizationId}
            playlistId={playlistId}
            clips={playlist?.totalClips}
            playlistTitle={playlist?.title}
            searchValue={searchValue}
          />
        )}
      </div>
    </div>
  );
};

export default PlaylistDetails;
