import { useEffect, useState } from "react";

export enum VideoToFramesMethod {
  fps,
  totalFrames,
}

export const useVideoFrames = (
  videoUrl: string,
  amount: number,
  type: VideoToFramesMethod | null = VideoToFramesMethod.fps,
): [string[], boolean, string] => {
  const [frames, setFrames] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const extractFrames = async () => {
      if (!videoUrl || type === null) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setFrames([]);
      setError("");

      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const video: HTMLVideoElement = document.createElement("video");
      video.crossOrigin = "anonymous";
      video.preload = "metadata";

      video.addEventListener("loadedmetadata", async () => {
        if (context) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const duration = video.duration;
          const frameInterval =
            type === VideoToFramesMethod.fps ? 1 / amount : duration / amount;

          for (let time = 0; time < duration; time += frameInterval) {
            await getVideoFrame(video, context, canvas, time)
              .then((frame: string) => {
                setFrames((prevFrames) => [...prevFrames, frame]);
              })
              .catch((err) => {
                setError("Error extracting frames");
                setLoading(false);
                return;
              });
          }
          setLoading(false);
        }
      });

      video.src = videoUrl;
      video.load();
    };

    const getVideoFrame = (
      video: HTMLVideoElement,
      context: CanvasRenderingContext2D,
      canvas: HTMLCanvasElement,
      time: number,
    ): Promise<string> => {
      return new Promise((resolve, reject) => {
        video.currentTime = time;
        video.addEventListener(
          "seeked",
          function onSeeked() {
            video.removeEventListener("seeked", onSeeked);
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            resolve(canvas.toDataURL());
          },
          { once: true },
        );
      });
    };

    extractFrames();
  }, [videoUrl, amount, type]);

  return [frames, loading, error];
};
