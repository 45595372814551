import { useEffect, useState } from "react";
import { Select, Form, Spin } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { filterPlaylists } from "src/components/Playlist/Playlist.utils";
import { useUserState } from "src/state/UserState";
import { CONVERSATION_SUBMODAL } from "../../ConversationModalContent";

const { Option } = Select;

const PlaylistsDropDown = ({
  type = CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST,
}) => {
  const { playlists, loadingPlaylists } = useOrganizationState();
  const { user } = useUserState();

  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(
    null,
  );
  const [searchText, setSearchText] = useState<string>("");

  const [playlistOptions, setPlaylistOptions] = useState([]);

  const [displayedPlaylists, setDisplayedPlaylists] =
    useState<any[]>(playlistOptions);
  const shouldFetchPlaylists = type === CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST;

  useEffect(() => {
    if (shouldFetchPlaylists) {
      if (!searchText) {
        setDisplayedPlaylists(playlistOptions);
      } else {
        const filteredPlaylists = playlistOptions?.filter((playlist) =>
          playlist.title.toLowerCase().includes(searchText.toLowerCase()),
        );
        setDisplayedPlaylists(filteredPlaylists);
      }
    }
  }, [playlistOptions, searchText]);

  useEffect(() => {
    if (shouldFetchPlaylists) {
      if (playlists && user) {
        setPlaylistOptions(filterPlaylists(playlists, user));
      }
    }
  }, [playlists, user]);

  const handleChange = (value: string) => {
    setSelectedPlaylistId(value);
    setSearchText("");
  };

  return (
    <Form.Item
      name="playlistId"
      label="Choose Playlist"
      rules={[{ required: true, message: "Playlist is required!" }]}
    >
      <Select
        showSearch
        value={selectedPlaylistId}
        placeholder="Select a playlist"
        style={{ width: "100%" }}
        filterOption={false}
        onSearch={(value) => setSearchText(value)}
        onChange={handleChange}
        dropdownRender={(menu) => (
          <>
            {menu}
            {loadingPlaylists && (
              <div style={{ textAlign: "center", padding: 10 }}>
                <Spin size="small" />
              </div>
            )}
          </>
        )}
      >
        {displayedPlaylists?.map((playlist) => (
          <Option key={playlist.playlistId} value={playlist.playlistId}>
            {playlist.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PlaylistsDropDown;
