import { GoogleIcon } from "src/components";
import {
  ButtonContents,
  ButtonIcon,
  ButtonState,
  ContentWrapper,
  StyledButton,
} from "./GoogleSignInButton.styles";

const GoogleSignInButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <ButtonState />
      <ContentWrapper>
        <ButtonIcon>
          <GoogleIcon />
        </ButtonIcon>
        <ButtonContents>Sign in with Google</ButtonContents>
      </ContentWrapper>
    </StyledButton>
  );
};

export default GoogleSignInButton;
