import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MODAL_TYPES, QUERY_PARAMS } from "src/utils/enums";

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalState, setModalState] = useState({
    isOpen: false,
    modalType: null,
    id: null,
    customData: null,
  });
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [customBreadcrumb, setCustomBreadcrumb] = useState(null);
  const skipQueryParams = [
    QUERY_PARAMS.CONVERSATION_SCOPE,
    QUERY_PARAMS.INVITATION_CODE,
    QUERY_PARAMS.SEND_FORGET_PASSWORD_MAIL,
    QUERY_PARAMS.FORGET_PASSWORD,
    QUERY_PARAMS.SHAREABLE_CLIP,
  ];
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let found = false;

    Object.entries(QUERY_PARAMS).forEach(([key, param]) => {
      const id = searchParams.get(param);

      if (skipQueryParams.includes(QUERY_PARAMS[key])) {
        return;
      }
      if (id) {
        setModalState({
          isOpen: true,
          modalType: MODAL_TYPES[key],
          id: id,
          customData: null,
        });
        addBreadcrumb(id, location.pathname + location.search);
        found = true;
      }
    });

    if (!found) {
      setModalState({
        isOpen: false,
        modalType: null,
        id: null,
        customData: null,
      });
      setCustomBreadcrumb(null);
    }
  }, [location.search, location.pathname]);

  const addBreadcrumb = (label, path) => {
    setBreadcrumbs((current) => {
      const existsAt = current.findIndex((crumb) => crumb.path === path);
      if (existsAt !== -1) {
        return current.slice(0, existsAt + 1);
      }
      return [...current, { label, path }];
    });
  };

  const removeBreadcrumb = (path) => {
    if (path) {
      const newPath = path.split("?")[0];
      const modalType = Object.keys(MODAL_TYPES).find((key) =>
        newPath.includes(MODAL_TYPES[key]),
      );
      setModalState({
        isOpen: modalType ? true : false,
        modalType: modalType || null,
        id: null,
        customData: null,
      });
    }
    setBreadcrumbs((current) => current.filter((crumb) => crumb.path !== path));
  };

  const openModal = (type, id = null, data = null) => {
    setModalState({ isOpen: true, modalType: type, id, customData: data });
  };

  const closeModal = () => {
    navigate(location.pathname, { replace: true });
    setModalState({
      isOpen: false,
      modalType: null,
      id: null,
      customData: null,
    });
    ResetBreadcrumb();
  };

  const handleBreadcrumbClick = (path) => {
    removeBreadcrumb(path);
  };
  const ResetBreadcrumb = () => {
    setBreadcrumbs([]);
    setCustomBreadcrumb(null);
  };

  const value = {
    modalState,
    closeModal,
    openModal,
    handleBreadcrumbClick,
    ResetBreadcrumb,
    breadcrumbs,
    setCustomBreadcrumb,
    customBreadcrumb,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
