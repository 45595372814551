import React from "react";
import { List } from "antd";
import { SearchLink } from "src/components";
import { TrucoParagraph } from "src/components";

interface SearchResult {
  id: string;
  url: string;
  title: string;
  transcript: string;
}

export const SearchResults: React.FC<{ searchResults: SearchResult[] }> = ({
  searchResults,
}) => {
  return (
    <>
      {searchResults && (
        <List
          dataSource={searchResults}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <SearchLink id={item.id} url={item.url} title={item.title} />
                }
                description={
                  <TrucoParagraph maxNumLines={3}>
                    {item.transcript}
                  </TrucoParagraph>
                }
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
};
