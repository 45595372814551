import React, { useState } from "react";
import { Button, Card, List, Space, Spin, Tooltip, notification } from "antd";
import {
  PlusOutlined,
  CopyOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { DivLink, StyledBorderedTag } from "src/components";
import { useNavigate } from "react-router-dom";
import { SpaceBetweenDiv, TagContainer } from "../Profile.styles";
import { Task } from "@mui/icons-material";
import { color, elementSize } from "src/styles/variables";
import { useUserState } from "src/state/UserState";
import { ACCESS_TYPE } from "src/utils/enums";
import { capitalizeFirstLetter } from "src/utils/utils";
import { ProfileTabs } from "../Profile.enums";

type OrganizationCardProps = {
  title: string;
  items: any[];
  path: string;
  type: string;
  docsUrl: string;
};

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  title,
  items,
  path,
  type,
  docsUrl,
}) => {
  const navigate = useNavigate();
  const { user } = useUserState();
  const hasAccessToEdit =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;
  const [copiedIDs, setCopiedIDs] = useState([]);

  const onCopy = (id) => {
    setCopiedIDs((currentIDs) => [...currentIDs, id]);
    navigator.clipboard.writeText(id).then(() => {
      notification.success({
        message: "Copied!",
        description: "ID has been copied to your clipboard.",
        duration: 2,
      });
    });
    setTimeout(() => {
      setCopiedIDs((currentIDs) =>
        currentIDs.filter((copyId) => copyId !== id),
      );
    }, 3000);
  };
  function renderTags(tags) {
    return tags.map((element, index) => {
      const isLongTag = element?.name?.length > 20;
      const tagElement = (
        <StyledBorderedTag key={index} color={element.color}>
          {isLongTag ? `${element?.name?.slice(0, 20)}...` : element?.name}
        </StyledBorderedTag>
      );

      return isLongTag ? (
        <Tooltip title={element?.name} key={element?.name}>
          {tagElement}
        </Tooltip>
      ) : (
        tagElement
      );
    });
  }
  function renderAssignmentOrEvaluationTags(item) {
    const tags =
      item.teams?.length > 0
        ? item.teams.map((team) => ({ name: team }))
        : item.evaluations?.length > 0
          ? item.evaluations.map((evaluation) => ({ name: evaluation }))
          : [];

    return renderTags(tags);
  }
  function renderCalibrationTags(item) {
    const tags = [
      {
        name: item.isDone ? "Closed" : "Active",
        color: !item.isDone ? color.green : undefined,
      },
    ];

    return renderTags(tags);
  }

  return (
    <Card
      title={
        <Space>
          {title}
          {docsUrl && (
            <InfoCircleTwoTone
              twoToneColor={color.orange}
              onClick={() => window.open(docsUrl, "_blank")}
            />
          )}
        </Space>
      }
      extra={
        hasAccessToEdit && (
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => navigate(path)}
          />
        )
      }
    >
      <Spin spinning={!items}>
        {items && Object.values(items).length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={Object.values(items).filter(
              (form) => !form?.["deleted"],
            )}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Space
                      size="large"
                      align="center"
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <DivLink
                        hoverColor={color.orange}
                        onClick={() =>
                          hasAccessToEdit && navigate(`${path}/${item.id}`)
                        }
                      >
                        {item?.title}
                      </DivLink>
                      {item?.analysisStructures?.length > 0 && (
                        <Tooltip
                          title="Copy ID to clipboard"
                          style={{ paddingBottom: elementSize.xs }}
                        >
                          {copiedIDs.includes(item.id) ? (
                            <Task
                              style={{
                                color: color.lightGreen,
                                width: elementSize.ml,
                                height: elementSize.md,
                              }}
                            />
                          ) : (
                            <CopyOutlined
                              style={{
                                width: elementSize.ml,
                                height: elementSize.ml,
                                cursor: "pointer",
                              }}
                              onClick={() => onCopy(item.id)}
                            />
                          )}
                        </Tooltip>
                      )}
                    </Space>
                  }
                  description={
                    <SpaceBetweenDiv>
                      {item?.description && <div>{item?.description}</div>}

                      {type === ProfileTabs.ASSIGNMENTS && (
                        <div>
                          {item?.recurrence} assigning
                          {item?.reviewsPerReviewer} reviews per reviewer.
                        </div>
                      )}
                      {type === ProfileTabs.CALIBRATIONS && (
                        <>
                          {`${capitalizeFirstLetter(
                            item?.assignmentLogic,
                          )} Assignment`}
                        </>
                      )}
                      <TagContainer>
                        {type === ProfileTabs.EVALUATION ||
                        type === ProfileTabs.ASSIGNMENTS
                          ? renderAssignmentOrEvaluationTags(item)
                          : type === ProfileTabs.CALIBRATIONS
                            ? renderCalibrationTags(item)
                            : null}
                      </TagContainer>
                    </SpaceBetweenDiv>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          "None yet!"
        )}
      </Spin>
    </Card>
  );
};

export default OrganizationCard;
