import { useState, useEffect } from "react";
import axios from "axios";

const useFetchFileUrl = (fileId) => {
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_TRUCO_BACKEND}/get_file_url`,
          { file_id: fileId },
          { headers: { "Content-Type": "application/json" } },
        );
        setUrl(response.data.url);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };

    if (fileId && !url) {
      fetchUrl();
    }
  }, [fileId, url]);

  return { url, error };
};

export default useFetchFileUrl;
