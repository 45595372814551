import styled from "styled-components";
import {
  spacing,
  color,
  fontSize,
  maxWidth,
  hexToRgba,
  deviceSize,
  elementSize,
  borderRadius,
  grayBoxShadow,
} from "src/styles/variables";
import { Menu, Badge, Avatar } from "antd";
import {
  AccountCircle,
  Close,
  Forum,
  Menu as MenuIcon,
} from "@mui/icons-material";

export const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 82px;
  ${(props) => {
    if (props.headercolor != null) {
      return `
    
        background-color: ${props.headercolor};
        color: ${color.white};
        fill: ${color.white};
      `;
    } else {
      return `
       
        background-color: transparent; // or any default color you wish
        color: ${color.grayDark};
        fill: ${color.olive};
      `;
    }
  }}
  > ul {
    list-style: none;
    white-space: nowrap;
    padding: 0;
  }
  > ul li {
    text-decoration: none;
    line-height: ${spacing.xl};
    letter-spacing: 2px;
    padding: 0px 40px 0px 0px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
  }
  > ul li a {
    text-decoration: none;
  }

  > ul li a.current {
    text-decoration: underline;
  }
`;
export const NavigationBarContainer = styled.ul`
  display: flex;
  max-width: ${maxWidth};

  > li a.current {
    text-decoration: underline;
  }
`;

export const SignInContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.xs};
  @media (min-width: ${deviceSize.large_phone}) {
    padding-left: 5.5vw;
  }
`;

const createStyledIcon = (Icon) => styled(Icon).withConfig({
  shouldForwardProp: (prop) => prop !== "visible",
})`
  && {
    width: 35px;
    height: 35px;
    fill: inherit;
    margin-left: auto;
    display: ${(props) => (props.visible ? "flex" : "none")};
    transition: opacity 0.3s ease;
    top: 20px;
    right: 4.5vw;
    opacity: 0;
    @media (min-width: ${deviceSize.large_phone}) {
      display: none;
    }

    ${({ visible }) =>
      visible &&
      `
        opacity: 1;
      `}
  }
`;
export const StyledMenuIcon = createStyledIcon(MenuIcon);
export const StyledCloseIcon = createStyledIcon(Close);

const styledLogIcon = (Icon, extraStyles = "") => styled(Icon)`
  && {
    width: 29px;
    height: 29px;
    display: flex;
    fill: inherit;
    transition: opacity 0.3s ease;
    ${extraStyles}// Interpolate extra styles here
  }
`;
export const StyledLogInIcon = styledLogIcon(
  AccountCircle,
  `
  @media (min-width: ${deviceSize.large_phone}) {
    display: none;
  }
`,
);

export const NavMenu = styled(Menu)`
  width: 90vw;
  justify-content: center;
  border: none;
  background: inherit;
  color: inherit;
  > li div span {
    font-size: ${fontSize.bodyLarge};
  }

  ${(props) => {
    if (props.headercolor != null) {
      return `
        color: ${color.white};
        > li div span,
        .ant-menu-item,
        .ant-menu-item-icon,
        .ant-menu-submenu-arrow,
        .ant-menu-title-content,
        .ant-menu-item-active {
            color: ${color.white} !important;
        }
      `;
    }
  }}

  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;

export const NavMenuMobileContainer = styled.div`
  top:-1px:
  position: absolute;
  @media (min-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;
export const NavMenuDeskTopContainer = styled.div`
  top:-1px:
  position: absolute;

  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;

export const CustomizedMenu = styled(Menu)`
  width: 90vw;
  border-radius: 8px;
  justify-content: center;
  border: none;
  ${(props) => {
    if (props.headercolor != null) {
      return `
        background-color: ${color.white};
        color: ${color.olive};
        > li div span,
        .ant-menu-item,
        .ant-menu-item-icon,
        .ant-menu-submenu-arrow,
        .ant-menu-title-content,
        .ant-menu-item-active {
            color: ${color.olive} !important;
        }
      `;
    } else {
      return `
        background-color: ${color.olive};
        color: ${color.white};
        > li div span,
        .ant-menu-item,
        .ant-menu-item-icon,
        .ant-menu-submenu-arrow,
        .ant-menu-title-content,
        .ant-menu-item-active {
            color: ${color.white} !important;
        }
      `;
    }
  }}

  z-index: 10;
  position: absolute;

  @media (min-width: ${deviceSize.large_phone}) {
    width: 20vw;
    right: 3vw;
  }

  &&.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
    margin-left: 10px !important;
  }

  &&.ant-menu-light.ant-menu-inline .ant-menu-item:first-child {
    min-height: ${elementSize.xxxxl};
  }
  &&.ant-menu-light .ant-menu-item-selected {
    background-color: ${hexToRgba(color.grayLight, 0.2)};
  }
  &&.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
  }
  && .ant-menu-submenu-title:hover {
    background-color: inherit;
  }

  &&.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    border-radius: 8px;
  }
`;

export const DescriptionMenuText = styled.div`
  font-size: 11px;
  display: block;
  margin-top: -23px;
`;

export const ConversationIcon = styled(Forum)`
  color: ${color.black};
  margin-top: ${borderRadius.xs};
  @media (max-width: ${deviceSize.large_phone}) {
    margin-top: ${borderRadius.sm};
    color: ${color.olive};
    width: ${elementSize.xxl}!important;
    height: ${elementSize.xxl}!important;
  }
`;

export const MessengerIconNavBar = styled(Avatar)`
  cursor: pointer;
  width: ${elementSize.xll};
  height: ${elementSize.xll};
  border-radius: 50%; /* Makes the border rounded */
  box-shadow: ${grayBoxShadow};
  line-height: 31px;
  font-size: ${fontSize.displayXXL};
  background-color: ${(props) =>
    props.headercolor == null ? `${color.gray}` : `${color.white}`};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${deviceSize.large_phone}) {
    background-color: transparent;
    border: none;
    box-shadow: none;
    overflow: hidden;
    width: 30px;
    height: 30px;
    line-height: 34px;
    path {
      color: ${(props) =>
        props.headercolor == null ? `${color.olive}` : `${color.white}`};
    }
  }
`;

export const CustomizedBadge = styled(Badge)`
  &&.ant-badge .ant-badge-count {
    background-color: ${color.orange};
    @media (max-width: ${deviceSize.large_phone}) {
      margin-right: ${spacing.xxxss};
    }
  }
`;
