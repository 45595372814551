import { Modal, Radio, Select, Form, Input, Alert } from "antd";
import { useEffect, useState } from "react";
import {
  addDocumentWithId,
  getRecordsFromFireStore,
  updateDocInFireStore,
} from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { COLLECTION_DATA } from "src/utils/enums";
import { getDomainFromEmail } from "../Customers.utils";
import { spacing } from "src/styles/variables";

export const AccountSetupModal = ({
  visible,
  onClose,
  selectedCustomer,
  companyDomain,
}) => {
  const [accountType, setAccountType] = useState("existing");
  const [accounts, setAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [newAccount, setNewAccount] = useState({
    domain: companyDomain || getDomainFromEmail(selectedCustomer.email) || "",
    name: "",
  });

  const [modalLoading, setModalLoading] = useState(false);
  const { organization } = useOrganizationState();
  const isFormComplete = () => {
    if (accountType === "existing") {
      return selectedAccountId !== null;
    } else {
      return newAccount.domain && newAccount.name;
    }
  };

  const fetchAccounts = async () => {
    try {
      const { data } = await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.ACCOUNTS}`,
      );
      console.log("data", data);
      setAccounts(data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchCustomersByDomain = async (domain) => {
    try {
      const { data } = await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
        [
          {
            comparisonField: "companyDomain",
            comparisonOperator: "==",
            value: domain,
          },
        ],
      );

      return data;
    } catch (error) {
      console.error("Error fetching customers by domain:", error);
      return [];
    }
  };
  useEffect(() => {
    if (accountType === "existing") {
      fetchAccounts();
    }
  }, []);

  const handleAccountTypeChange = (e) => {
    const selectedType = e.target.value;
    setAccountType(selectedType);
    setSelectedAccountId(null);
    setNewAccount({ ...newAccount, name: "" });
  };

  const handleSave = async () => {
    setModalLoading(true);
    try {
      let accountId = selectedAccountId;

      if (accountType === "new") {
        // Step 1: Create a new account in Firestore
        const newAccountData = {
          domain: newAccount.domain,
          name: newAccount.name,
          updatedAt: new Date().toISOString(),
        };
        const createdAccountID = await addDocumentWithId(
          `/organization/${organization}/${COLLECTION_DATA.ACCOUNTS}`,
          newAccountData,
        );
        accountId = createdAccountID; // Retrieve ID of the new account

        // Step 2: Fetch all customers with the matching companyDomain
        let matchingCustomers = await fetchCustomersByDomain(newAccount.domain);

        const isSelectedCustomerIncluded = matchingCustomers.some(
          (customer) => customer.id === selectedCustomer.id,
        );

        // If not included, add selectedCustomer to matchingCustomers
        if (!isSelectedCustomerIncluded) {
          matchingCustomers = [...matchingCustomers, selectedCustomer];
        }

        // Step 3: Update each matching customer with the new accountId
        await Promise.all(
          matchingCustomers.map(async (customer: any) => {
            let updateData = { accountId };

            // Only update companyDomain if newAccount.domain differs from the existing companyDomain
            if (
              newAccount.domain &&
              newAccount.domain !== customer.companyDomain
            ) {
              updateData["companyDomain"] = newAccount.domain;
            }
            if (
              newAccount?.name &&
              newAccount.name !== selectedCustomer.company
            ) {
              updateData["company"] = newAccount.name;
            }
            await updateDocInFireStore(
              `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${customer.id}`,
              updateData,
            );
          }),
        );
      } else {
        // Fetch domain of selected existing account (if using an existing account)
        const selectedAccount = accounts.find(
          (account) => account.id === selectedAccountId,
        );
        let domain = selectedAccount?.domain;

        const updateData = { accountId };

        // Only update companyDomain if domain differs from selectedCustomer's companyDomain
        if (domain && domain !== selectedCustomer.companyDomain) {
          updateData["companyDomain"] = domain;
        }
        if (
          selectedAccount?.name &&
          selectedAccount?.name !== selectedCustomer.company
        ) {
          updateData["company"] = selectedAccount.name;
        }
        await updateDocInFireStore(
          `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${selectedCustomer.id}`,
          updateData,
        );
      }

      onClose(); // Close modal after save
    } catch (error) {
      console.error("Error saving account information:", error);
    } finally {
      setModalLoading(false);
      window.location.reload();
    }
  };

  return (
    <Modal
      visible={visible}
      title="Select or Create an Account"
      onCancel={onClose}
      onOk={handleSave}
      confirmLoading={modalLoading}
      okButtonProps={{ disabled: !isFormComplete() }} // Disable OK button if form is incomplete
    >
      <Radio.Group onChange={handleAccountTypeChange} value={accountType}>
        <Radio value="existing">Use Existing Account</Radio>
        <Radio value="new">Create New Account</Radio>
      </Radio.Group>

      {accountType === "existing" ? (
        <Select
          placeholder="Select an account"
          style={{ width: "100%", marginTop: 16 }}
          onChange={setSelectedAccountId}
        >
          {accounts.map((account) => (
            <Select.Option key={account.id} value={account.id}>
              {account.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Form layout="vertical" style={{ marginTop: 16 }}>
          <Form.Item label="Domain" rules={[{ required: true }]}>
            <Input
              value={
                newAccount.domain ||
                companyDomain ||
                getDomainFromEmail(selectedCustomer.email)
              }
              disabled={
                !!companyDomain || !!getDomainFromEmail(selectedCustomer.email)
              }
              onChange={(e) =>
                setNewAccount({ ...newAccount, domain: e.target.value })
              }
              placeholder="e.g., example.com"
            />
          </Form.Item>
          <Form.Item label="Account Name" rules={[{ required: true }]}>
            <Input
              value={newAccount.name}
              onChange={(e) =>
                setNewAccount({ ...newAccount, name: e.target.value })
              }
              placeholder="Enter account name"
            />
          </Form.Item>
          <Alert
            message="Note: All customers with the same domain will be automatically added to this account."
            type="warning"
            showIcon
            style={{ marginBottom: spacing.lg }}
          />
        </Form>
      )}
    </Modal>
  );
};
