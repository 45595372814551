import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import {
  Input,
  Button,
  Badge,
  Popover,
  Space,
  Spin,
  Menu,
  message,
} from "antd";
import {
  EditTwoTone,
  SaveTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CaretRightFilled,
} from "@ant-design/icons";

import { RATINGS, ANALYSIS_TYPE, ACCESS_TYPE } from "src/utils/enums";

import {
  ThumbsUpIcon,
  ThumbsDownIcon,
  NAIcon,
} from "src/components/Loading/icons";

import { Chat, TrucoChat } from "src/components";
import {
  isReviewerInCalibration,
  getTranscriptFromUtterances,
} from "src/utils/utils";

import { color, elementSize } from "src/styles/variables";

import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import {
  generateDataSourceWithCalibration,
  handleFeedbackDelete,
  updateTranscriptToDb,
  updateScoresRemoveReason,
  updateEvaluation,
  ARRAY_SEPARATOR,
  handleAnalysisEdit,
  handleAnalysisBulkEdit,
  validateNewAnalysis,
  getCurrentEvaluationForm,
  runAnalysis,
  getConversationScopes,
  checkOrganizationAndScope,
} from "../../Dashboard.utils";

import { Team } from "./Team";
import { Participants } from "./Participants";
import { hasUpdateInfo } from "src/utils/utils";
import { EvaluationSelector } from "../../../Global/EvaluationSelector";
import { SpaceBetweenDiv } from "../../../Profile/Profile.styles";
import {
  AnalysisTableComponent,
  EditableText,
  UpdatedTextDiv,
} from "../../Dashboard.styles";
import { FeedbackCollapse } from "./AnalysisContent.styles";
import { SHARE_SCOPE } from "../../Dashboard";
import {
  BlurredBackGroundWithErrorMsg,
  INTERNAL_TEAM_MESSAGE,
} from "src/components/Global/NoPermissions";
import { PlaylistAndSignals } from "./PlaylistAndSignals";

export const RatingComponent = ({ rating }) => {
  return rating === RATINGS.ONE ? (
    <ThumbsUpIcon />
  ) : rating === RATINGS.ZERO ? (
    <ThumbsDownIcon />
  ) : rating === RATINGS.NONE ? (
    <NAIcon />
  ) : (
    rating
  );
};

export const RatingOptions = ({
  selectedRating,
  onClick = (newValue) => undefined,
}) => {
  const ratingOptions = [
    { value: RATINGS.ONE, label: <ThumbsUpIcon />, text: "Thumbs Up" },
    { value: RATINGS.ZERO, label: <ThumbsDownIcon />, text: "Thumbs Down" },
    { value: RATINGS.NONE, label: <NAIcon />, text: "None" },
  ];

  return (
    <Space style={{ display: "flex" }}>
      {ratingOptions.map((option) => (
        <div
          key={option.value}
          onClick={() => onClick(option.value)}
          style={{
            cursor: "pointer",
            opacity: selectedRating === option.value ? "1" : "0.2",
          }}
        >
          {option.label}
        </div>
      ))}
    </Space>
  );
};

export const AnalysisTable = ({
  modalData,
  setModalData = null,
  handleEvaluation = null,
  editPermission = false,
}) => {
  const {
    organization,
    organizationSettings,
    evaluationForms,
    setEvaluationForms,
  } = useOrganizationState();
  const { user } = useUserState();

  const { analysis, evaluation, evaluation_form, calibration, id } = modalData;
  const evaluation_id = evaluation_form?.id;
  const [editing, setEditing] = useState(null);

  const isCalibrator = isReviewerInCalibration(calibration, user);

  const resetEditing = () => {
    setEditing(null);
  };

  useEffect(() => {
    return () => {
      resetEditing();
    };
  }, []);

  const handleVerification = async (newVerified) => {
    handleEvaluation("verified", newVerified);
    const participantIndex = evaluation?.verifications?.indexOf(user?.email);
    if (participantIndex !== -1 && !newVerified) {
      evaluation?.verifications?.splice(participantIndex, 1);
      handleEvaluation("verifications", evaluation?.verifications);
    }
  };

  const handleSave = async () => {
    const validationErrors = validateNewAnalysis(editing);
    if (validationErrors?.length === 0) {
      let currentEvaluationForm = await getCurrentEvaluationForm(
        organization,
        evaluation_id,
        evaluation_form,
        evaluationForms,
        setEvaluationForms,
      );

      const updatesToSave = {
        evaluation: updateEvaluation(evaluation, editing, analysis, user),
        analysis: updateScoresRemoveReason(editing, currentEvaluationForm),
      };
      if (isCalibrator) {
        calibration["details"][user.email] = updatesToSave;
        const calibratorUpdates = { calibration };
        updateTranscriptToDb(organization, modalData.id, calibratorUpdates);
      } else {
        updateTranscriptToDb(organization, modalData.id, updatesToSave);
      }
      setModalData({
        ...modalData,
        ...updatesToSave,
      });
    } else {
      alert(
        "Rating invalid for the following\n" +
          validationErrors.map((tuple) => tuple.join(": ")).join("\n"),
      );
    }
    resetEditing();
  };

  const [onFlyEvaluation, setOnFlyEvaluation] = useState<{
    id: string | null;
    isDefault: boolean;
  } | null>(null);

  const [loadingAnalysisOnFly, setLoadingAnalysisOnFly] = useState(false);

  const handleEvaluationId = (selection) => {
    if (selection === "Default") {
      setOnFlyEvaluation({ id: null, isDefault: true });
    } else {
      setOnFlyEvaluation({ id: selection, isDefault: false });
    }
  };
  const handleAssignEvaluationOnFly = async () => {
    setLoadingAnalysisOnFly(true);

    try {
      const newAnalysisData = await runAnalysis(
        id,
        organization,
        onFlyEvaluation.id,
      );

      setModalData({
        ...modalData,
        analysis: newAnalysisData.analysis,
        evaluation_form: {
          id: onFlyEvaluation.id || "Default",
          title: onFlyEvaluation.id
            ? evaluationForms[onFlyEvaluation.id].title
            : onFlyEvaluation?.isDefault
              ? "Default"
              : null,
          version: onFlyEvaluation.id
            ? evaluationForms[onFlyEvaluation.id]?.version
            : null,
        },
      });

      message.success("Successfully added evaluation form to transcript.");
    } catch (error) {
      console.error(error);
      message.error("Failed to add evaluation form to transcript.");
    } finally {
      setTimeout(() => {
        setLoadingAnalysisOnFly(false);
      }, 2000);
    }
  };

  if (!analysis) {
    return null;
  }

  let dataSource = generateDataSourceWithCalibration(
    analysis,
    organizationSettings,
  );

  const columns = [
    {
      dataIndex: "label",
      key: "label",
      width: "30%",
      render: (text, record) => {
        if (record.isGroupHeader) {
          return <b>{text}</b>;
        }
        return text;
      },
    },
    {
      title: "Value",
      key: "value",
      width: "60%",
      dataIndex: ANALYSIS_TYPE.VALUE,
      render: (text, record) => {
        const { analysisIndex, metricIndex, isGroupHeader, analysisType } =
          record;

        let editValue = editing?.[analysisIndex]?.value?.[metricIndex]?.value;
        const valueEdited =
          editValue !== undefined && !isEqual(editValue, text);

        editValue = Array.isArray(editValue)
          ? editValue.join(ARRAY_SEPARATOR)
          : editValue;

        const { hasBeenUpdated, oldValue, updatedValue } = hasUpdateInfo(
          analysisType,
          record.key.split("-")[1],
          evaluation,
          "value",
        );
        const parsedText = Array.isArray(text)
          ? text.map((text_chunk) => (
              <>
                {" "}
                {text_chunk}
                <br />
              </>
            ))
          : editValue !== undefined
            ? editValue
            : text;

        const popOverValueText = Array.isArray(text) ? (
          text.map((text_chunk, index) =>
            hasBeenUpdated ? (
              <>
                <b>Old value: </b>
                {oldValue[index]}
                <br />
                <b>Updated value: </b>
                {updatedValue[index]}
                <br />
              </>
            ) : (
              <>
                {" "}
                {text_chunk}
                <br />
              </>
            ),
          )
        ) : editValue !== undefined ? (
          editValue
        ) : hasBeenUpdated ? (
          <>
            <b>Old value: </b>
            {oldValue}
            <br />
            <b>Updated value: </b>
            {updatedValue}
            <br />
          </>
        ) : (
          text
        );

        return editPermission && editing !== null && !isGroupHeader ? (
          <Input.TextArea
            value={editValue}
            style={{ color: valueEdited ? color.orange : undefined }}
            placeholder="Data"
            onChange={(e) =>
              handleAnalysisEdit(
                e.target.value,
                analysisIndex,
                metricIndex,
                ANALYSIS_TYPE.VALUE,
                editing,
                setEditing,
              )
            }
            onPaste={(e) => {
              e.preventDefault();
              handleAnalysisBulkEdit(
                e.clipboardData.getData("text/plain"),
                analysisIndex,
                metricIndex,
                "value",
                setEditing,
              );
            }}
          />
        ) : (
          <Popover content={popOverValueText}>
            <UpdatedTextDiv>
              <EditableText
                style={{ color: valueEdited ? color.orange : undefined }}
                onClick={() => setEditing(cloneDeep(analysis))}
              >
                {parsedText}
              </EditableText>
            </UpdatedTextDiv>
          </Popover>
        );
      },
    },
    {
      title: "Rating",
      key: ANALYSIS_TYPE.RATING,
      width: "10%",
      dataIndex: "rating",
      render: (text, record) => {
        const { analysisIndex, metricIndex, isGroupHeader, analysisType } =
          record;

        const { hasBeenUpdated, oldValue, updatedValue } = hasUpdateInfo(
          analysisType,
          record.key.split("-")[1],
          evaluation,
          ANALYSIS_TYPE.RATING,
        );

        const { hasBeenUpdated: hasValueUpdated } = hasUpdateInfo(
          analysisType,
          record.key.split("-")[1],
          evaluation,
          ANALYSIS_TYPE.VALUE,
        );
        const popOverRateText = hasBeenUpdated ? (
          <>
            <b>Old value: </b>
            {oldValue}
            <br />
            <b>Updated value: </b>
            {updatedValue}
            <br />
          </>
        ) : (
          text
        );

        return editPermission && editing !== null && !isGroupHeader ? (
          <RatingOptions
            selectedRating={editing[analysisIndex].value[metricIndex].rating}
            onClick={(newValue) => {
              handleAnalysisEdit(
                newValue,
                analysisIndex,
                metricIndex,
                ANALYSIS_TYPE.RATING,
                editing,
                setEditing,
              );
            }}
          />
        ) : (
          <Popover content={popOverRateText}>
            <UpdatedTextDiv>
              <EditableText onClick={() => setEditing(cloneDeep(analysis))}>
                <RatingComponent rating={text} />
              </EditableText>
              {(hasBeenUpdated || hasValueUpdated) && (
                <span
                  style={{ fontSize: elementSize.md, color: color.mediumGreen }}
                >
                  ✐
                </span>
              )}
            </UpdatedTextDiv>
          </Popover>
        );
      },
    },
  ];

  return (
    <>
      {editPermission && (
        <>
          {analysis.length > 0 ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Space>
                {editing ? (
                  <Button
                    onClick={() => handleSave()}
                    icon={<SaveTwoTone twoToneColor={color.orange} />}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    onClick={() => setEditing(cloneDeep(analysis))}
                    icon={<EditTwoTone twoToneColor={color.orange} />}
                  >
                    Edit
                  </Button>
                )}
                {evaluation?.verified ? (
                  <Button
                    onClick={() => handleVerification(false)}
                    icon={<CloseCircleTwoTone twoToneColor={color.yellow} />}
                  >
                    Unverify
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleVerification(true)}
                    icon={<CheckCircleTwoTone twoToneColor={color.orange} />}
                  >
                    Verify
                  </Button>
                )}
              </Space>
            </div>
          ) : (
            <SpaceBetweenDiv>
              <EvaluationSelector handleEvaluationId={handleEvaluationId} />
              <Button
                type="primary"
                onClick={handleAssignEvaluationOnFly}
                loading={loadingAnalysisOnFly}
                disabled={
                  loadingAnalysisOnFly ||
                  (!onFlyEvaluation?.id && !onFlyEvaluation?.isDefault)
                }
              >
                Run <CaretRightFilled />
              </Button>
            </SpaceBetweenDiv>
          )}
        </>
      )}
      {(dataSource?.length > 0 || loadingAnalysisOnFly) && (
        <Spin spinning={loadingAnalysisOnFly}>
          <AnalysisTableComponent
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="small"
            showHeader={false}
            rowClassName={(record) =>
              record.isGroupHeader ? "groupHeaderRow" : "normalRow"
            }
          />
        </Spin>
      )}
    </>
  );
};

export const AnalysisContent = ({
  modalData,
  setModalData,
  handleEvaluation,
  handleFeedbackUpdate,
  markAsRead,
}) => {
  const { evaluation, calibration, playlists } = modalData;
  const { organization } = useOrganizationState();
  const { user } = useUserState();
  const conversationScopes = getConversationScopes().scopeDetails;
  const viewAnalysis = checkOrganizationAndScope(
    modalData.organizationId,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_EVALUATION_ANALYSIS,
  );
  const viewParticipants = checkOrganizationAndScope(
    modalData.organizationId,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_TEAMS,
  );
  const viewFeedback = checkOrganizationAndScope(
    modalData.organizationId,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_FEEDBACK,
  );
  const viewAskTruco = checkOrganizationAndScope(
    modalData.organizationId,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_ASKTRUCO,
  );
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(
    viewFeedback ? "feedback" : viewAskTruco ? "askTruco" : "feedback",
  );

  const editPermission =
    modalData?.reviewer?.email === user?.email ||
    user?.accessType === ACCESS_TYPE.trucoAdmin;

  const collapseItems = [
    {
      key: "1",
      label: "Evaluation",
      children: (
        <>
          {viewAnalysis ? (
            <AnalysisTable
              modalData={modalData}
              setModalData={setModalData}
              editPermission={editPermission}
              handleEvaluation={handleEvaluation}
            />
          ) : (
            <BlurredBackGroundWithErrorMsg
              errorMessage={INTERNAL_TEAM_MESSAGE}
            />
          )}
        </>
      ),
    },
    playlists?.length > 0 && {
      key: "2",
      label: "Playlists & Signals",
      children: (
        <>
          {viewAnalysis ? (
            <PlaylistAndSignals
              modalData={modalData}
              setModalData={setModalData}
            />
          ) : (
            <BlurredBackGroundWithErrorMsg
              errorMessage={INTERNAL_TEAM_MESSAGE}
            />
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Team",
      children: (
        <>
          {viewParticipants ? (
            <Team
              modalData={modalData}
              setModalData={setModalData}
              handleEvaluation={handleEvaluation}
            />
          ) : (
            <BlurredBackGroundWithErrorMsg
              errorMessage={INTERNAL_TEAM_MESSAGE}
            />
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Participants",
      children: (
        <>
          {viewParticipants ? (
            <Participants modalData={modalData} setModalData={setModalData} />
          ) : (
            <BlurredBackGroundWithErrorMsg
              errorMessage={INTERNAL_TEAM_MESSAGE}
            />
          )}
        </>
      ),
    },
    (viewFeedback || viewAskTruco) && {
      key: "5",
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Menu
            mode="horizontal"
            selectedKeys={[selectedFeedbackType]}
            style={{
              border: "none",
              margin: 0,
              padding: 0,
              flex: 1,
              backgroundColor: "inherit",
            }}
            onClick={(e) => {
              setSelectedFeedbackType(e.key);
              e.domEvent.stopPropagation();
            }}
          >
            {viewFeedback && <Menu.Item key="feedback">Feedback</Menu.Item>}
            {viewAskTruco && <Menu.Item key="askTruco">Ask Truco!</Menu.Item>}
          </Menu>
        </div>
      ),
      children:
        selectedFeedbackType === "feedback" ? (
          <Chat
            header={false}
            border={false}
            onNewMessage={handleFeedbackUpdate}
            onDeleteMessage={(messageIndex, setMessages) =>
              handleFeedbackDelete(
                messageIndex,
                setMessages,
                organization,
                modalData.id,
                user,
                evaluation,
                calibration,
              )
            }
            markAsRead={markAsRead}
            initialMessages={evaluation?.feedback || []}
            padding={0}
            height={"40vh"}
            key={evaluation?.feedback?.length}
          />
        ) : (
          <TrucoChat
            header={false}
            bordered={false}
            height={"40vh"}
            padding={0}
            initialMessage={"Based on the meeting transcript, assist the user."}
            size={"small"}
            citations={[
              {
                url: modalData?.id,
                title: modalData?.file_name,
                transcript: getTranscriptFromUtterances(modalData?.utterances),
                summary: modalData?.summary,
              },
            ]}
          />
        ),
    },
  ].filter(Boolean);

  return (
    <Badge.Ribbon
      text={evaluation?.verified ? "Verified 🎉" : "Unverified 💀"}
      color={evaluation?.verified ? color.orange : color.yellow}
    >
      <FeedbackCollapse
        items={collapseItems}
        defaultActiveKey={collapseItems.map((item) => item.key)}
      />
    </Badge.Ribbon>
  );
};
