import {
  deviceSize,
  spacing,
  color,
  fontSize,
  elementSize,
} from "src/styles/variables";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";

export const MediaContainer = styled.div<{
  isFullscreen: boolean;
  isVideo: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: ${(props) =>
    props.isVideo ? (props.isFullscreen ? "100%" : "1024px") : "400px"};
  height: ${(props) => (props.isVideo && props.isFullscreen ? "100%" : "auto")};
  margin: 0px auto;

  video {
    width: 100%; /* Ensures video fits the container width */
    height: auto; /* Maintains aspect ratio */
    display: block;
  }

  audio {
    width: 100%; /* Ensures video fits the container width */
    height: auto; /* Maintains aspect ratio */
    display: block;
  }
`;

export const CustomControls = styled.div`
  border-radius: ${(props) => (props.isVideo ? "none" : `${spacing.xl}`)};
  background-color: ${color.grayMedium};
  padding: ${spacing.xs};
  display: flex;
  align-items: center;
`;

export const ProgressBar = styled.input`
  flex-grow: 1;
  margin: 0 ${spacing.xs};
  accent-color: ${color.orange};
  @media (max-width: ${deviceSize.large_phone}) {
    width: 30%;
  }
`;

export const TimeDisplay = styled.div`
  color: white;
  font-size: ${fontSize.body};
  @media (min-width: ${deviceSize.large_phone}) {
    width: 6rem;
    margin-left: ${elementSize.sm};
  }
`;

export const PlayPauseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: ${fontSize.bodyLarge};
  cursor: pointer;
`;

export const ControlButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: ${fontSize.bodyLarge};
  cursor: pointer;

  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const ActionContainer = styled.div`
  position: relative;
  display: flex;
  font-size: ${fontSize.body};
  align-items: center;
  vertical-align: middle;
`;

export const VolumeControl = styled.input`
  position: absolute;
  bottom: 45px;
  accent-color: ${color.orange};
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
  width: 80px;
  height: 20px;
  background: ${color.grayLight};
  padding: ${spacing.xs};
  border-radius: 5px;
  display: none;
  color: white;
  font-color: white;
  background-color: ${color.grayMedium};

  ${ActionContainer}:hover & {
    display: block;
  }
`;
export const SpeedControlButton = styled.button`
  background: none;
  border: none;
  color: ${color.white};
  cursor: pointer;
  font-size: ${fontSize.body};
  display: flex;
  align-items: center;
`;

export const SpeedControlDropdown = styled(Dropdown)`
  .ant-dropdown {
    display: none;
  }

  ${ActionContainer}:hover .ant-dropdown {
    display: block;
  }
`;

export const SpeedMenu = styled(Menu)`
  background: ${color.grayMedium};
  border-radius: 5px;
  .ant-dropdown-menu-item {
    color: ${color.white}; // Assuming you want white text on gray background
    &:hover {
      background-color: ${color.grayMedium};
    }
  }
  .ant-dropdown-menu-item-selected {
    background-color: ${color.grayLight};
    &::after {
      display: none; // Remove default selected style
    }
  }
`;

export const ClipInfo = styled.div`
  color: ${color.grayLight};
  font-size: ${fontSize.body};
  margin-left: 10px;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: inline-block;
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledProgressBar = styled(ProgressBar)`
  width: 100%;
  z-index: 2;
  padding: 0;
  margin: 0;
`;

export const ClipMarker = styled.div`
  position: absolute;
  top: 0;
  height: 50%;
  width: ${elementSize.xxs};
  background-color: white;
  opacity: 0.5;
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  gap: ${elementSize.md};
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.xl};
  flex-direction: ${({ isVideo }) => (isVideo ? "row" : "column")};

  @media (max-width: ${deviceSize.large_phone}) {
    flex-direction: column;
  }
`;
