import styled from "styled-components";
import { Card, List } from "antd";
import {
  color,
  borderRadius,
  spacing,
  fontSize,
  fontWeight,
} from "src/styles/variables";

export const CustomChatCard = styled(({ header, border, padding, ...rest }) => (
  <Card {...rest} />
))`
  ${(props) => {
    if (props.padding !== undefined) {
      return `
        .ant-card-body {
          padding: ${props.padding};
        }
      `;
    }
  }}

  ${(props) => {
    if (!props.border) {
      return `
          border: none;
      `;
    }
  }}

  ${(props) => {
    if (props.header) {
      return `
        margin-top: ${spacing.md};
        .ant-card-head {
          background-color: ${color.orange};
        }
        .ant-card-head-title {
          color: ${color.white};
        }
      `;
    } else {
      return `
        background-color: inherit;
        .ant-card-head {
          display: none;
        }
      `;
    }
  }}
`;
export const ChatMessageItem = styled(List.Item.Meta)`
  width: none;
  ${(props) => {
    if (props.title === "Truco") {
      return `
      .ant-list-item-meta-content{
      border-radius: ${borderRadius.xs};
      }
        .ant-list-item-meta-description{
  color:${color.black} !important;
  }
    .ant-list-item-meta-title{
  color:${color.black} !important;
  }

        `;
    } else {
      return `
        `;
    }
  }}
`;

export const TimeTextStyle = styled.div`
  color: ${color.grayDark};
  font-size: ${fontSize.body};
  text-decoration: none;
  font-weight: ${fontWeight.light};
`;
