import React, { useEffect, useState } from "react";
import { Badge, Tooltip, Col, Table, Typography, Spin } from "antd";

import {
  EditIconContainer,
  PlaylistImage,
  PlaylistImageContainer,
  PlaylistVideoThumbnail,
} from "./Playlist.styles";
import {
  EditOutlined,
  EyeOutlined,
  WifiOutlined,
  CaretUpOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import { useOrganizationState } from "src/state/OrganizationState";
import { color } from "src/styles/variables";
import {
  generatePlaylistShareableLink,
  getThumbnailSource,
  hasEditAccess,
  hasPlaylistAccess,
} from "./Playlist.utils";
import { useNavigate } from "react-router-dom";
import { useUserState } from "src/state/UserState";
import { PLAYLIST_TYPE } from "src/utils/enums";
import { PlaylistTrendCard } from "./PlaylistDetails/PlaylistTrendCard";

export const PlaylistImageCard = ({ playlist }) => {
  const { organization } = useOrganizationState();
  const { user } = useUserState();
  const navigate = useNavigate();
  const [loadingVideoThumbnail, setLoadingVideoThumbnail] = useState(true);
  const [thumbnail, setThumbnail] = useState<{ type: string; src: string }>({
    type: "image",
    src: "/playlist.jpeg",
  });
  const [thumbnailFetched, setThumbnailFetched] = useState(false);

  const hasAccess = hasPlaylistAccess(user, organization, playlist);
  const clipsWithAccess = playlist?.clips?.filter(
    (clip) =>
      (playlist.type === PLAYLIST_TYPE.SIGNAL &&
        clip.participants?.includes(user?.email)) ||
      hasAccess,
  );

  useEffect(() => {
    const fetchThumbnail = async () => {
      const thumbnailSource = await getThumbnailSource(playlist);
      setThumbnail(thumbnailSource);
      setThumbnailFetched(true);
    };
    fetchThumbnail();
  }, [playlist]);

  useEffect(() => {
    if (thumbnailFetched) {
      setLoadingVideoThumbnail(false);
    }
  }, [thumbnailFetched]);

  return (
    <Badge.Ribbon text={`${playlist.clipCount} clips`}>
      <PlaylistImageContainer
        onClick={() =>
          navigate(
            `${generatePlaylistShareableLink(organization, playlist.playlistId)}`,
          )
        }
      >
        {thumbnail.type.includes("video") ? (
          <PlaylistVideoThumbnail
            src={thumbnail.src}
            onLoadedData={() => setLoadingVideoThumbnail(false)}
            style={{ display: loadingVideoThumbnail ? "none" : "block" }}
          />
        ) : (
          <PlaylistImage alt="playlist" src={thumbnail.src} />
        )}

        <EditIconContainer>
          {playlist.type !== PLAYLIST_TYPE.SIGNAL && (
            <>
              {hasEditAccess(playlist, user) ? (
                <EditOutlined
                  style={{
                    color: color.white,
                  }}
                />
              ) : (
                <EyeOutlined
                  style={{
                    color: color.white,
                  }}
                />
              )}
            </>
          )}
          {playlist.type === PLAYLIST_TYPE.SIGNAL && (
            <Tooltip title="Signal Playlist">
              <WifiOutlined
                style={{
                  color: color.white,
                }}
              />
            </Tooltip>
          )}
        </EditIconContainer>
      </PlaylistImageContainer>
    </Badge.Ribbon>
  );
};

export const SignalCard = ({ spanValue = 24, height = 50 }) => {
  const navigate = useNavigate();
  const { organization, signalsData, loadingSignalsData } =
    useOrganizationState();

  const currentMonth = new Date().getMonth();
  const lastMonth = currentMonth - 1;
  const lastMonthDays = new Date(
    new Date().getFullYear(),
    lastMonth + 1,
    0,
  ).getDate();
  const currentMonthDays = new Date().getDate();

  const columns = [
    {
      title: "Signal",
      key: "signal",
      render: (_, playlist) => (
        <>
          <strong>{playlist?.playlistTitle?.toUpperCase()}</strong>
          <p>{playlist?.playlistDescription}</p>
        </>
      ),
    },
    {
      title: "Trend",
      key: "trend",
      width: "30%",
      render: (_, playlist) => (
        <PlaylistTrendCard
          key={`${playlist?.playlistTitle}-trend`}
          dailyCounts={playlist.dailyCounts}
        />
      ),
    },
    {
      title: "Volume (Percentage Change)",
      key: "percentageChange",
      render: (_, playlist) => {
        const lastMonthAvgClipsPerDay =
          playlist.previousPeriodCount / lastMonthDays;
        const currentMonthAvgClipsPerDay =
          playlist.currentPeriodCount / currentMonthDays;

        let percentageChange = 0; // Default to 0 if last month's avg clips per day is 0

        if (lastMonthAvgClipsPerDay > 0) {
          percentageChange = Math.round(
            ((currentMonthAvgClipsPerDay - lastMonthAvgClipsPerDay) /
              lastMonthAvgClipsPerDay) *
              100,
          );
        } else if (currentMonthAvgClipsPerDay > 0) {
          percentageChange = 100;
        }
        return (
          <>
            <Typography.Text>{playlist?.currentPeriodCount} (</Typography.Text>
            <Typography.Text
              type={percentageChange >= 0 ? "success" : "danger"}
            >
              {percentageChange >= 0 ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownFilled />
              )}
              {Math.abs(percentageChange)}%
            </Typography.Text>
            <Typography.Text>)</Typography.Text>
          </>
        );
      },
    },
  ];

  return (
    <Col xl={{ span: spanValue }} md={24}>
      <Spin spinning={loadingSignalsData}>
        <Table
          dataSource={signalsData}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id}
          onRow={(record) => ({
            onClick: () => {
              navigate(
                `${generatePlaylistShareableLink(organization, record.playlistId)}`,
              );
            },
          })}
        />
      </Spin>
    </Col>
  );
};
