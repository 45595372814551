import { color, deviceSize } from "src/styles/variables";
import styled from "styled-components";
import { Card, Progress } from "antd";
import { CenteredDiv } from "../LandingPage/LandingPage.styles";

export const StyledAudioCard = styled(Card)`
  background-color: ${color.grayLight};
  border: none;
  color: ${color.black};
  width: 17rem;

  &:hover {
    border-left: 3px solid ${color.orange};
  }

  .ant-card-body {
    padding: 12px;
  }

  .anticon {
    color: ${color.orange};
  }

  p {
    display: -webkit-box; // Necessary for line-clamp to work
    -webkit-line-clamp: 3; // Number of lines you want to display before truncating
    -webkit-box-orient: vertical; // Orient the content vertically
    overflow: hidden; // Hides the overflow text
    text-overflow: ellipsis; // Adds ellipsis at the end of the text
    line-height: 1.5; // Adjust line-height for consistent spacing, adjust as needed
    max-height: 4.5em; // This is 3 times the line-height
  }
`;
export const TrucoProgress = styled(Progress)`
  &&:where(.css-dev-only-do-not-override-pr0fja).ant-progress .ant-progress-bg {
    background-color: ${color.orange} !important;
  }
`;

export const ResponsiveSizedCard = styled(Card)`
  width: ${(props) => props.width && props.width};
  @media (max-width: ${deviceSize.large_phone}) {
    width: 90%;
  }
`;

export const FlexStartCenteredDiv = styled(CenteredDiv)`
  align-items: flex-start;

  @media (max-width: ${deviceSize.large_phone}) {
    align-items: center;
  }
`;

export const SandboxTabCard = styled(Card)`
  border: none;
  background-color: inherit;
  @media (max-width: ${deviceSize.regular_phone}) {
    > .ant-card-body {
      padding: 16px;
    }
  }
  > .ant-card-head {
    border-bottom: none;
    min-height: none;
    padding: 0;
  }
  > .ant-card-body {
    padding: 0;
  }
`;
