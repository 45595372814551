import React, { useEffect, useState } from "react";
import { Card, Tag, Tooltip, Space, Select, Avatar } from "antd";
import { BankOutlined } from "@ant-design/icons";
import { useOrganizationState } from "src/state/OrganizationState";
import { COLLECTION_DATA, LOCALSTORAGE_TYPES } from "src/utils/enums";
import {
  createOrUpdateDocInFirestore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetters,
  formatDateToHumanReadable,
  getColorForValue,
  humanize,
} from "src/utils/utils";
import { UsergroupDeleteOutlined, CalendarOutlined } from "@ant-design/icons";

import { useUserState } from "src/state/UserState";
import { TableBoardDashboard } from "../Dashboard/TableBoardDashboard/TableBoardDashboard";
import { HoverContainer } from "../CustomersDashboard/Customers.styles";
import {
  getCustomerCountsByAccount,
  updateAccountInfo,
} from "./AccountsDashboard.utilis";
import { FieldRenderer } from "../CustomersDashboard/Customers.utils";
import { AccountConverter } from "src/utils/converter";
import { Account } from "src/utils/types";

export const AccountAvatar = ({ account }) => {
  return account ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Avatar icon={<BankOutlined />} />
      <div style={{ marginLeft: "8px" }}>
        <Tooltip title={"Name"}>
          <div>{account.name}</div>
        </Tooltip>
        <Tooltip title={"Domain"}>
          <div style={{ fontSize: "12px", opacity: 0.7 }}>{account.domain}</div>
        </Tooltip>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const AccountsDashboard = () => {
  const {
    organization,
    organizationSettings,
    setOrganizationSettings,
    accounts,
    setAccounts,
  } = useOrganizationState();
  const { user } = useUserState();

  const accountsCustomFields = organizationSettings?.accountsCustomFields || [];

  const { customerStages } = organizationSettings;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(!accounts?.data);

  const [stageData, setStageData] = useState({});
  const [tableConfig, setTableConfig] = useState(
    JSON.parse(
      localStorage.getItem(LOCALSTORAGE_TYPES.ACCOUNTS_TABLE_CONFIG),
    ) || {},
  );

  // Toggle drawer visibility
  const saveConfigToLocalStorage = (config) => {
    localStorage.setItem(
      LOCALSTORAGE_TYPES.ACCOUNTS_TABLE_CONFIG,
      JSON.stringify(config),
    );
    setTableConfig(config);
  };

  // Handle switch change for each custom field
  const handleSwitchChange = (fieldKey, checked) => {
    const updatedConfig = { ...tableConfig, [fieldKey]: checked };
    saveConfigToLocalStorage(updatedConfig);
  };

  const fetchAccounts = async (stage = null) => {
    setLoading(true);

    // Set up pagination state based on the stage
    const paginationState = stage
      ? paginationPerStage[stage] || { lastDoc: null, hasMore: true }
      : {
          lastDoc: accounts?.lastDoc || null,
          hasMore: accounts?.hasMore ?? true,
        };

    // Set up filters for Firestore query
    const filters: any = [
      {
        comparisonField: "updatedAt",
        order: "desc",
      },
    ];

    if (stage) {
      if (stage === "Unassigned Stage") {
        filters.push({
          comparisonField: "currentStage",
          comparisonOperator: "==",
          value: "",
        });
      } else {
        filters.push({
          comparisonField: "currentStage",
          comparisonOperator: "in",
          value: [capitalizeFirstLetters(stage), stage.toLowerCase()],
        });
      }
    }

    // Fetch accounts from Firestore
    const docs = await getRecordsFromFireStore(
      `/${COLLECTION_DATA.ORGANIZATIONS}/${organization}/${COLLECTION_DATA.ACCOUNTS}`,
      filters,
      AccountConverter,
      undefined,
      50,
      paginationState.lastDoc,
    );

    // Get the list of account IDs from the fetched data
    const accountIds = docs.data.map((account) => account.id);

    // Use the helper function to get customer counts for each account
    const customerCounts = await getCustomerCountsByAccount(
      organization,
      accountIds,
    );

    // Add customer counts to each account
    const accountsWithCounts = docs.data.map((account) => ({
      ...account,
      customerCount: customerCounts[account.id] || 0,
    }));

    if (stage) {
      // Update stage data for pagination
      const check = accountsWithCounts.filter(
        (newAccount) =>
          !accounts.data.find((account) => account.id === newAccount.id),
      );

      const newData = [...accounts.data, ...check];

      // Update the overall accounts list to include new accounts
      setAccounts((prevState) => {
        const newAccounts = accountsWithCounts.filter(
          (newAccount) =>
            !prevState.data.find((account) => account.id === newAccount.id),
        );

        return {
          ...prevState,
          data: [...prevState.data, ...newAccounts],
        };
      });

      setPaginationPerStage((prevPagination) => ({
        ...prevPagination,
        [stage]: {
          lastDoc: docs.lastDoc || null,
          hasMore: docs.data.length >= 50 && docs.data.length > 0,
        },
      }));

      updateStageData(newData);
    } else {
      setAccounts((prevState) => {
        const filteredNewData = accountsWithCounts.filter(
          (newAccount) =>
            !prevState?.data?.find((account) => account.id === newAccount.id),
        );
        const newData = [...(prevState.data || []), ...filteredNewData];
        const hasMore = docs.data.length >= 50 && docs.data.length > 0;

        if (!hasMore) {
          setPaginationPerStage((prevPagination) => {
            const updatedPagination = { ...prevPagination };

            Object.keys(updatedPagination).forEach((stage) => {
              updatedPagination[stage] = {
                hasMore: false,
                lastDoc: null,
              };
            });
            return updatedPagination;
          });
        }

        return {
          ...prevState,
          data: newData.length > 0 ? newData : [],
          hasMore: hasMore,
          lastDoc: docs.lastDoc || null,
        };
      });

      updateStageData(docs.data);
    }

    setLoading(false);
  };

  const handleAccountClick = (account) => {
    navigate(
      `?account=${encodeURIComponent(account.id)}&domain=${encodeURIComponent(account.domain)}`,
    );
  };

  useEffect(() => {
    if (!organizationSettings || !organization) return;
    if (!accounts?.data) {
      fetchAccounts();
    } else {
      updateStageData(accounts.data);
    }
  }, [organization, accounts?.data, organizationSettings]);

  const assignStage = async (value, rowId, oldStage, selectedCustomer) => {
    const updateData: any = {
      currentStage: value,
    };

    if (oldStage !== value && Boolean(oldStage)) {
      updateData.stageHistory = [
        {
          stage: oldStage,
          updatedBy: user.email,
          timestamp: new Date().toISOString(),
        },
        ...(selectedCustomer.stageHistory ?? []),
      ];
    }

    updateAccountInfo(organization, rowId, updateData, setAccounts, {
      key: "currentStage",
    });
  };

  const columns = [
    {
      accessorFn: (row) => row.domain || row.name,
      enableEditing: false,
      header: "Account Identity",
      size: 200,
      Cell: ({ row }) => (
        <HoverContainer onClick={() => handleAccountClick(row.original)}>
          <AccountAvatar account={row.original} />
        </HoverContainer>
      ),
    },
    {
      header: "Update At",
      enableEditing: false,
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 150, // Adjust column width for better visibility
      Cell: ({ cell }) => {
        return formatDateToHumanReadable(cell.getValue());
      },
      enableColumnFilter: false,
    },
    {
      header: "# of Customers",
      enableEditing: false,
      accessorKey: "customerCount", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        return cell.getValue();
      },
      size: 100, // Adjust column width for better visibility
    },
    {
      header: "Stage",
      accessorKey: "currentStage",
      enableEditing: true, // Enable editing for this column
      Edit: ({ cell, row, table }) => {
        const stage = cell.getValue();
        const [selectedStage, setSelectedStage] = useState(stage);

        const handleChange = (value) => {
          setSelectedStage(value);
          assignStage(value, row.original.id, stage, row.original); // Pass row ID for proper updates
        };

        const handleBlur = () => {
          // Exit edit mode after losing focus
          table.setEditingCell(null);
        };

        return (
          <Select
            value={selectedStage || undefined}
            showSearch
            onChange={handleChange}
            onBlur={handleBlur}
            dropdownStyle={{ width: "20%" }} // Set dropdown width to auto
          >
            <Select.Option value="">None</Select.Option>
            {customerStages?.map((option) => (
              <Select.Option key={option} value={option}>
                {capitalizeFirstLetters(option)}
              </Select.Option>
            ))}
          </Select>
        );
      },
      Cell: ({ cell }) => {
        const stage = cell.getValue();
        return stage ? (
          <Tag color={getColorForValue(stage)}>
            {capitalizeFirstLetters(stage)}
          </Tag>
        ) : (
          ""
        );
      },
    },
    ...accountsCustomFields
      .filter((field) => tableConfig[field.key] !== false)
      .map((field) => ({
        header: humanize(field.key),
        accessorKey: field.key,
        enableEditing: field.editable,
        size: field.type === "string" ? 200 : 100, // Adjust column width for better visibility
        Edit: ({ cell, row, table }) => {
          // This renders in edit mode
          const [inputValue, setInputValue] = useState(cell.getValue());

          return (
            <FieldRenderer
              field={field}
              value={inputValue}
              onChange={(key, value) => {
                row.original[key] = value;
                setInputValue(value);
              }}
              isRowSetup={true}
              onSave={(key, value) => {
                // Immediately save the value
                row.original[key] = value;
                updateAccountInfo(
                  organization,
                  row.original.id,
                  { [key]: value },
                  setAccounts,
                  field,
                );

                // Exit edit mode
                table.setEditingCell(null);
              }}
              editMode={true}
            />
          );
        },
        Cell: ({ cell, row, table }) => {
          return (
            <Tooltip title={"Double click to edit"} arrow placement="top">
              {cell.getValue()}
            </Tooltip>
          );
        },
      })),
  ];

  const [paginationPerStage, setPaginationPerStage] = useState({});

  const updateStageData = (accountData) => {
    const newStages = {
      "Unassigned Stage": [],
      ...organizationSettings?.customerStages
        ?.map((stage) => stage.toLowerCase())
        .reduce((acc, stage) => {
          acc[stage] = [];
          return acc;
        }, {}),
    };
    accountData?.forEach((account) => {
      const stage = account?.currentStage?.toLowerCase() || "Unassigned Stage";
      if (newStages[stage]) {
        newStages[stage].push(account);
      }
    });

    setStageData(newStages);
    if (Object.keys(paginationPerStage).length === 0) {
      setPaginationPerStage((prevPagination) => {
        const updatedPagination = { ...prevPagination };

        Object.keys(newStages).forEach((stage) => {
          if (!updatedPagination[stage]) {
            updatedPagination[stage] = {
              hasMore: true,
              lastDoc: null,
            };
          }
        });
        return updatedPagination;
      });
    }
  };
  const loadMoreAccounts = async () => {
    if (!accounts.lastDoc) return;
    setLoading(true);
    try {
      fetchAccounts();
      setTimeout(() => {
        const lastBeforeNew = document.getElementById(
          `Account-${accounts.data[accounts.data.length - 2]?.id}`,
        );
        if (lastBeforeNew) {
          lastBeforeNew.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreAccountsPerStage = (stage) => {
    fetchAccounts(stage);
  };

  const onDragEnd = async (result) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) return;

    const sourceStage = source.droppableId;
    const destinationStage = destination.droppableId;

    if (
      sourceStage === destinationStage &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "Stage") {
      const stagesArray = Object.keys(stageData);
      const [removed] = stagesArray.splice(source.index, 1);
      stagesArray.splice(destination.index, 0, removed);

      setStageData((prevData) => {
        const updatedData = stagesArray.reduce((acc, stage) => {
          acc[stage] = prevData[stage];
          return acc;
        }, {});
        return updatedData;
      });

      stagesArray.splice(0, 1);
      const lowerCaseStages = stagesArray.map((stage) => stage.toLowerCase());

      setOrganizationSettings((prevSettings) => {
        return { ...prevSettings, accountStages: lowerCaseStages };
      });

      await createOrUpdateDocInFirestore(`/organization/${organization}`, {
        accountStages: stagesArray,
      });

      return;
    }

    const accountData = accounts.data.find(
      (account) => account.id === draggableId,
    );
    const updatedAccount = {
      ...accountData,
      currentStage:
        destinationStage === "Unassigned Stage" ? "" : destinationStage,
      updatedAt: new Date().toISOString(),
    };

    setAccounts((prevState) => {
      const updatedAccounts = prevState.data.map((account) => {
        if (account.id === draggableId) {
          return updatedAccount;
        }
        return account;
      });

      updatedAccounts.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });

      return {
        ...prevState,
        data: updatedAccounts,
      };
    });

    createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.ACCOUNTS}/${draggableId}`,
      updatedAccount,
    );
  };

  const renderAccountCard = (account: Account) => {
    return (
      <Card hoverable onClick={() => handleAccountClick(account)}>
        <Space direction="vertical">
          <Space>
            <Avatar icon={<BankOutlined />} />
            {account.account}
          </Space>
          <Space wrap style={{ marginTop: "8px" }}>
            <Tooltip title="# of Customers">
              <UsergroupDeleteOutlined
                style={{ marginRight: "8px", color: "#52c41a" }}
              />
              {account?.customerCounts || 0}
            </Tooltip>
            <Tooltip title="Updated At">
              <CalendarOutlined
                style={{ marginRight: "8px", color: "#52c41a" }}
              />
              {formatDateToHumanReadable(account.updatedAt)}
            </Tooltip>
          </Space>
        </Space>
      </Card>
    );
  };

  return (
    <TableBoardDashboard
      tableConfig={tableConfig}
      handleSwitchChange={handleSwitchChange}
      loading={loading}
      columns={columns}
      loadMoreFunction={loadMoreAccounts}
      loadMorePerStage={loadMoreAccountsPerStage}
      onDragEnd={onDragEnd}
      data={accounts}
      searchKey={"account"}
      stageData={stageData}
      paginationPerStage={paginationPerStage}
      renderCustomerCard={renderAccountCard}
      customFields={accountsCustomFields}
    />
  );
};
