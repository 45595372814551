import React, { useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, addUser, getRecordFromFireStore } from "src/firebaseAuth";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { Typography } from "antd";

import {
  Button,
  SignContainer,
  FormContainer,
  Form,
  DesktopOnlyButton,
  PageWrapper,
  CustomizedInput,
  LogoTitle,
  SubTitleSignIn,
  ForgotPasswordLink,
  OrDividor,
  CustomizedTextWithPadding,
  MarginTop,
  Spinner,
} from "./User.styles";
import { StyledLogInIcon } from "../NavigationBar/NavigationBar.styles";
import { GradientOrangeSpan } from "../LandingPage/LandingPage.styles";
import { color, fontSize } from "src/styles/variables";
import GoogleSignInButton from "./GoogleSignInButton/GoogleSignInButton";
import { useLocation } from "react-router-dom";
import {
  COLLECTION_DATA,
  NotificationType,
  QUERY_PARAMS,
} from "src/utils/enums";
import { notification } from "antd";
import { modifyQueryParams } from "src/utils/utils";
import { InvitedUser } from "src/utils/types";
import { updateUserDataBasedOnInvitation } from ".";
import { getUserInvitationIfWithOutCode } from "src/utils/apiCalls";
import EmailNotification from "./EmailNotification";

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [timeLeft, setTimeLeft] = useState(120); // Initialize with 120 seconds for the countdown
  const [buttonLoading, setButtonLoading] = useState(false); // Initialize with 60 seconds for the countdown

  const [api, contextHolder] = notification.useNotification();
  const googleProvider = new GoogleAuthProvider();
  const continueUrl = `${window.location.origin}/signin`;
  const searchParams = new URLSearchParams(location.search);
  const forgetPassword = JSON.parse(
    searchParams.get(QUERY_PARAMS.FORGET_PASSWORD),
  );
  const sendForgetMail = searchParams.get(
    QUERY_PARAMS.SEND_FORGET_PASSWORD_MAIL,
  );
  let invitationCode = searchParams.get(QUERY_PARAMS.INVITATION_CODE);
  const [invitationData, setInvitationData] = useState(null as InvitedUser); // Initialize with 60 seconds for the countdown

  const signWithEmailLink = isSignInWithEmailLink(auth, window.location.href);

  const openNotificationWithIcon = (
    type: NotificationType,
    msgTitle: string,
    msgContent: string,
  ) => {
    api[type]({
      message: msgTitle,
      description: msgContent,
    });
  };

  const handleForgetPassword = async (e) => {
    if (email.length > 0) {
      setButtonLoading(true);
      e.stopPropagation();
      e.preventDefault();

      sendPasswordResetEmail(auth, email, { url: continueUrl })
        .then(() => {
          const queryParams = new URLSearchParams(location.search);
          queryParams.delete(QUERY_PARAMS.FORGET_PASSWORD);
          queryParams.set(QUERY_PARAMS.SEND_FORGET_PASSWORD_MAIL, email);
          // Navigate to the same pathname with the updated query parameters
          navigate(`${location.pathname}?${queryParams.toString()}`, {
            replace: true,
          });
          openNotificationWithIcon(
            "success",
            "Email sent successfully",
            "You will receive a password recovery link at your email address within a few minutes.",
          );
        })
        .catch((error) => {
          const errorCode = error.code;
          openNotificationWithIcon("error", "Email Failed", errorCode);
        });

      setButtonLoading(false);
    }
  };

  const reSendEmail = async (e) => {
    setTimeLeft(120);
    e.stopPropagation();
    e.preventDefault();
    sendPasswordResetEmail(auth, sendForgetMail, { url: continueUrl })
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Email sent successfully",
          "You will receive a password recovery link at your email address within a few minutes.",
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        openNotificationWithIcon("error", "Email Failed", errorCode);
      });
  };
  const handleGoogleInvitationSignUp = async (
    res,
    invitationData,
    invitationCode,
  ) => {
    let updatedInvitationData = invitationData;
    if (!invitationCode) {
      const fetchedInvite = await getUserInvitationIfWithOutCode(
        res.user.email,
      );
      if (fetchedInvite) {
        invitationCode = fetchedInvite.invitationCode;
        updatedInvitationData = fetchedInvite.invitationData;
      }
    }
    await updateUserDataBasedOnInvitation(
      updatedInvitationData,
      res,
      invitationCode,
    );
    await addUser(res.user, "google");
    window.location.href = "/";
  };

  const signInWithGoogle = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      const res = await signInWithPopup(auth, googleProvider);
      // Call the separate function with the result
      await handleGoogleInvitationSignUp(res, invitationData, invitationCode);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert(error.message);
      });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    var charCode = typeof e.which == "number" ? e.which : e.keyCode;
    if (charCode === 13) {
      onLogin(e);
    }
  };

  useEffect(() => {
    let intervalId;

    if (timeLeft > 0 && sendForgetMail) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timeLeft, sendForgetMail]);

  const fetchInvitationData = async () => {
    const data = await getRecordFromFireStore(
      `${COLLECTION_DATA.INVITATIONS}/${invitationCode}`,
    );
    setInvitationData(data);
  };

  useEffect(() => {
    // Function to fetch invitation data
    if (invitationCode && signWithEmailLink) {
      fetchInvitationData();
    }
  }, [invitationCode]); // Dependency array to re-run this effect if invitationCode changes

  return (
    <PageWrapper>
      {contextHolder}
      {!sendForgetMail ? (
        <SignContainer>
          <LogoTitle>
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </LogoTitle>
          <SubTitleSignIn>
            {forgetPassword ? "Forgot Your Password?" : "Welcome"}
          </SubTitleSignIn>
          {forgetPassword && (
            <CustomizedTextWithPadding>
              Enter your email address and we will send you instructions to
              reset your password.
            </CustomizedTextWithPadding>
          )}
          <FormContainer>
            <Form>
              <CustomizedInput
                id="email-address"
                name="email"
                type="email"
                placeholder="Email"
                required={true}
                disabled={buttonLoading}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!forgetPassword ? (
                <>
                  <CustomizedInput
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  <ForgotPasswordLink>
                    <Link
                      style={{
                        color: `${color.grayDark}`,
                        textDecoration: "none",
                      }}
                      to={`/signin${modifyQueryParams(
                        `${location.search}`,
                        undefined,
                        {
                          [QUERY_PARAMS.FORGET_PASSWORD]: "true",
                        },
                      )}`}
                    >
                      Forgot password?
                    </Link>
                  </ForgotPasswordLink>

                  <MarginTop>
                    <Button type="submit" size={"fullWidth"} onClick={onLogin}>
                      Sign In
                    </Button>
                  </MarginTop>

                  <OrDividor>
                    <span>Or</span>
                  </OrDividor>

                  <GoogleSignInButton onClick={(e) => signInWithGoogle(e)} />
                </>
              ) : (
                <MarginTop>
                  <Button
                    type="submit"
                    size={"fullWidth"}
                    onClick={(e) => handleForgetPassword(e)}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? <Spinner /> : "Continue"}
                  </Button>
                </MarginTop>
              )}
            </Form>

            <Typography.Text>
              {forgetPassword ? (
                <NavLink
                  style={{ color: color.orange, textDecoration: "none" }}
                  to={`/signin${modifyQueryParams(location.search, [
                    QUERY_PARAMS.FORGET_PASSWORD,
                  ])}`}
                >
                  Back to Signin
                </NavLink>
              ) : (
                <>
                  No account yet?{" "}
                  <NavLink
                    style={{ color: color.orange }}
                    to={`/signup${modifyQueryParams(location.search)}`}
                  >
                    Sign up
                  </NavLink>
                </>
              )}
            </Typography.Text>
            <br />
            <Typography.Text
              type="secondary"
              style={{ fontSize: fontSize.body }}
            >
              By signing in, I agree to Truco's{" "}
              <Typography.Link
                href="/terms"
                style={{ fontSize: fontSize.body }}
              >
                Terms of Service
              </Typography.Link>{" "}
              and acknowledge I have read the{" "}
              <Typography.Link
                href="/privacy"
                style={{ fontSize: fontSize.body }}
              >
                Privacy Policy
              </Typography.Link>
              .
            </Typography.Text>
          </FormContainer>
        </SignContainer>
      ) : (
        <SignContainer>
          <EmailNotification
            messageText={`Please check the email address ${sendForgetMail} for
            instructions to reset your password.`}
            timeLeft={timeLeft}
            onResendEmail={reSendEmail}
          />
        </SignContainer>
      )}
    </PageWrapper>
  );
};

const OrangeButton = ({
  text = "Sign In",
  size = "normal",
  url = "/signin",
  style = {},
}) => {
  return (
    <a href={url}>
      <Button id="signin" size={size} style={style}>
        {text}
      </Button>
    </a>
  );
};

const SignInButton = () => {
  return (
    <a href={"/signin"} style={{ lineHeight: "0" }}>
      <DesktopOnlyButton id="signin" size={"normal"}>
        {"Sign In"}
      </DesktopOnlyButton>
      <StyledLogInIcon />
    </a>
  );
};

export { SignIn, OrangeButton, SignInButton };
