import { Avatar } from "antd";
import { UserAddOutlined, PhoneOutlined } from "@ant-design/icons";

import { useImageLoader } from "src/hooks/useImageLoader";
import { color } from "src/styles/variables";
import { capitalizeFirstLetter } from "src/utils/utils";

export const CollaboratorAvatar = ({
  collaborator,
  style = undefined,
  AvatarComponent = Avatar,
  avatarColor = `${color.olive}`,
  usePhoneIcon = false,
}) => {
  const { isLoading, imageError, imageUrl } = useImageLoader(
    collaborator?.photoURL,
  );
  const fallbackText = collaborator?.name
    ? collaborator.name[0]
    : collaborator?.email
      ? collaborator?.email[0]
      : null;

  const chosenStyle = {
    backgroundColor: !imageUrl || isLoading ? avatarColor : undefined,
    color: !imageUrl || isLoading ? color.white : undefined,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...style, // Allow for overriding or extending the styles
  };

  return (
    <AvatarComponent style={chosenStyle} src={imageUrl}>
      {(!imageUrl || isLoading || imageError) &&
        (fallbackText ? (
          capitalizeFirstLetter(fallbackText)
        ) : (
          <Avatar
            icon={usePhoneIcon ? <PhoneOutlined /> : <UserAddOutlined />}
          />
        ))}
    </AvatarComponent>
  );
};
