import * as React from "react";
import { Chart } from "react-google-charts";

import type { ColumnsType } from "antd/es/table";
import {
  Card,
  Statistic,
  Divider,
  Col,
  Spin,
  Tooltip,
  Space,
  Empty,
} from "antd";
import { humanize, formatTime, unformatTime } from "src/utils/utils";
import { useNavigate } from "react-router-dom";

import {
  RATING_VALUES,
  ANALYSIS_LABEL,
  ANALYSIS_TYPE,
  ANALYSIS_ID,
  ACCESS_TYPE,
  SENTIMENT,
} from "src/utils/enums";
import { TooltipIcon } from "../Global";
import { color, fontSize } from "src/styles/variables";
import { StyledSegmented } from "./BusinessInsights";

export enum METRIC_SCORE_ITEMS {
  TOTAL_DURATION = "Total Duration",
  AVERAGE_DURATION = "Average Duration",
  TOTAL_TRANSCRIPTS = "Total Transcripts",
  VERIFIED_PERCENTAGE = "Verified Percentage",
  TOTAL_AGENTS = "Total Agents",
  TOTAL_REVIEWERS = "Total Reviewers",
  OVERALL_RISK = "Overall Risk",
  OVERALL_SCORE = "Overall Score",
  SCORE = "Score",
  USER = "User",
}

export function getScoreKeysFromAnalysisScore(analysisScores, showRisk) {
  if (showRisk) {
    const scoreKey = METRIC_SCORE_ITEMS.OVERALL_RISK;
    const score =
      100 -
        analysisScores?.[ANALYSIS_ID.OVERALL]?.[ANALYSIS_LABEL.SCORE]?.[
          ANALYSIS_LABEL.SCORE
        ] || 10;
    const antiScoreKey = METRIC_SCORE_ITEMS.OVERALL_SCORE;
    return {
      scoreKey,
      score,
      antiScoreKey,
    };
  } else {
    const scoreKey = METRIC_SCORE_ITEMS.OVERALL_SCORE;
    const score =
      analysisScores?.[ANALYSIS_ID.OVERALL]?.[ANALYSIS_LABEL.SCORE]?.[
        ANALYSIS_LABEL.SCORE
      ] || 10;
    const antiScoreKey = METRIC_SCORE_ITEMS.OVERALL_RISK;
    return {
      scoreKey,
      score,
      antiScoreKey,
    };
  }
}

export function getWeeklyGraphData(insights, showRisk) {
  const scoreType = showRisk ? "Risk" : "Score";
  const sentimentsData = [["Week", "Neutral", "Positive", "Negative"]];
  const overallScoreData = [["Week", `Overall ${scoreType}`]];

  insights?.allInsightsData?.length > 0 &&
    insights?.allInsightsData?.forEach((week) => {
      const neutralPercentage =
        week?.totalTranscripts > 0
          ? (week.sentiments[SENTIMENT.NEUTRAL] * 100) / week?.totalTranscripts
          : 0;
      const positivePercentage =
        week?.totalTranscripts > 0
          ? (week.sentiments[SENTIMENT.POSITIVE] * 100) / week?.totalTranscripts
          : 0;
      const negativePercentage =
        week?.totalTranscripts > 0
          ? (week.sentiments[SENTIMENT.NEGATIVE] * 100) / week?.totalTranscripts
          : 0;
      sentimentsData.push([
        week.id,
        neutralPercentage,
        positivePercentage,
        negativePercentage,
      ]);
      const { score } = getScoreKeysFromAnalysisScore(
        week?.analysisScores,
        showRisk,
      );
      overallScoreData.push([week.id, score]);
    });

  return { sentimentsData, overallScoreData };
}

export const TopMetricsComponent = ({ insights, showRisk }) => {
  const { scoreKey, score, antiScoreKey } = getScoreKeysFromAnalysisScore(
    insights.analysisScores,
    showRisk,
  );
  const showCollaborations =
    insights.qaMetrics || insights.leadMetrics || insights.agentMetrics;
  const spanValue = showCollaborations ? 6 : 8;
  return (
    <>
      <Col xl={{ span: spanValue }} xs={24}>
        <Card key={"Duration"} title={"Duration"} style={{ height: 300 }}>
          <Statistic
            title={"Average Duration"}
            value={formatTime(
              insights.totalDuration / insights.totalTranscripts,
            )}
          />
          <Divider />
          <Statistic
            title={"Total Duration"}
            value={formatTime(insights.totalDuration)}
          />
        </Card>
      </Col>
      {showCollaborations && (
        <Col xl={{ span: 6 }} xs={24}>
          <Card
            key={"Collaborators"}
            title={"Collaborators"}
            style={{ height: 300 }}
          >
            <Chart
              chartType="PieChart"
              options={{
                pieHole: 0.4,
                title: `Out of ${
                  insights.qaMetrics.length +
                  insights.agentMetrics.length +
                  insights.leadMetrics.length
                } Users`,
              }}
              data={[
                ["Type", "Count"],
                ["Agents", insights.agentMetrics.length],
                ["Reviewers", insights.qaMetrics.length],
                ["Leads", insights.leadMetrics.length],
              ]}
            />
          </Card>
        </Col>
      )}
      <Col xl={{ span: spanValue }} xs={24}>
        <Card key={scoreKey} title={scoreKey} style={{ height: 300 }}>
          <Chart
            chartType="PieChart"
            data={[
              ["Type", "Count"],
              [scoreKey, score],
              [antiScoreKey, 100 - score],
            ]}
            options={{
              pieHole: 0.4,
              title: `Out of ${insights.totalTranscripts} conversations`,
            }}
          />
        </Card>
      </Col>
      <Col xl={{ span: spanValue }} xs={24}>
        <Card
          key={"Customer Sentiment"}
          title={"Customer Sentiment"}
          style={{ height: 300 }}
        >
          <Chart
            chartType="PieChart"
            data={[["Type", "Count"], ...Object.entries(insights.sentiments)]}
            options={{
              pieHole: 0.4,
              title: `Out of ${insights.totalTranscripts} conversations`,
            }}
          />
        </Card>
      </Col>
    </>
  );
};

export const WeeklyOverViewGraphs = ({
  // sentiments,
  overallScoreData,
  scoreType,
  spanWidth = 12,
}) => {
  return (
    <>
      <Col xl={{ span: spanWidth }} xs={24}>
        <Card
          key={`Weekly ${scoreType} Analysis`}
          title={`Weekly ${scoreType} Analysis`}
          style={{ height: "500px", alignItems: "center" }}
        >
          <Spin spinning={!overallScoreData}>
            {overallScoreData.length > 1 ? (
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={overallScoreData}
                options={{
                  curveType: "function",
                  legend: { position: "bottom" },
                  hAxis: {
                    title: "Week",
                    slantedText: true,
                    slantedTextAngle: 45,
                    textStyle: {
                      fontSize: 11, // Adjust font size to prevent cutoff
                    },
                  },
                  vAxis: {
                    title: "Percentage",
                  },
                  chartArea: {
                    left: 50,
                    top: 50,
                    width: "80%",
                    height: "62%", // Adjust height for mobile
                  },
                }}
              />
            ) : (
              <Empty description="No Data yet" />
            )}
          </Spin>
        </Card>
      </Col>
    </>
  );
};
export const EvaluationSummaryCard = ({
  currentInsightData,
  showRisk,
  spanWidth = 24,
  height = undefined,
}) => {
  const navigate = useNavigate();
  if (currentInsightData.totalTranscripts <= 0) return <></>;

  return (
    <Col xl={{ span: spanWidth }} xs={24}>
      <Card
        title={
          <span>
            Evaluation Summary{" "}
            <Tooltip
              title={`Shows ${
                showRisk ? "Risk" : "Quality"
              } for each category, along with individual metrics within each category. Hover over a score to see the number of conversations it is based on.`}
            >
              <TooltipIcon />
            </Tooltip>
          </span>
        }
        style={{ overflowY: "scroll", height: height }}
      >
        {currentInsightData.analysisScores && (
          <Space direction="vertical" style={{ maxHeight: "50vh" }}>
            {Object.keys(currentInsightData.analysisScores).map(
              (analysisType, index) => {
                const analysisData =
                  currentInsightData.analysisScores[analysisType];
                const options = Object.keys(analysisData)
                  .map((metric) => {
                    if (metric === "score") return null;
                    if (
                      analysisType === ANALYSIS_ID.OVERALL &&
                      isNaN(analysisData[metric]["score"])
                    )
                      return null;

                    return {
                      label: (
                        <Tooltip
                          title={`Out of ${analysisData[metric]["count"]} conversations`}
                          key={`${analysisType}-${metric}-tooltip`}
                        >
                          <div
                            style={{
                              width: "140px",
                              height: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                            key={analysisType + metric}
                            onClick={() => {
                              navigate(`/conversations`, {
                                state: {
                                  selectedColumn: metric,
                                  removeUser: true,
                                },
                              });
                            }}
                          >
                            <div
                              style={{
                                color: color.black,
                                whiteSpace: "wrap",
                              }}
                            >
                              {humanize(metric)}
                            </div>
                            <div
                              style={{
                                fontSize: fontSize.displayXL,
                                color:
                                  analysisData[metric]["score"] > 85
                                    ? color.green
                                    : analysisData[metric]["score"] > 70
                                      ? color.yellow
                                      : color.red,
                              }}
                            >
                              {isNaN(analysisData[metric]["score"])
                                ? "-"
                                : `${
                                    showRisk
                                      ? (
                                          100 - analysisData[metric]["score"]
                                        ).toFixed(0)
                                      : analysisData[metric]["score"]
                                  }%`}
                            </div>
                          </div>
                        </Tooltip>
                      ),
                      value: metric,
                    };
                  })
                  .filter((opt) => opt);

                const allOptions = [
                  {
                    label: (
                      <Tooltip
                        title={`Out of ${analysisData["score"]["count"]} conversations`}
                        key={`${analysisType}-overall-tooltip`}
                      >
                        <div
                          style={{
                            width: "140px",
                            height: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "rgba(0, 0, 0, 0.88)",
                              whiteSpace: "wrap",
                            }}
                          >
                            {humanize(analysisType)}
                          </div>
                          <div
                            style={{
                              fontSize: fontSize.displayXL,
                              color:
                                analysisData["score"]["score"] > 85
                                  ? color.green
                                  : analysisData["score"]["score"] > 70
                                    ? color.yellow
                                    : color.red,
                            }}
                          >
                            {`${
                              showRisk
                                ? (
                                    100 - analysisData["score"]["score"]
                                  ).toFixed(0)
                                : analysisData["score"]["score"]
                            }%`}
                          </div>
                        </div>
                      </Tooltip>
                    ),
                    value: humanize(analysisType),
                  },
                  ...options,
                ];

                return (
                  <StyledSegmented
                    options={allOptions}
                    key={`segmented-${analysisType}`}
                    disabled={true}
                    style={{ width: "100%", overflowX: "scroll" }}
                  />
                );
              },
            )}
          </Space>
        )}
      </Card>
    </Col>
  );
};

export const trucoMetricColumns: ColumnsType<{
  metricName: string;
  accuracy: number;
  totalCount: number;
}> = [
  {
    title: "Metric Name",
    dataIndex: "metricName",
    sorter: (a, b) => a.metricName.localeCompare(b.metricName),
  },
  {
    title: "Accuracy",
    dataIndex: "accuracy",
    defaultSortOrder: "descend", // This is correct
    sorter: (a, b) => a.accuracy - b.accuracy,
  },
  {
    title: "Total Verified Transcripts",
    dataIndex: "totalCount",
    sorter: (a, b) => a.totalCount - b.totalCount,
  },
];
export function getTrucoMetricsFromTrucoScore(trucoScores, analysisScores) {
  const data = Object.keys(analysisScores).reduce((acc, analysisLabel) => {
    const analysisId = analysisScores[analysisLabel][ANALYSIS_LABEL.SCORE]?.id;
    const metrics = Object.keys(analysisScores[analysisLabel]).flatMap(
      (metricLabel) => {
        if (metricLabel === ANALYSIS_LABEL.SCORE) return [];
        const metricScore = analysisScores[analysisLabel][metricLabel];
        if (!metricScore?.verified) return [];
        const metricId = metricScore?.id;
        return {
          metricName: metricLabel,
          accuracy: parseFloat(
            (
              ((metricScore.verified -
                (trucoScores[`${analysisId}.${metricId}`] ?? 0)) /
                metricScore.verified) *
              100
            ).toFixed(0),
          ),
          totalCount: metricScore.verified,
        };
      },
    );

    return acc.concat(metrics);
  }, []);

  return data;
}

// TODO remove no longer used (Insights )
export function getUserMetrics(transcripts, showRisk, accessType) {
  const groupedData = transcripts.reduce((acc, curr) => {
    const agent = curr?.[accessType]?.name;
    if (
      !agent ||
      ["null", "undefined", "none", ""].includes(agent.toLowerCase())
    )
      return acc;

    const scoreEntry = curr.analysis.find(
      (a) => a.id === ANALYSIS_LABEL.OVERALL,
    );
    if (scoreEntry?.[ANALYSIS_LABEL.SCORE] === "N/A") return acc;

    const overallScore = parseFloat(scoreEntry?.[ANALYSIS_LABEL.SCORE]);
    if (isNaN(overallScore)) return acc;

    acc[agent] = acc[agent] || {
      totalDuration: 0,
      overallScore: 0,
      totalCount: 0,
      user: curr?.[accessType],
    };
    acc[agent].totalDuration += unformatTime(curr.duration);
    acc[agent].totalCount += 1;
    acc[agent].overallScore += overallScore;

    return acc;
  }, {});

  return Object.keys(groupedData)
    .map((agent) => ({
      [METRIC_SCORE_ITEMS.USER]: groupedData[agent].user,
      [METRIC_SCORE_ITEMS.TOTAL_DURATION]: formatTime(
        groupedData[agent].totalDuration,
      ),
      [METRIC_SCORE_ITEMS.AVERAGE_DURATION]: formatTime(
        groupedData[agent].totalDuration / groupedData[agent].totalCount,
      ),
      [METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]: groupedData[agent].totalCount,
      [METRIC_SCORE_ITEMS.SCORE]: showRisk
        ? parseFloat(
            (
              100 -
              (groupedData[agent].overallScore * 100) /
                groupedData[agent].totalCount
            ).toFixed(0),
          )
        : parseFloat(
            (
              (groupedData[agent].overallScore * 100) /
              groupedData[agent].totalCount
            ).toFixed(0),
          ),
    }))
    .sort((a, b) => {
      if (a[METRIC_SCORE_ITEMS.SCORE] !== b[METRIC_SCORE_ITEMS.SCORE]) {
        return showRisk
          ? a[METRIC_SCORE_ITEMS.SCORE] - b[METRIC_SCORE_ITEMS.SCORE]
          : b[METRIC_SCORE_ITEMS.SCORE] - a[METRIC_SCORE_ITEMS.SCORE];
      } else if (
        a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] !==
        b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
      ) {
        return (
          b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] -
          a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
        );
      } else {
        return (
          unformatTime(a[METRIC_SCORE_ITEMS.AVERAGE_DURATION]) -
          unformatTime(b[METRIC_SCORE_ITEMS.AVERAGE_DURATION])
        );
      }
    });
}

export function getQAAgentMetrics(transcripts) {
  const groupedData = transcripts.reduce((acc, curr) => {
    const reviewer = curr?.[ACCESS_TYPE.reviewer]?.name;
    if (
      !reviewer ||
      ["null", "undefined", "none", ""].includes(reviewer.toLowerCase())
    )
      return acc;

    if (!acc[reviewer]) {
      acc[reviewer] = {
        totalDuration: 0,
        evaluatedCount: 0,
        totalCount: 0,
        user: curr?.[ACCESS_TYPE.reviewer],
      };
    }

    acc[reviewer].totalDuration += unformatTime(curr.duration);
    acc[reviewer].totalCount += 1;
    if (curr?.evaluation?.verified) {
      acc[reviewer].evaluatedCount += 1;
    }
    return acc;
  }, {});

  return Object.keys(groupedData)
    .map((reviewer) => ({
      [METRIC_SCORE_ITEMS.USER]: groupedData[reviewer].user,
      [METRIC_SCORE_ITEMS.TOTAL_DURATION]: formatTime(
        groupedData[reviewer].totalDuration,
      ),
      [METRIC_SCORE_ITEMS.SCORE]: parseFloat(
        (
          (groupedData[reviewer].evaluatedCount /
            groupedData[reviewer].totalCount) *
          100
        ).toFixed(0),
      ),
      [METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]: groupedData[reviewer].totalCount,
    }))
    .sort((a, b) => {
      if (
        a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] !==
        b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
      ) {
        return (
          b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] -
          a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
        );
      } else if (a[METRIC_SCORE_ITEMS.SCORE] !== b[METRIC_SCORE_ITEMS.SCORE]) {
        return b[METRIC_SCORE_ITEMS.SCORE] - a[METRIC_SCORE_ITEMS.SCORE];
      } else {
        return (
          unformatTime(b[METRIC_SCORE_ITEMS.TOTAL_DURATION]) -
          unformatTime(a[METRIC_SCORE_ITEMS.TOTAL_DURATION])
        );
      }
    });
}
// todo remove after fix
export function getTrucoMetrics(transcripts, analysisScores) {
  const verifiedTranscripts = transcripts.filter(
    (transcript) => transcript?.evaluation?.verified,
  );
  const trucoScores = verifiedTranscripts.reduce((acc, curr) => {
    const updates = curr?.evaluation?.updates;
    Object.keys(updates).forEach((update) => {
      if (updates[update]?.old_rating !== updates[update]?.updated_rating) {
        if (!acc[update]) {
          acc[update] = 0;
        }
        acc[update] += 1;
      }
    });
    return acc;
  }, {});

  const data = Object.keys(analysisScores).reduce((acc, analysisLabel) => {
    const analysisId = analysisScores[analysisLabel][ANALYSIS_LABEL.SCORE]?.id;
    const metrics = Object.keys(analysisScores[analysisLabel]).flatMap(
      (metricLabel) => {
        if (metricLabel === ANALYSIS_LABEL.SCORE) return [];
        const metricScore = analysisScores[analysisLabel][metricLabel];
        if (!metricScore?.verified) return [];
        const metricId = metricScore?.id;
        return {
          metricName: metricLabel,
          accuracy: parseFloat(
            (
              ((metricScore.verified -
                (trucoScores[`${analysisId}.${metricId}`] ?? 0)) /
                metricScore.verified) *
              100
            ).toFixed(0),
          ),
          totalCount: metricScore.verified,
        };
      },
    );

    return acc.concat(metrics);
  }, []);

  return data;
}

export function getAnalysisScores(transcripts) {
  const analysisScores = {};
  transcripts.forEach((transcriptI, idx) => {
    const analysisI = transcriptI?.analysis;
    const verifiedTranscript = transcriptI?.evaluation?.verified ? 1 : 0;
    if (Object.keys(analysisI).length === 0) return;
    analysisI.forEach((analysisGroup, analysisIndex) => {
      const analysisType = analysisGroup?.label
        ? analysisGroup?.label
        : humanize(analysisGroup?.id);

      analysisScores[analysisType] = analysisScores[analysisType] ??= {
        [ANALYSIS_LABEL.SCORE]: {
          count: 0,
          total: 0,
          verified: 0,
          id: analysisGroup?.id,
        },
      };

      analysisScores[analysisType][ANALYSIS_LABEL.SCORE]["verified"] +=
        verifiedTranscript;
      if (analysisGroup?.[ANALYSIS_LABEL.SCORE] !== RATING_VALUES.NONE) {
        analysisScores[analysisType][ANALYSIS_LABEL.SCORE]["count"] += 1;
        analysisScores[analysisType][ANALYSIS_LABEL.SCORE]["total"] +=
          analysisGroup?.[ANALYSIS_LABEL.SCORE];
      }

      analysisGroup?.value.forEach((metricGroup) => {
        const metricType = metricGroup?.label
          ? metricGroup?.label
          : humanize(metricGroup?.id);

        analysisScores[analysisType][metricType] = analysisScores[analysisType][
          metricType
        ] ??= {
          count: 0,
          total: 0,
          verified: 0,
          id: metricGroup?.id,
        };
        analysisScores[analysisType][metricType]["verified"] +=
          verifiedTranscript;
        if (metricGroup?.[ANALYSIS_TYPE.RATING] !== null) {
          analysisScores[analysisType][metricType]["count"] += 1;
          analysisScores[analysisType][metricType]["total"] +=
            metricGroup?.[ANALYSIS_TYPE.RATING];
        }
      });
    });
  });
  Object.keys(analysisScores).forEach((analysisType) => {
    Object.keys(analysisScores[analysisType]).forEach((metricType) => {
      analysisScores[analysisType][metricType][ANALYSIS_LABEL.SCORE] = Number(
        (
          (analysisScores[analysisType][metricType]["total"] * 100) /
          analysisScores[analysisType][metricType]["count"]
        ).toFixed(0),
      );
    });
  });

  return analysisScores;
}

export function getAggregateValueFromTrancripts(transcripts) {
  const results = transcripts.reduce(
    (acc, curr) => {
      acc.totalDuration += unformatTime(curr.duration);
      if (curr.evaluation?.verified) {
        acc.verifiedCount++;
      }
      const sentiment = curr.sentiments?.average?.sentiment;
      if (sentiment) {
        acc.sentiments[sentiment]++;
      }

      return acc;
    },
    {
      totalDuration: 0,
      verifiedCount: 0,
      sentiments: {
        [SENTIMENT.NEUTRAL]: 0,
        [SENTIMENT.POSITIVE]: 0,
        [SENTIMENT.NEGATIVE]: 0,
      },
    },
  );

  return {
    totalDuration: results.totalDuration,
    totalVerifiedCount: results.verifiedCount,
    sentiments: results.sentiments,
  };
}
export const processClipsData = (data) => {
  // Helper function to format the date as "DD MMM"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  };

  // Create the header row with the playlist titles
  const headerRow = ["Week/Date", ...data.map((p) => p.playlistTitle)];

  // Initialize the result array with the header row
  const result = [headerRow];

  // Create a map to track the clip counts per date for each playlist
  const datePlaylistMap = {};

  // Populate the map with all dates and playlist counts
  data.forEach((playlist) => {
    playlist.clipsData.forEach((clip) => {
      const formattedDate = formatDate(clip.created);

      // Initialize the date entry if not present
      if (!datePlaylistMap[formattedDate]) {
        datePlaylistMap[formattedDate] = {};
      }

      // Initialize playlist entry for that date if not present
      if (!datePlaylistMap[formattedDate][playlist.playlistTitle]) {
        datePlaylistMap[formattedDate][playlist.playlistTitle] = 0;
      }

      // Add the clip count for the playlist at the specific date
      datePlaylistMap[formattedDate][playlist.playlistTitle] += 1;
    });
  });

  // Get a sorted array of all unique dates
  const uniqueDates = Object.keys(datePlaylistMap).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime(),
  );

  // Generate the result array based on the map
  uniqueDates.forEach((date) => {
    const row = [date]; // Start with the date

    // Add the clip counts for each playlist on that date
    data.forEach((playlist) => {
      // If the playlist has clips on this date, add the count, otherwise add 0
      row.push(datePlaylistMap[date][playlist.playlistTitle] || 0);
    });

    result.push(row); // Add the row to the result array
  });

  return result;
};

export function transformPlaylistDataForChart(playlistData) {
  const tagCountsByDate = {};

  // Step 1: Process each clip in the playlist to extract tag counts by date
  playlistData?.clipsData?.forEach((clip) => {
    const { created, clipData } = clip;
    const date = new Date(created).toISOString().split("T")[0]; // Extract date (YYYY-MM-DD)
    const tags = JSON.parse(clipData).tags; // Assuming clipData contains an array of tags

    // Ensure the date exists in the tagCountsByDate object
    if (!tagCountsByDate[date]) {
      tagCountsByDate[date] = {};
    }

    // Count each tag occurrence for the date
    tags?.forEach((tag) => {
      if (!tagCountsByDate[date][tag]) {
        tagCountsByDate[date][tag] = 0;
      }
      tagCountsByDate[date][tag] += 1;
    });
  });

  // Step 2: Extract all unique tags from the processed data
  const allTags = new Set();
  Object.values(tagCountsByDate)?.forEach((tagsOnDate) => {
    Object.keys(tagsOnDate)?.forEach((tag) => allTags.add(tag));
  });
  const tagsArray = Array.from(allTags);

  // Step 3: Create chart data format
  const chartData = [["Date", ...tagsArray]]; // First row: ['Date', 'Tag1', 'Tag2', ...]

  // Fill the rows with the date and tag counts
  Object.entries(tagCountsByDate)?.forEach(([date, tagsOnDate]) => {
    const row = [date]; // Start with the date
    tagsArray?.forEach((tag: string) => {
      row.push(tagsOnDate[tag] || 0); // Push the count for each tag, or 0 if not present
    });
    chartData.push(row); // Add the row to chartData
  });

  return chartData;
}

export function transformResultsToRawData(
  results: Record<string, Record<string, { count: number; id: string }>>,
) {
  // Calculate categories on the fly
  const categories = new Set<string>();
  Object.values(results).forEach((dayData) => {
    Object.keys(dayData).forEach((category) => categories.add(category));
  });
  const categoriesArray = Array.from(categories).sort();

  const rawData: (string | number)[][] = [["date", ...categoriesArray]];
  const playlists = {};
  let currentCategory = null;

  for (const [date, values] of Object.entries(results)) {
    const row: (string | number)[] = [date];
    for (const category of categoriesArray) {
      row.push(values[category]?.count || 0);
      if (values[category]) {
        playlists[category] = values[category].id;
        currentCategory = values[category].id;
      }
    }
    rawData.push(row);
  }

  return {
    currentTrendData: rawData,
    currentPlaylists: Object.entries(playlists).map(([title, id]) => ({
      id,
      title,
    })),
    currentCategory: currentCategory,
  };
}
