import React, { useMemo } from "react";
import { Chart } from "react-google-charts";
import { color } from "src/styles/variables";

// Fills in missing dates with zero counts
const fillMissingDates = (dailyCounts, numDays) => {
  const filledCounts = [];
  const today = new Date();
  for (let i = 0; i < numDays; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const dateStr = date.toISOString().split("T")[0];
    const existing = dailyCounts.find(([d]) => d === dateStr);
    filledCounts.unshift(existing || [dateStr, 0]);
  }
  return filledCounts;
};

export const PlaylistTrendCard = ({ dailyCounts, numDays = 30 }) => {
  // Generate initial data with zero counts
  const initialData = useMemo(() => {
    const zeroCounts = [];
    const today = new Date();
    for (let i = 0; i < numDays; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const dateStr = date.toISOString().split("T")[0];
      zeroCounts.unshift([dateStr, 0]);
    }
    return [["Date", "Clips"], ...zeroCounts];
  }, [numDays]);

  // Fill missing dates to ensure continuity with actual data
  const filledData = useMemo(
    () => fillMissingDates(dailyCounts, numDays),
    [dailyCounts, numDays],
  );

  // Decide which data to display: initial data or filled data based on dailyCounts availability
  const chartData = dailyCounts.length
    ? [["Date", "Clips"], ...filledData]
    : initialData;

  return (
    <Chart
      width={numDays > 30 ? "100%" : "200px"}
      height={numDays > 30 ? "100px" : "50px"}
      chartType="ColumnChart"
      loader={<div>Loading Chart...</div>}
      data={chartData}
      options={{
        hAxis: {
          textPosition: "none",
          gridlines: { color: "transparent" },
          baselineColor: "transparent",
          ticks: [],
        },
        vAxis: {
          textPosition: "none",
          gridlines: { color: "transparent" },
          baselineColor: "transparent",
          ticks: [],
        },
        legend: { position: "none" },
        axisTitlesPosition: "none",
        chartArea: {
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
        tooltip: { trigger: "hover" },
        backgroundColor: "transparent",
        colors: [color.orange],
        animation: { startup: true, duration: 1000, easing: "out" },
      }}
    />
  );
};
