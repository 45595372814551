import { FileTextOutlined } from "@ant-design/icons";
import { Space, Typography, Tag, Spin, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  generatePlaylistShareableLink,
  getMimeTypeFix,
} from "../Playlist.utils";
import { capitalizeFirstLetter, formatTime } from "src/utils/utils";
import { ACCESS_TYPE } from "src/utils/enums";
import { DeleteWithPopConfirm, StyledBorderedTag } from "src/components/Global";
import { useUserState } from "src/state/UserState";
import Thumbnail from "./Thumbnail/Thumbnail";
import { useOrganizationState } from "src/state/OrganizationState";
import { TagModal } from "./TagModal";
import { fetchFileUrl } from "src/components/Dashboard/Dashboard.utils";

export const PlaylistClips = ({
  clips = [],
  handleDeleteClip,
  playlistId = null,
  disabledTags = false,
}) => {
  const navigate = useNavigate();
  const { user } = useUserState();
  const { organization } = useOrganizationState();
  const [selectedTag, setSelectedTag] = useState(null);
  const [clipsWithUrls, setClipsWithUrls] = useState([]);
  const [loadingUrls, setLoadingUrls] = useState(true);

  const handleClipClick = (clip) => {
    if (!disabledTags)
      navigate(
        `${generatePlaylistShareableLink(
          organization,
          playlistId,
        )}?clip=${encodeURIComponent(clip.id)}`,
      );
  };

  useEffect(() => {
    if (Array.isArray(clips)) {
      const addUrlsToClips = async () => {
        setLoadingUrls(true);
        const clipsWithFetchedUrls = await Promise.all(
          clips.map(async (clip) => ({
            ...clip,
            url: await fetchFileUrl(clip.conversationId),
          })),
        );
        setClipsWithUrls(clipsWithFetchedUrls);
        setLoadingUrls(false);
      };
      addUrlsToClips();
    } else {
      setClipsWithUrls([]);
      setLoadingUrls(false);
    }
  }, [clips]);

  // Define table columns with conditional rendering based on disabledTags flag
  const columns = [
    {
      title: "Preview",
      dataIndex: "url",
      key: "preview",
      render: (url, clip) =>
        clip?.mimeType && getMimeTypeFix(clip.mimeType).includes("text") ? (
          <FileTextOutlined style={{ fontSize: "24px" }} />
        ) : (
          <Thumbnail
            url={url}
            start={clip.start}
            end={clip.end}
            type={getMimeTypeFix(clip.mimeType)}
            clipId={`${clip.id}-${clip.conversationId}`}
          />
        ),
      width: 100,
    },
    {
      title: "Details",
      key: "details",
      render: (_, clip) => (
        <Space direction="vertical">
          <Typography.Text strong>
            {capitalizeFirstLetter(clip.title || "Untitled")}
          </Typography.Text>
          <Typography.Text type="secondary">
            {clip.summary || "No summary available"}
          </Typography.Text>
          <Typography.Text type="secondary">
            Last updated:{" "}
            {clip.updatedAt ? new Date(clip.updatedAt).toLocaleString() : ""}
          </Typography.Text>
        </Space>
      ),
      width: disabledTags ? "70%" : "50%", // Expand width when disabledTags is true
      responsive: ["sm" as const],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags) =>
        tags && tags.length > 0 ? (
          tags.map((tag) =>
            disabledTags ? (
              <Tag key={tag} color="default">
                {tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            ) : (
              <StyledBorderedTag
                key={tag}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedTag(tag);
                }}
              >
                <span>{tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}</span>
              </StyledBorderedTag>
            ),
          )
        ) : (
          <Typography.Text type="secondary">No tags</Typography.Text>
        ),
      width: 120,
      responsive: ["md" as const],
    },
    {
      title: "Participants",
      dataIndex: "participants",
      key: "participants",
      render: (participants) =>
        participants && participants.length > 0 ? (
          <Space wrap>
            {participants.map((participant, index) => (
              <Tag key={index}>{participant}</Tag>
            ))}
          </Space>
        ) : (
          <Typography.Text type="secondary">No participants</Typography.Text>
        ),
      width: 120,
      responsive: ["md" as const],
    },
    !disabledTags && {
      title: "Duration",
      dataIndex: "end",
      key: "duration",
      render: (_, clip) =>
        clip?.timeline?.length > 0
          ? `${clip.timeline.length} Clips`
          : formatTime(clip.end - clip.start),
      width: 120,
      responsive: ["sm" as const],
    },
    !disabledTags && {
      title: "Actions",
      key: "actions",
      render: (_, clip) => (
        <Space>
          {DeleteWithPopConfirm(
            "Delete Clip",
            "Are you sure you want to delete this clip?",
            (e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDeleteClip(clip.id);
            },
            clip.user !== user?.email ||
              user.accessType === ACCESS_TYPE.agent ||
              user.accessType === ACCESS_TYPE.lead,
          )}
        </Space>
      ),
      width: 100,
    },
  ].filter(Boolean); // Filter out undefined columns when disabledTags is true

  return (
    <>
      {loadingUrls ? (
        <Spin spinning={loadingUrls}>Loading clips...</Spin>
      ) : (
        <Table
          dataSource={clipsWithUrls}
          columns={columns}
          rowKey={(clip) => clip.id}
          pagination={false} // Disable pagination
          onRow={(clip) => ({
            onClick: () => handleClipClick(clip),
          })}
        />
      )}
      {selectedTag && (
        <TagModal
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          playlistId={playlistId}
        />
      )}
    </>
  );
};
