import styled from "styled-components";
import { Table, Button, Tag, Collapse } from "antd";
import { SpaceBetweenDiv } from "src/components/Profile/Profile.styles";

import {
  color,
  spacing,
  deviceSize,
  borderRadius,
  elementSize,
  hexToRgba,
} from "src/styles/variables";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { AutoAwesome, Theaters } from "@mui/icons-material";

export const EditableText = styled.span`
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const UpdatedTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HoveredSpan = styled.span`
  align-items: center;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;

  ${(props) =>
    props.numLines &&
    `
    text-overflow: ellipsis;
    -webkit-line-clamp: ${props.numLines}; /* number of lines to show */
  `}

  &:hover {
    overflow: visible;
    -webkit-line-clamp: unset; /* Remove line clamping on hover */
    display: block; /* Change display on hover to show all content */
  }
`;

export const ResponsiveTable = styled(Table)`
  @media (max-width: ${deviceSize.large_phone}),
    (min-width: ${deviceSize.xl}) and (max-width: ${deviceSize.xxl}) {
    .ant-table-cell {
      display: block;
      width: 100% !important;
      justify-content: center !important;
      border-bottom: none !important;
      text-align: center !important;
      vertical-align: center !important;
    }
    .ant-table-cell:last-child {
      border-bottom: 1px solid ${color.gray} !important;
      padding-bottom: ${spacing.lg} !important;
    }
  }
`;

export const AnalysisTableComponent = styled(Table)`
  margin-top: ${spacing.md} !important;
  .ant-table-tbody > tr.ant-table-row.normalRow {
    background-color: #ffff !important;
  }

  .ant-table-tbody > tr.ant-table-row.groupHeaderRow {
    background-color: #f2f2f2 !important;
  }
`;

export const TeamTitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: ${color.orange};
  }
`;

export const TeamTitleSection = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
`;
export const NumberSection = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${color.orange};
`;

export const StyledAttachmentList = styled.div`
  padding: 10px;
  white-space: nowrap; // Prevent wrapping of items
  max-width: 100%; // Ensure the list does not exceed the parent container width
  max-height: 20vh;
  overflow-y: auto; // Enable vertical scrolling
  scrollbar-width: none; // For Firefox
  &::-webkit-scrollbar {
    display: none; // For Chrome, Safari, and Opera
  }

  @media (max-width: ${deviceSize.large_phone}) {
    white-space: normal; // Allow wrapping
  }
`;

export const AttachmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.grayLight};
  border: 1px solid ${color.gray};
  padding: ${elementSize.xs};
  border-radius: ${borderRadius.xs};
  gap: ${elementSize.xs};
`;

export const IconWrapper = styled.span`
  font-size: 20px;
`;
export const AttachedFileName = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::first-letter {
    text-transform: uppercase;
  }
  @media (max-width: ${deviceSize.large_phone}) {
    text-overflow: ellipsis;
    width: 50vw;
  }
`;

export const EditableIcon = styled(EditOutlined)`
  &:hover,
  &:active {
    color: ${color.orange};
  }
`;
export const SaveIcon = styled(SaveOutlined)`
  &:hover,
  &:active {
    color: ${color.orange};
  }
`;
export const StyledFilterButton = styled(({ filtersapplied, ...props }) => (
  <Button {...props} />
))`
  ${(props) => {
    const { filtersapplied } = props;
    return filtersapplied
      ? `
        background-color: ${color.lightOrange};
        color: ${color.white};
        border: 1px solid ${color.lightOrange};
        .anticon {
          color: ${color.white};
        }
        &&:hover {
          background-color: ${color.lightOrange} !important;
          color: ${color.white} !important;
        }
      `
      : `
        background-color: transparent;
        color: inherit;
        border: none;
        .anticon {
          color: ${color.lightOrange};
        }
        &&:hover {
          background-color: transparent !important;
          color: inherit !important;
        }
      `;
  }}
  box-shadow: none;
`;

export const FilterContainer = styled.div`
  padding-bottom: 3px;
  margin-bottom: 3px;
   }
`;
export const OrgContainer = styled.div`
  width: 100%;
  background-color: ${color.white};
  margin-bottom: ${elementSize.ml};
  flex-direction: row;
  @media (max-width: ${deviceSize.regular_phone}) {
    flex-direction: column;
    gap: ${elementSize.sm};
    justify-content: center;
  }
`;
// export const OrgContainer = styled.div`
//   display: flex; /* Make it a flex container */
//   width: 100%;
//   background-color: ${color.white};
//   margin-bottom: ${elementSize.ml};
//   gap: ${elementSize.sm}; /* Add some space between children */

//   @media (max-width: ${deviceSize.regular_phone}) {
//     flex-direction: column;
//     gap: ${elementSize.sm};
//     justify-content: center;
//   }
// `;

// export const CalendarCard = styled.div`
//   flex: 2; /* Take up 2/3 of the space */
//   /* Add any other styles specific to CalendarCard */
// `;

export const OrgContainerHeader = styled(SpaceBetweenDiv)`
  margin: ${elementSize.sm} 0px ${elementSize.ml} ${elementSize.xs};
  @media (max-width: ${deviceSize.regular_phone}) {
    flex-direction: column;
    gap: ${elementSize.sm};
    justify-content: center;
  }
`;

export const DashboardActionsCard = styled.div`
  // flex: 1;
  background: ${hexToRgba(color.grayLight, 0.6)};
  padding: ${elementSize.sm} ${elementSize.ml};
  border-radius: ${borderRadius.md};
  border: 1px solid ${hexToRgba(color.grayLight, 0.6)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${deviceSize.regular_phone}) {
    flex-direction: column;
    gap: ${elementSize.sm};
    justify-content: center;
    align-items: flex-start;
  }
`;

export const DashboardCalendarCollapseCard = styled(Collapse)`
  // flex: 1;
  background: ${hexToRgba(color.grayLight, 0.6)};
  padding: ${elementSize.sm} ${elementSize.ml};
  border-radius: ${borderRadius.md};
  border: 1px solid ${hexToRgba(color.grayLight, 0.6)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${deviceSize.regular_phone}) {
    flex-direction: column;
    gap: ${elementSize.sm};
    justify-content: center;
    align-items: flex-start;
  }

  .ant-collapse-item {
    width: 100%;
  }
  .ant-collapse-content {
    background-color: ${hexToRgba(color.grayLight, 0.6)} !important;
    margin-top: 10px !important;
  }

  .ant-collapse-header {
    padding-left: 0 !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
`;

export const CustomerDiv = styled(Tag)`
  cursor: pointer;
  &:hover {
    color: ${color.orange};
  }
`;
const getStyledIcon = (IconComponent) => styled(IconComponent)`
  cursor: pointer;
  position: absolute;
  top: ${(props) => (props.isAudio ? "-10px" : "10px")};
  left: ${(props) => (props.isAudio ? "-3px" : "10px")};
  background-color: ${color.white};
  border-radius: 50%;
  padding: ${(props) => (props.isAudio ? "3px" : "6px")};
  width: ${(props) => (props.isAudio ? "18px !important" : "auto")};
  height: ${(props) => (props.isAudio ? "18px !important" : "auto")};
  border: 0.7px solid ${color.orange};
  color: ${color.orange};
  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: ${color.orange};
    color: ${color.white};
  }
`;

export const HighlightsIcon = getStyledIcon(AutoAwesome);
export const FullVideoIcon = getStyledIcon(Theaters);
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
