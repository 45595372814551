import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Menu, Spin } from "antd";
import { StandardPage } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { OrganizationSettingCard, ProfileContainer } from "./Profile.styles";
import { ACCESS_TYPE } from "src/utils/enums";
import { getAccountStats } from "src/utils/apiCalls";
import { OrganizationSettingComponent } from "./Components/Settings/OrganizationSettingComponent";
import { Col, Row } from "antd";
import { IntegrationsCard } from "./Components/Intergrations/IntegrationsCard";
import { CollaboratorsCard } from "./Components/Collaborators/CollaboratorsCard";
import OrganizationCard from "./Components/OrganizationCard";
import { ProfileCard } from "./Components/ProfileCard";
import useFetchAssignments from "src/hooks/useFetchAssignments";
import useFetchCalibrations from "src/hooks/useFetchCalibrations";
import useFetchSignals from "src/hooks/useFetchSignals";
import { menuItems } from "./Profile.constants";
import { ProfileTabs, ProfileDocs } from "./Profile.enums";
import { EXCLUDED_ORGANIZATIONAL_SETTINGS } from "./Components/Intergrations/Integrations.variables";
import { SubscriptionCard } from "./Components/SubscriptionCard/SubscriptionCard";

export const Profile = () => {
  const { organization, organizationSettings, evaluationForms } =
    useOrganizationState();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUserStatistics } = useUserState();
  const [loadingSetting, setLoadingSetting] = useState(false);
  const [current, setCurrent] = useState<string>(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (Object.values(ProfileTabs).includes(tab as ProfileTabs)) {
      return tab;
    } else {
      return ProfileTabs.SETTINGS;
    }
  });
  const { assignments, fetchAssignments } = useFetchAssignments();
  const { calibrations, fetchCalibrations } = useFetchCalibrations();
  const { signals, fetchSignals } = useFetchSignals();

  const hasAccessToEdit =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;
  useEffect(() => {
    organization && getAccountStats(organization, user, setUserStatistics);
  }, [organization, user, setUserStatistics]);

  useEffect(() => {
    if (current === ProfileTabs.ASSIGNMENTS) {
      fetchAssignments();
    }
    if (current === ProfileTabs.CALIBRATIONS) {
      fetchCalibrations();
    }
    if (current === ProfileTabs.EVALUATION) {
      fetchSignals();
    }
    const params = new URLSearchParams(location.search);
    params.set("tab", current);
    navigate({ search: params.toString() }, { replace: true });
  }, [current, organization]);

  const profileContent = (
    <ProfileContainer>
      <Row justify="space-between">
        <Col xs={24} md={24} lg={5}>
          <Menu
            onClick={({ key }) => {
              if (key === "Home") {
                navigate("/");
              } else {
                setCurrent(key);
              }
            }}
            selectedKeys={[current]}
            mode="inline"
            items={menuItems}
            style={{
              borderRight: "none",
            }}
          />
        </Col>
        <Col xs={24} md={24} lg={18}>
          <Flex vertical gap={20}>
            <ProfileCard />
            {current === ProfileTabs.SETTINGS && (
              <Spin spinning={loadingSetting}>
                <OrganizationSettingCard title={ProfileTabs.SETTINGS}>
                  {Object.keys(organizationSettings ?? {})
                    .filter(
                      (settingsKey) =>
                        !EXCLUDED_ORGANIZATIONAL_SETTINGS.includes(settingsKey),
                    )
                    .map((settingsKey, index) => {
                      return (
                        <OrganizationSettingComponent
                          settingsKey={settingsKey}
                          key={index + "setting"}
                          hasAccessToEdit={hasAccessToEdit}
                          setLoadingSetting={setLoadingSetting}
                        />
                      );
                    })}
                </OrganizationSettingCard>
              </Spin>
            )}
            {current === ProfileTabs.INTEGRATIONS && <IntegrationsCard />}
            {current === ProfileTabs.SUBSCRIPTION && <SubscriptionCard />}
            {current === ProfileTabs.COLLABORATORS && <CollaboratorsCard />}

            {current === ProfileTabs.ASSIGNMENTS && (
              <OrganizationCard
                title={ProfileTabs.ASSIGNMENTS}
                docsUrl={ProfileDocs.ASSIGNMENTS}
                items={assignments}
                path="/assignments"
                type={ProfileTabs.ASSIGNMENTS}
              />
            )}
            {current === ProfileTabs.CALIBRATIONS && (
              <OrganizationCard
                title={ProfileTabs.CALIBRATIONS}
                docsUrl={ProfileDocs.CALIBRATIONS}
                items={calibrations}
                path="/calibrations"
                type={ProfileTabs.CALIBRATIONS}
              />
            )}
            {current === ProfileTabs.EVALUATION && (
              <>
                <OrganizationCard
                  title={ProfileTabs.EVALUATION}
                  docsUrl={ProfileDocs.EVALUATION}
                  items={evaluationForms}
                  path="/evaluation"
                  type={ProfileTabs.EVALUATION}
                />
                <OrganizationCard
                  title={ProfileTabs.SIGNALS}
                  docsUrl={ProfileDocs.SIGNALS}
                  items={signals}
                  path="/signals"
                  type={ProfileTabs.SIGNALS}
                />
              </>
            )}
          </Flex>
        </Col>
      </Row>
    </ProfileContainer>
  );

  return <StandardPage content={profileContent} />;
};
